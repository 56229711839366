import { Button } from '@compass/components'
import { Grid, Link, Paper, Skeleton, TextField, Typography } from '@mui/material'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { Plus, TrashIcon } from 'lucide-react'
import { useState } from 'react'

import { DEFAULT_ENVIRONMENT, DOCS_MULTIPLE_ENVIRONMENTS_URL } from '../../../../const'
import { usePermissions, useToast } from '../../../../hooks'
import { useEntityLimit } from '../../../../hooks/api/context'
import { useWorkspaceEnvironments } from '../../../../hooks/api/environment'
import { useEncryptionKeys, useSubscriptionTokens } from '../../../../hooks/api/tokens'
import { ApiKey, Limits, WorkspaceEnvironment } from '../../../../models'
import { useCurrentSubscription } from '../../../subscription'
import CreateWorkspaceEnvironmentDialog from '../CreateEnvironmentDialog/CreateWorkspaceEnvironmentDialog'
import DeleteWorkspaceEnvironmentDialog from '../DeleteEnvironmentDialog/DeleteWorkspaceEnvironmentDialog'
import styles from './WorkspaceEnvironmentTable.module.scss'

export function WorkspaceEnvironmentTable() {
  const { showToast } = useToast()
  const { isReadOnly } = usePermissions()
  const { currentSubscriptionId } = useCurrentSubscription()
  const maxEnvironmentsLimit = useEntityLimit(currentSubscriptionId, Limits.WorkspaceEnvironments)

  const [isDeleteEnvironmentDialogOpen, setIsDeleteEnvironmentDialogOpen] = useState(false)
  const [isCreateEnvironmentDialogOpen, setIsCreateEnvironmentDialogOpen] = useState(false)
  const [selectedEnv, setSelectedEnv] = useState<WorkspaceEnvironment | null>(null)

  const { data: workspaceEnvironmentsData, isLoading } = useWorkspaceEnvironments(currentSubscriptionId)

  const envLimitReached =
    !isLoading && workspaceEnvironmentsData != null && maxEnvironmentsLimit <= workspaceEnvironmentsData.length

  //TODO Need to get api keys related only to the environment that is being checked to be deleted
  const { data: apiKeysData, isLoading: isLoadingApiKeys } = useSubscriptionTokens(currentSubscriptionId)
  const { data: encryptionKeysData, isLoading: isLoadingEncryptionKeys } = useEncryptionKeys(currentSubscriptionId)

  const apiKeyPerEnv: { [id: string]: boolean } = {}
  apiKeysData &&
    apiKeysData.forEach((apiKey: ApiKey) => {
      if (apiKey.workspaceEnvironmentId) {
        apiKeyPerEnv[apiKey.workspaceEnvironmentId] = true
      }
    })

  const encryptionKeyPerEnv: { [id: string]: boolean } = {}
  encryptionKeysData &&
    encryptionKeysData.forEach((encryptionKey: ApiKey) => {
      if (encryptionKey.workspaceEnvironmentId) {
        encryptionKeyPerEnv[encryptionKey.workspaceEnvironmentId] = true
      }
    })

  const handleDeleteEnvironment = (workspaceEnvironment: WorkspaceEnvironment) => {
    setSelectedEnv(workspaceEnvironment)
    setIsDeleteEnvironmentDialogOpen(true)
  }

  const onAddEnvironment = () => {
    setIsCreateEnvironmentDialogOpen(true)
  }

  const onCloseDialog = () => {
    setIsCreateEnvironmentDialogOpen(false)
    setIsDeleteEnvironmentDialogOpen(false)
  }

  const canBeDeleted = (workspaceEnvironment: WorkspaceEnvironment) => {
    return (
      !isReadOnly &&
      workspaceEnvironment.name !== DEFAULT_ENVIRONMENT &&
      apiKeysData &&
      !apiKeyPerEnv[workspaceEnvironment.id] &&
      encryptionKeysData &&
      !encryptionKeyPerEnv[workspaceEnvironment.id]
    )
  }

  const alertDeleteBlocked = (workspaceEnvironment: WorkspaceEnvironment) => {
    let message: string
    if (isReadOnly) {
      message = "You don't have permission to update environments configuration"
    } else if (workspaceEnvironment.name === DEFAULT_ENVIRONMENT) {
      message = 'The default environment cannot be deleted.'
    } else {
      message =
        'Environments with API keys cannot be deleted. You must delete all API keys under this environment to delete it.'
    }

    showToast({
      severity: 'warning',
      message: message,
    })
  }

  return (
    <ContentColumn>
      <SubHeader
        title='Environments'
        description={
          <>
            <Typography variant='bodyM'>
              Use environments to contain API keys and request filtering rules, like &quot;staging&quot;.&nbsp;
              <Link href={DOCS_MULTIPLE_ENVIRONMENTS_URL} target='_blank' underline='hover'>
                Learn more
              </Link>
            </Typography>
          </>
        }
        actions={
          <Button onPress={onAddEnvironment} isDisabled={isReadOnly || envLimitReached}>
            <Plus />
            Create environment
          </Button>
        }
      />

      <Paper className='p-6'>
        <Grid container spacing={2}>
          {isLoading || isLoadingApiKeys || isLoadingEncryptionKeys ? (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Skeleton height={56} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Skeleton height={56} />
              </Grid>
            </>
          ) : null}
          {!isLoading &&
            !isLoadingApiKeys &&
            !isLoadingEncryptionKeys &&
            workspaceEnvironmentsData?.map((workspaceEnvironment, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TextField
                  value={workspaceEnvironment.name}
                  fullWidth
                  className={styles.environmentField}
                  InputProps={{
                    endAdornment: (
                      <div
                        className={`${styles.deleteIcon} ${canBeDeleted(workspaceEnvironment) ? '' : styles.disabled}`}
                      >
                        <Button
                          isIcon
                          variant='ghost'
                          aria-label='Delete environment'
                          onPress={
                            canBeDeleted(workspaceEnvironment)
                              ? () => handleDeleteEnvironment(workspaceEnvironment)
                              : () => alertDeleteBlocked(workspaceEnvironment)
                          }
                        >
                          <TrashIcon />
                        </Button>
                      </div>
                    ),
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Paper>
      {workspaceEnvironmentsData && isCreateEnvironmentDialogOpen && (
        <CreateWorkspaceEnvironmentDialog open onClose={onCloseDialog} environments={workspaceEnvironmentsData} />
      )}
      {isDeleteEnvironmentDialogOpen && selectedEnv && (
        <DeleteWorkspaceEnvironmentDialog open onClose={onCloseDialog} workspaceEnvironment={selectedEnv} />
      )}
    </ContentColumn>
  )
}
