import { Skeleton } from '@mui/material'
import keyBy from 'lodash/keyBy'
import { useEffect, useMemo, useState } from 'react'

import {
  useUserSubscriptionSettings,
  useUserSubscriptionSettingsUpdate,
} from '../../../../hooks/api/useUserSubscriptionSettings'
import { SubscriptionStatistic } from '../../../../models'
import { DataCard } from '../../../commonUI'
import styles from '../SubscriptionOverview/SubscriptionOverview.module.scss'
import { insightFilter, replaceByIndex } from './formatters'
import { InsightsAction } from './InsightsAction'
import { InsightsNumericWidget } from './InsightsNumericWidget'
import { useInsightsStats } from './useInsightsStats'

export function NumericWidgets({ subscriptionId }: { subscriptionId?: string }) {
  const { data: userSettings, isLoading: settingsLoading } = useUserSubscriptionSettings(
    subscriptionId,
    'metric_list_preference'
  )
  const { data, error, isLoading: statsLoading, availability, dateRange } = useInsightsStats()
  const { mutate: updateUserSettings } = useUserSubscriptionSettingsUpdate({ revertOnError: false })

  const [toDisplay, setToDisplay] = useState<SubscriptionStatistic[]>([])

  useEffect(() => {
    if (userSettings) {
      setToDisplay(
        userSettings
          .filter(insightFilter)
          .map((metric) => metric.statKey)
          .slice(0, 4)
      )
    }
  }, [userSettings])

  const statistics = useMemo(() => keyBy(data, 'type'), [data])
  const all = useMemo(() => Object.keys(statistics) as SubscriptionStatistic[], [statistics])
  const unavailable = useMemo(() => new Set(toDisplay), [toDisplay])

  if (!subscriptionId || settingsLoading || statsLoading) {
    return (
      <div className={styles.insightsWidgets}>
        {[1, 2, 3, 4].map((_, idx) => (
          <DataCard title={<Skeleton width={100} height={20} className='transform-none' />} key={`skel_${idx}`}>
            <Skeleton width={200} height={28} className='transform-none' />
          </DataCard>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.insightsWidgets}>
      {toDisplay
        .filter((type) => statistics?.[type] != null)
        .map((type, index) => {
          const title = (
            <InsightsAction
              blockIndex={index}
              selected={type}
              all={all}
              unavailable={unavailable}
              onSelect={(selected: SubscriptionStatistic) => {
                if (selected != null) {
                  if (userSettings) {
                    updateUserSettings({
                      params: { subscriptionId },
                      data: {
                        settings_name: 'metric_list_preference',
                        settings: replaceByIndex(userSettings, index, {
                          statKey: selected,
                          statType: 'insight',
                        }),
                      },
                    })
                  }
                  setToDisplay((oldValues) => {
                    const newValue = [...oldValues]
                    newValue[index] = selected
                    return newValue
                  })
                }
              }}
            />
          )

          return (
            <InsightsNumericWidget
              key={`widget_${index}`}
              title={title}
              type={type}
              isError={error}
              statistic={statistics?.[type]}
              availabilityBoundary={availability?.[type]}
              compareDateRange={dateRange}
            />
          )
        })}
    </div>
  )
}
