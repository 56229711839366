import { useQuery } from '@tanstack/react-query'
import { Product } from 'models'

import { extractData, useBuildRequest } from './api/base'
import { useAuth } from './user'

export function usePrices(products: Product[] = [Product.ApiCalls]) {
  const { isAuthorized } = useAuth()
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['prices'],
    queryFn: () =>
      extractData(
        buildRequest('pricesGet', {
          queryParams: {
            product: products,
          },
        })
      ),
    enabled: isAuthorized,
    gcTime: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000,
  })
}

export function usePrice(product: Product) {
  const { data: prices, isLoading } = usePrices()
  // we want to show default price only if the price for the product is not found on the server,
  // otherwise show loader to avoid blinking of different prices
  const price = prices?.[product]
  return { price, isLoading }
}
