import { IncorrectETLDPlusOneWithOriginData } from 'models'
import { useMemo } from 'react'

import DistributionWidget from '../../../commonUI/components/Distributions/DistributionWidget'

export type MismatchedDomainsDistributionProps = {
  data?: IncorrectETLDPlusOneWithOriginData[]
  isError?: boolean
}

export default function MismatchedDomainsDistribution({ data, isError }: MismatchedDomainsDistributionProps) {
  const filteredData = useMemo(() => data?.filter((it) => it.invalidRequestsCount > 0) ?? [], [data])

  const distribution = useMemo(() => {
    if (!filteredData?.length) {
      return undefined
    }

    return filteredData.map((it) => ({
      totalEvents: it.invalidRequestsCount.toString(),
      domain: it.originEtldPlusOne,
    }))
  }, [filteredData])

  return (
    <DistributionWidget
      hideHeader
      isError={isError}
      distribution={distribution}
      title='Mismatched Domains'
      valueLabel='events'
      keyLabel='domain'
      labelKey='domain'
      rowProps={{
        labelColor: 'hsl(var(--fpds-color-red-8))',
        background: 'hsl(var(--fpds-color-red-3))',
      }}
    />
  )
}
