import { Button } from '@compass/components'
import { Grid, InputLabel, Paper, TextField, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { AppRoute, buildRoute } from 'appRoutes'
import FullpageLoader from 'components/Loader/FullpageLoader/FullpageLoader'
import { Stepper } from 'components/Stepper/Stepper'
import {
  GenericError,
  IS_PRODUCTION_MODE,
  PRO_PLUS_99_BILLING_DATA,
  PRO_PLUS_BILLING_DATA,
  REGIONS,
  SUBSCRIPTION_TIER_TO_DISPLAY_PLAN,
  SubscriptionDisplayPlan,
} from 'const'
import { PlanCard } from 'features/planManagement'
import { useCurrentSubscription } from 'features/subscription'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { domainNameRegex } from 'helpers/regex'
import { useUserContext } from 'hooks/api/context'
import { BillingData, PaymentMethod, PurchaseData, SubscriptionTier } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import PaymentForm from '../PaymentForm/PaymentForm'
import PurchaseConfirmation from '../PurchaseConfirmation/PurchaseConfirmation'
import RegionSelector from '../RegionSelector/RegionSelector'
import styles from './SubscriptionCreateForm.module.scss'

const availableRegions = Object.values(REGIONS)

enum Step {
  PlanSelection,
  Info,
  Payment,
  Confirmation,
}

const STEP_LABELS: Record<Step, string> = {
  [Step.PlanSelection]: 'Select Plan',
  [Step.Info]: 'Info',
  [Step.Payment]: 'Payment',
  [Step.Confirmation]: 'Confirmation',
}

export type CreateSubscriptionFormData = {
  name: string
  domain?: string
  regionCode: string
  cardHolderName?: string
  paymentMethodId?: Pick<PaymentMethod, 'id'>
  tier?: SubscriptionTier
  billingCycleAnchor?: Date
}

export type Props = {
  error?: GenericError | null
  isLoading?: boolean
  paymentMethods: PaymentMethod[]
  billingData?: BillingData
  purchaseData?: PurchaseData
  onSubmit: (data: CreateSubscriptionFormData) => void
}

export default function SubscriptionCreateForm({
  error,
  isLoading,
  paymentMethods = [],
  billingData,
  purchaseData,
  onSubmit,
}: Props) {
  const formMethods = useForm<CreateSubscriptionFormData>()
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = formMethods

  const [activeStep, setActiveStep] = useState(Step.PlanSelection)
  const { currentSubscriptionId } = useCurrentSubscription()

  const tier = watch('tier')
  const { data: userContext } = useUserContext()
  const queryClient = useQueryClient()

  const isEligibleForAndroidFreemium = userContext?.isEligibleForAndroidFreemium

  const tierPrice = useMemo(() => {
    if (tier === SubscriptionTier.Plus) {
      return PRO_PLUS_BILLING_DATA
    }

    if (tier === SubscriptionTier.Plus99 || tier === SubscriptionTier.Plus99AndroidFreemium) {
      return PRO_PLUS_99_BILLING_DATA
    }

    return billingData
  }, [tier, billingData])

  useEffect(() => {
    if (error?.param === 'domain' || error?.param === 'name') {
      setActiveStep(Step.Info)
    }
  }, [error])

  useEffect(() => {
    if (purchaseData) {
      queryClient.invalidateQueries({ queryKey: ['userContext'] })
      setActiveStep(Step.Confirmation)
    }
  }, [purchaseData, queryClient])

  return (
    <div className={styles.root}>
      <Stepper
        steps={Object.values(STEP_LABELS).map((label) => ({ label }))}
        activeStep={activeStep}
        onMoveBack={activeStep === Step.Confirmation ? undefined : () => setActiveStep(activeStep - 1)}
        action={activeStep === Step.Confirmation ? undefined : 'Cancel'}
        actionTo={buildRoute(AppRoute.SubscriptionOverview, { subscriptionId: currentSubscriptionId })}
      >
        <div className={styles.container}>
          {activeStep === Step.PlanSelection && (
            <>
              <Typography variant='h1Widget' data-testid='createAppFormHeader'>
                Create a workspace
              </Typography>
              <div className={styles.selector}>
                <PlanCard
                  key='plus'
                  plan={
                    isEligibleForAndroidFreemium
                      ? SubscriptionDisplayPlan.Plus99AndroidFreemium
                      : SubscriptionDisplayPlan.Plus99
                  }
                  priceLabel='Everything you need to get started.'
                  priceSubtitleContent='20,000 API calls incl. then $4 per 1,000'
                  action='Select Pro Plus'
                  onAction={() => {
                    setValue(
                      'tier',
                      isEligibleForAndroidFreemium ? SubscriptionTier.Plus99AndroidFreemium : SubscriptionTier.Plus99
                    )
                    setActiveStep(Step.Info)
                  }}
                />
                <PlanCard
                  key='enterprise'
                  plan={SubscriptionDisplayPlan.Enterprise}
                  priceLabel='Build your own plan.'
                  action='Request pricing'
                  actionHref={AppRoute.ContactSalesEnterprise}
                  variant='contained'
                />
              </div>
            </>
          )}

          {activeStep !== Step.Confirmation && (
            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit((payload: CreateSubscriptionFormData) => {
                  onSubmit({ ...payload })
                })}
              >
                <div hidden={activeStep !== Step.Info}>
                  <Typography variant='h1' className={styles.heading}>
                    General Info
                  </Typography>

                  <Paper className={styles.generalInfo}>
                    <Grid container columnSpacing={2} rowSpacing={1} className={styles.formContent}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor='applicationName'>What is your workspace name?</InputLabel>
                        <TextField
                          fullWidth
                          id='applicationName'
                          type='text'
                          placeholder='My workspace'
                          variant='outlined'
                          {...muiRegister(register, 'name', {
                            required: 'Workspace name required',
                            maxLength: { value: 80, message: 'Workspace names can only be 80 characters long.' },
                          })}
                          {...getErrorParams('name', errors, error)}
                          inputProps={{ 'aria-label': 'applicationName' }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel htmlFor='domainName'>What is your domain name? (Optional)</InputLabel>
                        <TextField
                          fullWidth
                          id='domainName'
                          type='text'
                          placeholder='workspace.com'
                          variant='outlined'
                          {...muiRegister(register, 'domain', {
                            pattern: { value: domainNameRegex, message: 'Invalid domain name' },
                          })}
                          {...getErrorParams('domain', errors, error)}
                          inputProps={{ 'aria-label': 'domainName' }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <RegionSelector
                          regions={availableRegions}
                          className={styles.regionSelector}
                          source={'account settings'}
                        />
                      </Grid>

                      {!IS_PRODUCTION_MODE && (
                        <Grid item xs={12}>
                          <InputLabel htmlFor='billingCycleAnchor'>
                            Billing Cycle Ends At (Test Environment Only)
                          </InputLabel>
                          <TextField
                            fullWidth
                            id='billingCycleAnchor'
                            type='datetime-local'
                            variant='outlined'
                            {...muiRegister(register, 'billingCycleAnchor')}
                            inputProps={{ 'aria-label': 'billingCycleAnchor' }}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Button
                      isDisabled={isLoading}
                      className={styles.button}
                      aria-label='Proceed to Payment'
                      size='lg'
                      onPress={async () => {
                        const isPassingValidation = (await Promise.all([trigger('name'), trigger('domain')])).every(
                          (field) => field
                        )

                        if (isPassingValidation) {
                          setActiveStep(activeStep + 1)
                        }
                      }}
                    >
                      Create
                    </Button>
                  </Paper>
                </div>

                {activeStep === Step.Payment && (
                  <PaymentForm
                    displayPlan={tier ? SUBSCRIPTION_TIER_TO_DISPLAY_PLAN[tier] : undefined}
                    isLoading={isLoading}
                    error={error}
                    paymentMethods={paymentMethods}
                    billingData={tierPrice}
                  />
                )}
              </form>
            </FormProvider>
          )}
        </div>

        {activeStep === Step.Confirmation && purchaseData && (
          <PurchaseConfirmation purchaseData={purchaseData} billingData={tierPrice} />
        )}
      </Stepper>

      {isLoading && <FullpageLoader />}
    </div>
  )
}
