import * as fpjsReact from '@fingerprintjs/fingerprintjs-pro-react'
import { AppRoute } from 'appRoutes'
import { isOnboardingInProgress } from 'features/onboarding'
import { errorWithFallback } from 'helpers/data'
import { useAuth, useDocumentTitle, useHomepageRedirect, useLogInMutation, useOAuth, useSsoStatusCheck } from 'hooks'
import { useQueryParams } from 'hooks/queryParams'
import { useSavedEmail } from 'hooks/savedEmail'
import { UserConsentModel } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { FakeDashboardInfoLayout } from '../../components/AnimatedLayout/FakeDashboardInfoLayout'
import { LogInForm, LogInFormProps } from '../../components/LogInForm/LogInForm'

export function LogInPage() {
  useHomepageRedirect()
  useDocumentTitle('Log In')

  const { mutate: sendLogInRequest, isPending, error } = useLogInMutation()
  const { setCredentials } = useAuth()
  const history = useHistory()
  const { redirect_to } = useQueryParams()

  const { savedEmail, rememberEmail } = useSavedEmail()
  const [email, setEmail] = useState(savedEmail)
  const [userConsent, setUserConsent] = useState<Partial<UserConsentModel>>({})

  const { data: visitorData, isLoading: isLoadingFpjs, getData } = fpjsReact.useVisitorData({ extendedResult: true })
  const handleCheckSso = useCallback((emailAddress: string, termsOfService: boolean, privacyPolicy: boolean) => {
    setEmail(emailAddress)
    setUserConsent({
      termsOfService,
      privacyPolicy,
    })
  }, [])

  const state = {
    redirectUrl: redirect_to,
    ...userConsent,
  }
  const { data: ssoStatus } = useSsoStatusCheck(email, state)
  const { data: oAuthProviders, isLoading: isLoadingOAuth } = useOAuth()

  const onSubmit = useCallback<LogInFormProps['onSubmit']>(
    async (payload) => {
      if (ssoStatus?.sso.isEnabled) {
        if (ssoStatus.sso.ssoLink) {
          rememberEmail(payload.email)
          window.location.assign(ssoStatus.sso.ssoLink)
        }
      } else {
        // Additional visitor id call is needed to send behavioral signals collected from the form
        // Unhandled errors are suppressed because we don't need to handle or know about them
        getData({ ignoreCache: true }).then(undefined, () => undefined)

        const fpjsVisitorId = visitorData?.visitorId
        sendLogInRequest(
          { data: { ...payload, fpjsVisitorId } },
          {
            onSuccess: (result) => {
              setCredentials({ accessToken: result.accessToken, refreshToken: result.refreshToken })
              rememberEmail(payload.email)

              ampli.userLoggedIn({ loginType: 'password' })

              const onboardingStep = result.context.onboardingStep
              if (isOnboardingInProgress(onboardingStep)) {
                history.replace(AppRoute.Onboarding)
                return
              }

              history.replace(redirect_to ?? '/')
            },
          }
        )
      }
    },
    [
      visitorData,
      ssoStatus?.sso.isEnabled,
      ssoStatus?.sso.ssoLink,
      rememberEmail,
      sendLogInRequest,
      history,
      redirect_to,
      setCredentials,
      getData,
    ]
  )

  return (
    <FakeDashboardInfoLayout srcPage='login'>
      <LogInForm
        isLoading={isLoadingFpjs || isPending}
        isLoadingOAuth={isLoadingOAuth}
        error={errorWithFallback(error, 'email', 'Invalid email or password')}
        onSubmit={onSubmit}
        isSsoEnabled={!!ssoStatus?.sso.isEnabled}
        onCheckSso={handleCheckSso}
        oAuthProviders={oAuthProviders}
      />
    </FakeDashboardInfoLayout>
  )
}
