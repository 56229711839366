import { DateTime } from 'luxon'
import { ChartAggregation } from 'models'

const monthFormat = 'LLL yyyy'
const dayFormat = 'dd LLL yyyy'
const dateTimeFormat = 'dd LLL yyyy HH:mm'

export function formatChartTimestamp(dt?: DateTime, granularity?: ChartAggregation) {
  if (dt == null) {
    return ''
  }

  if (granularity == null) {
    return dt.toFormat(dateTimeFormat)
  }

  switch (granularity) {
    case 'month':
      return dt.toFormat(monthFormat)

    case 'day':
      return dt.toFormat(dayFormat)

    case 'hour':
      return dt.toFormat(dateTimeFormat)
  }
}
