import { Button, ButtonGroup } from '@compass/components'
import { calculateGranularity, useDateRangeContext } from 'features/commonUI'
import { calculateUnavailableGranularities } from 'helpers/dateRange'
import { ChartAggregation } from 'models'
import { useEffect } from 'react'

type GranularitySelectorProps = {
  value?: ChartAggregation
  onChange: (granularity: ChartAggregation) => void
}

export function GranularitySelector({ value, onChange }: GranularitySelectorProps) {
  const { dateRange } = useDateRangeContext()
  const { startDate, endDate } = dateRange ?? {}
  const defaultGranularity = calculateGranularity(startDate, endDate) as ChartAggregation | undefined

  const { hourlyUnavailable, dailyUnavailable, monthlyUnavailable } = calculateUnavailableGranularities(
    startDate,
    endDate
  )

  const selected = value ?? defaultGranularity

  useEffect(() => {
    if (value == null && defaultGranularity != null) {
      onChange(defaultGranularity)
    } else if (
      defaultGranularity != null &&
      ((value === 'hour' && hourlyUnavailable) ||
        (value === 'day' && dailyUnavailable) ||
        (value === 'month' && monthlyUnavailable))
    ) {
      onChange(defaultGranularity)
    }
  }, [dailyUnavailable, defaultGranularity, hourlyUnavailable, monthlyUnavailable, onChange, value])

  return (
    <ButtonGroup>
      <Button
        variant='secondary'
        size='sm'
        isDisabled={hourlyUnavailable}
        onPress={() => onChange('hour')}
        tone={selected === 'hour' ? 'background2' : undefined}
        className={selected === 'hour' ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Use hourly aggregation'
      >
        Hourly
      </Button>
      <Button
        variant='secondary'
        size='sm'
        isDisabled={dailyUnavailable}
        onPress={() => onChange('day')}
        tone={selected === 'day' ? 'background2' : undefined}
        className={selected === 'day' ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Use daily aggregation'
      >
        Daily
      </Button>
      <Button
        variant='secondary'
        size='sm'
        isDisabled={monthlyUnavailable}
        onPress={() => onChange('month')}
        tone={selected === 'month' ? 'background2' : undefined}
        className={selected === 'month' ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Use monthly aggregation'
      >
        Monthly
      </Button>
    </ButtonGroup>
  )
}
