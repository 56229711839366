import type { AriaToastProps } from '@react-aria/toast'
import { useToast } from '@react-aria/toast'
import { ToastState } from '@react-stately/toast'
import { AlertTriangleIcon, CheckCircleIcon, InfoIcon, OctagonAlertIcon, XIcon } from 'lucide-react'
import { useRef } from 'react'
import { tv, VariantProps } from 'tailwind-variants'
import { ToastParams } from 'toast'

import { Button } from '../Button/Button'
import { focusRing } from '../utils'

interface ToastProps<T> extends AriaToastProps<T> {
  closeButton?: boolean
  state: ToastState<T>
}

export const toastStyles = tv({
  extend: focusRing,
  base: [
    'max-w-full sm:max-w-96',
    'flex items-center justify-stretch gap-3',
    'ml-4 px-4 pr-3 py-3',
    'rounded-lg border',
    'text-base text-white',
    'shadow-toast',
  ],
  variants: {
    state: {
      entering: 'animate-in fade-in motion-safe:slide-in-from-bottom ease duration-300',
      queued: '',
      exiting: 'animate-out fade-out motion-safe:slide-out-to-bottom ease-in-out-quart duration-300',
    },
    variant: {
      default: ['bg-white text-gray-1000'],
      info: ['bg-blue-800 border-blue-900'],
      success: ['bg-green-800 border-green-900 [&_svg]:text-white'],
      warning: ['bg-yellow-800 border-yellow-900 text-gray-1000'],
      error: ['bg-red-800 border-red-900 [&_svg]:text-white'],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

type ToastVariantProps = VariantProps<typeof toastStyles>
const icons: Record<NonNullable<ToastVariantProps['variant']>, typeof InfoIcon> = {
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: AlertTriangleIcon,
  error: OctagonAlertIcon,
  default: InfoIcon,
} as const

export function Toast<T extends ToastParams>({ closeButton, state, ...props }: ToastProps<T>) {
  const ref = useRef(null)
  const { toastProps, contentProps, titleProps, closeButtonProps } = useToast(props, state, ref)

  const ToastIcon = icons[props.toast.content.variant ?? 'info'] ?? InfoIcon

  return (
    <div
      {...toastProps}
      ref={ref}
      // Use a data attribute to trigger animations in CSS.
      data-animation={props.toast.animation}
      onAnimationEnd={() => {
        // Remove the toast when the exiting animation completes.
        if (props.toast.animation === 'exiting') {
          state.remove(props.toast.key)
        }
      }}
      className={toastStyles({
        state: props.toast.animation,
        variant: props.toast.content.variant ?? 'default',
      })}
    >
      {!props.toast.content.children && <ToastIcon size={16} />}
      <div {...contentProps} className='w-full'>
        <div {...titleProps}>{props.toast.content.children}</div>
      </div>
      {closeButton && (
        <Button isIcon variant='ghost' tone='opaque' {...closeButtonProps} aria-label='Dismiss'>
          <XIcon />
        </Button>
      )}
    </div>
  )
}
