import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { APITypesMap, CounterQueryType, counterQueryTypeToCounterTypes, counterQueryTypeToProduct } from 'const'
import { DateTime } from 'luxon'
import { ClientCounterType, UsageCounterPeriod } from 'models'
import { useMemo } from 'react'

import { filterUndefinedProperties } from '../../helpers/common'
import { useBuildRequest, useGenericErrorHandler, withMeta } from './base'

// get usage counters for a time period for subscription
export function useUsageCounters({
  subscriptionId,
  from,
  to,
  queryType,
  customTypes,
  period,
  timezone,
  enabled = true,
  keepPrevious = false,
}: {
  subscriptionId?: string
  from?: DateTime
  to?: DateTime
  queryType?: CounterQueryType
  customTypes?: ClientCounterType[]
  period?: UsageCounterPeriod
  timezone?: string
  enabled?: boolean
  keepPrevious?: boolean
}) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  const queryParams = useMemo(
    () =>
      getChartDataRequestParams({
        from,
        to,
        queryType,
        customTypes,
        period,
        timezone,
      }),
    [from, to, queryType, customTypes, period, timezone]
  )

  return withMeta(
    useQuery({
      queryKey: ['usageCounters', subscriptionId, queryParams] as const,
      queryFn: ({ queryKey: [_, subId] }) =>
        withErrorHandling(
          buildRequest('usageCounters', {
            params: {
              subscriptionId: subId!,
            },
            queryParams,
          })
        ),
      enabled: enabled && subscriptionId != null && queryParams != null && period != null,
      placeholderData: keepPrevious ? keepPreviousData : undefined,
    })
  )
}

function getChartDataRequestParams({
  from,
  to,
  queryType,
  customTypes,
  period,
  timezone,
}: {
  from?: DateTime
  to?: DateTime
  queryType?: CounterQueryType
  customTypes?: ClientCounterType[]
  period?: UsageCounterPeriod
  timezone?: string
}): APITypesMap['usageCounters']['queryParams'] | undefined {
  if (!from || !to || !queryType || !period || !timezone) {
    return undefined
  }

  const usageCounterTypes = customTypes?.sort() ?? counterQueryTypeToCounterTypes[queryType]
  const usageCounterProduct = counterQueryTypeToProduct[queryType]
  return filterUndefinedProperties({
    from: timezone ? from.setZone(timezone).toFormat("yyyy-MM-dd'T'TT") : from.toFormat("yyyy-MM-dd'T'TT"),
    to: timezone ? to.setZone(timezone).toFormat("yyyy-MM-dd'T'TT") : to.toFormat("yyyy-MM-dd'T'TT"),
    type: usageCounterTypes,
    period,
    product: usageCounterProduct,
  })
}
