import { ButtonOrLink, ButtonOrLinkProps } from '@compass/components'
import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import styles from './GenericEmptyState.module.scss'

type GenericEmptyStateProps = {
  icon?: ReactNode
  title?: ReactNode
  description?: ReactNode
  buttonProps?: ButtonOrLinkProps
  className?: string
}

export function GenericEmptyState({ icon, title, description, buttonProps, className }: GenericEmptyStateProps) {
  return (
    <Box className={twMerge('px-6 py-8', styles.emptyState, className)}>
      {icon ? <Box className={styles.emptyStateIcon}>{icon}</Box> : null}
      {title || description ? (
        <Box className={styles.emptyStateTextContent}>
          {title ? <Typography variant='semiBody1'>{title}</Typography> : null}
          {description ? (
            <Typography variant='bodyS' textAlign='center'>
              {description}
            </Typography>
          ) : null}
        </Box>
      ) : null}
      {buttonProps ? (
        <ButtonOrLink {...buttonProps} className={clsx(styles.button, buttonProps.className)}>
          {buttonProps.children}
        </ButtonOrLink>
      ) : null}
    </Box>
  )
}
