import { Subscription } from 'models'

export const UI_PREFERENCES_KEY = 'fpjsDashboardUIPreferences'

export interface UiPreferences {
  sidebar?: SidebarState
  subscriptionOverview?: Record<Subscription['id'], SubscriptionOverviewState>
  account?: AccountState
  isTrialExtendedModalDismissed?: boolean
  // This flag is not on subscriptionOverview level, since it's possible to do only one forced trial subscription
  isForcedTrialModalDismissed?: boolean
  isFeedbackDrawerDismissed?: boolean
  isAndroidFreemiumAnnouncementDismissed?: boolean
  isUpgradeToAndroidFreemiumDismissed?: boolean
}

export type SidebarState = {
  isOpened: boolean
}

export type SubscriptionOverviewState = {
  widgets: Record<WidgetName, WidgetState>
  banners: Record<BannerName, BannerState>
}

export type AccountState = {
  banners: Record<AccountBannerName, BannerState>
  modals: Record<AccountModalName, AccountModalState>
  confirmations: Record<Subscription['id'], Record<AccountConfirmationsName, AccountConfirmationsState>>
}

export type AccountModalState = {
  isHidden: boolean
}

export type WidgetState = {
  isHidden: boolean
}

export type BannerState = {
  isHidden: boolean
}

export enum AccountModalName {
  BotDIntro = 'botDIntro',
}

export enum AccountConfirmationsName {
  BotDEnableRequestSent = 'botDEnableRequestSent',
}

export type AccountConfirmationsState = {
  isConfirmed: boolean
}

export enum WidgetName {
  IntegrationStatus = 'integrationStatus',
}

export enum BannerName {}

export enum AccountBannerName {
  InviteUser = 'inviteUser',
  PriceChange = 'priceChange',
  TrialCountdown = 'trialCountdown',
  TrialEnded = 'trialEnded',
  ForcedTrial = 'forcedTrial',
  ProPlus = 'proPlus',
  FreeTierSunset = 'freeTierSunset',
  POC = 'POC',
}

export const DEFAULT_WIDGET_STATE = {
  isHidden: false,
}

export const DEFAULT_BANNER_STATE = {
  isHidden: false,
}

export const DEFAULT_MODAL_STATE = {
  isHidden: false,
}

export const DEFAULT_CONFIRMATION_STATE = {
  isConfirmed: false,
}

function getDefaultOverviewState(): SubscriptionOverviewState {
  const widgets = Object.fromEntries(
    Object.values(WidgetName).map((widgetName) => [widgetName, DEFAULT_WIDGET_STATE])
  ) as Record<WidgetName, WidgetState>
  const banners = Object.fromEntries(
    Object.values(BannerName ?? {}).map((bannerName) => [bannerName, DEFAULT_BANNER_STATE])
  ) as Record<BannerName, BannerState>

  return { widgets, banners }
}

export const DEFAULT_OVERVIEW_STATE = getDefaultOverviewState()

function getDefaultAccountState(): AccountState {
  const banners = Object.fromEntries(
    Object.values(AccountBannerName).map((bannerName) => [bannerName, DEFAULT_BANNER_STATE])
  ) as Record<AccountBannerName, BannerState>

  const modals = Object.fromEntries(
    Object.values(AccountModalName).map((modalName) => [modalName, DEFAULT_MODAL_STATE])
  ) as Record<AccountModalName, AccountModalState>

  const confirmations = {}

  return { banners, modals, confirmations }
}

export const DEFAULT_ACCOUNT_STATE = getDefaultAccountState()
