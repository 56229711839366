import { useQuery, useQueryClient } from '@tanstack/react-query'

import { UserFeedbackData } from '../models'
import { extractData, useBuildRequest, useRequestMutation } from './api/base'
import { useAuth } from './user'

export const QUERY_KEY = ['userFeedback'] as const

export function useFeedbackData() {
  const { isAuthorized } = useAuth()
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: () => extractData(buildRequest('userFeedbackGet', {})) ?? {},
    enabled: isAuthorized,
    // very big cache lifetime; though this cache is memory-only, so it's cleared on every page reload,
    // which is exactly what we need
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export function useUpdateFeedbackData() {
  const queryClient = useQueryClient()
  return useRequestMutation('userFeedbackUpdate', {
    onMutate: (variables) => {
      queryClient.setQueryData<UserFeedbackData>(QUERY_KEY, (old) => ({ ...old, ...variables }))
    },
  })
}
