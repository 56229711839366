import { ButtonOrLink } from '@compass/ButtonOrLink'
import { WithTooltip } from '@compass/WithTooltip'
import { List, ListItem, ListItemIcon, ListItemText, Paper, Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { BaseReactProps } from 'helpers/types'
import { Check } from 'lucide-react'
import { CSSProperties, ReactElement } from 'react'

import {
  PLAN_FEATURES,
  SUBSCRIPTION_DISPLAY_PLANS,
  SUBSCRIPTION_PLAN_PRICES,
  SubscriptionDisplayPlan,
} from '../../../../const'
import { formatPriceInDollars } from '../../../../helpers/format'
import styles from './PlanCard.module.scss'

export interface PlanCardProps extends BaseReactProps {
  plan: SubscriptionDisplayPlan
  currentPlan?: SubscriptionDisplayPlan
  priceLabel?: string
  variant?: 'contained' | 'outlined' | 'secondary'
  action?: string
  onAction?: () => void
  actionHref?: string
  locked?: boolean
  dense?: boolean
  isTrialSubscription?: boolean
  isLoading?: boolean
  isHighlighted?: boolean
  topBadge?: string
  priceSubtitleContent?: ReactElement | string
  oldPrice?: number
}

export function PlanCard({
  plan,
  currentPlan,
  priceLabel,
  variant,
  action,
  onAction,
  actionHref,
  locked,
  isTrialSubscription,
  dense,
  isLoading,
  className,
  topBadge,
  priceSubtitleContent,
  oldPrice,
}: PlanCardProps) {
  interface ActionProps {
    to?: string
    href?: string
    onPress?: () => void
  }

  const price = SUBSCRIPTION_PLAN_PRICES[plan]
  const selected = currentPlan && plan === currentPlan
  const isDowngrade = currentPlan && plan < currentPlan
  let actionName = action
  if (isLoading) {
    actionName = 'Loading'
  } else if (selected && !isTrialSubscription) {
    actionName = 'Current plan'
  } else if (isDowngrade && !isTrialSubscription) {
    actionName = currentPlan === SubscriptionDisplayPlan.Enterprise ? 'Included' : 'Downgrade'
  }

  const actionProps: ActionProps = {
    onPress: onAction,
    href: actionHref,
  }

  return (
    <Paper className={clsx(styles.plan, { [styles.selected]: selected }, className)}>
      {topBadge && (
        <div className={styles.upperBadge}>
          <span>{topBadge}</span>
        </div>
      )}
      <Typography variant='bodyLMedium' component='h3'>
        {SUBSCRIPTION_DISPLAY_PLANS[plan].name}
      </Typography>

      {priceLabel && (
        <Typography variant='bodyS' component='span' className='mt-2'>
          {isLoading ? <Skeleton width={96} className='transform-none' /> : priceLabel}
        </Typography>
      )}

      <Typography variant='h2' component='span' className={clsx(styles.price)}>
        {oldPrice && (
          <Typography variant='h2' component='span' className={styles.oldPrice}>
            {isLoading ? <Skeleton width={40} className='transform-none' /> : formatPriceInDollars(oldPrice, false)}
          </Typography>
        )}
        {isLoading ? <Skeleton width={64} className='transform-none' /> : <Price price={price} />}
      </Typography>

      {priceSubtitleContent ? (
        <Typography variant='bodyXS'>{priceSubtitleContent}</Typography>
      ) : (
        <span className='mt-4' />
      )}

      <List dense disablePadding className={styles.list}>
        {PLAN_FEATURES[plan].map(({ name, badge, tooltip }) => (
          <ListItem key={name} disableGutters disablePadding={dense}>
            <ListItemIcon className='min-w-0'>
              <Check size={16} className='text-orange-700' />
            </ListItemIcon>
            <ListItemText
              className='m-0 ml-2'
              primary={
                tooltip ? (
                  <WithTooltip content={tooltip}>
                    <span className='underline underline-offset-2 decoration-1 decoration-dotted cursor-help'>
                      {name}
                    </span>
                    {badge && (
                      <Typography variant='body3' component='span' className={styles.badge}>
                        {badge}
                      </Typography>
                    )}
                  </WithTooltip>
                ) : (
                  <>
                    {name}
                    {badge && (
                      <Typography variant='body3' component='span' className={styles.badge}>
                        {badge}
                      </Typography>
                    )}
                  </>
                )
              }
              primaryTypographyProps={{ variant: 'bodyS' }}
            />
          </ListItem>
        ))}
      </List>

      {action && (
        <ButtonOrLink
          {...actionProps}
          variant={(variant ?? (!actionProps.href && (isDowngrade || price === 'custom'))) ? 'secondary' : undefined}
          fullWidth
          isDisabled={isLoading || (selected && !isTrialSubscription) || locked}
          data-testid={`subscription-plan-action-${plan}`}
          className={styles.button}
          size='lg'
        >
          {actionName}
        </ButtonOrLink>
      )}
    </Paper>
  )
}

interface PriceProps {
  price: number | 'custom'
  labelStyles?: CSSProperties
}

function Price({ price, labelStyles }: PriceProps) {
  if (price === 'custom') {
    return <>Custom pricing</>
  }

  return (
    <>
      {formatPriceInDollars(price, false)}
      <Typography variant='bodyXS' component='span' style={{ ...labelStyles }}>
        /month
      </Typography>
    </>
  )
}
