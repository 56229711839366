import { Button } from '@compass/components'
import { Close, CloseOutlined, InfoOutlined } from '@mui/icons-material'
import { Box, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import clsx from 'clsx'
import { Filter } from 'components/Filter/Filter'
import {
  ComponentErrorBoundary,
  interpretDateRange,
  PersistentDateRangePicker,
  useDefaultPredefinedRanges,
} from 'features/commonUI'
import { getErrorParams } from 'helpers/data'
import { isPredefinedRange } from 'helpers/dateRange'
import { IPV4_CIDR_REGEX, IPV6_CIDR_REGEX, requestIdRegex, visitorIdRegex } from 'helpers/regex'
import { useLogPageView } from 'helpers/vendor'
import { useWorkspaceEnvironments } from 'hooks/api/environment'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import toPairs from 'lodash/toPairs'
import { DateTime, Duration } from 'luxon'
import { VisitsFilter, VisitsFilterAndDateRange } from 'models'
import { ampli } from 'models/ampli'
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, RegisterOptions, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { IdentificationUrlKeys, useFilterSearchParams } from '../../hooks/useFilterSearchParams'
import { defaultValues } from '../../utils/const'
import styles from './VisitHistoryFilter.module.scss'

export interface VisitHistoryFilterProps {
  timezone?: string
  subscriptionId: string
  onFilterChange: (isFiltering: boolean) => void
}
export function VisitHistoryFilter({ onFilterChange, timezone, subscriptionId }: VisitHistoryFilterProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { filterValues, defaultValuesWithSearchParams, setSearchParams, resetSearchParams } = useFilterSearchParams()
  const handleOpen = useCallback(() => {
    setIsOpen(true)
    ampli.attributeFilterDialogOpened({ datasetFocus: 'Identification Events' })
  }, [])
  const handleClose = useCallback(() => setIsOpen(false), [])

  const {
    handleSubmit,
    reset,
    resetField,
    control,
    formState: { errors, dirtyFields },
  } = useForm<VisitsFilter>({
    defaultValues: defaultValuesWithSearchParams,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldUnregister: false,
  })

  const dirtyCount = differenceWith(
    toPairs(omit(filterValues, ['since', 'before', 'period', 'limit'])),
    toPairs(omit(defaultValues, ['since', 'before', 'period', 'limit'])),
    isEqual
  ).length

  useEffect(() => {
    onFilterChange(dirtyCount > 0)
  }, [dirtyCount, onFilterChange])

  const onSubmit = useCallback<(values: VisitsFilterAndDateRange) => void>(
    (values) => {
      setSearchParams(values)
      handleClose()
    },
    [handleClose, setSearchParams]
  )

  const onManualSubmit = useCallback<(values: VisitsFilterAndDateRange) => void>(
    (values) => {
      onSubmit(values)
      const filters = {
        'IP address': values[IdentificationUrlKeys.IpAddress] != null && values[IdentificationUrlKeys.IpAddress] !== '',
        URL: values[IdentificationUrlKeys.Url] != null && values[IdentificationUrlKeys.Url] !== '',
        linkedID: values[IdentificationUrlKeys.LinkedId] != null && values[IdentificationUrlKeys.LinkedId] !== '',
        requestID: values[IdentificationUrlKeys.RequestId] != null && values[IdentificationUrlKeys.RequestId] !== '',
        visitorID: values[IdentificationUrlKeys.VisitorId] != null && values[IdentificationUrlKeys.VisitorId] !== '',
        origin: values[IdentificationUrlKeys.Origin] != null && values[IdentificationUrlKeys.Origin] !== '',
        environment:
          values[IdentificationUrlKeys.Environment] != null && values[IdentificationUrlKeys.Environment].length > 0,
      }
      const setFilters = Object.entries(filters)
        .filter(([_, value]) => value === true)
        .map(([key]) => key as keyof typeof filters)
      ampli.attributeFilterApplied({
        datasetFocus: 'Identification Events',
        attributeFilters: setFilters,
      })
    },
    [onSubmit]
  )

  const { data: environments } = useWorkspaceEnvironments(subscriptionId)

  useTrackInitialFilterParams()

  function handleClear() {
    resetSearchParams(Object.keys(defaultValuesWithSearchParams) as Array<keyof VisitsFilter>)
    reset(defaultValues)
  }

  const { ranges } = useDefaultPredefinedRanges()

  const definedRanges = useMemo(
    () =>
      Object.entries(ranges)
        .filter(([key]) => !['last_6_months', 'last_12_months'].includes(key))
        .map(([, value]) => value),
    [ranges]
  )

  return (
    <ComponentErrorBoundary>
      <Filter
        anchorOriginHorizontal='left'
        transformOriginHorizontal='left'
        triggerContent={
          <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
            Filter
            {dirtyCount > 0 ? (
              <div className={styles.dirtyCount}>
                <Typography variant='bodyS' color='inherit'>
                  {dirtyCount}
                </Typography>
              </div>
            ) : null}
          </Box>
        }
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        containerClassName={styles.paper}
      >
        <form onSubmit={handleSubmit(onManualSubmit)} className={styles.form} data-testid='visits-filter-form'>
          <header className={styles.header}>
            <Typography variant='h2' component='h2'>
              Filter by
            </Typography>

            <IconButton onClick={handleClose} title='Cancel' className={styles.closeButton}>
              <CloseOutlined />
            </IconButton>
          </header>

          <div className={styles.content}>
            {FILTER_CONFIGS.map(({ key, label, description, placeholder, validate }) => (
              <Fragment key={key}>
                <ComponentErrorBoundary>
                  <InputLabel htmlFor={key} className={styles.inputLabelContainer}>
                    <Typography variant='bodyM' className={styles.inputLabel}>
                      {label}
                    </Typography>
                    {description ? (
                      <Tooltip
                        className={styles.inputLabelTooltip}
                        title={
                          <Typography variant='bodyS' color='inherit'>
                            {description}
                          </Typography>
                        }
                      >
                        <Typography variant='bodyM' className={styles.inputLabelInfoIcon}>
                          <InfoOutlined fontSize='inherit' />
                        </Typography>
                      </Tooltip>
                    ) : null}
                  </InputLabel>
                  <Controller
                    name={key}
                    control={control}
                    rules={{ validate }}
                    render={({ field: { onChange, value } }) => {
                      if (key === 'environment') {
                        const hasEnvironments = environments && environments.length > 0
                        if (!Array.isArray(value)) {
                          value = value.split(',')
                        }

                        return (
                          <Select
                            multiple
                            id={key}
                            variant='outlined'
                            spellCheck={false}
                            onChange={(event) => onChange(event.target.value)}
                            value={value ?? []}
                            fullWidth
                            {...getErrorParams(key, errors)}
                            className={clsx(styles.environmentSelect, styles.input)}
                          >
                            {hasEnvironments &&
                              environments.map((env) => (
                                <MenuItem key={env.id} value={env.id}>
                                  {env.name}
                                </MenuItem>
                              ))}
                          </Select>
                        )
                      }
                      return (
                        <TextField
                          id={key}
                          variant='outlined'
                          placeholder={placeholder}
                          spellCheck={false}
                          onChange={onChange}
                          value={value}
                          {...getErrorParams(key, errors)}
                          InputProps={{
                            endAdornment: dirtyFields[key] ? (
                              <Tooltip title='Clear filter' arrow placement='top'>
                                <IconButton onClick={() => resetField(key)} edge='end' size='small'>
                                  <Close />
                                </IconButton>
                              </Tooltip>
                            ) : null,
                          }}
                          className={styles.input}
                        />
                      )
                    }}
                  />
                </ComponentErrorBoundary>
              </Fragment>
            ))}
          </div>

          <footer className={styles.footer}>
            {dirtyCount > 0 ? (
              <Button variant='ghost' onPress={handleClear}>
                Clear all
              </Button>
            ) : null}
            <Button type='submit' aria-label='Apply filters'>
              Apply
            </Button>
          </footer>
        </form>
      </Filter>

      <PersistentDateRangePicker
        timezone={timezone}
        route={AppRoute.IdentificationEvents}
        routeParams={{ subscriptionId }}
        definedRanges={definedRanges}
        defaultDateRange={ranges.today}
        maxRange={Duration.fromDurationLike({ months: 3 })}
        onOpen={() => ampli.dateFilterSelectorOpened({ datasetFocus: 'Identification Events' })}
        rangeInterpreter={(dateRange) => interpretDateRange({ dateRange })}
        onApplyRange={(range) => {
          ampli.dateFilterApplied({
            datasetFocus: 'Identification Events',
            dateRange: isPredefinedRange(range) ? range.label : 'Other',
            periodLengthDays: Math.round(range.endDate.diff(range.startDate, 'days').days),
          })
        }}
        className={styles.datePicker}
      />
    </ComponentErrorBoundary>
  )
}

type FilterConfig = {
  key: keyof VisitsFilter
  label: string
  description?: ReactNode
  placeholder?: string
  validate?: RegisterOptions['validate']
}
export const FILTER_CONFIGS: FilterConfig[] = [
  {
    key: 'environment',
    label: 'Environment',
    description: 'Find all events that were reported from the specified environment.',
    placeholder: 'Default Environment',
    validate: (origin) => origin.length <= 10_000 || 'The maximum character limit is 10,000.',
  },
  {
    key: 'ipAddress',
    label: 'IP Address',
    description:
      'Find all events originating from the specified IP address, or use CIDR notation to match IP address range.',
    placeholder: '127.0.0.0',
    validate: (ip: string) =>
      ip === '' ||
      (ip.includes('.') ? IPV4_CIDR_REGEX.test(ip) : IPV6_CIDR_REGEX.test(ip)) ||
      'The entered IP is not formatted correctly. Example: 192.168.1.0/22',
  },
  {
    key: 'url',
    label: 'URL',
    description: 'Find all web and mobile events by reported URL and package identifier, respectively.',
    placeholder: 'https://example.com/landing-page',
    validate: (url) => url.length <= 10_000 || 'The maximum character limit is 10,000.',
  },
  {
    key: 'origin',
    label: 'Origin',
    description: 'Find all events that were reported for the given origin. Enter website domain or mobile app bundle.',
    placeholder: 'example.com',
    validate: (origin) => origin.length <= 10_000 || 'The maximum character limit is 10,000.',
  },
  {
    key: 'linkedId',
    label: 'Linked ID',
    description: 'Find all events that were reported with the given linked ID.',
    placeholder: 'user_23567864',
    validate: (id) => id.length <= 256 || 'The maximum character limit is 256.',
  },
  {
    key: 'requestId',
    label: 'Request ID',
    description: 'Find an event by request ID.',
    placeholder: '1701234567890.ABcDef',
    validate: (id) => id === '' || requestIdRegex.test(id) || 'The provided ID is not formatted correctly.',
  },
  {
    key: 'visitorId',
    label: 'Visitor ID',
    description: 'Find all events that were reported with the given visitor ID.',
    placeholder: 'B4549twNaClX4RDz8TUr',
    validate: (id) => id === '' || visitorIdRegex.test(id) || 'The provided ID is not formatted correctly.',
  },
]

function useTrackInitialFilterParams() {
  const history = useHistory()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchOnLoad = useMemo(() => history.location.search, [])

  useLogPageView(() => {
    const searchParams = new URLSearchParams(searchOnLoad)

    const sinceParam = searchParams.get(IdentificationUrlKeys.Since)
    const beforeParam = searchParams.get(IdentificationUrlKeys.Before)
    const initialStartDate = sinceParam ? new Date(sinceParam) : DateTime.now().startOf('day').toJSDate()
    const initialEndDate = beforeParam ? new Date(beforeParam) : DateTime.now().endOf('day').toJSDate()
    const values = {
      'IP address': searchParams.has(IdentificationUrlKeys.IpAddress),
      URL: searchParams.has(IdentificationUrlKeys.Url),
      linkedID: searchParams.has(IdentificationUrlKeys.LinkedId),
      requestID: searchParams.has(IdentificationUrlKeys.RequestId),
      visitorID: searchParams.has(IdentificationUrlKeys.VisitorId),
      origin: searchParams.has(IdentificationUrlKeys.Origin),
      environment: searchParams.has(IdentificationUrlKeys.Environment),
    }
    const setFilters = Object.entries(values)
      .filter(([_, value]) => value === true)
      .map(([key]) => key as keyof typeof values)

    if (setFilters.length > 0) {
      ampli.dataWithFiltersAppliedViewed({
        datasetFocus: 'Identification Events',
        attributeFilters: setFilters,
        periodLengthDays: Math.round(
          DateTime.fromJSDate(initialEndDate).diff(DateTime.fromJSDate(initialStartDate), 'days').days
        ),
      })
    }
  })
}
