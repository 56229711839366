import { Button } from '@compass/components'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useToast } from 'hooks'
import { ApiKey } from 'models'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useEncryptionKeyActivateMutation } from '../../../../hooks/api/tokens'
import CalloutBox from '../CalloutBox/CalloutBox'
import styles from './ActivateApiKeyDialog.module.scss'

interface ActivateApiKeyDialogProps extends DialogProps {
  apiKey: ApiKey
  onClose: () => void
}

// connected component
export default function ActivateApiKeyDialog({ apiKey, ...dialogProps }: ActivateApiKeyDialogProps) {
  const { mutate: sendEncryptionKeyActivateRequest, isPending } = useEncryptionKeyActivateMutation()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { showToast } = useToast()

  const onActivate = useCallback(() => {
    sendEncryptionKeyActivateRequest(
      { params: { keyId: apiKey.id, subscriptionId: currentSubscriptionId } },
      {
        onSuccess: () => {
          dialogProps.onClose()
          showToast({ message: "It's been done. Your API token has been activated.", severity: 'success' })
        },
        onError: () => {
          showToast({
            message: 'An error occurred when trying to activate your API token. Please try again.',
            severity: 'error',
          })
        },
      }
    )
  }, [apiKey.id, currentSubscriptionId, sendEncryptionKeyActivateRequest, dialogProps, showToast])

  return <ActivateApiKeyDialogView {...dialogProps} onActivate={onActivate} apiKey={apiKey} isLoading={isPending} />
}

export interface ActivateApiKeyDialogViewProps extends ActivateApiKeyDialogProps {
  onActivate: () => void
}

// display component for storybook
export function ActivateApiKeyDialogView({
  onActivate,
  apiKey,
  isLoading,
  ...dialogProps
}: ActivateApiKeyDialogViewProps) {
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<{ active: boolean }>({ defaultValues: { active: false } })

  return (
    <Dialog {...dialogProps} isLoading={isLoading} classes={{ paper: styles.dialog }}>
      <DialogTitle onClose={dialogProps.onClose} className={styles.dialogTitle}>
        <Typography variant='h1' component='div' className={styles.heading}>
          Activate encryption key
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onActivate)}>
        <DialogContent className={styles.dialogContent}>
          <CalloutBox title='Read this carefully!' type='warning'>
            By activating your encryption key, all client-side <strong>payloads</strong> will be{' '}
            <strong>encrypted</strong>. Please ensure your backend is set up with the key for decrypting payloads. Also,
            it can take a few minutes for changes to be reflected.
          </CalloutBox>
          <Box>
            <FormControlLabel
              control={
                <Controller
                  name='active'
                  control={control}
                  render={({ field }) => (
                    <Checkbox color='primary' id='active' {...field} classes={{ root: styles.checkbox }} />
                  )}
                />
              }
              label={
                <Typography variant='body2'>
                  By activating this key, I confirm that client-side payloads will be encrypted and require decryption
                  on my servers.
                </Typography>
              }
              className={styles.control}
            />
          </Box>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button variant='ghost' onPress={dialogProps.onClose}>
            Cancel
          </Button>
          <Button type='submit' isDisabled={!isValid || !isDirty}>
            Activate key
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
