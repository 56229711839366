import { Alert, Typography } from '@mui/material'

export function IntegrationMismatchedDomainsAlert() {
  return (
    <Alert severity='error'>
      <Typography variant='body1'>
        Your proxy is running on mismatched domains which lowers accuracy of Fingerprint. To resolve this issue, ensure
        your proxy is used exclusively on the intended domains listed below. Alternatively, configure proxies for the
        current (mismatched) domains shown.
      </Typography>
    </Alert>
  )
}
