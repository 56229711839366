import { Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { HTMLAttributes } from 'react'

import styles from './Distribution.module.scss'

const COLORS = ['#77361c', '#4d3b8b', '#165424', '#0a4c7b', '#8b1e4a', '#64420d', '#035347']
const BACKGROUNDS = ['#fdf4f1', '#f8f7fe', '#f3f9f4', '#f3f9fe', '#fef7f9', '#f9f5ea', '#f3fbf9']

export type DistributionRowProps = {
  label?: string
  value?: string
  percentage: number
  index: number
  className?: string
  labelColor?: string
  background?: string
}

export function DistributionRow({
  label,
  value,
  percentage,
  index,
  className,
  background,
  labelColor,
}: DistributionRowProps) {
  const isLoading = label == null
  const percentageStyle = {
    '--right': `${(1 - percentage) * 100}%`,
    '--color': labelColor ?? COLORS[index % COLORS.length],
    '--background': isLoading
      ? 'hsl(var(--fpds-color-gray-2))'
      : (background ?? BACKGROUNDS[index % BACKGROUNDS.length]),
  } as React.HTMLAttributes<HTMLDivElement>['style']

  return (
    <div className={clsx(styles.distributionRow, className)} style={percentageStyle}>
      <div className={styles.label}>
        <Typography variant='bodyM'>{isLoading ? <>&nbsp;</> : label || <em>Unknown</em>}</Typography>
      </div>
      <Typography variant='bodyMMedium' className={styles.value}>
        {value}
      </Typography>
    </div>
  )
}

export function DistributionHeaderRow({
  keyLabel,
  valueLabel,
  className,
}: {
  keyLabel?: string
  valueLabel?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
}) {
  return (
    <div className={className}>
      <Typography variant='caption' className={styles.label}>
        {keyLabel ?? <Skeleton width={90} />}
      </Typography>
      <Typography variant='caption' className={styles.value}>
        {valueLabel ?? <Skeleton width={50} />}
      </Typography>
    </div>
  )
}
