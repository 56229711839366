import { bisector, tickStep } from 'd3-array'
import { DateTime, DateTimeUnit } from 'luxon'

// Based on
// - https://github.com/d3/d3-time/blob/main/src/ticks.js
// - https://github.com/d3/d3-time/blob/main/src/interval.js
// - https://github.com/d3/d3-time/blob/main/src/duration.js
//
// 1. Find the best fitting interval:
//    a. Calculate the target interval duration (domain / target tick count)
//    b. Find the closest interval in the predefined interval list
// 2. With the selected interval calculate the ticks, including the start and the end.

const durationSecond = 1000
const durationMinute = durationSecond * 60
const durationHour = durationMinute * 60
const durationDay = durationHour * 24
const durationWeek = durationDay * 7
const durationMonth = durationDay * 30
const durationYear = durationDay * 365

const predefinedIntervals: Array<[DateTimeUnit, number, number]> = [
  ['second', 1, durationSecond],
  ['second', 5, 5 * durationSecond],
  ['second', 15, 15 * durationSecond],
  ['second', 30, 30 * durationSecond],
  ['minute', 1, durationMinute],
  ['minute', 5, 5 * durationMinute],
  ['minute', 15, 15 * durationMinute],
  ['minute', 30, 30 * durationMinute],
  ['hour', 1, durationHour],
  ['hour', 3, 3 * durationHour],
  ['hour', 6, 6 * durationHour],
  // ['hour', 12, 12 * durationHour], // The dashboard has no use for the 12 hour interval.
  ['day', 1, durationDay],
  ['day', 2, 2 * durationDay],
  ['week', 1, durationWeek],
  ['month', 1, durationMonth],
  ['month', 3, 3 * durationMonth],
  ['year', 1, durationYear],
]

function calculateFittingInterval(start: number, stop: number, count: number): [DateTimeUnit, number] {
  const target = Math.abs(stop.valueOf() - start.valueOf()) / count
  const i = bisector(([, , step]) => step).right(predefinedIntervals, target)

  if (i === 0) return ['millisecond', Math.max(tickStep(start, stop, count), 1)]
  if (i === predefinedIntervals.length) return ['year', tickStep(start / durationYear, stop / durationYear, count)]

  const closestIndex = target / predefinedIntervals[i - 1][2] < predefinedIntervals[i][2] / target ? i - 1 : i
  const [t, step] = predefinedIntervals[closestIndex]

  return [t, step]
}

function calculateTicksInRange(start: DateTime, stop: DateTime, unit: DateTimeUnit, amount: number = 1) {
  let current = start
    .minus({ millisecond: 1 })
    .startOf(unit)
    .plus({ [unit]: 1 })
    .startOf(unit)

  if (!(current < stop) || !(amount > 0)) {
    return []
  }

  const result: DateTime[] = []

  let previous
  do {
    result.push(current)
    previous = current
    current = current.plus({ [unit]: amount }).startOf(unit)
  } while (previous < current && current < stop)

  return result
}

export function zonedTicks(start: DateTime, end: DateTime, count: number) {
  const reverse = end < start
  if (reverse) [start, end] = [end, start]

  const [unit, amount] = calculateFittingInterval(start.valueOf(), end.valueOf(), count)
  const ticks = calculateTicksInRange(
    start,
    end.plus({ millisecond: 1 }), // Include the end of the range.
    unit,
    amount
  )

  return reverse ? ticks.reverse() : ticks
}

function getFormat(dt: DateTime) {
  if (dt.startOf('second') < dt) return 'SSS' //millisecond
  if (dt.startOf('minute') < dt) return ':ss' //second
  if (dt.startOf('hour') < dt) return 'hh:mm' //minute
  if (dt.startOf('day') < dt) return 'hh a' //hour
  if (dt.startOf('month') < dt) return 'LLL dd' //week
  if (dt.startOf('year') < dt) return 'LLL' //month
  // if (dt.startOf('week') < dt) return 'ccc dd' //day
  return 'yyyy' //year
}

export function formatDateTime(dt: DateTime) {
  return dt.toFormat(getFormat(dt))
}
