import { useInvalidETldPlusOneDataByHostname } from '../../../../hooks/api/integrations'
import { useSdkHealthQuery } from '../../../health/hooks/useSdkHealthQuery'
import { useCurrentSubscription } from '../../../subscription'
import { ThirdPartyIntegration } from '../../components/IntegrationMetadata/integrationMetadata'
import { useIntegrationStatsQuery } from '../../hooks/useIntegrationStatsQuery'
import { IntegrationCustomContents } from './IntegrationCustomContents'

export function IntegrationCustomPage() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data, isLoading: isSDKHealthLoading } = useSdkHealthQuery(currentSubscriptionId)
  const { data: requestsMade, isLoading: statsIsLoading } = useIntegrationStatsQuery(
    ThirdPartyIntegration.CustomProxy,
    currentSubscriptionId
  )

  const incorrectETLDPlusOneQuery = useInvalidETldPlusOneDataByHostname(
    ThirdPartyIntegration.CustomProxy,
    currentSubscriptionId
  )

  const isLoading = isSDKHealthLoading || statsIsLoading || incorrectETLDPlusOneQuery.isLoading

  const customProxyHealth = data?.platform.find((t) => t.sdkName === 'Custom Proxy')

  return (
    <IntegrationCustomContents
      subscriptionId={currentSubscriptionId}
      requestsMade={requestsMade}
      health={customProxyHealth}
      isLoading={isLoading}
      incorrectETLDPlusOneRequests={incorrectETLDPlusOneQuery.data}
    />
  )
}
