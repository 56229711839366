export function trimTimezone(date?: string) {
  if (!date) {
    return ''
  }

  // 2025-03-01T00:00:00.000-04:00
  // 2025-03-01T00:00:00 .000-04:00
  // 2025-03-01T00:00:00 .000
  // 2025-03-01T00:00:00.000
  const datetimeContent = date.substring(0, 19)
  const timezoneContent = date.substring(19)
  const cleanedTimezoneContent = timezoneContent?.split(/[-+Z]/).shift()

  return datetimeContent + cleanedTimezoneContent
}
