import { AppRoute, buildRoute, useRouteParams } from 'appRoutes'
import Loader from 'components/Loader/Loader'
import { GenericError, RESEND_CONFIRMATION_TIMER_PERIOD } from 'const'
import {
  useAuth,
  useDocumentTitle,
  useResendConfirmationTimer,
  useSignUpConfirmMutation,
  useSignUpConfirmResend,
} from 'hooks'
import { useUserContext } from 'hooks/api/context'
import { useInterval } from 'hooks/interval'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { SignUpConfirmBrokenLink } from '../../components/SignupConfirm/SignUpConfirmBrokenLink'
import { SignUpConfirmCheckYourMail } from '../../components/SignupConfirm/SignUpConfirmCheckYourMail'
import { SignUpConfirmError } from '../../components/SignupConfirm/SignUpConfirmError'
import { SignUpConfirmLinkInvalid } from '../../components/SignupConfirm/SignUpConfirmLinkInvalid'
import { SignUpConfirmSuccess } from '../../components/SignupConfirm/SignUpConfirmSuccess'

function getCurrentCooldown(lastSent: number) {
  return Math.floor(RESEND_CONFIRMATION_TIMER_PERIOD - (Date.now() - lastSent) / 1000)
}

export function SignUpConfirmPage() {
  useDocumentTitle('Sign Up')
  const { mutate: sendSignUpConfirmRequest } = useSignUpConfirmMutation()

  const { getLastSent, markLastSentNow } = useResendConfirmationTimer()
  const [lastSent, setLastSent] = useState(getLastSent())
  const [cooldown, setCooldown] = useState(getCurrentCooldown(lastSent))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<GenericError | null>(null)
  const [resendSuccess, setResendSuccess] = useState(false)
  const [confirmationSentOnce, setConfirmationSentOnce] = useState(false)

  const { signupIntent } = useRouteParams<AppRoute.SignupConfirmWithIntent>()
  const { data: context, isFetching: isLoadingUserContext } = useUserContext(false, false)
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const code = urlParams.get('confirmationCode')
  const { setCredentials } = useAuth()
  const { mutate: resendConfirmation, isPending: isResendingConfirmation } = useSignUpConfirmResend()
  const history = useHistory()

  useInterval(() => setCooldown(getCurrentCooldown(lastSent)), 1000)
  useEffect(() => {
    setCooldown(getCurrentCooldown(lastSent))
  }, [lastSent])

  const SUCCESS_STATE_TIMEOUT = 4000
  const [successTimeout, setSuccessTimeout] = useState(SUCCESS_STATE_TIMEOUT / 1000)

  const sendConfirmRequest = useCallback(() => {
    if (!code || confirmationSentOnce || isLoadingUserContext) {
      return
    }
    setConfirmationSentOnce(true)
    setLoading(true)
    let intervalId: ReturnType<typeof setInterval>
    sendSignUpConfirmRequest(
      { data: { signupIntent, confirmationCode: code } },
      {
        onSuccess: (result) => {
          if (result) {
            setError(null)
            setCredentials({ accessToken: result.accessToken, refreshToken: result.refreshToken })
            setLoading(false)
            // Timeout to show success page
            intervalId = setInterval(() => {
              setSuccessTimeout((oldTimeout) => Math.max(oldTimeout - 1, 0))
            }, 1000)
            setTimeout(() => {
              history.replace(AppRoute.Onboarding)
              clearInterval(intervalId)
            }, SUCCESS_STATE_TIMEOUT)
          }
        },
        onError: (err) => {
          setError(err)
          setLoading(false)
          clearInterval(intervalId)
        },
      }
    )
  }, [
    sendSignUpConfirmRequest,
    code,
    history,
    setCredentials,
    signupIntent,
    confirmationSentOnce,
    isLoadingUserContext,
  ])

  useEffect(() => {
    if (!signupIntent) {
      // Workaround: if we don't have signupIntent, it means we just came from Signup page,
      // so the email has been just sent. Thus, we set resendSuccess to true to show proper screen.
      setResendSuccess(true)
      setLastSent(getLastSent())
      setLoading(false)
    }
  }, [getLastSent, signupIntent])

  useEffect(() => {
    if (signupIntent) {
      if (!code) {
        setError({ code: 'value_invalid', param: 'signupIntent' })
        setLoading(false)
      } else {
        sendConfirmRequest()
      }
    }
  }, [sendConfirmRequest, signupIntent, code])

  const doResend = useCallback(
    (ampliEventSource: string) => {
      resendConfirmation(
        {},
        {
          onSuccess: () => {
            markLastSentNow()
            setResendSuccess(true)
            ampli.confirmationEmailResent({ source: ampliEventSource })
          },
        }
      )
    },
    [markLastSentNow, resendConfirmation]
  )

  if (loading || isLoadingUserContext) {
    return <Loader />
  }

  if (resendSuccess) {
    if (context?.email) {
      return (
        <>
          <SignUpConfirmCheckYourMail
            email={context?.email}
            cooldown={cooldown}
            onResend={() => doResend('signup_confirm_after_signup')}
            isResendingConfirmation={isResendingConfirmation}
          />
        </>
      )
    } else {
      return (
        <SignUpConfirmBrokenLink
          onSignupClick={() => {
            history.replace(buildRoute(AppRoute.Signup))
          }}
        />
      )
    }
  }

  if (error) {
    if (
      error.code === 'value_invalid' &&
      error.param === 'confirmationCode' &&
      error.message !== 'Confirmation code is expired'
    ) {
      return (
        <>
          <SignUpConfirmLinkInvalid
            descriptionText='The link you followed is not valid. Click the button below to resend confirmation link.'
            isLoggedIn={!!context?.email}
            onResend={() => doResend('signup_confirm_link_invalid')}
            onLogin={() => history.replace(AppRoute.Login)}
          />
        </>
      )
    }

    if (
      error.code === 'value_invalid' &&
      error.param === 'signupIntent' &&
      error.message === 'Email is already confirmed'
    ) {
      history.replace(context?.email ? AppRoute.Onboarding : AppRoute.Login)
      return <></>
    }

    if (error.code === 'value_invalid') {
      if (error.param === 'signupIntent') {
        if (context?.email) {
          return (
            <>
              <SignUpConfirmLinkInvalid
                descriptionText='The link you followed is not valid. Click the button below to resend confirmation link.'
                isLoggedIn={true}
                onResend={() => doResend('signup_confirm_link_invalid')}
                onLogin={() => history.replace(AppRoute.Login)}
              />
            </>
          )
        } else {
          return (
            <SignUpConfirmBrokenLink
              onSignupClick={() => {
                history.replace(buildRoute(AppRoute.Signup))
              }}
            />
          )
        }
      }
      if (error.param === 'confirmationCode') {
        return (
          <>
            <SignUpConfirmLinkInvalid
              descriptionText={
                'This link went sour after 7 days. Click the button below to send a fresh confirmation email.'
              }
              isLoggedIn={!!context?.email}
              onResend={() => doResend('signup_confirm_link_expired')}
              onLogin={() => history.replace(AppRoute.Login)}
            />
          </>
        )
      }
    }

    return <SignUpConfirmError />
  }

  return (
    <>
      <SignUpConfirmSuccess timeout={successTimeout} />
    </>
  )
}
