import { Header, Layout, MainColumn, SettingsLayout } from 'features/commonUI'

import { useDocumentTitle } from '../../hooks'
import { EmailNotificationsForm } from './components/EmailNotificationsForm'

export function NotificationSettingsPage() {
  useDocumentTitle('My Notifications')

  return (
    <Layout>
      <Header title='Settings' />
      <MainColumn>
        <SettingsLayout>
          <EmailNotificationsForm />
        </SettingsLayout>
      </MainColumn>
    </Layout>
  )
}
