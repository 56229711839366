import { Button, LinkButton } from '@compass/components'
import { Menu, MenuItem } from '@compass/Menu/Menu'
import { Popover } from '@compass/Popover'
import clsx from 'clsx'
import { Ellipsis, KeyRoundIcon, MonitorSmartphone, ReceiptText, SettingsIcon } from 'lucide-react'
import { ExpandedSubscription } from 'models'
import { ReactNode, useMemo } from 'react'
import { MenuTrigger } from 'react-aria-components'

import { AppRoute, buildRoute } from '../../../../appRoutes'

type ActionsType = 'identification_events' | 'api_keys' | 'plan_and_usage' | 'settings'
type ActionsSelectOption = {
  key: ActionsType
  label: string
  route: string
  icon: ReactNode
}

export function ActionsMenu({ subscriptionId }: { subscriptionId: ExpandedSubscription['id'] }) {
  const actions: ActionsSelectOption[] = useMemo(
    () => [
      {
        key: 'identification_events',
        label: 'Identification events',
        icon: <MonitorSmartphone />,
        route: buildRoute(AppRoute.IdentificationEvents, { subscriptionId }),
      },
      {
        key: 'api_keys',
        label: 'API keys',
        icon: <KeyRoundIcon />,
        route: buildRoute(AppRoute.ApiKeys, { subscriptionId }),
      },
      {
        key: 'plan_and_usage',
        label: 'Plan & usage',
        icon: <ReceiptText />,
        route: buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }),
      },
      {
        key: 'settings',
        label: 'Settings',
        icon: <SettingsIcon />,
        route: buildRoute(AppRoute.SubscriptionSettings, { subscriptionId }),
      },
    ],
    [subscriptionId]
  )

  return (
    <>
      <MenuTrigger>
        <Button tone='background2' className='md:hidden' isIcon variant='ghost' aria-label='Show workspace actions'>
          <Ellipsis />
        </Button>
        <Popover placement='bottom end' className='min-w-[150px] w-56'>
          <Menu>
            {actions.map((option) => (
              <MenuItem key={option.key} href={option.route} id={option.key} value={option} suffix={option.icon}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Popover>
      </MenuTrigger>
      {actions.map(({ route, label, key, icon }) => (
        <ActionMenuItem route={route} label={label} key={key}>
          {icon}
        </ActionMenuItem>
      ))}
    </>
  )
}

type ActionMenuItemProps = {
  label: ActionsSelectOption['label']
  route: string
  className?: string
  children: ReactNode
}
function ActionMenuItem({ children, label, route, className }: ActionMenuItemProps) {
  return (
    <LinkButton
      isIcon
      variant='ghost'
      tone='background2'
      alt
      aria-label={label}
      href={route}
      className={clsx('hidden md:flex items-center', className)}
    >
      {children}
    </LinkButton>
  )
}
