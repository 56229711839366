import { Alert, Breadcrumbs, Link, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { AppRoute, buildRoute } from 'appRoutes'
import CodeViewer from 'components/CodeViewer/CodeViewer'
import KeyValueList, { Entry } from 'components/KeyValueList/KeyValueList'
import {
  ENTERPRISE_SMART_SIGNALS,
  GenericError,
  PRO_PLUS_DISPLAY_SIGNALS,
  SIGNAL_NAMES,
  SubscriptionDisplayPlan,
  SUPPORT_EMAIL_MAILTO,
} from 'const'
import { ContentColumn, formatDatetime, Header, MainColumn, SubHeader } from 'features/commonUI'
import { ChangePlanAction } from 'features/planManagement'
import { formatDateShortFullYear, formatJSON } from 'helpers/format'
import { getAvailableSmartSignals, getEnabledSmartSignals, getSubscriptionDisplayPlan } from 'helpers/subscription'
import { useUserContext } from 'hooks/api/context'
import { usePaginationForQuery } from 'hooks/usePaginationForQuery'
import { TriangleAlert } from 'lucide-react'
import { AppProduct, ExpandedSubscription, PaginatedVisitsResponse, Subscription, SubscriptionType } from 'models'
import { ampli } from 'models/ampli'
import { IdentificationProduct, PlatformEvent } from 'models/event'
import { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { VisitsList } from '../VisitsList/VisitsList'
import { PRODUCT_FORMATTERS } from './event'
import styles from './VisitDetails.module.scss'

export interface VisitDetailsProps {
  event?: PlatformEvent
  subscription?: ExpandedSubscription
  isLoading?: boolean
  error?: Error | null
  query: UseInfiniteQueryResult<InfiniteData<PaginatedVisitsResponse>, GenericError>
}

export function VisitDetails({ event, subscription, isLoading, error, query }: VisitDetailsProps) {
  const identification = event?.products.identification?.data

  return (
    <>
      <Header title='Identification' />
      <MainColumn>
        <VisitBreadcrumbs isLoading={isLoading} identification={identification} subscription={subscription} />

        {error ? (
          <Alert severity='error' className={styles.alert}>
            {error.message ?? 'Could not get details for this visit.'}
          </Alert>
        ) : (
          <>
            <ContentColumn>
              <SubHeader title='Event details' />
              <Paper className={styles.paper}>
                <VisitInfo isLoading={isLoading} identification={identification} />
              </Paper>
            </ContentColumn>

            <ContentColumn>
              <SubHeader title='Smart Signals' />
              <Paper className={styles.paper}>
                <SmartSignals isLoading={isLoading} event={event} subscription={subscription} />
              </Paper>
            </ContentColumn>

            <ContentColumn>
              <SubHeader title='Response' />
              <Paper className={styles.paper}>
                <Response isLoading={isLoading} event={event} identification={identification} />
              </Paper>
            </ContentColumn>

            <ContentColumn>
              <SubHeader title='Visitor history' />
              <Paper className={styles.paper}>
                <VisitorHistory
                  isLoading={isLoading}
                  identification={identification}
                  subscription={subscription}
                  query={query}
                />
              </Paper>
            </ContentColumn>
          </>
        )}
      </MainColumn>
    </>
  )
}

type VisitBreadcrumbsProps = {
  isLoading?: boolean
  identification?: IdentificationProduct
  subscription?: ExpandedSubscription
}

function VisitBreadcrumbs({ isLoading, identification, subscription }: VisitBreadcrumbsProps) {
  const currentSubscriptionId = subscription?.id ?? ''

  return (
    <Breadcrumbs aria-label='breadcrumbs' separator='/' className='mt-2 [&>*>*:last-child]:h-5'>
      <Link
        component={RouterLink}
        to={buildRoute(AppRoute.IdentificationEvents, { subscriptionId: currentSubscriptionId })}
        color='inherit'
        underline='hover'
      >
        Identification
      </Link>

      {isLoading ? (
        <Skeleton width={200} data-testid='visit-details-breadcrumbs-skeleton' />
      ) : (
        <Typography component='span' className='text-sm font-mono'>
          {identification?.requestId}
        </Typography>
      )}
    </Breadcrumbs>
  )
}

type VisitInfoProps = {
  isLoading?: boolean
  identification?: IdentificationProduct
}

function VisitInfo({ isLoading, identification }: VisitInfoProps) {
  const visitInfo = [
    { key: 'Date', value: identification && formatDatetime(identification.time, 'precise') },
    {
      key: 'requestID',
      value: identification?.requestId,
      monospace: true,
      copyText: identification?.requestId,
      onCopy: useCallback(() => ampli.identificationRequestIdCopied(), []),
    },
    {
      key: 'visitorID',
      value: identification?.visitorId ?? 'Not available',
      monospace: !!identification?.visitorId,
      faded: !identification?.visitorId,
      copyText: identification?.visitorId,
      onCopy: useCallback(() => ampli.identificationVisitorIdCopied(), []),
    },
    {
      key: 'linkedID',
      value: identification?.linkedId ?? 'Not provided',
      monospace: !!identification?.linkedId,
      faded: !identification?.linkedId,
      copyText: identification?.linkedId,
      onCopy: useCallback(() => ampli.identificationLinkedIdCopied(), []),
    },
  ]

  return <KeyValueList items={visitInfo} isLoading={isLoading} className={styles.keyValueList} />
}

const BLURRED_VALUES = ['Apricot', 'Pumpkin', 'Kiwano', 'Tangerine', 'Kaki persimmon', 'Orange tangelo']

type SmartSignalsProps = {
  isLoading?: boolean
  subscription?: ExpandedSubscription
  event?: PlatformEvent
}

function SmartSignals({ isLoading, subscription, event }: SmartSignalsProps) {
  const isEnterprise = subscription?.type === SubscriptionType.Prepaid
  const availableSmartSignals = getAvailableSmartSignals(subscription)
  const enabledSmartSignals = getEnabledSmartSignals(subscription)
  const missingSmartSignals = availableSmartSignals.filter((signal) => !enabledSmartSignals.includes(signal))

  const canUpgradeToSmartSignals =
    subscription &&
    missingSmartSignals.length > 0 &&
    [SubscriptionDisplayPlan.Free, SubscriptionDisplayPlan.Pro].includes(getSubscriptionDisplayPlan(subscription))

  // Use pro plus signals while loading to show proper skeletons while enterprise signals are being fetched.
  const smartSignalProducts = isLoading || !isEnterprise ? PRO_PLUS_DISPLAY_SIGNALS : ENTERPRISE_SMART_SIGNALS
  const smartSignals: Entry[] = smartSignalProducts
    .map((product) => ({
      product,
      key: SIGNAL_NAMES[product],
      formatted: PRODUCT_FORMATTERS[product](event),
    }))
    // While loading, don't filter out empty values so the skeletons can render.
    // Subscriptions that don't have access to smart signals show the available signals with an upgrade prompt.
    .filter(({ product, formatted }) => formatted !== undefined || isLoading || !enabledSmartSignals.includes(product))
    .map(({ product, key, formatted }, index) => {
      const blurred = !enabledSmartSignals.includes(product)
      const text = blurred ? BLURRED_VALUES[index % BLURRED_VALUES.length] : (formatted?.value ?? 'Upgrade now')
      const formattedValue = formatted?.warning ? (
        <Stack direction='row' spacing={1} alignItems='center'>
          <span>{text}</span>
          <TriangleAlert className='text-red-700 size-4' />
        </Stack>
      ) : (
        <>{text}</>
      )

      return {
        key,
        blurred,
        value: blurred ? text : formattedValue,
        faded: !blurred && formatted?.faded,
      }
    })
  const halfSmartSignalsLength = Math.ceil(smartSignals.length / 2)

  return (
    <>
      {!isLoading && subscription && (
        <GetSmartSignals
          subscription={subscription}
          canUpgradeToSmartSignals={canUpgradeToSmartSignals}
          availableSmartSignals={availableSmartSignals}
          missingSmartSignals={missingSmartSignals}
        />
      )}

      <div className={styles.signalsContainer}>
        <KeyValueList
          items={smartSignals.slice(0, halfSmartSignalsLength)}
          isLoading={isLoading}
          className={styles.keyValueList}
        />
        <KeyValueList
          items={smartSignals.slice(halfSmartSignalsLength)}
          isLoading={isLoading}
          className={styles.keyValueList}
        />
      </div>
    </>
  )
}

type GetSmartSignalsProps = {
  subscription: Pick<Subscription, 'id' | 'type'>
  availableSmartSignals: AppProduct[]
  missingSmartSignals: AppProduct[]
  canUpgradeToSmartSignals?: boolean
}

function GetSmartSignals({
  subscription,
  canUpgradeToSmartSignals,
  availableSmartSignals,
  missingSmartSignals,
}: GetSmartSignalsProps) {
  const { data: userContext } = useUserContext()

  if (missingSmartSignals.length === 0) {
    return null
  }

  if (canUpgradeToSmartSignals && subscription.type !== SubscriptionType.TrialOnly) {
    return (
      <Alert severity='info' className={styles.smartSignalsAlert}>
        <Typography variant='bodyMMedium'>This visitor could be masking their true identity</Typography>
        <br />
        <Typography variant='bodyM' className={styles.infoText}>
          <RouterLink
            to={buildRoute(
              AppRoute.ChangePlan,
              { subscriptionId: subscription.id },
              {
                action: userContext?.isEligibleForAndroidFreemium
                  ? ChangePlanAction.UpgradeToPlus99AndroidFreemium
                  : ChangePlanAction.UpgradeToPlus99,
              }
            )}
            onClick={() =>
              ampli.billingPlanUpgradeConsidered({
                newBillingPlanName: 'Fingerprint Pro Plus',
                context: 'identification',
              })
            }
            className={styles.upgradeLink}
          >
            Upgrade to Pro Plus
          </RouterLink>{' '}
          to see device intelligence about future events like this one.
        </Typography>
      </Alert>
    )
  }

  const isMissingAll = availableSmartSignals.length === missingSmartSignals.length
  return (
    <Alert severity='info' className={styles.smartSignalsAlert}>
      <Typography variant='bodyMMedium'>This visitor could be masking their true identity</Typography>
      <br />
      <Typography variant='bodyM' className={styles.infoText}>
        <Link href={SUPPORT_EMAIL_MAILTO} underline='hover'>
          Contact support
        </Link>{' '}
        to
        {isMissingAll ? ' see device intelligence about ' : ' enable more smart signals for '}
        future events like this one.
      </Typography>
    </Alert>
  )
}

type RequestProps = {
  isLoading?: boolean
  identification?: IdentificationProduct
  event?: PlatformEvent
}

function Response({ event, identification, isLoading }: RequestProps) {
  return identification || isLoading ? (
    <CodeViewer
      code={formatJSON(event?.products ?? {})}
      skeletonPrefix='{'
      skeletonIndent={15}
      isLoading={isLoading}
      className={styles.codeViewer}
      onCopy={() => ampli.identificationRequestCopied()}
    />
  ) : null
}

type VisitorHistoryProps = {
  isLoading?: boolean
  identification?: IdentificationProduct
  subscription?: ExpandedSubscription
  query: UseInfiniteQueryResult<InfiniteData<PaginatedVisitsResponse>, GenericError>
}
function VisitorHistory({ isLoading, query, identification, subscription }: VisitorHistoryProps) {
  const currentSubscriptionId = subscription?.id ?? ''

  const pagination = usePaginationForQuery(query, (page) => page.visits)
  const { values: visits } = pagination

  const hasHistory = visits != null && visits.length >= 1
  const historyStart = hasHistory && formatDateShortFullYear(new Date(visits[visits.length - 1].timestamp))
  const historyEnd = hasHistory && formatDateShortFullYear(new Date(visits[0].timestamp))

  return (
    <VisitsList
      selectedRequestId={identification?.requestId}
      getVisitPath={(visit) =>
        buildRoute(AppRoute.IdentificationEventDetails, {
          subscriptionId: currentSubscriptionId,
          requestId: visit.requestId,
        })
      }
      query={query}
      pagination={pagination}
      footerContent={
        hasHistory ? (
          <Typography variant='bodyM'>
            {query.isLoading ? (
              <Skeleton width={74} />
            ) : (
              <>
                {historyEnd}
                {historyEnd !== historyStart && ` - ${historyStart}`}
              </>
            )}
          </Typography>
        ) : null
      }
      isLoading={isLoading}
    />
  )
}
