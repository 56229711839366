import { ResponsiveLine, SliceTooltipProps } from '@nivo/line'
import mapValues from 'lodash/mapValues'
import { DateTime } from 'luxon'
import { useReducer } from 'react'

import { generateTickValues, pickDateValues } from '../../../../helpers/chart'
import { ProxyIntegrationRequests, RequestsMadeByDay } from '../../../../models'
import { formatDate } from '../../../commonUI'
import { IntegrationUsageValueColor } from '../IntegrationPage/types'
import ChartCounter from './Counter/ChartCounter'
import styles from './ProxyIntegrationUsageChart.module.scss'

export type ProxyIntegrationUsageChartProps = {
  requests: ProxyIntegrationRequests
  integrationName: string
}

type ToggleCount = {
  proxyRequests: boolean
  nonProxyRequests: boolean
  proxySecretErrors: boolean
  allRequests: boolean
  proxyRequestsWithInvalidEtldPlusOne: boolean
}

type ToggleCountEvent = {
  type: keyof ToggleCount
}

const humanizeNumber = (num: number) => {
  const separators = ['', 'K', 'M', 'B', 'T']
  const formatted = Math.floor(Math.log(num) / Math.log(1000))
  const result = num !== 0 ? num / Math.pow(1000, formatted) + ' ' + separators[formatted] : 0
  return result
}

const roundUpToPretty = (num: number) => {
  const nums: number[] = []
  const dividers = [1, 2, 4, 5]
  Array.from({ length: 15 }).forEach((_, i) => {
    dividers.forEach((divider) => nums.push(Math.ceil(Math.pow(10, i) / divider)))
  })
  return Math.min(...nums.filter((t) => t > num))
}

const CustomTooltip = ({ slice: { points } }: SliceTooltipProps) => {
  return (
    <div className={styles.customTooltip}>
      <span>{DateTime.fromJSDate(new Date(points[0].data.x)).toFormat('MM/dd/yyyy')}</span>
      {points.map((item) => (
        <div key={`${item.id}_${item.index}`} className={styles.customTooltipItem}>
          <span>{item.serieId}:</span>
          <strong className={styles.customTooltipTitle}>{item.data.yFormatted}</strong>
        </div>
      ))}
    </div>
  )
}

const toggleCountReducer = (state: ToggleCount, dispatch: ToggleCountEvent) => {
  switch (dispatch.type) {
    default:
      return { ...state }
    case 'allRequests':
      return { ...state, allRequests: !state.allRequests }
    case 'nonProxyRequests':
      return { ...state, nonProxyRequests: !state.nonProxyRequests }
    case 'proxyRequests':
      return { ...state, proxyRequests: !state.proxyRequests }
    case 'proxySecretErrors':
      return { ...state, proxySecretErrors: !state.proxySecretErrors }
    case 'proxyRequestsWithInvalidEtldPlusOne':
      return { ...state, proxyRequestsWithInvalidEtldPlusOne: !state.proxyRequestsWithInvalidEtldPlusOne }
  }
}

const requestsMadeMapFunction = (item: RequestsMadeByDay) => {
  return {
    x: item.timestamp,
    y: item.value,
  }
}

function getIntegrationUsageColor(type: { id: string }): IntegrationUsageValueColor {
  switch (type.id) {
    case 'All requests':
    default:
      return IntegrationUsageValueColor.AllRequests
    case ChartId.ProxySecretErrors:
      return IntegrationUsageValueColor.ProxySecretErrors
    case ChartId.ProxyRequests:
      return IntegrationUsageValueColor.ProxyRequests
    case ChartId.NonProxyRequests:
      return IntegrationUsageValueColor.NonProxyRequests
    case ChartId.InvalidEtldPlusOne:
      return IntegrationUsageValueColor.InvalidEtldPlusOne
  }
}

enum ChartId {
  ProxyRequests = 'Proxy requests',
  ProxySecretErrors = 'Proxy secret errors',
  NonProxyRequests = 'Other requests',
  AllRequests = 'All requests',
  InvalidEtldPlusOne = 'Requests with invalid domain',
}

const requestMadeToChartData = (
  requests: ProxyIntegrationRequests,
  disabled: Record<keyof ProxyIntegrationRequests, boolean>
) => {
  const data = []
  if (!disabled.proxyRequests) {
    data.push({
      id: ChartId.ProxyRequests,
      color: IntegrationUsageValueColor.ProxyRequests,
      data: requests.proxyRequests.map(requestsMadeMapFunction),
    })
  }
  if (!disabled.proxySecretErrors) {
    data.push({
      id: ChartId.ProxySecretErrors,
      color: IntegrationUsageValueColor.ProxySecretErrors,
      data: requests.proxySecretErrors.map(requestsMadeMapFunction),
    })
  }
  if (!disabled.nonProxyRequests) {
    data.push({
      id: ChartId.NonProxyRequests,
      color: IntegrationUsageValueColor.NonProxyRequests,
      data: requests.nonProxyRequests.map(requestsMadeMapFunction),
    })
  }
  if (!disabled.allRequests) {
    data.push({
      id: ChartId.AllRequests,
      color: IntegrationUsageValueColor.AllRequests,
      data: requests.allRequests.map(requestsMadeMapFunction),
    })
  }
  if (!disabled.proxyRequestsWithInvalidEtldPlusOne) {
    data.push({
      id: ChartId.InvalidEtldPlusOne,
      color: IntegrationUsageValueColor.InvalidEtldPlusOne,
      data: requests.proxyRequestsWithInvalidEtldPlusOne.map(requestsMadeMapFunction),
    })
  }
  return data
}

export function ProxyIntegrationUsageChart({ requests, integrationName }: ProxyIntegrationUsageChartProps) {
  const {
    proxyRequests: proxyRequestsCount,
    allRequests: allRequestsCount,
    proxySecretErrors: proxySecretErrorsCount,
    nonProxyRequests: nonProxyRequestsCount,
    proxyRequestsWithInvalidEtldPlusOne: proxyRequestsWithInvalidEtldPlusOneCount,
  } = mapValues(requests, (values) => values.reduce((acc, item) => acc + item.value, 0))

  const [disabled, dispatchDisabled] = useReducer(toggleCountReducer, {
    nonProxyRequests: false,
    allRequests: false,
    proxyRequests: false,
    proxySecretErrors: false,
    proxyRequestsWithInvalidEtldPlusOne: false,
  })

  const chartData = requestMadeToChartData(requests, disabled)

  const maxRequestValue = roundUpToPretty(Math.max(...requests.allRequests.map((r) => r.value)))
  const leftAxisTickValues = generateTickValues(5, 0, maxRequestValue).map((item) => Math.floor(item))

  const allDateValues = requests.allRequests.map((item) => item.timestamp)
  const bottomAxisTickValues = pickDateValues(7, allDateValues)

  return (
    <div className={styles.integrationUsageChartContainer}>
      <div className={styles.labeledCounterContainer}>
        <ChartCounter
          label='Proxy secret errors'
          value={proxySecretErrorsCount}
          color={IntegrationUsageValueColor.ProxySecretErrors}
          disabled={disabled.proxySecretErrors}
          total={allRequestsCount}
          onDisabled={() => dispatchDisabled({ type: 'proxySecretErrors' })}
          tooltipText={`Requests from your ${integrationName} integration with missing or incorrect proxy secret`}
        />
        <ChartCounter
          label='Invalid domain'
          value={proxyRequestsWithInvalidEtldPlusOneCount}
          color={IntegrationUsageValueColor.InvalidEtldPlusOne}
          disabled={disabled.proxyRequestsWithInvalidEtldPlusOne}
          total={allRequestsCount}
          onDisabled={() => dispatchDisabled({ type: 'proxyRequestsWithInvalidEtldPlusOne' })}
          tooltipText={`Requests from your ${integrationName} integration with invalid domain`}
        />
        <ChartCounter
          label={`${integrationName} proxy requests`}
          value={proxyRequestsCount}
          color={IntegrationUsageValueColor.ProxyRequests}
          disabled={disabled.proxyRequests}
          total={allRequestsCount}
          onDisabled={() => dispatchDisabled({ type: 'proxyRequests' })}
          tooltipText={`All requests successfully proxied through your ${integrationName} integration`}
        />
        <ChartCounter
          label='Other requests'
          value={nonProxyRequestsCount}
          color={IntegrationUsageValueColor.NonProxyRequests}
          disabled={disabled.nonProxyRequests}
          total={allRequestsCount}
          onDisabled={() => dispatchDisabled({ type: 'nonProxyRequests' })}
          tooltipText={`Requests not coming from your ${integrationName} integration`}
        />
        <ChartCounter
          label='All requests'
          value={allRequestsCount}
          color={IntegrationUsageValueColor.AllRequests}
          disabled={disabled.allRequests}
          total={allRequestsCount}
          isTotal={true}
          onDisabled={() => dispatchDisabled({ type: 'allRequests' })}
          tooltipText='All successful identification requests'
        />
      </div>
      <div className={styles.chartContainer}>
        <ResponsiveLine
          data={chartData}
          colors={getIntegrationUsageColor}
          margin={{ top: 50, bottom: 70, right: 8, left: 50 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: maxRequestValue,
            stacked: false,
            reverse: false,
          }}
          xFormat={(value) => formatDate(new Date(value))}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: -65,
            legend: '',
            legendOffset: 48,
            format: (value) => DateTime.fromJSDate(new Date(value)).toFormat('MMM, dd'),
            tickValues: bottomAxisTickValues,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -40,
            legendPosition: 'middle',
            tickValues: leftAxisTickValues,
            format: humanizeNumber,
          }}
          enableSlices='x'
          sliceTooltip={CustomTooltip}
          pointSize={8}
          enablePoints={true}
          curve='linear'
          enableGridX={false}
          enableGridY={true}
        />
      </div>
    </div>
  )
}
