import { Grid, Link, Switch, Tooltip, Typography } from '@mui/material'
import { SubHeader } from 'features/commonUI'
import { ampli } from 'models/ampli'
import { useCallback } from 'react'

import { DOCS_AI_BOTS_FILTERING, DOCS_SEARCH_BOTS_FILTERING } from '../../../../const'
import {
  useApplicationFeatureToggleMutation,
  useIsApplicationFeatureEnabled,
  usePermissions,
  useToast,
} from '../../../../hooks'
import { ApplicationFeatureName } from '../../../../models'
import { useCurrentSubscription } from '../../../subscription'
import styles from './BotsSection.module.scss'

export default function BotsSection() {
  const { mutate: sendApplicationFeatureToggleRequest } = useApplicationFeatureToggleMutation()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { isReadOnly } = usePermissions()

  const isExcludeSearchBotsEnabled = useIsApplicationFeatureEnabled(
    currentSubscriptionId,
    ApplicationFeatureName.FilterSearchBots
  )
  const isExcludeAiBotsEnabled = useIsApplicationFeatureEnabled(
    currentSubscriptionId,
    ApplicationFeatureName.FilterAiBots
  )
  const { showToast } = useToast()

  const handleChange = useCallback(
    (
      featureName: ApplicationFeatureName,
      readableFeatureName: string,
      isEnabled: boolean,
      onEnable?: () => void,
      onDisable?: () => void
    ) => {
      sendApplicationFeatureToggleRequest(
        {
          params: { subscriptionId: currentSubscriptionId },
          data: { featureName },
        },
        {
          onSuccess: () => {
            if (!isEnabled) {
              onEnable?.()
            } else {
              onDisable?.()
            }
          },
          onError: () => {
            showToast({
              message: `An error occurred when trying to toggle the exclude ${readableFeatureName} feature. Please try again.`,
              severity: 'error',
            })
          },
        }
      )
    },
    [currentSubscriptionId, sendApplicationFeatureToggleRequest, showToast]
  )

  const handleToggleSearchBots = () =>
    handleChange(
      ApplicationFeatureName.FilterSearchBots,
      'search bots',
      isExcludeSearchBotsEnabled,
      () => ampli.searchBotsFilteringEnabled(),
      () => ampli.searchBotsFilteringDisabled()
    )
  const handleToggleAiBots = () =>
    handleChange(
      ApplicationFeatureName.FilterAiBots,
      'AI bots',
      isExcludeAiBotsEnabled,
      () => ampli.aiBotsFilteringEnabled(),
      () => ampli.aiBotsFilteringDisabled()
    )

  return (
    <BotsSectionView
      toggles={[
        {
          name: 'Search Bots',
          descriptionName: 'search engine bots',
          docs: DOCS_SEARCH_BOTS_FILTERING,
          isToggleEngaged: isExcludeSearchBotsEnabled,
          onToggle: handleToggleSearchBots,
          isEditingDisabled: isReadOnly,
        },
        {
          name: 'AI Bots',
          descriptionName: 'AI bots',
          docs: DOCS_AI_BOTS_FILTERING,
          isToggleEngaged: isExcludeAiBotsEnabled,
          onToggle: handleToggleAiBots,
          isEditingDisabled: isReadOnly,
        },
      ]}
    />
  )
}

export interface BotsSectionViewProps {
  toggles: BotToggleProps[]
}

// disconnected for storybook
export function BotsSectionView({ toggles }: BotsSectionViewProps) {
  return (
    <Grid item xs={12} className={styles.grid}>
      <SubHeader
        title='Bots'
        description={<Typography variant='bodyM'>Filter out unwanted, automated identification requests.</Typography>}
        className='mb-4'
      />

      <section>
        {toggles.map((props) => (
          <BotToggle key={props.name} {...props} />
        ))}
      </section>
    </Grid>
  )
}

interface BotToggleProps {
  name: string
  descriptionName: string
  docs: string
  isEditingDisabled: boolean
  isToggleEngaged: boolean
  onToggle: () => void
}

function BotToggle({ name, descriptionName, docs, isEditingDisabled, isToggleEngaged, onToggle }: BotToggleProps) {
  return (
    <div className={styles.textSection}>
      <Tooltip title={isEditingDisabled ? 'You do not have sufficient permissions to edit this setting' : ''}>
        <div className={styles.switchContainer}>
          <Switch
            id='active'
            color='primary'
            checked={isToggleEngaged}
            onChange={onToggle}
            disabled={isEditingDisabled}
          />
        </div>
      </Tooltip>
      <div className={styles.text}>
        <Typography variant='h3' component='h1' className={styles.title}>
          Exclude {name}
        </Typography>
        <Typography variant='body2'>
          Turn on to exclude {descriptionName} from your billable usage.{' '}
          <Link underline='hover' className={styles.link} href={docs} target={'_blank'}>
            Learn more
          </Link>
        </Typography>
      </div>
    </div>
  )
}
