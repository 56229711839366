import { useQuery } from '@tanstack/react-query'
import { IS_ON_VERCEL, VERCEL_SKEW_PROTECTION_ENABLED } from 'const'

type UpdateAvailableResponse = {
  currentDeploymentId: string
  availableDeploymentId: string
  updateAvailable: boolean
}

const queryKey = ['deployment-update-available'] as const
export function useUpdatedDeploymentsAvailable() {
  const { data: currentDeploymentId } = useQuery({
    queryKey: ['deployment-id'],
    queryFn: () => fetch('/deployment').then((r) => r.json().then((j) => j?.deploymentId) as Promise<string>),
    retry: false,
    gcTime: Infinity,
    staleTime: Infinity,
    enabled: !!IS_ON_VERCEL && VERCEL_SKEW_PROTECTION_ENABLED,
  })

  return useQuery({
    queryKey,
    queryFn: () =>
      fetch('/deployment?dpl=upstream')
        .then((r) => r.json() as Promise<{ deploymentId: string }>)
        .then(
          ({ deploymentId: availableDeploymentId }) =>
            ({
              currentDeploymentId: currentDeploymentId!,
              availableDeploymentId,
              updateAvailable: !!currentDeploymentId && currentDeploymentId !== availableDeploymentId,
            }) satisfies UpdateAvailableResponse
        ),
    retry: false,
    refetchInterval: 60_000,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    enabled: !!IS_ON_VERCEL && VERCEL_SKEW_PROTECTION_ENABLED && !!currentDeploymentId,
  })
}
