import { BigIntValue, DataCard, NumericValue } from 'features/commonUI'
import { CustomDateRange } from 'helpers/dateRange'
import { DateTime } from 'luxon'
import { StatisticReturnTypes, SubscriptionStatistic } from 'models'
import { ReactNode } from 'react'

import { WIDGETS } from './const'

export function InsightsNumericWidget({
  type,
  statistic,
  title,
  availabilityBoundary,
  isError,
  compareDateRange,
}: {
  type: SubscriptionStatistic
  statistic?: StatisticReturnTypes
  title?: ReactNode
  availabilityBoundary?: DateTime
  isError: boolean
  compareDateRange?: CustomDateRange
}) {
  return (
    <DataCard title={title} info={WIDGETS[type].info} hasError={isError}>
      {WIDGETS[type].type === 'bigint' && (
        <BigIntValue
          availabilityBoundary={availabilityBoundary}
          value={statistic?.data.currentPeriod ? BigInt(statistic?.data.currentPeriod) : undefined}
          previousValue={statistic?.data?.prevPeriod ? BigInt(statistic?.data.prevPeriod) : undefined}
          compareDateRange={compareDateRange}
        />
      )}
      {WIDGETS[type].type === 'precise' && (
        <NumericValue
          availabilityBoundary={availabilityBoundary}
          value={parseFloat(statistic?.data?.currentPeriod?.toString() ?? '0')}
          previousValue={parseFloat(statistic?.data?.prevPeriod?.toString() ?? '0')}
          compareDateRange={compareDateRange}
          precise
        />
      )}
    </DataCard>
  )
}
