import { CounterQueryType, ENTERPRISE_SMART_SIGNALS, PRO_PLUS_DISPLAY_SIGNALS, SubscriptionDisplayPlan } from 'const'
import {
  AppProduct,
  BillingType,
  ExpandedSubscription,
  RestrictedReason,
  Subscription,
  SubscriptionStatus,
  SubscriptionTier,
  SubscriptionType,
} from 'models'

import { PartialWithRequired } from './types'

export function canUpgrade(subscription?: PartialWithRequired<Subscription, 'type' | 'status' | 'restrictedReason'>) {
  return (
    subscription?.type === SubscriptionType.Free &&
    subscription?.status !== SubscriptionStatus.Canceled &&
    !(
      subscription?.status === SubscriptionStatus.Restricted &&
      subscription?.restrictedReason === RestrictedReason.AccountEmailNotConfirmed
    )
  )
}

export function canCreate<SubscriptionType extends Pick<Subscription, 'type' | 'status' | 'restrictedReason'>>(
  subscriptions?: SubscriptionType[]
) {
  return (
    subscriptions &&
    (subscriptions.length > 1 || subscriptions[0]?.type !== SubscriptionType.Free || isEmailConfirmed(subscriptions))
  )
}

export function isEmailConfirmed<SubscriptionType extends Pick<Subscription, 'status' | 'restrictedReason'>>(
  subscriptions?: SubscriptionType[]
) {
  return subscriptions
    ? !subscriptions.find(
        (subscription) =>
          subscription.status === SubscriptionStatus.Restricted &&
          subscription.restrictedReason === RestrictedReason.AccountEmailNotConfirmed
      )
    : false
}

export function canCancel(subscription: PartialWithRequired<Subscription, 'type' | 'status' | 'cancelAt'>) {
  return (
    subscription.type !== SubscriptionType.Free &&
    subscription.type !== SubscriptionType.TrialOnly &&
    subscription.type !== SubscriptionType.Prepaid &&
    subscription.status !== SubscriptionStatus.Restricted &&
    subscription.status !== SubscriptionStatus.Canceled &&
    subscription.status !== SubscriptionStatus.Trialing &&
    !subscription.cancelAt &&
    !subscription.downgradeScheduledAt
  )
}

export function canRename(subscription: PartialWithRequired<Subscription, 'status'>) {
  return subscription.status !== SubscriptionStatus.Canceled
}

export function getIdentificationCounterQueryType(subscription: PartialWithRequired<Subscription, 'billingType'>) {
  switch (subscription.billingType) {
    case BillingType.ApiCalls:
      return CounterQueryType.IdentificationApiCalls
    case BillingType.UniqueVisitors:
      return CounterQueryType.IdentificationUniqueVisitors
  }
}

export function isProductEnabled(
  subscription: PartialWithRequired<Subscription, 'products'> | undefined,
  product: AppProduct
) {
  return !!subscription?.products?.find(({ product: enabledProduct }) => product === enabledProduct)
}

export function getAvailableSmartSignals(
  subscription: Pick<ExpandedSubscription, 'type' | 'products' | 'latestTier'> | undefined
) {
  if (subscription) {
    return subscription.type === SubscriptionType.Prepaid ? ENTERPRISE_SMART_SIGNALS : PRO_PLUS_DISPLAY_SIGNALS
  }
  return []
}

export function getEnabledSmartSignals(
  subscription: Pick<ExpandedSubscription, 'type' | 'products' | 'latestTier'> | undefined
) {
  if (subscription) {
    return getAvailableSmartSignals(subscription).filter((product) => isProductEnabled(subscription, product))
  }
  return []
}

export function getSubscriptionDisplayPlan(
  subscription: Pick<ExpandedSubscription, 'type' | 'latestTier' | 'products'>
): SubscriptionDisplayPlan {
  switch (subscription.type) {
    case SubscriptionType.Free:
      return SubscriptionDisplayPlan.Free
    case SubscriptionType.Paid:
    case SubscriptionType.TrialOnly:
      if (subscription.latestTier) {
        switch (subscription.latestTier) {
          case SubscriptionTier.Plus:
            return SubscriptionDisplayPlan.Plus
          case SubscriptionTier.PlusAndroidFreemium:
            return SubscriptionDisplayPlan.PlusAndroidFreemium
          case SubscriptionTier.Plus99:
            return SubscriptionDisplayPlan.Plus99
          case SubscriptionTier.Plus99AndroidFreemium:
            return SubscriptionDisplayPlan.Plus99AndroidFreemium
          default:
            return SubscriptionDisplayPlan.Pro
        }
      } else {
        // If the tier is not available, try to infer it from the products.
        return isProductEnabled(subscription, AppProduct.Botd)
          ? // It can be either Plus or Plus99, but Plus99 is a default one during the Q1 2024 pricing experiment
            SubscriptionDisplayPlan.Plus99
          : SubscriptionDisplayPlan.Pro
      }
    case SubscriptionType.Prepaid:
    case SubscriptionType.ProofOfConcept:
      return SubscriptionDisplayPlan.Enterprise
  }
}

export function isCustomerLedTrial(
  subscription?: PartialWithRequired<Subscription, 'type' | 'status'>
): subscription is PartialWithRequired<Subscription, 'type' | 'status'> {
  return subscription?.type === SubscriptionType.TrialOnly && subscription?.status !== SubscriptionStatus.ProofOfConcept
}

export function isFreeSubscription(subscription?: PartialWithRequired<Subscription, 'type'>) {
  return subscription?.type === SubscriptionType.Free || subscription?.type === SubscriptionType.TrialOnly
}
