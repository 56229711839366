import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useToast } from '../toast'
import { extractData, useBuildRequest, useRequestMutation } from './base'

// This function exists to specifically fetch data related to CloudFront, which is excluded from the more generic useIntegrationStatsQuery.
export function useCloudFrontIntegrationData(subscriptionId: string) {
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['cloudFrontIntegrationGet', subscriptionId],
    queryFn: () => extractData(buildRequest('cloudFrontIntegrationGet', { params: { subscriptionId } })),
    enabled: Boolean(subscriptionId),
  })
}

export function useCreateCloudFrontIntegration() {
  const { showToast } = useToast()
  const queryClient = useQueryClient()

  return useRequestMutation('cloudFrontIntegrationCreate', {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['cloudFrontIntegrationGet'],
      })
      showToast({
        severity: 'success',
        message: 'Success! Your AWS CloudFront integration now has automatic updates enabled.',
      })
    },
    onError: () => {
      showToast({
        severity: 'error',
        message: 'An error occurred when saving your AWS CloudFront integration changes. Please try again.',
      })
    },
  })
}

export function useUpdateCloudFrontIntegration() {
  const { showToast } = useToast()
  const queryClient = useQueryClient()

  return useRequestMutation('cloudFrontIntegrationUpdate', {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['cloudFrontIntegrationGet'],
      })
      showToast({
        severity: 'success',
        message: 'Success! Your AWS CloudFront integration was updated.',
      })
    },
    onError: () => {
      showToast({
        severity: 'error',
        message: 'An error occurred when saving your AWS CloudFront integration changes. Please try again.',
      })
    },
  })
}

export function useDeleteCloudFrontIntegration() {
  const { showToast } = useToast()
  const queryClient = useQueryClient()

  return useRequestMutation('cloudFrontIntegrationDelete', {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['cloudFrontIntegrationGet'],
      })
      showToast({
        severity: 'success',
        message:
          'Automatic updates for your AWS CloudFront integration have been disabled. This make take a few minutes to  take effect.',
      })
    },
    onError: () => {
      showToast({
        severity: 'error',
        message:
          'An error occurred when trying to disable updates for your AWS CloudFront integration. Please try again.',
      })
    },
  })
}
