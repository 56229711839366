import { OctagonAlert } from 'lucide-react'
import { FC, forwardRef, ReactNode, useMemo } from 'react'
import {
  Input as RACInput,
  InputProps,
  LabelProps,
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  TextFieldRenderProps,
  TextProps,
  ValidationResult,
} from 'react-aria-components'
import { tv, VariantProps } from 'tailwind-variants'

import { Description, FieldError, Label } from '../Field'
import { cn } from '../utils'

const fieldStyles = tv({
  base: [],
  slots: {
    description: [],
    label: [],
    error: [],
  },
  variants: {
    isDisabled: {
      true: { label: 'text-gray-600' },
    },
    isReadOnly: {
      true: { label: 'text-gray-900' },
    },
  },
})

const inputStyles = tv({
  base: [],
  slots: {
    wrapper: [
      'flex flex-row gap-3 items-center relative',
      'text-base text-gray-1000 placeholder:text-gray-800 disabled:text-gray-600',
      'bg-white border border-gray-400 rounded-sm',
      'flex-1 w-full min-w-0',
    ],
    input: ['outline-none bg-transparent flex-grow'],
    prefix: ['text-gray-800'],
    suffix: ['text-gray-800'],
    shadow: ['absolute inset-0', 'shadow-[inset_0_1px_0_0] shadow-[rgba(0,0,0,0.07)]', 'pointer-events-none'],
  },
  variants: {
    size: {
      default: { wrapper: 'px-3 h-8', input: 'h-8' },
      lg: { wrapper: 'px-3 h-10', input: 'h-10' },
    },
    isDisabled: {
      true: {
        wrapper: 'border-gray-400 bg-gray-200 cursor-not-allowed *:cursor-not-allowed text-gray-600',
        label: 'text-gray-600',
      },
      false: { wrapper: 'cursor-text' },
    },
    isReadOnly: {
      true: {
        wrapper: ['bg-gray-200 cursor-not-allowed focus:outline-0 hover:border-gray-400'],
        input: 'text-gray-900',
      },
      false: {
        wrapper: [
          'focus-within:border-primary',
          'focus-within:shadow-[0_0_0_2px] focus-within:shadow-orange-400',
          'focus:outline-offset-4 focus:border-gray-500 focus:outline-primary focus:shadow-none',
          'focus-within:hover:border-primary focus:hover:border-gray-600',
        ],
      },
    },
    isInvalid: {
      true: { wrapper: 'border-red-800 bg-red-100' },
      false: { wrapper: 'hover:border-gray-500' },
    },
    isFocusWithin: {
      false: { wrapper: '' },
      true: { wrapper: 'border-pink-500 border-5' },
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

type TextFieldVariantProps = VariantProps<typeof inputStyles> & VariantProps<typeof fieldStyles>

export interface TextFieldProps extends TextFieldVariantProps, AriaTextFieldProps {
  label?:
    | ((props: { Label: FC<LabelProps>; necessityIndicator?: 'icon' | 'label'; className: string }) => ReactNode)
    | ReactNode
  placeholder?: string
  description?: ((props: { Description: FC<TextProps>; className: string }) => ReactNode) | ReactNode
  errorMessage?: string | ((validation: ValidationResult) => string)
  necessityIndicator?: 'icon' | 'label'
  classes?: {
    wrapper?: string
    input?: string
    shadow?: string
    description?: string
    label?: string
    error?: string
  }
  prefix?: ReactNode
  suffix?: ReactNode
}

function FieldPrimitive({
  label,
  description,
  errorMessage,
  className,
  classes,
  necessityIndicator,
  children,
  ...props
}: TextFieldProps) {
  const necessityIndicatorString = useMemo(() => {
    if (necessityIndicator === 'label') {
      if (props.isRequired) {
        return ' (required)'
      } else {
        return ' (optional)'
      }
    }
    if (necessityIndicator === 'icon' && props.isRequired) {
      return ' *'
    }

    return ''
  }, [necessityIndicator, props.isRequired])

  return (
    <AriaTextField {...props} className={cn(classes?.wrapper, className, 'flex flex-col gap-1')}>
      {(renderProps) => {
        const sx = fieldStyles(renderProps)

        return (
          <>
            {label &&
              (typeof label === 'function' ? (
                label({ Label, necessityIndicator, className: sx.label({ ...renderProps }) })
              ) : (
                <Label className={sx.label({ className: classes?.label, ...renderProps })}>
                  {label}
                  {necessityIndicatorString}
                </Label>
              ))}
            {typeof children === 'function' ? children(renderProps) : children}
            {description &&
              (typeof description === 'function' ? (
                description({
                  Description,
                  className: sx.description(),
                })
              ) : (
                <Description className={sx.description({ className: classes?.description })}>{description}</Description>
              ))}
            <FieldError className={sx.error({ className: classes?.error })}>
              {({ isInvalid, defaultChildren, ...validationResult }) => {
                return typeof errorMessage === 'function' ? (
                  errorMessage({ isInvalid, ...validationResult })
                ) : (
                  <div className='grid grid-cols-[auto_1fr] sm:grid-cols-[1rem_1fr] text-xs gap-1 items-center'>
                    <OctagonAlert size={12} />
                    {defaultChildren}
                  </div>
                )
              }}
            </FieldError>
          </>
        )
      }}
    </AriaTextField>
  )
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, suffix, prefix, autoFocus, ...props }, ref) => {
    return (
      <FieldPrimitive {...props}>
        {(renderProps) => (
          <CompositeInputField
            ref={ref}
            {...renderProps}
            suffix={suffix}
            prefix={prefix}
            classes={props.classes}
            autoFocus={autoFocus}
            size={props.size}
          />
        )}
      </FieldPrimitive>
    )
  }
)

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <RACInput {...props} ref={ref} />
})

type CompositeInputFieldProps = {
  size?: TextFieldProps['size']
  prefix?: TextFieldProps['prefix']
  suffix?: TextFieldProps['suffix']
  autoFocus?: boolean
  classes?: {
    wrapper?: string
    input?: string
    shadow?: string
    description?: string
    label?: string
    error?: string
  }
} & TextFieldRenderProps & {
    defaultChildren: ReactNode | undefined
  }
const CompositeInputField = forwardRef<HTMLInputElement, CompositeInputFieldProps>(
  ({ size, prefix, suffix, classes, ...renderProps }, forwardedRef) => {
    const sx = inputStyles({
      size,
      ...renderProps,
    })

    return (
      <div className={cn(sx.wrapper(renderProps), classes?.wrapper)}>
        <span className={cn(sx.shadow(renderProps), classes?.shadow)} />
        {prefix && <span className={sx.prefix(renderProps)}>{prefix}</span>}
        <Input ref={forwardedRef} {...renderProps} className={cn(sx.input(renderProps), classes?.input)} />
        {suffix && <span className={sx.suffix(renderProps)}>{suffix}</span>}
      </div>
    )
  }
)
