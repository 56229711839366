import { Skeleton, Table } from '@mui/material'
import { curveMonotoneX } from '@visx/curve'
import { Group } from '@visx/group'
import { TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import {
  ChartError,
  ChartGraph,
  ChartHeader,
  ChartLayout,
  ChartLegend,
  ChartLegendItem,
  ChartLoading,
  ChartMainValue,
  ChartNoData,
  ChartPill,
  ChartTitle,
  ChartValue,
  ChartValueComparison,
  CircleMark,
  formatChartTimestamp,
  LineWithShadow,
  OrangeMarker,
  PurpleMarker,
  ResponsiveVisXChart,
  TooltipContainer,
  TooltipContent,
  TooltipHeader,
  useTimelineIndexer,
} from 'features/chart'
import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum, formatNumShort } from 'helpers/format'
import { useCurrentUser } from 'hooks/api/users'
import { ChartAggregation, SubscriptionStatistic, SubscriptionStatisticValue } from 'models'
import { Fragment } from 'react'

import { useInsightsStats } from '../../../WorkspaceInsights/useInsightsStats'
import { useFailedApiCallsComparison, Value } from '../hooks/useFailedApiCallsComparison'
import { useFailedApiCallsTooltipData } from '../hooks/useFailedApiCallsTooltipData'
import { InsightsChartProps } from '../InsightsChartProps'

const timestampAccessor = (v: Value) => v.timestamp.valueOf()

export function FailedApiCallsContent({ title, action, granularity, showTable }: InsightsChartProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const { isLoading, error, values, xDomain, yDomain } = useFailedApiCallsComparison(
    subscriptionId,
    currentUser,
    dateRange,
    granularity
  )

  const { handlePointer, tooltipIndex, position } = useTimelineIndexer(values, timestampAccessor)
  const tooltipData = useFailedApiCallsTooltipData(tooltipIndex, values, dateRange)

  return (
    <ChartLayout>
      <Header title={title} action={action} />
      {showTable ? (
        <ChartTable isLoading={isLoading} values={values} granularity={granularity} />
      ) : (
        <>
          <Legend />
          <ChartGraph>
            {isLoading ? <ChartLoading /> : null}
            {error ? <ChartError /> : null}
            {!isLoading && !error && values != null && values?.length === 0 ? <ChartNoData /> : null}
            {isLoading || error || values?.length === 0 ? null : (
              <ResponsiveVisXChart
                timezone={currentUser?.timezone}
                xDomain={xDomain}
                yDomain={yDomain}
                margin={{ top: 0, right: 8, bottom: 20, left: 24 }}
                svgClassName='overflow-visible'
                handlePointer={handlePointer}
              >
                {({ xScale, yScale, parentWidth }) => {
                  const restrictedLine = (
                    <LineWithShadow
                      data={values}
                      curve={curveMonotoneX}
                      x={(point) => xScale(point.timestamp)}
                      y={(point) => yScale(point.restricted ?? 0)}
                      stroke='hsl(var(--fpds-color-purple-7))'
                      strokeWidth={1.5}
                      strokeOpacity={1}
                    />
                  )

                  const throttledLine = (
                    <LineWithShadow
                      data={values}
                      curve={curveMonotoneX}
                      x={(point) => xScale(point.timestamp)}
                      y={(point) => yScale(point.throttled ?? 0)}
                      stroke='hsl(var(--fpds-color-orange-7))'
                      strokeWidth={1.5}
                      strokeOpacity={1}
                    />
                  )

                  const tooltipMarkers =
                    tooltipData != null ? (
                      <>
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.restricted ?? 0)}
                          fill='hsl(var(--fpds-color-purple-7))'
                        />
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.throttled ?? 0)}
                          fill='hsl(var(--fpds-color-orange-7))'
                        />
                      </>
                    ) : null

                  const valuePills =
                    granularity !== 'hour' &&
                    parentWidth / (values.length || 1) > 50 &&
                    values.find((v) => (v.restricted ?? 0) > 0 || (v.throttled ?? 0) > 0)
                      ? values.map((point, i) => (
                          <Fragment key={i}>
                            {point.restricted != null ? (
                              <ChartPill
                                x={xScale(point.timestamp)}
                                y={yScale(point.restricted)}
                                text={`${point.restricted >= 100000 ? formatNumShort(point.restricted) : formatNum(point.restricted)}`}
                                circleColor='hsl(var(--fpds-color-purple-7))'
                                yOffset={32}
                              />
                            ) : null}
                            {point.throttled != null ? (
                              <ChartPill
                                x={xScale(point.timestamp)}
                                y={yScale(point.throttled)}
                                text={`${point.throttled >= 100000 ? formatNumShort(point.throttled) : formatNum(point.throttled)}`}
                                circleColor='hsl(var(--fpds-color-orange-7))'
                              />
                            ) : null}
                          </Fragment>
                        ))
                      : null

                  return (
                    <>
                      {/* To make sure that the lines don't go under the axis, apply a cliping path around the Lines. */}
                      <Group clipPath='url(#content)'>
                        {restrictedLine}
                        {throttledLine}
                      </Group>
                      {tooltipMarkers}
                      {valuePills}
                    </>
                  )
                }}
              </ResponsiveVisXChart>
            )}
            {tooltipData != null ? (
              <TooltipContainer position={position}>
                <TooltipHeader>
                  <span className='font-mono'>{formatChartTimestamp(tooltipData.periodStart, granularity)}</span>
                </TooltipHeader>
                <TooltipContent className='grid grid-cols-[auto_1fr_auto] gap-x-3 gap-y-2 items-center justify-between '>
                  <OrangeMarker />
                  <span className='text-gray-800'>Throttled calls</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.throttled != null ? formatNum(tooltipData.throttled) : 0}
                  </span>

                  <PurpleMarker />
                  <span className='text-gray-800'>Restricted calls</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.restricted != null ? formatNum(tooltipData.restricted) : 0}
                  </span>
                </TooltipContent>
              </TooltipContainer>
            ) : null}
          </ChartGraph>
        </>
      )}
    </ChartLayout>
  )
}

function Header({ title, action }: Pick<InsightsChartProps, 'action' | 'title'>) {
  const { data: stats, isLoading: statsLoading } = useInsightsStats()
  const throttled = stats?.find((s) => s.type === SubscriptionStatistic.ThrottledApiCalls)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.ThrottledApiCalls]
    | undefined
  const restricted = stats?.find((s) => s.type === SubscriptionStatistic.RestrictedApiCalls)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.RestrictedApiCalls]
    | undefined

  const currentThrottled = throttled ? parseInt(throttled.currentPeriod) : null
  const currentRestricted = restricted ? parseInt(restricted.currentPeriod) : null
  const currentValue =
    currentThrottled == null || currentRestricted == null ? null : currentThrottled + currentRestricted

  const previousThrottled = throttled ? parseInt(throttled.prevPeriod) : null
  const previousRestricted = restricted ? parseInt(restricted.prevPeriod) : null
  const previousValue =
    previousThrottled == null || previousRestricted == null ? null : previousThrottled + previousRestricted

  return (
    <ChartHeader>
      <ChartTitle
        title={title}
        info='Shows the number of throttled and restricted API calls for the selected period.'
        action={action}
      />
      <ChartValue>
        <ChartMainValue>
          {currentValue != null ? formatNum(currentValue) : <Skeleton width={80} height={28} />}
        </ChartMainValue>
        <ChartValueComparison
          isLoading={statsLoading}
          value={currentValue ?? undefined}
          previousValue={previousValue ?? undefined}
        />
      </ChartValue>
    </ChartHeader>
  )
}

function Legend() {
  return (
    <ChartLegend>
      <ChartLegendItem>
        <OrangeMarker />
        Throttled calls
      </ChartLegendItem>
      <ChartLegendItem>
        <PurpleMarker />
        Restricted Calls
      </ChartLegendItem>
    </ChartLegend>
  )
}

function ChartTable({
  isLoading,
  values,
  granularity,
}: {
  isLoading?: boolean
  values: Value[]
  granularity?: ChartAggregation
}) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='30%'>Time</TableCell>
          <TableCell width='35%' align='right'>
            Throttled calls
          </TableCell>
          <TableCell width='35%' align='right'>
            Restricted calls
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody columnCount={3} isLoading={isLoading}>
        <TableBodyData>
          {values.map((v) => (
            <TableRow key={v.timestamp.valueOf()}>
              <TableCell>{formatChartTimestamp(v.timestamp, granularity)}</TableCell>
              <TableCell align='right'>{v.throttled != null ? formatNum(v.throttled) : null}</TableCell>
              <TableCell align='right'>{v.restricted != null ? formatNum(v.restricted) : null}</TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
