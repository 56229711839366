import { Header, MainColumn, SettingsLayout } from 'features/commonUI'
import { useDocumentTitle } from 'hooks'

import { USE_WORKSPACE_ENVIRONMENT } from '../../const'
import { WorkspaceEnvironmentTable } from './components/WorkspaceEnvironmentTable/WorkspaceEnvironmentTable'

export function EnvironmentsPage() {
  useDocumentTitle('Environments')

  return (
    <>
      <Header title='Settings' />
      <MainColumn>
        <SettingsLayout>{USE_WORKSPACE_ENVIRONMENT && <WorkspaceEnvironmentTable />}</SettingsLayout>
      </MainColumn>
    </>
  )
}
