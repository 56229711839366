import { Stack } from '@compass/components'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import { ComponentProps, PropsWithChildren } from 'react'

type SubHeaderProps = {
  title?: React.ReactNode
  description?: React.ReactNode
  actions?: React.ReactNode
  actionsPosition?: 'top' | 'bottom'
  keepRowLayout?: boolean
  className?: string
  info?: React.ReactNode
  titleClassName?: string
} & Omit<ComponentProps<'div'>, 'title'>

export function SubHeader({
  title,
  description,
  actions,
  actionsPosition = 'bottom',
  keepRowLayout = false,
  className,
  info,
  titleClassName,
  ...props
}: SubHeaderProps) {
  return (
    <Stack
      direction={keepRowLayout ? 'row' : { default: 'column', lg: 'row' }}
      gapX={10}
      gapY={4}
      className={clsx(
        className,
        keepRowLayout ? 'justify-between' : 'flex-wrap justify-between',
        actionsPosition === 'bottom' ? (description ? 'lg:items-end' : 'lg:items-start') : 'lg:items-start'
      )}
      {...props}
    >
      {title || description ? (
        <Stack direction='column' gap={1}>
          {title ? (
            typeof title === 'string' ? (
              <Typography variant='bodyLMedium' component='h3' className={titleClassName}>
                {title}
              </Typography>
            ) : (
              title
            )
          ) : null}
          {description ? (
            typeof description === 'string' ? (
              <Typography component='h4' variant='bodyM'>
                {description}
              </Typography>
            ) : (
              description
            )
          ) : null}
        </Stack>
      ) : null}
      {actions ? (
        <Stack direction={{ default: 'column', sm: 'row' }} gap={2} className='lg:justify-end'>
          {actions}
        </Stack>
      ) : null}
      {info && <Typography variant='bodyM'>{info}</Typography>}
    </Stack>
  )
}

export function ContentColumn({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx('flex flex-col gap-4', className)}>{children}</div>
}
