import { SelectItem, SelectListBox, SelectPopover, SelectTrigger, SelectValue, Stack } from '@compass/components'
import { Tooltip } from '@compass/Tooltip'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { Select, TooltipTrigger } from 'react-aria-components'

import { SupportedSignalName } from '../../utils/SignalName'
import { SmartSignalMetric } from '../../utils/SmartSignalMetric'
import { supportedSignalsPresentationalInfo } from '../../utils/supportedSignalsPresentationalInfo'

export type SmartSignalChartSelectOption = {
  key: SupportedSignalName
  label: string
}

export function SmartSignalsChartSelector({
  displayedMetrics,
  selectedChart,
  onSelectionChange,
  className,
}: {
  displayedMetrics: SmartSignalMetric[]
  selectedChart?: SupportedSignalName
  onSelectionChange: (selected: SupportedSignalName) => void
  className?: string
}) {
  return (
    <Stack gap={2} className={clsx('items-center', className)}>
      <Select<SmartSignalChartSelectOption>
        aria-label='Displayed Smart Signal'
        selectedKey={selectedChart}
        onSelectionChange={onSelectionChange}
        isDisabled={displayedMetrics == null || displayedMetrics.length === 0}
        className='-ml-2'
      >
        <TooltipTrigger delay={750}>
          <Tooltip>Change displayed Smart Signal</Tooltip>
          <SelectTrigger className='border-none shadow-none text-base' size='sm' tone='ghost' fullWidth>
            <SelectValue<SmartSignalChartSelectOption> className='flex flex-col text-left'>
              {({ selectedItem }) => {
                if (selectedItem == null) {
                  return <Skeleton width={100} height={20} className='transform-none' />
                }

                return selectedItem.label
              }}
            </SelectValue>
          </SelectTrigger>
        </TooltipTrigger>
        <SelectPopover>
          <SelectListBox>
            {displayedMetrics?.map((metric) => {
              if (metric.signalName === 'totalSuccessfulIdentifications') {
                return null
              }

              const option = supportedSignalsPresentationalInfo[metric.signalName]
              if (option == null) {
                return null
              }

              return (
                <SelectItem
                  key={metric.signalName}
                  id={metric.signalName}
                  value={option}
                  isDisabled={metric.signalName === selectedChart}
                >
                  {option.label}
                </SelectItem>
              )
            })}
          </SelectListBox>
        </SelectPopover>
      </Select>
    </Stack>
  )
}
