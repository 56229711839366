import { useQuery } from '@tanstack/react-query'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

export function useDeploymentStatus(deploymentId: string, withPolling?: boolean, pollingRate = 20) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['deployment_status/id', deploymentId] as const,
    queryFn: ({ queryKey: [_, id] }) =>
      extractData(withErrorHandling(buildRequest('cloudflareDeploymentStatus', { params: { id } }))),
    enabled: deploymentId != null,
    refetchInterval: withPolling ? pollingRate * 1000 : undefined,
  })
}

export function useStartDeployWorkerMutation() {
  return useRequestMutation('startDeploymentWorkerCF')
}

export function useValidateCloudflareApiTokenMutation() {
  return useRequestMutation('cloudflareVerifyToken')
}

type UseCloudflareDomainsArgs = {
  subscriptionId: string
  cloudflareAccountId?: string
  cloudflareApiToken?: string
  queryEnabled?: boolean
}

export const getCloudflareDomainsQueryKey = 'cloudflareDomainsGet' as const

export function useCloudflareDomains({
  subscriptionId,
  cloudflareAccountId,
  cloudflareApiToken,
  queryEnabled,
}: UseCloudflareDomainsArgs) {
  const buildRequest = useBuildRequest()
  return useQuery({
    queryKey: [getCloudflareDomainsQueryKey, subscriptionId, cloudflareAccountId, cloudflareApiToken] as const,
    queryFn: async () => {
      if (!cloudflareApiToken) {
        throw new Error('Cloudflare API token is required to fetch domains')
      }

      if (!cloudflareAccountId) {
        throw new Error('Cloudflare account ID is required to fetch domains')
      }

      return extractData(
        buildRequest('cloudflareDomainsGet', {
          data: { subscriptionId, accountId: cloudflareAccountId, apiToken: cloudflareApiToken },
        })
      )
    },
    enabled: Boolean(subscriptionId) && Boolean(cloudflareAccountId) && Boolean(cloudflareApiToken) && queryEnabled,
    /**
     * When a user selects the wrong domain a goes back a step within 60s, we don't need to refetch the domains
     */
    staleTime: 60 * 1000,
  })
}

export function useCloudflareSubdomains() {
  return useRequestMutation('cloudflareSubdomainsGet')
}
