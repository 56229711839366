import { Button, Flex, Stack } from '@compass/components'
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import clsx from 'clsx'
import { CalloutBox } from 'features/apiKeys'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  formatDatetime,
  useDefaultPredefinedRanges,
} from 'features/commonUI'
import { IdentificationUrlKeys, useFilterSearchParams } from 'features/identificationVisits'
import { useCurrentSubscription } from 'features/subscription'
import { getErrorParams } from 'helpers/data'
import { DateRange } from 'helpers/dateRange'
import { getClientTimezone } from 'helpers/locale'
import { muiRegister } from 'helpers/reactHookForm'
import { EMAIL_VALIDATION } from 'helpers/validation'
import { useToast } from 'hooks'
import { useWorkspaceEnvironments } from 'hooks/api/environment'
import { useIdentificationEventsExport } from 'hooks/api/identification_events'
import { useCurrentUser } from 'hooks/api/users'
import { DateTime } from 'luxon'
import { IdentificationEventExportBody, VisitsFilter, VisitsFilterKeys, VisitsFilterValues } from 'models'
import { ampli, DataExportRequestedProperties } from 'models/ampli'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FILTER_CONFIGS } from '../VisitHistoryFilter/VisitHistoryFilter'
import styles from './ExportIdentificationEventsModal.module.scss'

export interface ExportIdentificationEventsModalProps {
  isOpen: boolean
  handleClose: () => void
}

const isExportFiltersUiVisible = true

export default function ExportIdentificationEventsModal({ isOpen, handleClose }: ExportIdentificationEventsModalProps) {
  const { data: currentUser } = useCurrentUser()
  const { ranges } = useDefaultPredefinedRanges()
  const { currentSubscriptionId } = useCurrentSubscription()
  const defaultEmail = currentUser?.email ?? ''
  const defaultLocalTz = currentUser?.timezone ?? getClientTimezone()

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors: formErrors },
  } = useForm({
    defaultValues: {
      timeZone: defaultLocalTz,
      dateRangeIndex: 0,
      email: defaultEmail,
    },
  })

  const watchedEmail = watch('email')

  const { mutate: initiateIdentificationEventExport, isPending } = useIdentificationEventsExport()
  const { showToast } = useToast()
  const { filterValues, dateRange } = useFilterSearchParams()

  const onClose = useCallback(() => {
    reset()
    handleClose()
  }, [handleClose, reset])

  const onSubmit = useCallback<(data: { timeZone: string; email: string }) => void>(
    (data) => {
      const { timeZone } = data

      initiateIdentificationEventExport(
        {
          params: { subscriptionId: currentSubscriptionId },
          data: identificationEventExportBodyAdapter({
            email: data.email,
            filterValues,
            dateRange,
            timeZone,
          }),
        },
        {
          onSuccess: () => {
            showToast({
              severity: 'success',
              message: (
                <>
                  Success! Be on the lookout for a <strong>download link</strong> sent to <strong>{data.email}</strong>.
                </>
              ),
            })
            const attributeFiltersToTrack = [
              IdentificationUrlKeys.IpAddress,
              IdentificationUrlKeys.Url,
              IdentificationUrlKeys.LinkedId,
              IdentificationUrlKeys.RequestId,
              IdentificationUrlKeys.VisitorId,
              IdentificationUrlKeys.Origin,
              IdentificationUrlKeys.Environment,
            ]

            const attributeFilters = (Object.keys(filterValues) as IdentificationUrlKeys[]).filter((f) =>
              attributeFiltersToTrack.includes(f)
            )

            ampli.dataExportRequested({
              exportFormat: 'CSV',
              timeZone: timeZone === 'UTC' ? 'UTC' : 'Local Time',
              dateRange: ranges[filterValues.period]?.label ?? 'Other',
              attributeFilters: attributeFilters as DataExportRequestedProperties['attributeFilters'],
            })
            onClose()
          },
        }
      )
    },
    [initiateIdentificationEventExport, currentSubscriptionId, filterValues, dateRange, showToast, ranges, onClose]
  )

  const emailInputError = getErrorParams('email', formErrors)

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: styles.dialog }}>
      <DialogTitle onClose={onClose} className={styles.dialogTitle}>
        <Typography variant='h1' component='span' className={styles.heading}>
          Export events
        </Typography>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={styles.content}>
          <Box className={styles.biggerMargin}>
            <CalloutBox title='How does this work?' type='info'>
              Select your desired time zone and date range then click “Start Export”. Our servers will crunch the data
              and email you, <b>{watchedEmail}</b>, a .CSV download link.
              <br />
              <br />
              Only columns available with your current billing plan will be exported, regardless of presence in
              historical events. Also, events captured prior to the introduction of this feature (October 3rd) will be
              excluded from the export.
            </CalloutBox>
          </Box>
          <Flex className='gap-6 sm:gap-4 pb-32 sm:pb-0'>
            <Stack direction={{ default: 'column', sm: 'row' }} gap={2}>
              <Flex className='sm:w-28 flex-grow-0'>
                <Typography variant='bodyM'>Applied filters</Typography>
              </Flex>

              <Flex className='flex-grow w-fit gap-3'>
                <dl data-testid='applied-filter-date'>
                  <Typography component='dt' variant='bodyM'>
                    Date Range
                  </Typography>
                  <Typography component='dd' variant='bodyMMedium'>
                    {formatDatetime(
                      dateRange.startDate.toJSDate(),
                      'precise',
                      getValues('timeZone'),
                      'standardWithYear'
                    )}
                    {` - ${formatDatetime(dateRange.endDate.toJSDate(), 'precise', getValues('timeZone'), 'standardWithYear')}`}
                  </Typography>
                </dl>
                {isExportFiltersUiVisible &&
                  filterValues &&
                  Object.entries(filterValues)
                    .filter(([key]) => key !== 'period' && key !== 'before' && key !== 'since')
                    .map(([k, val]) => (
                      <AppliedFiltersList
                        key={k}
                        filterKey={k as VisitsFilterKeys}
                        filterValue={val as string | string[]}
                        subscriptionId={currentSubscriptionId}
                      />
                    ))}
              </Flex>
            </Stack>

            <Stack
              direction={{ default: 'column', sm: 'row' }}
              gap={1}
              className={clsx(defaultLocalTz?.toLowerCase() === 'utc' && 'hidden')}
            >
              <Flex className='sm:w-28 flex-grow-0 h-8 justify-center'>
                <Typography variant='bodyM'>Time zone</Typography>
              </Flex>

              <Controller
                name='timeZone'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} className='flex flex-col flex-nowrap'>
                    <FormControlLabel
                      value={defaultLocalTz}
                      control={<Radio className='h-8' />}
                      label={<Typography variant='bodyMMedium'>Local Time ({defaultLocalTz})</Typography>}
                    />
                    <FormControlLabel
                      value='UTC'
                      control={<Radio className='h-8' />}
                      label={<Typography variant='bodyMMedium'>UTC</Typography>}
                    />
                  </RadioGroup>
                )}
              />
            </Stack>

            <Stack direction={{ default: 'column', sm: 'row' }} gap={1} className='sm:items-center'>
              <Flex className='sm:w-28 flex-grow-0'>
                <Typography variant='bodyM'>Email CSV to</Typography>
              </Flex>

              <TextField
                fullWidth
                className='w-full sm:w-[350px]'
                id='email'
                variant='outlined'
                {...muiRegister(register, 'email', EMAIL_VALIDATION)}
                {...(emailInputError.error && { ...emailInputError })}
              />
            </Stack>
          </Flex>
        </DialogContent>

        <DialogActions className={styles.dialogActions}>
          <Button variant='ghost' onPress={onClose}>
            Cancel
          </Button>
          <Button type='submit' isDisabled={isPending} isLoading={isPending}>
            Start Export
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

type AppliedFiltersListProps = { filterKey: VisitsFilterKeys; filterValue: VisitsFilterValues; subscriptionId: string }
function AppliedFiltersList({ filterKey, filterValue, subscriptionId }: AppliedFiltersListProps) {
  const { data: environments } = useWorkspaceEnvironments(subscriptionId)
  let displayedValue = filterValue

  if (filterKey === 'environment') {
    displayedValue = (filterValue as string[])
      ?.map((environment) => {
        return environments?.find((e) => e.id === environment)?.name
      })
      .join(', ')
  }

  return (
    <dl data-testid={`applied-filter-${filterKey}`}>
      <Typography component='dt' variant='bodyM' className='mb-0.5'>
        {FILTER_CONFIGS.find((c) => c.key === filterKey)?.label}
      </Typography>
      <Typography component='dd' variant='bodyMMedium' className='mb-1.5'>
        {displayedValue}
      </Typography>
    </dl>
  )
}

const convertDateWithZone = (date: DateTime, timeZone: string) => {
  const formattedDate = date.setZone(timeZone, { keepLocalTime: true }).toISO({ includeOffset: true })!

  if (formattedDate) {
    return DateTime.fromISO(formattedDate).toUTC().toString()
  }

  return date.toISO() as string
}

type AppliedEnvironmentFilters = { environmentIds: string[] } | Object
type AppliedFilters = { filters: AppliedEnvironmentFilters } | Object
type IdentificationEventExportBodyAdapterProps = {
  timeZone: string
  email: string
  filterValues: VisitsFilter
  dateRange: Required<DateRange>
}
function identificationEventExportBodyAdapter({
  email,
  filterValues,
  dateRange,
  timeZone,
}: IdentificationEventExportBodyAdapterProps): IdentificationEventExportBody {
  const filtersThatNeedsAdapting = ['period', 'since', 'before', 'environment']
  const filteredUrlFilters = Object.keys(filterValues).filter((f) => !filtersThatNeedsAdapting.includes(f))
  const urlFilters = filteredUrlFilters.reduce((acc, filterKey) => {
    return {
      ...acc,
      [filterKey]: filterValues[filterKey as keyof VisitsFilter],
    }
  }, {})

  const appliedEnvironmentFilters: AppliedEnvironmentFilters = filterValues.environment
    ? { environmentIds: filterValues.environment }
    : {}

  const appliedFilters: AppliedFilters = filterValues.environment
    ? { filters: { ...appliedEnvironmentFilters, ...urlFilters } }
    : filteredUrlFilters.length > 0
      ? { filters: { ...urlFilters } }
      : {}

  return {
    email,
    dateRange: {
      from: convertDateWithZone(dateRange.startDate, timeZone),
      to: convertDateWithZone(dateRange.endDate, timeZone),
    },
    ...appliedFilters,
  }
}
