import { Button } from '@compass/Button'
import { download } from 'helpers/download'
import { MIMEType } from 'helpers/types'
import { Download } from 'lucide-react'
import { IncorrectETLDPlusOneWithOriginData } from 'models'

export type MismatchedDomainsCsvExportButtonProps = {
  data?: IncorrectETLDPlusOneWithOriginData[]
  domain?: string
}

const columns = ['Proxy Integration Domain', 'Origin Domain', 'Invalid Requests Count']

function exportAsCSV(data?: IncorrectETLDPlusOneWithOriginData[], domain?: string) {
  if (!data?.length) {
    return
  }

  const rows = [
    columns,
    ...data
      .filter((it) => it.invalidRequestsCount > 0)
      .map((it) => {
        return [it.apiHostname, it.originEtldPlusOne, it.invalidRequestsCount]
      }),
  ]

  const csvContents = rows.map((it) => it.join(',')).join('\n')
  const fileName = domain ? `${domain}_mismatched_domains.csv` : 'mismatched_domains.csv'
  download(csvContents, fileName, MIMEType.Csv)
}

export default function MismatchedDomainsCsvExportButton({ data, domain }: MismatchedDomainsCsvExportButtonProps) {
  return (
    <Button onPress={() => exportAsCSV(data, domain)} isDisabled={!data?.length} variant='secondary'>
      <Download />
      Export
    </Button>
  )
}
