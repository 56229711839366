import './styles/global.scss'

import { GlobalToastRegion } from '@compass/components'
import { ErrorBoundary } from '@rollbar/react'
import { AppRoute, SUBSCRIPTION_SHORTCUT_ROUTES } from 'appRoutes'
import { ErrorFallbackUI } from 'components'
import { PrivateRoutes } from 'components/redirects'
import { UpdatedDeploymentAvailable } from 'components/UpdatedDeploymentAvailable'
import { IS_MAINTENANCE_MODE } from 'const'
import { AccountApplicationsPage } from 'features/accountApplications'
import { AddSecondaryApplicationPage } from 'features/addSecondaryApplication'
import { ApiKeysPage } from 'features/apiKeys'
import {
  ImpersonatePage,
  InvitationConfirmPage,
  LogInPage,
  MaintenancePage,
  PasswordResetConfirmPage,
  PasswordResetPage,
  ReadmeLoginPage,
  SignUpConfirmPage,
  SignUpPage,
  SSOPage,
} from 'features/auth'
import { BillingPage, InvoicePage } from 'features/billing'
import { BotdOverviewPage } from 'features/botd'
import { CancellationSurveyPage } from 'features/cancellationSurvey'
import { Layout } from 'features/commonUI'
import { CustomSubdomainCreatePage, CustomSubdomainListPage } from 'features/customSubdomains'
import { UnsubscribeNewsletterPage, UnsubscribePage } from 'features/emailUnsubscribe'
import { EnterpriseContactSalesPage } from 'features/enterpriseContactSales'
import { GetStartedPage } from 'features/getStarted'
import { HealthPage, SdkHealthPage } from 'features/health'
import { HomeRedirectPage } from 'features/homePage'
import { IdentificationVisitDetailsPage, IdentificationVisitsPage } from 'features/identificationVisits'
import { IntegrationListPage, IntegrationPage } from 'features/integrations'
import { OnboardingPage } from 'features/onboarding'
import { ChangePlanPage, PlanManagementPage } from 'features/planManagement'
import { NotificationSettingsPage, ProfileSettingsPage } from 'features/profileSettings'
import { RequestFilteringPage } from 'features/requestFiltering'
import { SmartSignalsPage } from 'features/smartSignals'
import { CurrentSubscriptionProvider, SubscriptionShortcutRedirect } from 'features/subscription'
import { SubscriptionOverviewPage } from 'features/subscriptionOverview'
import { EnvironmentsPage, SubscriptionSettingsPage } from 'features/subscriptionSettings'
import { SuspectScorePage } from 'features/suspectScore'
import { UserConsentPage } from 'features/userConsent'
import { UserManagementPage } from 'features/userManagement'
import { WebhookEventsPage, WebhooksPage, WebhookVerificationPage } from 'features/webhooks'
import { useDatadog } from 'helpers/vendor/datadog'
import { usePrices } from 'hooks'
import { useAppAnalytics } from 'hooks/analytics'
import { Settings } from 'luxon'
import { Redirect, Route, Switch } from 'react-router-dom'
import { toastQueue } from 'toast'

import { useFeedbackData } from './hooks/feedback'

Settings.defaultLocale = 'en-US'

/**
 * The App component has not any router wrapper because it uses both with tests, storybook and browser.
 * Each environment should had a high order router component
 *
 * @example
 * <BrowserRouter>
 *   <App />
 * </BrowserRouter>
 */
export default function App() {
  if (IS_MAINTENANCE_MODE) {
    return (
      <ErrorBoundary fallbackUI={ErrorFallbackUI}>
        <MaintenancePage />
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary fallbackUI={ErrorFallbackUI}>
      <AppLevelEffects />
      <GlobalToastRegion queue={toastQueue} />
      <Switch>
        <Route
          path='/subscriptions/:rest*'
          render={({ match, location }) => {
            return (
              <Redirect
                to={{
                  pathname: `/workspaces/${match.params.rest}`,
                  search: location.search,
                  hash: location.hash,
                }}
              />
            )
          }}
        />

        <Route path={AppRoute.Login} component={LogInPage} />
        <Route path={AppRoute.SSOCallbackRoute} component={SSOPage} />
        <Route exact path={AppRoute.Signup} component={SignUpPage} />
        <Route exact path={AppRoute.SignupConfirm} component={SignUpConfirmPage} />
        <Route exact path={AppRoute.SignupConfirmWithIntent} component={SignUpConfirmPage} />

        <Route path={AppRoute.PasswordResetConfirm} component={PasswordResetConfirmPage} />
        <Route path={AppRoute.InvitationConfirm} component={InvitationConfirmPage} />
        <Route exact path={AppRoute.PasswordReset} component={PasswordResetPage} />

        <Route exact path={AppRoute.Unsubscribe} component={UnsubscribePage} />
        <Route exact path={AppRoute.UnsubscribeNewsletter} component={UnsubscribeNewsletterPage} />

        <Route path={AppRoute.Impersonate} component={ImpersonatePage} />

        {/* This provider calls the /subscriptions endpoint. It should only wrap authenticated routes. */}
        <CurrentSubscriptionProvider>
          <PrivateRoutes>
            <Route exact path={AppRoute.Home} component={HomeRedirectPage} />
            <Route exact path={AppRoute.Onboarding} component={OnboardingPage} />
            <Route exact path={AppRoute.Consent} component={UserConsentPage} />

            <Route exact path={AppRoute.AddSecondaryApplication} component={AddSecondaryApplicationPage} />

            <Route path={AppRoute.ProfileSettings} component={ProfileSettingsPage} />
            <Route path={AppRoute.NotificationSettings} component={NotificationSettingsPage} />
            <Redirect from={AppRoute.AccountDangerZone} to={AppRoute.ProfileSettings} />
            <Route path={AppRoute.SubscriptionCancellationSurvey} component={CancellationSurveyPage} />
            <Route path={AppRoute.ContactSalesEnterprise} component={EnterpriseContactSalesPage} />

            <Route
              path={[
                '/subscriptions/:subscriptionId',
                '/workspaces/:subscriptionId',
                '/settings',
                AppRoute.UserManagement,
                AppRoute.Billing,
                AppRoute.WorkspaceList,
                ...SUBSCRIPTION_SHORTCUT_ROUTES,
              ]}
            >
              <Layout>
                <Switch>
                  {/* Shortcut paths used in the documentation. */}
                  <Route path={SUBSCRIPTION_SHORTCUT_ROUTES} component={SubscriptionShortcutRedirect} />

                  {/* Legacy redirects */}
                  <Redirect from='/subscriptions/:subscriptionId/upgrade' to={AppRoute.SubscriptionPlan} />
                  <Redirect from='/subscriptions/upgrade' to='/plan' />
                  <Redirect from='/subscriptions/:subscriptionId/tokens' to={AppRoute.ApiKeys} />

                  <Redirect exact from='/settings' to={AppRoute.WorkspaceList} />
                  <Redirect exact from={AppRoute.DeprecatedAccountApplications} to={AppRoute.WorkspaceList} />

                  <Route exact path={AppRoute.SubscriptionGetStarted} component={GetStartedPage} />
                  <Route exact path={AppRoute.SubscriptionOverview} component={SubscriptionOverviewPage} />
                  <Route exact path={AppRoute.Botd} component={BotdOverviewPage} />
                  <Route exact path={AppRoute.SubscriptionSettings} component={SubscriptionSettingsPage} />
                  <Route exact path={AppRoute.Environments} component={EnvironmentsPage} />
                  <Route exact path={AppRoute.ApiKeys} component={ApiKeysPage} />
                  <Route exact path={AppRoute.Webhooks} component={WebhooksPage} />
                  <Route exact path={AppRoute.WebhookEvents} component={WebhookEventsPage} />
                  <Route exact path={AppRoute.WebhookVerification} component={WebhookVerificationPage} />

                  <Redirect exact from={AppRoute.DeprecatedCustomSubdomains} to={AppRoute.Subdomains} />
                  <Redirect exact from={AppRoute.DeprecatedCustomSubdomainNew} to={AppRoute.SubdomainNew} />
                  <Redirect exact from={AppRoute.DeprecatedCustomSubdomainValidate} to={AppRoute.SubdomainValidate} />
                  <Redirect exact from={AppRoute.DeprecatedCustomSubdomainIssued} to={AppRoute.SubdomainIssued} />
                  <Route exact path={AppRoute.SubdomainNew} component={CustomSubdomainCreatePage} />
                  <Route exact path={AppRoute.SubdomainValidate} component={CustomSubdomainCreatePage} />
                  <Route exact path={AppRoute.SubdomainIssued} component={CustomSubdomainCreatePage} />
                  <Route exact path={AppRoute.Subdomains} component={CustomSubdomainListPage} />

                  <Route exact path={AppRoute.RequestFiltering} component={RequestFilteringPage} />
                  <Route exact path={AppRoute.SuspectScore} component={SuspectScorePage} />
                  <Route exact path={AppRoute.SubscriptionPlan} component={PlanManagementPage} />

                  <Redirect exact from={AppRoute.DeprecatedChangePlan} to={AppRoute.ChangePlan} />
                  <Route exact path={AppRoute.ChangePlan} component={ChangePlanPage} />

                  <Route exact path={AppRoute.Health} component={HealthPage} />
                  <Route exact path={AppRoute.SdkHealth} component={SdkHealthPage} />
                  <Route exact path={AppRoute.Integrations} component={IntegrationListPage} />
                  <Route exact path={AppRoute.Integration} component={IntegrationPage} />
                  <Route exact path={AppRoute.Invoice} component={InvoicePage} />
                  <Route exact path={AppRoute.SmartSignals} component={SmartSignalsPage} />

                  <Redirect exact from={AppRoute.DeprecatedIdentificationVisits} to={AppRoute.IdentificationEvents} />
                  <Redirect
                    exact
                    from={AppRoute.DeprecatedIdentificationVisitDetails}
                    to={AppRoute.IdentificationEventDetails}
                  />
                  <Route exact path={AppRoute.IdentificationEvents} component={IdentificationVisitsPage} />
                  <Route exact path={AppRoute.IdentificationEventDetails} component={IdentificationVisitDetailsPage} />

                  <Route path={AppRoute.WorkspaceList} component={AccountApplicationsPage} />
                  <Route path={AppRoute.UserManagement} component={UserManagementPage} />
                  <Route path={AppRoute.Billing} component={BillingPage} />

                  <Redirect exact from={AppRoute.SubscriptionRoot} to={AppRoute.SubscriptionOverview} />
                </Switch>
              </Layout>
            </Route>

            <Route path={AppRoute.ReadmeLogin} component={ReadmeLoginPage} />

            <Route path='*'>
              <Redirect to='/' />
            </Route>
          </PrivateRoutes>
        </CurrentSubscriptionProvider>
      </Switch>
    </ErrorBoundary>
  )
}

/**
 * Extracting hooks with re-rendering side effects into a small component here
 * prevents the rerender of the whole App hierarchy to be rerendered every time
 * the hooks or the component logic causes a rerender internally.
 */
const AppLevelEffects = () => {
  return (
    <>
      <UsePrices />
      <UseAppAnalytics />
      <UseDatadog />
      <UseFeedbackData />
      <UpdatedDeploymentAvailable />
    </>
  )
}

const UseFeedbackData = () => {
  useFeedbackData()
  return null
}

const UsePrices = () => {
  usePrices()
  return null
}

const UseAppAnalytics = () => {
  useAppAnalytics()
  return null
}

const UseDatadog = () => {
  useDatadog()
  return null
}
