import { Button, ButtonGroup } from '@compass/components'
import { ChartLineIcon, Table2Icon } from 'lucide-react'
import { ChartDisplay } from 'models'

type DisplaySelectorProps = {
  value?: ChartDisplay
  onChange: (display: ChartDisplay) => void
}

export function DisplaySelector({ value, onChange }: DisplaySelectorProps) {
  return (
    <ButtonGroup>
      <Button
        variant='secondary'
        size='sm'
        isIcon
        onPress={() => onChange(ChartDisplay.Line)}
        tone={value === ChartDisplay.Line ? 'background2' : undefined}
        className={value === ChartDisplay.Line ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Display as chart'
      >
        <ChartLineIcon />
      </Button>
      <Button
        variant='secondary'
        size='sm'
        isIcon
        onPress={() => onChange(ChartDisplay.Table)}
        tone={value === ChartDisplay.Table ? 'background2' : undefined}
        className={value === ChartDisplay.Table ? '[&_svg]:text-current shadow-inset-t-black-200' : undefined}
        aria-label='Display as table'
      >
        <Table2Icon />
      </Button>
    </ButtonGroup>
  )
}
