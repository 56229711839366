import Stripe from 'stripe'

export interface BillingData {
  overagePrice: number
  prepaidQuantity: number
  flatAmount: number // minimal amount in cents that is charged regardless of the usage
  unitLabel: string
  product: Product
  platformBilling?: {
    android?: PlatformBillingData
    ios?: PlatformBillingData
    js?: PlatformBillingData
  }
}

export interface PlatformBillingData {
  overagePrice: number
  prepaidQuantity: number
}

export enum Product {
  ApiCalls = 'api',
  UniqueVisitors = 'uv',
}

export type Price = {
  id: string
  unitAmountDecimal: string | null
  tiers: Stripe.Price.Tier[]
}

export type PricingData = Partial<Record<Product, BillingData>>
