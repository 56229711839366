import { Button, LoadingLogo, Stack } from '@compass/components'
import {
  contentStyles,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ModalOverlay,
} from '@compass/Modal'
import { Alert, InputLabel, Link, TextField, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { getErrorParams } from 'helpers/data'
import { muiRegister } from 'helpers/reactHookForm'
import { useSubscriptions } from 'hooks'
import { CircleAlert, CircleCheck, RotateCw, XCircle } from 'lucide-react'
import { UserInvitation } from 'models'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'

import { useLockPageRedirect } from '../../../hooks/useLockPageRedirect'

export interface AcceptInvitationModalProps {
  isOpen: boolean
  className?: string
  invitation: UserInvitation
  blockedReason?: 'activeSubscriptions' | 'ownerWithOtherUsers'
  willDeleteAccount: boolean
  errorMessage?: string
  onClose: () => void
  onAccept: (invitationId: string, callback: () => void) => void
  isTransferring: boolean
}

export function AcceptInvitationModal(props: AcceptInvitationModalProps) {
  const [requiresReload, setRequiresReload] = useState(false)
  return (
    <ModalOverlay
      isOpen={props.isOpen}
      isDismissable={!props.isTransferring}
      onOpenChange={() => {
        if (!props.isTransferring) {
          props.onClose()

          // We need to ensure that the page reloads if a transfer has occurred
          if (requiresReload) {
            location.reload()
          }
        }
      }}
    >
      <DialogContent>
        <AcceptInvitationModalContents
          {...props}
          setRequiresReload={() => setRequiresReload(true)}
          className={contentStyles()}
        />
      </DialogContent>
    </ModalOverlay>
  )
}

function AcceptInvitationModalContents({
  className,
  invitation,
  onClose,
  onAccept,
  blockedReason,
  willDeleteAccount,
  errorMessage,
  isTransferring,
  setRequiresReload,
}: AcceptInvitationModalProps & { setRequiresReload: () => void }) {
  const [transferCompleted, setTransferCompleted] = useState(false)
  const challengeText = willDeleteAccount ? 'delete my workspaces' : 'accept invite'

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
  } = useForm<{ validationMessage: string }>({
    defaultValues: { validationMessage: '' },
  })

  const handleAccept = useCallback(
    () =>
      onAccept(invitation.id, () => {
        setTransferCompleted(true)
        setRequiresReload()
      }),
    [invitation.id, onAccept, setRequiresReload]
  )

  if (transferCompleted) {
    return <TransferDone />
  }

  if (isTransferring) {
    return <LoadingTransfer invitedByEmail={invitation.invitedByUser.email} />
  }

  return (
    <form onSubmit={handleSubmit(handleAccept)} className={className}>
      <DialogHeader>
        <DialogTitle>Accept invitation</DialogTitle>
      </DialogHeader>
      <DialogBody>
        {!errorMessage && blockedReason && (
          <Alert severity='warning' icon={<CircleAlert />}>
            <WarningText blockedReason={blockedReason} />
          </Alert>
        )}
        {!errorMessage && !blockedReason && (
          <>
            <SummaryText willDeleteAccount={willDeleteAccount} invitedByName={invitation.invitedByUser.name} />
            <Stack gap={4} direction='column'>
              <Alert severity='error'>
                <strong>Warning!</strong> This cannot be undone, so please be certain.
              </Alert>
              <Stack gap={2} direction='column'>
                <InputLabel>
                  Type <strong>{challengeText}</strong> to continue
                </InputLabel>
                <TextField
                  autoFocus
                  {...muiRegister(register, 'validationMessage', {
                    validate: (value) => value === challengeText || 'The entered text does not match.',
                  })}
                  {...getErrorParams('validationMessage', formErrors)}
                  fullWidth
                  autoComplete='off'
                  variant='outlined'
                  disabled={!!blockedReason}
                />
              </Stack>
            </Stack>
          </>
        )}
        {errorMessage && (
          <Stack gap={4} direction='column' className='items-center justify-center'>
            <XCircle size={24} strokeWidth={1.75} className='text-red-700' />
            <Stack gap={2} direction='column' className='text-center'>
              <Typography variant='bodyMMedium'>Account transfer failed</Typography>
              <Typography variant='bodyS'>
                An error occurred when accepting the invitation. Your account remains unchanged. Please try again or{' '}
                <Link
                  href='https://fingerprint.com/support/'
                  target='_blank'
                  rel='noreferrer'
                  color='inherit'
                  underline='always'
                >
                  contact support
                </Link>{' '}
                if this issue persists.
              </Typography>
            </Stack>
            <Typography className='text-2xs text-gray-700 font-mono'>Error: {errorMessage}</Typography>
          </Stack>
        )}
      </DialogBody>
      <DialogFooter>
        <Button variant='secondary' onPress={onClose} type='button'>
          Cancel
        </Button>
        <Button isDisabled={!!blockedReason} type='submit'>
          {errorMessage ? 'Retry' : 'Accept invite'}
        </Button>
      </DialogFooter>
    </form>
  )
}

function SummaryText({ willDeleteAccount, invitedByName }: { willDeleteAccount: boolean; invitedByName: string }) {
  if (willDeleteAccount) {
    return (
      <Typography>
        By accepting this invite, your user profile will be moved to another and your current account, including all
        workspaces, will be deleted.
      </Typography>
    )
  }

  return (
    <Typography>
      Are you sure you want to accept an invitation from {invitedByName} to join their Fingerprint account?
    </Typography>
  )
}

function LoadingTransfer({ invitedByEmail }: { invitedByEmail: string }) {
  useLockPageRedirect('Navigating away from the page is disabled during account transfer.')
  return (
    <>
      <DialogHeader>
        <DialogTitle>Accept invitation</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <Stack gap={4} direction='column' className='items-center justify-center'>
          <LoadingLogo />
          <Stack gap={2} direction='column' className='text-center'>
            <Typography>
              Moving you to <strong>{invitedByEmail}&apos;s</strong> account
            </Typography>
            <Typography variant='bodyS'>This may take a few moments. Maybe grab a cup of coffee?</Typography>
          </Stack>
        </Stack>
      </DialogBody>
      <DialogFooter>
        <Button variant='secondary' isDisabled>
          Cancel
        </Button>
        <Button isDisabled isLoading>
          Accept invite
        </Button>
      </DialogFooter>
    </>
  )
}

function TransferDone() {
  const onRefresh = useCallback(() => {
    location.reload()
  }, [])

  return (
    <>
      <DialogHeader>
        <DialogTitle>Accept invitation</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <Stack gap={4} direction='column' className='items-center justify-center'>
          <CircleCheck size={24} strokeWidth={1.75} className='text-green-700' />
          <Stack gap={2} direction='column' className='text-center'>
            <Typography variant='bodyMMedium'>Account transferred!</Typography>
            <Typography variant='bodyS'>Please refresh the page to see your new home.</Typography>
          </Stack>
        </Stack>
      </DialogBody>
      <DialogFooter>
        <Button onPress={onRefresh} fullWidth>
          <RotateCw />
          Reload page
        </Button>
      </DialogFooter>
    </>
  )
}

function WarningText({ blockedReason }: { blockedReason: string | undefined }) {
  const { data: subscriptions = [] } = useSubscriptions()

  const descriptionText =
    subscriptions.length > 1
      ? 'Before moving to another account, you must first cancel the following active workspaces. Use the links below to manage each subscription.'
      : 'Before moving to another account, you must first cancel the following active workspace. Use the link below to manage your subscription.'

  if (blockedReason === 'activeSubscriptions') {
    return (
      <Typography color='yellowSubtle.contrastText'>
        {descriptionText}

        <ul className='list-disc pl-4 pt-4'>
          {subscriptions.map(({ id, name }) => (
            <li key={id}>
              <Link
                component={RouterLink}
                to={buildRoute(AppRoute.SubscriptionPlan, {
                  subscriptionId: id,
                })}
                color='inherit'
                underline='always'
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </Typography>
    )
  }

  if (blockedReason === 'ownerWithOtherUsers') {
    return (
      <Typography>
        Before moving to another account, you must first transfer ownership of this account. You may transfer ownership
        from the{' '}
        <Link component={RouterLink} to={{ pathname: AppRoute.UserManagement }} color='inherit' underline='always'>
          team page
        </Link>{' '}
        in settings.
      </Typography>
    )
  }

  return (
    <Typography>
      By accepting this invite, your user profile will be moved to another account and your current account, including
      all workspaces, will be deleted. This cannot be undone.
    </Typography>
  )
}
