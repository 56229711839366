import {
  PRO_PLUS_99_ANDROID_FREEMIUM_BILLING_DATA,
  PRO_PLUS_ANDROID_FREEMIUM_BILLING_DATA,
  SUBSCRIPTION_PLAN_PRICES,
  SubscriptionDisplayPlan,
} from 'const/content'
import { formatPriceInDollars } from 'helpers/format'
import { ExpandedSubscription } from 'models'
import { BillingData } from 'models/price'

interface GetEntriesParams {
  subscription: ExpandedSubscription
  useNewBillingQuota: boolean
  usageLimit: number | undefined
  isSubscriptionPlan: boolean
  getOveragePrice: (subscription: ExpandedSubscription) => number | undefined
  displayPlan: SubscriptionDisplayPlan
}

export function getEntries({
  subscription,
  useNewBillingQuota,
  usageLimit = 0,
  isSubscriptionPlan,
  getOveragePrice,
  displayPlan,
}: GetEntriesParams) {
  const planPriceInCents = SUBSCRIPTION_PLAN_PRICES[displayPlan] as number
  const ANDROID_FREEMIUM_BILLING_DATA_MAP: Partial<Record<SubscriptionDisplayPlan, BillingData>> = {
    [SubscriptionDisplayPlan.Plus99AndroidFreemium]: PRO_PLUS_99_ANDROID_FREEMIUM_BILLING_DATA,
    [SubscriptionDisplayPlan.PlusAndroidFreemium]: PRO_PLUS_ANDROID_FREEMIUM_BILLING_DATA,
  }

  const billingData = ANDROID_FREEMIUM_BILLING_DATA_MAP[displayPlan] || undefined

  if (useNewBillingQuota && subscription.billingQuota) {
    return [
      {
        platform: 'Web',
        includedWithPlan: billingData?.platformBilling?.js?.prepaidQuantity ?? usageLimit,
        overagePrice: billingData?.platformBilling?.js?.overagePrice ?? getOveragePrice(subscription),
        currentUsage: subscription.billingQuota.byClientType.js.billed,
        cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
      },
      {
        platform: 'iOS',
        includedWithPlan: billingData?.platformBilling?.ios?.prepaidQuantity ?? usageLimit,
        overagePrice: billingData?.platformBilling?.ios?.overagePrice ?? getOveragePrice(subscription),
        currentUsage: subscription.billingQuota.byClientType.ios.billed,
        cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
      },
      {
        platform: 'Android',
        includedWithPlan: billingData?.platformBilling?.android?.prepaidQuantity ?? usageLimit,
        overagePrice: billingData?.platformBilling?.android?.overagePrice ?? getOveragePrice(subscription),
        currentUsage: subscription.billingQuota.byClientType.android.billed,
        cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
      },
    ]
  }

  return [
    {
      platform: 'Web',
      includedWithPlan: usageLimit,
      overagePrice: getOveragePrice(subscription),
      currentUsage: subscription.usageQuota?.billed ?? 0,
      cost: isSubscriptionPlan ? formatPriceInDollars(planPriceInCents) : undefined,
    },
  ]
}
