import { CloudflareIntegration, DeploymentStatus } from 'models'

import { IntegrationStatus } from './components/IntegrationPage/types'

const AUTH_ERROR_PREFIX = 'Authentication error:'

const statusMap: Record<DeploymentStatus, IntegrationStatus> = {
  [DeploymentStatus.Queued]: IntegrationStatus.ChangesPending,
  [DeploymentStatus.Error]: IntegrationStatus.IssueDetected,
  [DeploymentStatus.Completed]: IntegrationStatus.Installed,
  // On removal error, we show a specific error message, so we don't need to show the generic "Issue detected" status
  [DeploymentStatus.RemovalError]: IntegrationStatus.Installed,
  [DeploymentStatus.InProgress]: IntegrationStatus.ChangesPending,
  [DeploymentStatus.RemovalQueued]: IntegrationStatus.RemovalPending,
  [DeploymentStatus.RemovalInProgress]: IntegrationStatus.RemovalPending,
  [DeploymentStatus.Deleted]: IntegrationStatus.NotInstalled,
}

export function resolveCloudflareIntegrationStatus(integrationData?: CloudflareIntegration | null) {
  if (integrationData) {
    return statusMap[integrationData.lastDeploymentStatus] ?? IntegrationStatus.NotInstalled
  }

  return IntegrationStatus.NotInstalled
}

export function extractCloudflareAuthError(lastDeploymentError?: string) {
  if (lastDeploymentError?.startsWith(AUTH_ERROR_PREFIX)) {
    return lastDeploymentError.replace(AUTH_ERROR_PREFIX, '').trim()
  }

  return undefined
}
