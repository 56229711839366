import { Stack } from '@compass/components'
import { PropsWithChildren } from 'react'

export function ChartHeader({ children }: PropsWithChildren) {
  return (
    <Stack direction='column' gap={3} className='-mt-2'>
      {children}
    </Stack>
  )
}
