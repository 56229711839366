import { Badge, Stack } from '@compass/components'
import { Typography } from '@mui/material'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { useSubscriptions } from 'hooks'
import { useAcceptInvitation, useIgnoreInvitation, useMyInvitations } from 'hooks/api/user_invitations'
import { useCurrentUser, useUsers } from 'hooks/api/users'
import { SubscriptionStatus, SubscriptionType, UserInvitation, UserRole } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useState } from 'react'

import { AcceptInvitationModal } from './AcceptInvitationModal/AcceptInvitationModal'
import { IgnoreInvitationModal } from './IgnoreInvitationModal/IgnoreInvitationModal'

type Action = 'accept' | 'ignore'
export function PendingInvitations() {
  const [selectedModal, setSelectedModal] = useState<Action | undefined>()
  const { data: invitations } = useMyInvitations()
  const {
    mutate: acceptInvitation,
    isPending: isAcceptingInvitation,
    error: acceptInvitationError,
  } = useAcceptInvitation()
  const { mutate: ignoreInvitation, isPending: isIgnoringInvitation } = useIgnoreInvitation()
  const { blockedReason, willDeleteAccount } = useAccountTransferContext()

  const [invitation, setInvitation] = useState<UserInvitation>()

  useSendAmpliEventsForSeenInvitations(invitations)

  const onClickInviteAction = useCallback(
    (action: Action, invitationId: string) => {
      setSelectedModal(action)
      setInvitation(invitations?.find((inv) => inv.id === invitationId))
    },
    [invitations]
  )

  const onCloseModal = useCallback(() => {
    setSelectedModal(undefined)
    if (!selectedModal) {
      setInvitation(undefined)
    }
  }, [selectedModal])

  return (
    <>
      {invitations ? (
        <>
          {invitations.map((invite) => {
            return (
              <DashboardBanner
                title={
                  <Stack gap={2} className='items-center'>
                    You have a pending invite
                    <Badge tone='danger' variant='subtle'>
                      Pending action
                    </Badge>
                  </Stack>
                }
                action='Accept invite'
                secondaryAction='Ignore'
                onClickAction={() => onClickInviteAction('accept', invite.id)}
                onClickSecondaryAction={() => onClickInviteAction('ignore', invite.id)}
                key={invite.id}
                vertical
                className='sm:mt-2'
              >
                <Typography>
                  {invite.invitedByUser.name} (<strong>{invite.invitedByUser.email}</strong>) has invited you to join
                  their Fingerprint account as {handleUserRoleFormatting(invite.inviteUserInfo.role)}. Accepting this
                  invite will move you to another account and you will lose access to your existing account.
                </Typography>
              </DashboardBanner>
            )
          })}
        </>
      ) : null}
      {!!invitation && (
        <AcceptInvitationModal
          invitation={invitation}
          onClose={onCloseModal}
          errorMessage={acceptInvitationError?.message}
          isOpen={selectedModal === 'accept'}
          isTransferring={isAcceptingInvitation}
          blockedReason={blockedReason}
          willDeleteAccount={willDeleteAccount}
          onAccept={(invitationId, onSuccessCallback) => acceptInvitation(invitationId, onSuccessCallback)}
        />
      )}
      {!!invitation && (
        <IgnoreInvitationModal
          isOpen={selectedModal === 'ignore'}
          invitation={invitation}
          isLoading={isIgnoringInvitation}
          onClose={onCloseModal}
          onIgnore={(invitationId, declineAllFutureRequestsFromAccount) =>
            ignoreInvitation(invitationId, declineAllFutureRequestsFromAccount, onCloseModal)
          }
        />
      )}
    </>
  )
}

function useBlockedReason() {
  const { data: users } = useUsers()
  const { data: currentUser } = useCurrentUser()
  const { data: subscriptions } = useSubscriptions()

  if (!!users && users.length > 1 && currentUser?.role === UserRole.Owner) {
    return 'ownerWithOtherUsers'
  }

  if (!!users && users.length === 1 && currentUser?.role === UserRole.Owner) {
    const activeSubCount = subscriptions
      ?.filter((sub) => sub.status !== SubscriptionStatus.Canceled)
      // we allow users with only trial_only to be able to delete their account
      .filter((sub) => sub.type !== SubscriptionType.TrialOnly).length
    if (activeSubCount && activeSubCount > 0) {
      return 'activeSubscriptions'
    }
  }

  return undefined
}

function useAccountTransferContext() {
  const { data: users } = useUsers()
  const blockedReason = useBlockedReason()
  const hasOtherUsers = (users?.length ?? 0) > 1

  return {
    willDeleteAccount: !hasOtherUsers && !blockedReason,
    blockedReason,
  } as const
}

function handleUserRoleFormatting(role: UserRole) {
  const article = [UserRole.Admin, UserRole.Administrator, UserRole.Owner].includes(role) ? 'an' : 'a'
  const displayRole = role === UserRole.ReadOnly ? 'read only user' : role

  return `${article} ${displayRole}`
}

function useSendAmpliEventsForSeenInvitations(invitations: UserInvitation[] | undefined) {
  useEffect(() => {
    if (invitations?.length) {
      invitations.forEach((invitation) => {
        ampli.accountInvitationShown({ insert_id: `account_invitation_shown_${invitation.id}` })
      })
    }
  }, [invitations])
}
