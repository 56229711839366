import { Group } from '@visx/group'
import { Text } from '@visx/text'
import { useEffect, useRef, useState } from 'react'

import { CircleMark } from './CircleMark'

export function ChartPill({
  x,
  y,
  text,
  px = 10,
  py = 5,
  yOffset = -18,
  circleColor = 'hsl(var(--fpds-color-orange-7))',
}: {
  x: number
  y: number
  text: string
  px?: number
  py?: number
  yOffset?: number
  circleColor?: string
}) {
  const textRef = useRef<SVGTextElement>(null)
  const [textWidth, setTextWidth] = useState(0)
  const [textHeight, setTextHeight] = useState(0)

  useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current?.getBoundingClientRect()
      setTextWidth(bbox.width)
      setTextHeight(bbox.height)
    }
  }, [text])

  const height = textHeight + 2 * py

  return (
    <Group className='pointer-events-none'>
      <CircleMark cx={x} cy={y} fill={circleColor} />
      <rect
        x={x - textWidth / 2 - px}
        y={y + yOffset - textHeight - py + 3}
        rx={height / 2}
        ry={height / 2}
        width={textWidth + 2 * px}
        height={height}
        fill='#ffffffee'
      />
      <text x='0' y='0' ref={textRef} className='invisible font-mono text-xs'>
        {text}
      </text>
      <Text x={x} y={y + yOffset} textAnchor='middle' fill='black' className='font-mono text-xs select-none'>
        {text}
      </Text>
    </Group>
  )
}
