import { PredefinedRange } from 'helpers/dateRange'
import { LimitSelectorValues } from 'models'

export type VisitsFilterView = {
  requestId?: string
  visitorId?: string
  ipAddress?: string
  url?: string
  linkedId?: string
  origin?: string
  since?: string
  before?: string
  period?: PredefinedRange['id']
  environment?: string[]
}

export const defaultValues: VisitsFilterView = {
  ipAddress: '',
  linkedId: '',
  origin: '',
  requestId: '',
  url: '',
  visitorId: '',
  environment: [],
}

export const LIMIT_SELECTOR_VALUES = [10, 20, 30, 40, 50] satisfies LimitSelectorValues[]
