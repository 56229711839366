import { Circle } from '@visx/shape'
import { ComponentProps } from 'react'

export function CircleMark({
  cx,
  cy,
  fill,
  className,
}: Pick<ComponentProps<typeof Circle>, 'cx' | 'cy' | 'fill' | 'className'>) {
  return <Circle cx={cx} cy={cy} r={4} fill={fill} stroke='white' strokeWidth={2} className={className} />
}
