import { DistributionReturnType, SubscriptionDistribution } from 'models'

import DistributionWidget, {
  DistributionWidgetProps,
} from '../../../commonUI/components/Distributions/DistributionWidget'
import { CAPTION_MAPPING, INFO_MAPPING, LABEL_KEY_MAPPING } from './const'

export type SubscriptionDistributionWidgetProps<
  Distribution extends SubscriptionDistribution = SubscriptionDistribution,
> = Pick<
  DistributionWidgetProps<DistributionReturnType<Distribution>['data'][number]>,
  'rowProps' | 'title' | 'isError' | 'hideHeader'
> & {
  type?: Distribution
  distribution?: DistributionReturnType<Distribution>
}

export function SubscriptionDistributionWidget<
  Distribution extends SubscriptionDistribution = SubscriptionDistribution,
>({
  type,
  distribution,
  title,
  isError,
  hideHeader = false,
  rowProps,
}: SubscriptionDistributionWidgetProps<Distribution>) {
  const labelKey = distribution
    ? (LABEL_KEY_MAPPING[distribution.type] as keyof (typeof distribution)['data'][number])
    : undefined

  return (
    <DistributionWidget
      labelKey={labelKey}
      hideHeader={hideHeader}
      info={distribution ? INFO_MAPPING[distribution.type] : undefined}
      title={title}
      isError={isError}
      distribution={distribution?.data}
      valueLabel={type ? 'events' : undefined}
      keyLabel={type ? CAPTION_MAPPING[type] : undefined}
      rowProps={rowProps}
    />
  )
}
