import { Button, ButtonProps } from '@compass/components'
import { Menu, MenuItem } from '@compass/Menu/Menu'
import { Popover } from '@compass/Popover'
import clsx from 'clsx'
import { USE_IDENTIFICATION_EXPORT } from 'const'
import { useCurrentSubscriptionData } from 'features/subscription'
import { Download, Ellipsis } from 'lucide-react'
import { SubscriptionStatus, SubscriptionType } from 'models'
import { ReactNode, useState } from 'react'
import { MenuTrigger } from 'react-aria-components'

import ExportIdentificationEventsModal from '../ExportEvent/ExportIdentificationEventsModal'

type ActionItem = {
  label: string
  isDisabled?: boolean
  onPress: () => void
  icon: ReactNode
  className?: string
  variant?: ButtonProps['variant']
  shouldDisplay?: boolean
}

type ActionsMenuProps = {
  isExportDisabled: boolean
}

export function ActionsMenu({ isExportDisabled }: ActionsMenuProps) {
  const [exportEventsModalOpen, setExportEventsModalOpen] = useState(false)

  const { subscription } = useCurrentSubscriptionData()

  const isPocWithRestrictedStatus =
    subscription?.status === SubscriptionStatus.Restricted && subscription?.type === SubscriptionType.ProofOfConcept
  const isExportEnabled =
    !!USE_IDENTIFICATION_EXPORT &&
    !!subscription &&
    subscription.featureSettings?.visitsExport !== false &&
    (EXPORT_ENABLED_FOR_STATUSES.includes(subscription.status) || isPocWithRestrictedStatus)

  const actions: ActionItem[] = [
    {
      label: 'Export',
      isDisabled: isExportDisabled,
      onPress: () => setExportEventsModalOpen(true),
      icon: <Download size={16} />,
      shouldDisplay: isExportEnabled,
    },
  ]

  return (
    <>
      <MenuTrigger>
        <Button className='md:hidden' isIcon variant='secondary' aria-label='Show identification visits actions'>
          <Ellipsis />
        </Button>
        <Popover placement='bottom end' className='min-w-[150px] w-fit'>
          <Menu className='p-0'>
            {actions.map(
              ({ label, icon, onPress, shouldDisplay }) =>
                shouldDisplay && (
                  <MenuItem key={label} aria-label={label} prefix={icon} className='' onAction={onPress}>
                    {label}
                  </MenuItem>
                )
            )}
          </Menu>
        </Popover>
      </MenuTrigger>
      {actions.map((action) => (
        <ActionMenuItem key={action.label} {...action} className='hidden md:flex' />
      ))}

      <ExportIdentificationEventsModal
        isOpen={exportEventsModalOpen}
        handleClose={() => setExportEventsModalOpen(false)}
      />
    </>
  )
}

function ActionMenuItem({
  label,
  variant = 'secondary',
  isDisabled,
  onPress,
  icon,
  className,
  shouldDisplay,
}: ActionItem) {
  if (!shouldDisplay) return null

  return (
    <Button
      variant={variant}
      isDisabled={isDisabled}
      aria-label={label}
      onPress={onPress}
      className={clsx('items-center', className)}
    >
      {icon}
      {label}
    </Button>
  )
}

const EXPORT_ENABLED_FOR_STATUSES = [
  SubscriptionStatus.Trialing,
  SubscriptionStatus.Active,
  SubscriptionStatus.ProofOfConcept,
]
