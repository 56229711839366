import { Stack } from '@compass/components'
import { Paper, Skeleton, Typography } from '@mui/material'
import { formatNum } from 'helpers/format'

export type TotalUsageProps = {
  totalUsage?: number
  totalProjectedUsage?: number
  isLoading?: boolean
}

export function TotalUsage({ totalUsage = 0, totalProjectedUsage = 0, isLoading }: TotalUsageProps) {
  return (
    <>
      <Paper>
        <Stack className='px-6 py-5' gap={6} direction={{ default: 'column', sm: 'row' }}>
          <Stack gap={1} direction='column'>
            <Typography variant='bodyS' component='span'>
              Total contract usage
            </Typography>

            {isLoading ? (
              <Skeleton height={20} width={8} className='transform-none' />
            ) : (
              <Typography variant='bodyMMedium' component='span' data-testid='enterprise-total-usage'>
                {formatNum(totalUsage)}
              </Typography>
            )}
          </Stack>
          <Stack gap={1} direction='column'>
            <Typography variant='bodyS' component='span'>
              Total contract projected
            </Typography>
            {isLoading ? (
              <Skeleton height={20} width={8} className='transform-none' />
            ) : (
              <Typography variant='bodyMMedium' component='span' data-testid='enterprise-total-projected-usage'>
                {formatNum(totalProjectedUsage)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Paper>
    </>
  )
}
