import { Button } from '@compass/components'
import { HelpOutline } from '@mui/icons-material'
import { Alert } from '@mui/lab'
import { InputLabel, Link, OutlinedInput, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import clsx from 'clsx'
import { useValidateCloudflareApiTokenMutation } from 'hooks'
import { AnalyticsContext } from 'models'
import { ampli } from 'models/ampli'
import { MouseEventHandler, PropsWithChildren, useCallback, useState } from 'react'

import { DOCS_CLOUDFLARE_WIZARD, GenericError } from '../../../../const'
import WizardStep, { WizardStepActions, WizardStepContent } from '../../../Wizard/Step/WizardStep'
import styles from './AccountCredentials.module.scss'

export interface AccountCredentialsProps {
  subscriptionId: string
  onChooseCredentials: (accountId: string, tokenApi: string) => void
  onNext: () => void
  analyticsContext: AnalyticsContext
}

export default function AccountCredentials({
  subscriptionId,
  onChooseCredentials,
  onNext,
  analyticsContext,
}: AccountCredentialsProps) {
  const [accountId, setAccountId] = useState('')
  const [apiToken, setApiToken] = useState('')
  const [error, setError] = useState<GenericError>()

  const { mutate: verifyApiToken, isPending } = useValidateCloudflareApiTokenMutation()

  const onAccountIDHintClick = useCallback(
    () => ampli.cloudflareIntegrationAccountIdHintClicked({ context: analyticsContext }),
    [analyticsContext]
  )
  const onAPITokenHintClick = useCallback(
    () => ampli.cloudflareIntegrationApiTokenHintClicked({ context: analyticsContext }),
    [analyticsContext]
  )

  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  async function handleSubmit() {
    if (accountId && apiToken) {
      verifyApiToken(
        {
          data: {
            subscriptionId,
            accountId,
            apiToken,
          },
        },
        {
          onSuccess: (result) => {
            if (result.isValid) {
              onChooseCredentials(accountId, apiToken)
              onNext()
            } else {
              setError({ code: 'value_invalid', message: 'Account ID & API Token does not match. Please try again.' })
            }
          },
          onError: (requestError) => setError(requestError),
        }
      )
    }
  }

  return (
    <WizardStep
      title='Cloudflare Integration'
      subtitle='Add your account details to connect your Cloudflare account.'
      error={error}
    >
      <WizardStepContent>
        <Alert severity='info' className={styles.alert}>
          If you change these details you will need to reconnect.
        </Alert>

        <div className={styles.forms}>
          <div>
            <InputLabel htmlFor='accountId'>Cloudflare Account ID</InputLabel>
            <OutlinedInput
              fullWidth
              id='accountId'
              placeholder='Enter your Cloudflare account ID'
              value={accountId}
              onChange={(e) => setAccountId(e.target.value.trim())}
              endAdornment={
                <InputHelp compact={mdDown} href={DOCS_CLOUDFLARE_WIZARD.accountId} onClick={onAccountIDHintClick}>
                  Where can I find this?
                </InputHelp>
              }
              className={clsx({ [styles.monospace]: accountId.length > 0 })}
            />
          </div>
          <div>
            <InputLabel htmlFor='apiToken'>API Token</InputLabel>
            <OutlinedInput
              fullWidth
              id='apiToken'
              type='password'
              autoComplete='new-password'
              placeholder='Enter your API Token'
              value={apiToken}
              onChange={(e) => setApiToken(e.target.value.trim())}
              endAdornment={
                <InputHelp compact={mdDown} href={DOCS_CLOUDFLARE_WIZARD.apiToken} onClick={onAPITokenHintClick}>
                  How do I generate this?
                </InputHelp>
              }
              className={clsx({ [styles.monospace]: apiToken.length > 0 })}
            />
          </div>
        </div>
      </WizardStepContent>

      <WizardStepActions>
        <Button
          key='proceed'
          onPress={handleSubmit}
          isDisabled={isPending || !accountId || !apiToken}
          className={styles.button}
        >
          Continue
        </Button>
      </WizardStepActions>
    </WizardStep>
  )
}

interface InputHelpProps {
  href: string
  compact?: boolean
  onClick?: MouseEventHandler
}

function InputHelp({ href, compact, onClick, children }: PropsWithChildren<InputHelpProps>) {
  return (
    <Link className={styles.helpLink} underline='none' href={href} target='_blank' onClick={onClick}>
      {compact ? <HelpOutline className={styles.helpIcon} /> : children}
    </Link>
  )
}
