import { Badge } from '@compass/Badge'
import { Stack } from '@compass/Layout/Stack'
import { Typography } from '@mui/material'
import { CircleCheck } from 'lucide-react'

import MismatchedDomainsAccordionSection from '../mismatchedDomainsAccordionSection/MismatchedDomainsAccordionSection'

interface IntendedDomainCalloutProps {
  domain: string
  domainEtld: string
}

export function IntendedDomainCallout({ domain, domainEtld }: IntendedDomainCalloutProps) {
  return (
    <MismatchedDomainsAccordionSection
      icon={<CircleCheck size={16} className='text-blue-700' />}
      label='Intended domain'
    >
      <Stack
        direction='row'
        gap={2}
        className='items-center p-4 bg-gray-200 rounded shadow-inset-100 overflow-hidden flex-wrap'
      >
        <Typography variant='bodyM'>{domain}</Typography>
        <Badge tone='secondary' variant='subtle'>
          Proxy
        </Badge>
        <Typography variant='bodyM'>→</Typography>
        <Typography className='text-blue-800' variant='bodyM'>
          {domainEtld}
        </Typography>
      </Stack>
    </MismatchedDomainsAccordionSection>
  )
}
