import { useCurrentSubscription } from 'features/subscription'
import { useUserSubscriptionSettings, useUserSubscriptionSettingsUpdate } from 'hooks/api/useUserSubscriptionSettings'
import { ChartAggregation, ChartDisplay, ChartLocation, ChartPreference } from 'models'
import { useCallback } from 'react'

export function useChartPreferences<ChartType extends string>(
  location: ChartLocation,
  defaultPreference: Omit<ChartPreference, 'location'>
) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: chartPreferences, isError: preferencesFailedToLoad } = useUserSubscriptionSettings(
    currentSubscriptionId,
    'chart_list_preference'
  )
  const { mutate: updateUserSettings } = useUserSubscriptionSettingsUpdate({ revertOnError: false })

  const updatePreference = useCallback(
    (changes: Partial<Omit<ChartPreference, 'location'>>) => {
      // If the preferences are still loading, don't overwrite them.
      if (chartPreferences == null) {
        return
      }

      const copiedPreferences: typeof chartPreferences = JSON.parse(JSON.stringify(chartPreferences ?? []))
      const oldValueIndex = copiedPreferences.findIndex((p) => p.location === location) ?? -1
      if (oldValueIndex === -1) {
        copiedPreferences.push({
          ...defaultPreference,
          location,
          ...changes,
        })
      } else {
        copiedPreferences[oldValueIndex] = {
          ...defaultPreference,
          ...copiedPreferences[oldValueIndex],
          ...changes,
        }
      }

      updateUserSettings({
        params: { subscriptionId: currentSubscriptionId },
        data: {
          settings_name: 'chart_list_preference',
          settings: copiedPreferences,
        },
      })
    },
    [chartPreferences, currentSubscriptionId, defaultPreference, location, updateUserSettings]
  )

  const firstPreferenceForLocation = chartPreferences?.find((p) => p.location === location)

  const preference = preferencesFailedToLoad
    ? defaultPreference
    : chartPreferences == null
      ? undefined // loading state
      : (firstPreferenceForLocation ?? defaultPreference)

  const setSelectedChart = useCallback((type: ChartType) => updatePreference({ type }), [updatePreference])
  const setGranularity = useCallback(
    (aggregation: ChartAggregation) => updatePreference({ aggregation }),
    [updatePreference]
  )
  const setDisplay = useCallback((d: ChartDisplay) => updatePreference({ display: d }), [updatePreference])

  return { preference, setSelectedChart, setGranularity, setDisplay }
}
