import { Alert } from '@compass/components'
import { Link, Skeleton, Table } from '@mui/material'
import { curveMonotoneX } from '@visx/curve'
import { Group } from '@visx/group'
import { TableBody, TableBodyData, TableCell, TableHead, TableRow } from 'components/Table/Table'
import {
  ChartError,
  ChartGraph,
  ChartHeader,
  ChartLayout,
  ChartLegend,
  ChartLegendItem,
  ChartLoading,
  ChartMainValue,
  ChartNoData,
  ChartPill,
  ChartTitle,
  ChartValue,
  ChartValueComparison,
  CircleMark,
  formatChartTimestamp,
  LineWithShadow,
  OrangeMarker,
  PurpleMarker,
  ResponsiveVisXChart,
  TooltipContainer,
  TooltipContent,
  TooltipHeader,
  useTimelineIndexer,
} from 'features/chart'
import { useDateRangeContext } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum, formatNumShort } from 'helpers/format'
import { useCurrentUser } from 'hooks/api/users'
import { ChartAggregation, SubscriptionStatistic, SubscriptionStatisticValue } from 'models'
import { Fragment } from 'react'

import { useInsightsStats } from '../../../WorkspaceInsights/useInsightsStats'
import { useEventsPerVisitorComparison, Value } from '../hooks/useEventsPerVisitorComparison'
import { useEventsPerVisitorTooltipData } from '../hooks/useEventsPerVisitorTooltipData'
import { InsightsChartProps } from '../InsightsChartProps'

const timestampAccessor = (v: Value) => v.timestamp.valueOf()

export function EventsPerVisitorContent({ title, action, granularity, showTable }: InsightsChartProps) {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const { dateRange } = useDateRangeContext()

  const { isLoading, error, values, xDomain, yDomain } = useEventsPerVisitorComparison(
    subscriptionId,
    currentUser,
    dateRange,
    granularity
  )

  const { handlePointer, tooltipIndex, position } = useTimelineIndexer(values, timestampAccessor)
  const tooltipData = useEventsPerVisitorTooltipData(tooltipIndex, values, dateRange)

  return (
    <ChartLayout>
      <Header title={title} action={action} />
      {showTable ? (
        <ChartTable isLoading={isLoading} values={values} granularity={granularity} />
      ) : (
        <>
          <Legend />
          <ChartGraph>
            {isLoading ? <ChartLoading /> : null}
            {error ? <ChartError /> : null}
            {!isLoading && !error && values != null && values?.length === 0 ? <ChartNoData /> : null}
            {isLoading || error || values?.length === 0 ? null : (
              <ResponsiveVisXChart
                timezone={currentUser?.timezone}
                xDomain={xDomain}
                yDomain={yDomain}
                margin={{ top: 0, right: 8, bottom: 20, left: 24 }}
                svgClassName='overflow-visible'
                handlePointer={handlePointer}
                values={values}
                granularity={granularity}
              >
                {({ xScale, yScale, parentWidth }) => {
                  const visitorsLine = (
                    <LineWithShadow
                      data={values}
                      curve={curveMonotoneX}
                      x={(point) => xScale(point.timestamp)}
                      y={(point) => yScale(point.visitorCount ?? 0)}
                      stroke='hsl(var(--fpds-color-purple-7))'
                      strokeWidth={1.5}
                      strokeOpacity={1}
                    />
                  )

                  const eventsLine = (
                    <LineWithShadow
                      data={values}
                      curve={curveMonotoneX}
                      x={(point) => xScale(point.timestamp)}
                      y={(point) => yScale(point.eventCount ?? 0)}
                      stroke='hsl(var(--fpds-color-orange-7))'
                      strokeWidth={1.5}
                      strokeOpacity={1}
                    />
                  )

                  const tooltipMarkers =
                    tooltipData != null ? (
                      <>
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.visitorCount ?? 0)}
                          fill='hsl(var(--fpds-color-purple-7))'
                        />
                        <CircleMark
                          cx={tooltipData.periodStart ? xScale(tooltipData.periodStart) : undefined}
                          cy={yScale(tooltipData.eventCount ?? 0)}
                          fill='hsl(var(--fpds-color-orange-7))'
                        />
                      </>
                    ) : null

                  const valuePills =
                    granularity !== 'hour' && parentWidth / (values.length || 1) > 50
                      ? values.map((point, i) => (
                          <Fragment key={i}>
                            {point.visitorCount != null ? (
                              <ChartPill
                                x={xScale(point.timestamp)}
                                y={yScale(point.visitorCount)}
                                text={`${point.visitorCount >= 100000 ? formatNumShort(point.visitorCount) : formatNum(point.visitorCount)}`}
                                circleColor='hsl(var(--fpds-color-purple-7))'
                              />
                            ) : null}
                            {point.eventCount != null ? (
                              <ChartPill
                                x={xScale(point.timestamp)}
                                y={yScale(point.eventCount)}
                                text={`${point.eventCount >= 100000 ? formatNumShort(point.eventCount) : formatNum(point.eventCount)}`}
                                circleColor='hsl(var(--fpds-color-orange-7))'
                              />
                            ) : null}
                          </Fragment>
                        ))
                      : null

                  return (
                    <>
                      {/* To make sure that the lines don't go under the axis, apply a cliping path around the Lines. */}
                      <Group clipPath='url(#content)'>
                        {visitorsLine}
                        {eventsLine}
                      </Group>
                      {tooltipMarkers}
                      {valuePills}
                    </>
                  )
                }}
              </ResponsiveVisXChart>
            )}
            {tooltipData != null ? (
              <TooltipContainer position={position}>
                <TooltipHeader>
                  <span className='font-mono'>{formatChartTimestamp(tooltipData.periodStart, granularity)}</span>
                </TooltipHeader>
                <TooltipContent className='grid grid-cols-[auto_1fr_auto] gap-x-3 gap-y-2 items-center justify-between '>
                  <OrangeMarker />
                  <span className='text-gray-800'>API calls</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.eventCount != null ? formatNum(tooltipData.eventCount) : 0}
                  </span>

                  <PurpleMarker />
                  <span className='text-gray-800'>Unique visitors</span>
                  <span className='ml-4 font-mono font-medium text-right'>
                    {tooltipData.visitorCount != null ? formatNum(tooltipData.visitorCount) : 0}
                  </span>

                  <div className='col-span-3 border-b border-b-gray-200' />

                  <span className='text-gray-800 col-span-2'>Ratio</span>
                  <span className='font-mono font-medium text-right'>
                    {tooltipData.eventCount != null && tooltipData.visitorCount != null && tooltipData.visitorCount != 0
                      ? formatNum(tooltipData.eventCount / tooltipData.visitorCount, true)
                      : 0}
                  </span>
                </TooltipContent>
              </TooltipContainer>
            ) : null}
          </ChartGraph>
          <Alert variant='secondary' size='sm' alt className='mt-4'>
            Spikes in ratio value may indicate that you are making excessive API calls, and you may need to optimize
            your implementation.{' '}
            <Link
              href='https://dev.fingerprint.com/docs/optimize-javascript-agent'
              underline='none'
              target='_blank'
              className='text-inherit font-medium hover:underline'
            >
              Learn more
            </Link>
          </Alert>
        </>
      )}
    </ChartLayout>
  )
}
function Header({ title, action }: Pick<InsightsChartProps, 'action' | 'title'>) {
  const { data: stats, isLoading: statsLoading } = useInsightsStats()
  const valueTotal = stats?.find((s) => s.type === SubscriptionStatistic.EventsPerVisitor)?.data as
    | SubscriptionStatisticValue[SubscriptionStatistic.EventsPerVisitor]
    | undefined

  return (
    <ChartHeader>
      <ChartTitle
        title={title}
        info='Shows the ratio of identification requests per unique visitor ID.'
        action={action}
      />
      <ChartValue>
        <ChartMainValue>
          {valueTotal?.currentPeriod != null ? (
            formatNum(valueTotal.currentPeriod, true)
          ) : (
            <Skeleton width={80} height={28} />
          )}
        </ChartMainValue>
        <ChartValueComparison
          isLoading={statsLoading}
          value={valueTotal?.currentPeriod}
          previousValue={valueTotal?.prevPeriod}
        />
      </ChartValue>
    </ChartHeader>
  )
}

function Legend() {
  return (
    <ChartLegend>
      <ChartLegendItem>
        <OrangeMarker />
        API calls
      </ChartLegendItem>
      <ChartLegendItem>
        <PurpleMarker />
        Unique visitors
      </ChartLegendItem>
    </ChartLegend>
  )
}

function ChartTable({
  isLoading,
  values,
  granularity,
}: {
  isLoading?: boolean
  values: Value[]
  granularity?: ChartAggregation
}) {
  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='25%'>Time</TableCell>
          <TableCell width='25%' align='right'>
            API calls
          </TableCell>
          <TableCell width='25%' align='right'>
            Unique visitors
          </TableCell>
          <TableCell width='25%' align='right'>
            Ratio
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody columnCount={4} isLoading={isLoading}>
        <TableBodyData>
          {values.map((v) => (
            <TableRow key={v.timestamp.valueOf()}>
              <TableCell>{formatChartTimestamp(v.timestamp, granularity)}</TableCell>
              <TableCell align='right'>{v.eventCount != null ? formatNum(v.eventCount) : null}</TableCell>
              <TableCell align='right'>{v.visitorCount != null ? formatNum(v.visitorCount) : null}</TableCell>
              <TableCell align='right'>
                {v.eventCount != null && v.visitorCount != null && v.visitorCount != 0
                  ? formatNum(v.eventCount / v.visitorCount, true)
                  : 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBodyData>
      </TableBody>
    </Table>
  )
}
