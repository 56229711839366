import { ReactNode } from 'react'

import IntegrationTopFrame, { IntegrationTopFrameProps } from '../IntegrationTopFrame/IntegrationTopFrame'
import styles from './IntegrationPageContents.module.scss'

export type IntegrationPageContentsProps = IntegrationTopFrameProps & {
  sections?: ReactNode
}

export default function IntegrationPageContents({ sections, ...props }: IntegrationPageContentsProps) {
  return (
    <div className={styles.contents}>
      <IntegrationTopFrame {...props} />
      {sections}
    </div>
  )
}
