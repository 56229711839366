import { WithTooltip } from '@compass/WithTooltip'
import { Paper, Skeleton, Typography } from '@mui/material'
import clsx from 'clsx'
import { Info, Meh } from 'lucide-react'
import { PropsWithChildren, ReactNode } from 'react'

import styles from './DataCard.module.scss'

type Props = PropsWithChildren<{
  title?: ReactNode
  info?: ReactNode
  subtitle?: ReactNode
  hasError?: boolean
}>

export function DataCard({ title, info, subtitle, children, hasError }: Props) {
  if (hasError) {
    return (
      <Paper className={clsx(styles.card, styles.error)}>
        <Meh className='size-4 text-gray-800' />
        <Typography variant='bodyS'>There was an error displaying the data.</Typography>
      </Paper>
    )
  }

  return (
    <Paper className={styles.card}>
      <div className={styles.header}>
        <div className={styles.titleRow}>
          {title == null ? <Skeleton width={80} height={20} className='transform-none' /> : null}
          {typeof title === 'string' ? <Typography variant='bodyMMedium'>{title}</Typography> : title}
          {info ? (
            <div className='flex flex-grow justify-end'>
              <WithTooltip content={info}>
                <Info size={12} className='text-gray-800' />
              </WithTooltip>
            </div>
          ) : null}
        </div>
        {subtitle ?? null}
      </div>
      {children ?? <Skeleton width={128} height={28} className='transform-none' />}
    </Paper>
  )
}
