import { Button, SelectItem, SelectListBox, SelectPopover } from '@compass/components'
import { ChevronsUpDownIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Select } from 'react-aria-components'
import { useHistory } from 'react-router-dom'

import { LIMIT_SELECTOR_VALUES } from '../../utils/const'

export const selectOptions = LIMIT_SELECTOR_VALUES.map((key) => ({
  key: key,
  value: key,
}))

export function LimitSelector() {
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)
  const [limit, setLimit] = useState(params.get('limit') ?? 20)

  const handleChange = (changedLimit: string) => {
    setLimit(changedLimit)
    params.set('limit', changedLimit)
    history.push({
      search: params.toString(),
    })
  }

  useEffect(() => {
    const prevParams = new URLSearchParams(history.location.search)
    setLimit(prevParams.get('limit') ?? 20)
  }, [history.location])

  return (
    <div>
      <Select<typeof selectOptions>
        aria-label='Change result limit'
        selectedKey={limit}
        onSelectionChange={handleChange}
      >
        <Button variant='ghost' aria-label='Change amount of results per page' className='text-gray-900'>
          {limit} <ChevronsUpDownIcon />
        </Button>
        <SelectPopover>
          <SelectListBox>
            {selectOptions?.map((option) => (
              <SelectItem key={option.key} id={option.key} value={option}>
                {option.key}
              </SelectItem>
            ))}
          </SelectListBox>
        </SelectPopover>
      </Select>
    </div>
  )
}
