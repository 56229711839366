import { useDefaultPredefinedRanges } from 'features/commonUI'
import { PredefinedRange } from 'helpers/dateRange'
import { useSearchParams } from 'hooks/useSearchParams'
import { DateTime } from 'luxon'
import { DateRange, DateRangePeriod, VisitsFilter, VisitsFilterAndDateRange } from 'models'
import { useMemo } from 'react'

import { defaultValues } from '../utils/const'

export enum IdentificationUrlKeys {
  RequestId = 'requestId',
  VisitorId = 'visitorId',
  IpAddress = 'ipAddress',
  Url = 'url',
  LinkedId = 'linkedId',
  Origin = 'origin',
  Since = 'since',
  Before = 'before',
  Period = 'period',
  Environment = 'environment',
  Limit = 'limit',
}

const MANAGED_KEYS: Array<keyof VisitsFilterAndDateRange> = [
  IdentificationUrlKeys.RequestId,
  IdentificationUrlKeys.VisitorId,
  IdentificationUrlKeys.IpAddress,
  IdentificationUrlKeys.Url,
  IdentificationUrlKeys.LinkedId,
  IdentificationUrlKeys.Origin,
  IdentificationUrlKeys.Since,
  IdentificationUrlKeys.Before,
  IdentificationUrlKeys.Period,
  IdentificationUrlKeys.Environment,
  IdentificationUrlKeys.Limit,
]

export const useFilterSearchParams = () => {
  const [currentSearchParams, setSearchParams] = useSearchParams<VisitsFilterAndDateRange & Record<string, any>>(
    MANAGED_KEYS
  )

  const { ranges } = useDefaultPredefinedRanges()

  const handleSetSearchParams = ({ environment, ...params }: VisitsFilterAndDateRange) => {
    const period = currentSearchParams.period ? { period: currentSearchParams.period } : {}
    const since = currentSearchParams.since ? { since: currentSearchParams.since } : {}
    const before = currentSearchParams.before ? { before: currentSearchParams.before } : {}

    const paramsToBeSet = {
      ...period,
      ...since,
      ...before,
      ...params,
      ...environmentAdapter(environment),
    }

    const filteredParams = Object.keys(paramsToBeSet).reduce<VisitsFilterAndDateRange>(
      (acc, param: keyof VisitsFilterAndDateRange) => {
        if (paramsToBeSet[param]) {
          return { ...acc, [param]: paramsToBeSet[param] }
        }
        return acc
      },
      {} as VisitsFilterAndDateRange
    )

    setSearchParams(filteredParams)
  }

  const defaultValuesWithSearchParams = useMemo(() => {
    const { period, since, before, ...rest } = currentSearchParams

    return Object.assign({}, defaultValues, {
      ...rest,
    }) as VisitsFilter
  }, [currentSearchParams])

  const resetSearchParams = (paramsToBeRemoved: Array<keyof VisitsFilter>) => {
    setSearchParams(
      Object.keys(currentSearchParams).reduce<VisitsFilterAndDateRange & Record<string, any>>(
        (acc, param: keyof VisitsFilterAndDateRange & string) => {
          if (paramsToBeRemoved.includes(param as any)) return acc
          return { ...acc, [param]: currentSearchParams[param] }
        },
        {} as VisitsFilterAndDateRange & Record<string, any>
      )
    )
  }

  const adaptedDate = dateRangeAdapter({
    ...(currentSearchParams.period ? ({ period: currentSearchParams.period } as DateRangePeriod) : {}),
    before: currentSearchParams.before,
    since: currentSearchParams.since,
    ranges,
  })

  const dateRange = useMemo(
    () => ({
      ...(adaptedDate.period ? ({ period: adaptedDate.period } as DateRangePeriod) : {}),
      startDate: adaptedDate.since,
      endDate: adaptedDate.before,
    }),
    [adaptedDate]
  )

  return {
    filterValues: filterAdapter(currentSearchParams as any, ranges),
    dateRange,
    setSearchParams: handleSetSearchParams,
    defaultValuesWithSearchParams,
    resetSearchParams,
  }
}

// Add the CIDR notation if it's missing.
function ipAddressAdapter(ipAddress?: string) {
  if (ipAddress === undefined) {
    return {}
  }

  if (ipAddress === '' || ipAddress.includes('/')) {
    return { ipAddress }
  }

  const isIpV4 = ipAddress.includes('.')
  const postFix = isIpV4 ? 32 : 128

  return {
    ipAddress: `${ipAddress}/${postFix}`,
  }
}

function dateRangeAdapter({
  period,
  before,
  since,
  ranges,
}: {
  period?: DateRangePeriod['period']
  before?: string
  since?: string
  ranges: Record<DateRangePeriod['period'], PredefinedRange>
}): DateRange & DateRangePeriod {
  let periodTransformed = {} as DateRangePeriod
  let sinceTransformed = {}
  let beforeTransformed = {}

  if (period) {
    sinceTransformed = {
      since: ranges[period].startDate,
    }

    beforeTransformed = {
      period,
      before: ranges[period].endDate.endOf('day'),
    }
  } else {
    if (since) {
      sinceTransformed = {
        since: DateTime.fromISO(since).startOf('day'),
      }
    }
    if (before) {
      beforeTransformed = {
        before: DateTime.fromISO(before).endOf('day'),
      }
    }
  }

  const isBeforeValid = (beforeTransformed as { before: DateTime })?.before?.isValid
  const isSinceValid = (sinceTransformed as { since: DateTime })?.since?.isValid

  if ((!period && !since && !before) || !isBeforeValid || !isSinceValid) {
    periodTransformed = {
      period: 'today',
      since: DateTime.fromJSDate(new Date()).startOf('day'),
      before: DateTime.fromJSDate(new Date()).endOf('day'),
    } as DateRange & DateRangePeriod
  }

  return {
    ...sinceTransformed,
    ...beforeTransformed,
    ...periodTransformed,
  } as DateRange & DateRangePeriod
}

function environmentAdapter(environment?: string[]) {
  if (!environment) return {}

  if (Array.isArray(environment) && environment.length > 0) {
    return { environment }
  }

  if (typeof environment == 'string' && environment != '') {
    return { environment: (environment as string).split(',').filter(Boolean) }
  }

  return {}
}

type FiltersFromUrl = VisitsFilter & {
  before: string
  since: string
} & DateRangePeriod
type FiltersWithoutAdapters = Omit<
  VisitsFilterAndDateRange,
  'ipAddress' | 'period' | 'before' | 'since' | 'environment'
>
function filterAdapter(
  { ipAddress, period, before, since, environment, ...params }: FiltersFromUrl,
  ranges: Record<DateRangePeriod['period'], PredefinedRange>
): VisitsFilter & DateRangePeriod & DateRange {
  const onlyManagedParams = Object.keys(params)
    .filter((p) => MANAGED_KEYS.includes(p as keyof VisitsFilterAndDateRange))
    .reduce((acc, param: keyof FiltersWithoutAdapters) => {
      return {
        ...acc,
        [param]: params[param],
      }
    }, {})

  return {
    ...onlyManagedParams,
    ...ipAddressAdapter(ipAddress),
    ...dateRangeAdapter({ period, before, since, ranges }),
    ...environmentAdapter(environment),
  }
}
