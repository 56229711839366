import { Stack } from '@compass/components'
import { Paper } from '@mui/material'
import { ErrorBoundary } from '@rollbar/react'
import {
  ChartError,
  ChartGraph,
  ChartHeader,
  ChartLayout,
  ChartLoading,
  ChartTitle,
  DisplaySelector,
  GranularitySelector,
} from 'features/chart'
import { ChartDisplay, ChartLocation, ChartPreference } from 'models'
import { FunctionComponent, ReactNode } from 'react'

import { useChartPreferences } from '../../../../../hooks/api/useChartPreferences'
import { ApiUsageContent } from './content/ApiUsageContent'
import { EventsPerVisitorContent } from './content/EventsPerVisitorContent'
import { FailedApiCallsContent } from './content/FailedApiCallsContent'
import { RpsContent } from './content/RpsContent'
import { InsightsChartProps } from './InsightsChartProps'
import { InsightsChartSelector, OverviewChartType } from './InsightsChartSelector'

const CHART_KEY_COMPONENT: Record<OverviewChartType, FunctionComponent<InsightsChartProps>> = {
  api_usage: ApiUsageContent,
  events_per_visitor: EventsPerVisitorContent,
  failed_api_calls: FailedApiCallsContent,
  rps: RpsContent,
}

const defaultPreference = {
  aggregation: 'day',
  display: ChartDisplay.Line,
  type: 'api_usage',
} satisfies Omit<ChartPreference, 'location'>

export function InsightsChart() {
  const { preference, setDisplay, setGranularity, setSelectedChart } = useChartPreferences(
    ChartLocation.Overview,
    defaultPreference
  )
  const { aggregation: granularity, display, type } = preference ?? {}
  const selectedChart = type as OverviewChartType | undefined

  const Component = selectedChart ? CHART_KEY_COMPONENT[selectedChart] : FallbackLayout
  const title = <InsightsChartSelector selectedChart={selectedChart} onSelectionChange={setSelectedChart} />

  return (
    <Paper className='relative p-6'>
      <ErrorBoundary
        errorMessage={`InsightsChart error for ${selectedChart}`}
        fallbackUI={() => <FallbackLayout title={title} />}
      >
        <Component
          title={title}
          action={
            <Stack gap={2} className='flex-grow flex-wrap items-center justify-start sm:justify-end -my-1'>
              <GranularitySelector value={granularity} onChange={setGranularity} />
              <DisplaySelector value={display} onChange={setDisplay} />
            </Stack>
          }
          granularity={granularity}
          showTable={display === 'table'}
        />
      </ErrorBoundary>
    </Paper>
  )
}

function FallbackLayout({ title, isError }: { title: ReactNode; isError?: boolean }) {
  return (
    <ChartLayout>
      <ChartHeader>
        <ChartTitle title={title} />
      </ChartHeader>
      <ChartGraph>{isError ? <ChartError /> : <ChartLoading />}</ChartGraph>
    </ChartLayout>
  )
}
