import { SelectItem, SelectListBox, SelectPopover, SelectTrigger, SelectValue } from '@compass/components'
import { Tooltip } from '@compass/Tooltip'
import { Skeleton } from '@mui/material'
import { SubscriptionStatistic } from 'models'
import { useCallback, useMemo } from 'react'
import { Select, TooltipTrigger } from 'react-aria-components'

import { ampli } from '../../../../models/ampli'
import { WIDGETS } from './const'

export const selectOptions = Object.entries(WIDGETS).map(([key, value]) => ({
  key: key as SubscriptionStatistic,
  ...value,
}))

export function InsightsAction({
  selected,
  all,
  unavailable,
  onSelect,
  blockIndex,
}: {
  selected: SubscriptionStatistic
  all: SubscriptionStatistic[]
  unavailable: Set<SubscriptionStatistic>
  onSelect: (selected: SubscriptionStatistic) => void
  blockIndex: number
}) {
  const handleItemClick = useCallback(
    (item: SubscriptionStatistic) => {
      ampli.chartSettingsChanged({
        chartType: 'KPI',
        metricName: selected,
        newMetricName: item,
        slotColumn: blockIndex,
        slotRow: 1,
      })
      onSelect(item)
    },
    [blockIndex, onSelect, selected]
  )

  const options = useMemo(() => {
    const set = new Set(all)
    return selectOptions.filter((o) => set.has(o.key))
  }, [all])

  return (
    <Select<typeof selectOptions>
      aria-label='Change displayed metric'
      selectedKey={selected}
      onSelectionChange={handleItemClick}
      onOpenChange={(open) => {
        if (open) {
          ampli.chartSettingsOpened({ chartType: 'KPI', metricName: selected })
        }
      }}
      className='-my-1 -ml-2'
    >
      <TooltipTrigger delay={750}>
        <Tooltip>Change displayed metric</Tooltip>
        <SelectTrigger className='border-none shadow-none text-base' size='sm' tone='ghost' fullWidth>
          <SelectValue<(typeof selectOptions)[number]> className='flex flex-col text-left'>
            {({ selectedItem }) => {
              if (selectedItem == null) {
                return <Skeleton width={100} height={20} className='transform-none' />
              }

              return selectedItem.title
            }}
          </SelectValue>
        </SelectTrigger>
      </TooltipTrigger>
      <SelectPopover>
        <SelectListBox>
          {options?.map((option) => (
            <SelectItem key={option.key} id={option.key} value={option} isDisabled={unavailable.has(option.key)}>
              {option.optionLabel}
            </SelectItem>
          ))}
        </SelectListBox>
      </SelectPopover>
    </Select>
  )
}
