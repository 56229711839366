import { Badge, WithTooltip } from '@compass/components'
import { Skeleton, Typography } from '@mui/material'
import { CustomDateRange } from 'helpers/dateRange'
import { formatNum, formatNumShort, formatPercent } from 'helpers/format'
import { TrendingDown, TrendingUp, TriangleAlert } from 'lucide-react'
import { DateTime } from 'luxon'

import styles from './ValueWithDifference.module.scss'

type Props = {
  value?: number | bigint
  difference?: number | bigint
  percentage?: number
  precise?: boolean
  availabilityBoundary?: DateTime
  shouldShowDiff?: boolean
  compareDateRange?: CustomDateRange
}

export function ValueWithDifference({
  value,
  difference,
  percentage,
  precise,
  availabilityBoundary,
  shouldShowDiff,
  compareDateRange,
}: Props) {
  return (
    <div className={styles.container}>
      <Value value={value} precise={precise} />
      {shouldShowDiff && <Chip difference={difference} percentage={percentage} compareDateRange={compareDateRange} />}
      {availabilityBoundary && (
        <WithTooltip
          content={
            <Typography variant='bodyS' color='white'>
              Data for this metric starts from: {availabilityBoundary.toFormat('yyyy-MM-dd')}
            </Typography>
          }
        >
          <TriangleAlert className='size-4 text-gray-800' />
        </WithTooltip>
      )}
    </div>
  )
}

function Value({ value, precise }: Pick<Props, 'value' | 'precise'>) {
  if (value == null) {
    return <Skeleton width={96} height={28} />
  }

  return (
    <WithTooltip content={precise ? `${value}` : formatNum(value)}>
      <Typography variant='h2' className={styles.value}>
        {value > 500000n ? formatNumShort(value) : formatNum(value, precise)}
      </Typography>
    </WithTooltip>
  )
}

function Chip({
  difference,
  percentage,
  compareDateRange,
}: Pick<Props, 'difference' | 'percentage' | 'compareDateRange'>) {
  if (difference == null || percentage == null || Number.isNaN(percentage) || !Number.isFinite(percentage)) {
    return null
  }

  const positive = difference >= 0n
  const prefix = positive ? '+' : ''
  const title =
    `Changed by ${prefix}${formatPercent(percentage)}` +
    (compareDateRange
      ? ` compared to the period from ` +
        `${compareDateRange?.compareStart?.toFormat('MMM dd, yyyy')} to ` +
        `${compareDateRange?.compareEnd?.toFormat('MMM dd, yyyy')}.`
      : '')
  const label = `${prefix}${formatPercent(percentage)}`
  const tone = positive ? 'default' : 'special'

  return (
    <WithTooltip content={title}>
      <div className={styles.chipContainer}>
        <Badge tone={tone} variant='solid'>
          {positive ? <TrendingUp /> : <TrendingDown />} {label}
        </Badge>
      </div>
    </WithTooltip>
  )
}
