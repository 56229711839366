import { Skeleton, Tooltip, Typography } from '@mui/material'
import { BILLING_TYPES } from 'const'
import { formatDate } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { formatNum, formatNumShort } from 'helpers/format'
import { useBillingQuota, useSubscription } from 'hooks'
import { BillingType, ExpandedSubscription, SubscriptionStatus } from 'models'

import { Widget } from './Widget'

export function UsageWidget() {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: subscription } = useSubscription(currentSubscriptionId)

  return <ApiCallsWidgetContent subscription={subscription} />
}

export function ApiCallsWidgetContent({ subscription }: { subscription?: ExpandedSubscription }) {
  const title =
    subscription?.billingType === BillingType.UniqueVisitors
      ? BILLING_TYPES.unique_visitors.name
      : BILLING_TYPES.api_calls.name

  const { currentUsage } = useBillingQuota(subscription)

  const infoContent = extractUsageTooltipContent(subscription)

  const shortenUsage = currentUsage > 500_000

  return (
    <Widget
      title={title}
      info={
        infoContent ? (
          <Typography variant='bodyS' color='white'>
            {infoContent}
          </Typography>
        ) : null
      }
    >
      <Tooltip title={shortenUsage ? (formatNum(currentUsage) ?? '') : ''}>
        <div>
          {subscription != null ? (
            <Typography variant='bodyMMedium'>
              {shortenUsage ? formatNumShort(currentUsage) : formatNum(currentUsage)}
            </Typography>
          ) : (
            <Skeleton width={80} />
          )}
        </div>
      </Tooltip>
    </Widget>
  )
}

function extractUsageTooltipContent(subscription?: ExpandedSubscription) {
  if (
    subscription == null ||
    subscription.status === SubscriptionStatus.Canceled ||
    subscription.billingType === BillingType.UniqueVisitors
  ) {
    return null
  }

  const period = extractPeriodDates(subscription)
  return `API calls made during the current period${period ? `: ${period.startDate} - ${period.endDate}` : ''}`
}

function extractPeriodDates(subscription: ExpandedSubscription) {
  if (subscription.currentPeriodStartedAt && subscription.currentPeriodEndsAt) {
    return {
      startDate: formatDate(subscription.currentPeriodStartedAt, 'shortWithYear'),
      endDate: formatDate(subscription.currentPeriodEndsAt, 'shortWithYear'),
    }
  }
  return undefined
}
