import { IncorrectETLDPlusOneData } from '../models'

export function sumInvalidOriginsCount(data: IncorrectETLDPlusOneData[]) {
  const seenOrigins = new Set<string>()

  data.forEach((it) => {
    if (it.invalidOrigins?.length) {
      it.invalidOrigins.forEach((origin) => seenOrigins.add(origin))
    }
  })

  return seenOrigins.size
}
