import { useQuery } from '@tanstack/react-query'
import { isGranularitySupported } from 'helpers/dateRange'
import { DateTime } from 'luxon'
import { ChartAggregation, UsageCounterPeriod } from 'models'

import { extractData, useBuildRequest, useGenericErrorHandler } from './base'

export function useTimeline(
  subscriptionId: string | undefined,
  metric: Array<'rps' | 'uniqueVisitors' | 'smartSignals'>,
  from?: DateTime,
  to?: DateTime,
  granularity?: ChartAggregation
) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['timeline', subscriptionId, from, to, metric, granularity] as const,
    queryFn: ({ queryKey: [_, subId, fromParam, toParam, metricParam] }) =>
      extractData(
        withErrorHandling(
          buildRequest('timeline', {
            params: { subscriptionId: subId! },
            queryParams: {
              from: fromParam!.setZone('UTC').toISO() ?? undefined,
              to: toParam!.setZone('UTC').toISO() ?? undefined,
              metric: metricParam,
              period: granularity as UsageCounterPeriod,
            },
          })
        )
      ),
    enabled:
      subscriptionId != null &&
      from != null &&
      to != null &&
      granularity != null &&
      metric.every(Boolean) &&
      isGranularitySupported(granularity, from, to),
  })
}
