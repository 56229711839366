import { useQueryClient } from '@tanstack/react-query'
import { SubscriptionTier } from 'models'
import { AddPaymentMethodProperties } from 'models/ampli'

import { useRequestMutation } from './api/base'
import { useSubscription } from './api/subscriptions'
import { useExistingOrNewPaymentMethod } from './stripe'

export interface ChangePlanData extends UpgradeFormData {
  newPlan: SubscriptionTier
  promotionId?: number
}

export type UpgradeFormData = {
  cardHolderName?: string
  paymentMethodId?: string
}

export function useChangePlan(subscriptionId: string, source: AddPaymentMethodProperties['source']) {
  const queryClient = useQueryClient()

  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription(subscriptionId)
  const [findOrCreatePaymentMethod, { isLoading: isLoadingPaymentMethod, error: paymentMethodError }] =
    useExistingOrNewPaymentMethod(source)
  const {
    isPending: isUpgrading,
    error: upgradeError,
    mutate: sendUpgradeRequest,
  } = useRequestMutation('subscriptionUpgrade', { errorHandling: { forceToast: true } })

  interface ChangePlanParams {
    data: ChangePlanData
    onSuccess: () => void
  }

  // TODO useCallback, simplify
  async function changePlan({ data, onSuccess }: ChangePlanParams) {
    if (subscription) {
      const { paymentMethodId, cardHolderName, newPlan, promotionId } = data

      const paymentMethod = await findOrCreatePaymentMethod(paymentMethodId, { name: cardHolderName })

      if (paymentMethod) {
        // Change plan
        sendUpgradeRequest(
          {
            params: { subscriptionId },
            data: {
              paymentMethod,
              promotionId,
              tier: newPlan,
            },
          },
          {
            onSuccess: () => {
              onSuccess()
              queryClient.invalidateQueries({ queryKey: ['subscriptions'] })
              queryClient.invalidateQueries({ queryKey: ['subscription'] })
              queryClient.invalidateQueries({ queryKey: ['userContext'] })
            },
          }
        )
      }
    }
  }

  return {
    changePlan,
    isLoading: isLoadingPaymentMethod || isLoadingSubscription || isUpgrading,
    error: paymentMethodError ?? upgradeError,
  } as const
}

export function useDowngrade() {
  const queryClient = useQueryClient()

  return useRequestMutation('subscriptionDowngrade', {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscriptions'] })
      queryClient.invalidateQueries({ queryKey: ['subscription'] })
      queryClient.invalidateQueries({ queryKey: ['userContext'] })
    },
    errorHandling: { forceToast: true },
  })
}
