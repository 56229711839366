import { USE_BILLING_QUOTA } from 'const'
import { ExpandedSubscription, SubscriptionType } from 'models'
import { useMemo } from 'react'

export type BillingQuotaSubscription = Pick<
  ExpandedSubscription,
  'type' | 'billingQuota' | 'usageQuota' | 'projectedBillingUsage' | 'projectedUsage'
>

export function useBillingQuota(subscription?: BillingQuotaSubscription) {
  const isBillingQuotaEnabled = useMemo(() => {
    // Only use billing quota for Paid and TrialOnly subscriptions since we don't have enough data for Prepaid and ProofOfConcept
    const isEligibleType =
      subscription?.type === SubscriptionType.Paid ||
      subscription?.type === SubscriptionType.TrialOnly ||
      subscription?.type === SubscriptionType.Free

    if (!isEligibleType) return false

    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get('useBillingQuota') === 'true' || USE_BILLING_QUOTA
  }, [subscription?.type])

  const currentUsage = useMemo(() => {
    if (isBillingQuotaEnabled && subscription?.billingQuota) {
      return subscription.billingQuota.total.billed
    }
    return subscription?.usageQuota?.billed ?? 0
  }, [isBillingQuotaEnabled, subscription])

  const projectedUsage = useMemo(() => {
    return subscription?.projectedUsage
  }, [subscription])

  return {
    useNewBillingQuota: isBillingQuotaEnabled,
    currentUsage,
    projectedUsage,
  }
}
