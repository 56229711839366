import 'swiper/swiper.min.css'

import { Alert, LinkButton, Stack } from '@compass/components'
import { CheckCircleOutline, DataUsage } from '@mui/icons-material'
import { Divider, Grid, Link, Paper, Skeleton, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { ConditionalOverduePaymentCallout } from 'features/billing'
import { ContentColumn, formatDate, Header, MainColumn, MainGrid, SubHeader } from 'features/commonUI'
import { ChangePlanAction } from 'features/planManagement'
import { useCurrentSubscription } from 'features/subscription'
import { useCurrentUser } from 'hooks/api/users'
import { useRouteState } from 'hooks/router'
import { useHasMadeRequestsPreviously } from 'hooks/useHasMadeRequestsPreviously'
import { DateTime } from 'luxon'
import {
  AnalyticsContext,
  ContactSupportTopic,
  CouponDurationType,
  Discount,
  ExpandedSubscription,
  Promotion,
  SubscriptionStatus,
  SubscriptionTier,
  SubscriptionType,
  UserRole,
} from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import DashboardBanner from '../../../../components/DashboardBanner/DashboardBanner'
import { PlanBadge } from '../../../../components/PlanBadge/PlanBadge'
import StatusDialog from '../../../../components/StatusDialog/StatusDialog'
import { SubscriptionStatusDot } from '../../../../components/SubscriptionStatusDot/SubscriptionStatusDot'
import {
  DEFAULT_PRODUCT,
  DOCS_BILLING,
  IS_TEST_MODE,
  PRO_PLUS_99_ANDROID_FREEMIUM_BILLING_DATA,
  PRO_PLUS_99_BILLING_DATA,
  PRO_PLUS_ANDROID_FREEMIUM_BILLING_DATA,
  PRO_PLUS_BILLING_DATA,
  SUBSCRIPTION_ANALYTICS_PLAN_NAMES,
  SUBSCRIPTION_DISPLAY_PLANS,
  SUBSCRIPTION_TIER_TO_DISPLAY_PLAN,
  SubscriptionDisplayPlan,
  SUPPORT_PAGE_URL,
} from '../../../../const'
import { date } from '../../../../helpers/data'
import { formatNum } from '../../../../helpers/format'
import { getSubscriptionDisplayPlan } from '../../../../helpers/subscription'
import {
  useAuth,
  useBillingQuota,
  useDocumentTitle,
  usePermissions,
  usePrice,
  useSubscription,
} from '../../../../hooks'
import { useUserContext } from '../../../../hooks/api/context'
import { useContactSupportMutation } from '../../../../hooks/api/support'
import { PlanDiscount } from '../../components/PlanDiscount/PlanDiscount'
import { Plan, PlanSelector } from '../../components/PlanSelector/PlanSelector'
import { PlanSummary } from '../../components/PlanSummary/PlanSummary'
import { PlanUsage } from '../../components/PlanUsage/PlanUsage'
import { getEntries } from '../../helpers/entries'

function generateTopBadge(
  planType: SubscriptionDisplayPlan,
  currentPlanValue: SubscriptionDisplayPlan
): string | undefined {
  return planType !== currentPlanValue &&
    (currentPlanValue === SubscriptionDisplayPlan.Plus ||
      currentPlanValue === SubscriptionDisplayPlan.PlusAndroidFreemium)
    ? 'Current plan - new pricing available'
    : undefined
}

export function PlanManagementPage() {
  useDocumentTitle('Plan & Usage')

  const { isImpersonating } = useAuth()

  const today = new Date()
  const routeState = useRouteState<{ didContactSupport?: boolean }>()
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription(subscriptionId)
  const { data: userContext, isLoading: isLoadingUserContext, isFetching: isFetchingUserContext } = useUserContext()
  const { subscriptionPermissions } = usePermissions()
  const { price: proBillingData } = usePrice(DEFAULT_PRODUCT)
  const { data: currentUser } = useCurrentUser()

  const { hasMadeRequests, isLoading: isLoadingUsageStatus } = useHasMadeRequestsPreviously()

  const { mutate: contactSupport, isPending: isContactingSupport } = useContactSupportMutation()

  const canCancelSubscription = subscription && subscriptionPermissions.canCancel(subscription)

  const userCanCancelSubscription = currentUser?.role === UserRole.Owner
  const plan = subscription ? SUBSCRIPTION_DISPLAY_PLANS[getSubscriptionDisplayPlan(subscription)].name : undefined
  const currentPlan =
    (!isLoadingSubscription && subscription && getSubscriptionDisplayPlan(subscription)) || SubscriptionDisplayPlan.Pro
  const isTrial = subscription?.type === SubscriptionType.TrialOnly
  const isTrialActive = subscription?.status === SubscriptionStatus.Trialing

  const promoTiers = subscription?.promotions?.reduce(
    (acc, promo) => {
      if (promo?.priceLookupKey) {
        acc[SUBSCRIPTION_TIER_TO_DISPLAY_PLAN[promo?.priceLookupKey]] = promo
      }
      return acc
    },
    {} as Record<SubscriptionDisplayPlan, Promotion>
  )

  let usageLimit: number | undefined
  if (isTrialActive) {
    usageLimit = Infinity
  } else if (subscription?.usageQuota?.quota) {
    usageLimit = subscription?.usageQuota?.quota
  } else if (subscription?.latestTier === SubscriptionTier.Pro) {
    usageLimit = proBillingData?.prepaidQuantity
  } else if (subscription?.latestTier === SubscriptionTier.PlusAndroidFreemium) {
    usageLimit = PRO_PLUS_ANDROID_FREEMIUM_BILLING_DATA.prepaidQuantity
  } else if (subscription?.latestTier === SubscriptionTier.Plus99) {
    usageLimit = PRO_PLUS_99_BILLING_DATA.prepaidQuantity
  } else if (subscription?.latestTier === SubscriptionTier.Plus99AndroidFreemium) {
    usageLimit = PRO_PLUS_99_ANDROID_FREEMIUM_BILLING_DATA.prepaidQuantity
  } else {
    usageLimit = PRO_PLUS_BILLING_DATA.prepaidQuantity
  }
  const relatedVisitorsUsage = subscription?.relatedVisitorsQuota?.billed
  const relatedVisitorsLimit = subscription?.relatedVisitorsQuota?.quota

  const relatedVisitorsLimitLabel =
    relatedVisitorsLimit === 0 ? (
      'Related Visitors API calls (Trial Period)'
    ) : (
      <>
        of{' '}
        {isLoadingSubscription ? (
          <Skeleton width={48} sx={{ display: 'inline-block' }} />
        ) : (
          formatNum(relatedVisitorsLimit ?? 0)
        )}
        {' Related Visitors API calls'}
      </>
    )

  const isEnterprise = subscription?.type === SubscriptionType.Prepaid
  const isSubscriptionPlan = subscription?.type === SubscriptionType.Paid
  const isPOC = subscription?.type === SubscriptionType.ProofOfConcept

  const { useNewBillingQuota, currentUsage, projectedUsage } = useBillingQuota(subscription)

  const getOveragePrice = useCallback(
    (currentSubscription: ExpandedSubscription) => {
      if (!isSubscriptionPlan) return undefined

      const displayPlan = getSubscriptionDisplayPlan(currentSubscription)
      switch (displayPlan) {
        case SubscriptionDisplayPlan.Pro:
          return proBillingData?.overagePrice
        case SubscriptionDisplayPlan.Plus:
          return PRO_PLUS_BILLING_DATA.overagePrice
        case SubscriptionDisplayPlan.PlusAndroidFreemium:
          return PRO_PLUS_ANDROID_FREEMIUM_BILLING_DATA.overagePrice
        case SubscriptionDisplayPlan.Plus99:
          return PRO_PLUS_99_BILLING_DATA.overagePrice
        case SubscriptionDisplayPlan.Plus99AndroidFreemium:
          return PRO_PLUS_99_ANDROID_FREEMIUM_BILLING_DATA.overagePrice
        default:
          return undefined
      }
    },
    [isSubscriptionPlan, proBillingData]
  )

  const entries = useMemo(
    () =>
      subscription
        ? getEntries({
            subscription,
            useNewBillingQuota,
            usageLimit,
            isSubscriptionPlan,
            getOveragePrice,
            displayPlan: currentPlan,
          })
        : [],
    [subscription, useNewBillingQuota, usageLimit, isSubscriptionPlan, getOveragePrice, currentPlan]
  )

  const email = userContext?.email ?? ''
  const willDowngrade = !!(
    subscription?.downgradeScheduledAt && DateTime.now() < DateTime.fromJSDate(date(subscription?.downgradeScheduledAt))
  )
  const hasFinishedTrial =
    subscription &&
    subscription.type === SubscriptionType.TrialOnly &&
    subscription.status === SubscriptionStatus.Canceled

  const hasFinishedPOC =
    subscription &&
    subscription.type === SubscriptionType.ProofOfConcept &&
    subscription.status === SubscriptionStatus.Restricted &&
    subscription.currentPeriodEndsAt &&
    today > new Date(subscription.currentPeriodEndsAt)

  const isLoading = isLoadingSubscription || isContactingSupport || isLoadingUserContext || isFetchingUserContext
  const isCancelingOrCanceled = !!(
    !hasFinishedTrial &&
    !hasFinishedPOC &&
    (subscription?.cancelAt || subscription?.status === SubscriptionStatus.Canceled)
  )
  const appliedDiscount = subscription?.discounts?.reduce<Discount | undefined>((acc, d) => {
    // Find discount with duration === 'repeating' and with the most percents off
    return d.coupon?.duration === CouponDurationType.Repeating &&
      (acc === undefined || (d.coupon?.percentOff ?? 0) > (acc.coupon?.percentOff ?? 0))
      ? d
      : acc
  }, undefined)

  const trackPlanChangeIntent = useMemo(
    () => (oldPlan: SubscriptionDisplayPlan, newPlan: SubscriptionDisplayPlan) => {
      if (newPlan === SubscriptionDisplayPlan.Free) {
        // This should never happen, but typescript is typescript.
        return
      }
      const isDowngrade = !isTrial && newPlan < oldPlan
      if (isDowngrade) {
        ampli.billingPlanDowngradeConsidered({
          newBillingPlanName: SUBSCRIPTION_ANALYTICS_PLAN_NAMES[newPlan],
        })
      } else {
        ampli.billingPlanUpgradeConsidered({
          context: AnalyticsContext.AccountSettings,
          newBillingPlanName: SUBSCRIPTION_ANALYTICS_PLAN_NAMES[newPlan],
        })
      }
    },
    [isTrial]
  )
  const onContactSupport = useCallback(() => {
    ampli.contactSupportClicked({
      source: 'plan-management-page-subheader',
    })
    return false
  }, [])

  const trackCancelIntent = () => {
    const proPlusPlan = SUBSCRIPTION_DISPLAY_PLANS[SubscriptionDisplayPlan.Plus].name
    ampli.subscriptionCancellationConsidered({
      billingPlanName: plan === proPlusPlan ? 'Fingerprint Pro Plus' : 'Fingerprint Pro',
    })
  }

  const getNextPaymentInfo = () => {
    if (isCancelingOrCanceled && subscription?.currentPeriodEndsAt) {
      return {
        key: 'Last payment on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'shortWithYear'),
        action: 'View Invoices',
        actionTo: AppRoute.Billing,
      }
    } else if (isSubscriptionPlan && subscription?.currentPeriodEndsAt) {
      return {
        key: 'Next payment on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'shortWithYear'),
      }
    } else if (isEnterprise && subscription?.currentPeriodEndsAt) {
      return {
        key: 'Renews on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'shortWithYear'),
        action: 'Extend',
        onAction: () => handleContactSupport(ContactSupportTopic.ExtendContract),
      }
    } else if (isTrial && subscription?.currentPeriodEndsAt) {
      return {
        key: subscription?.status === SubscriptionStatus.Trialing ? 'Trial ends on' : 'Trial ended on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'shortWithYear'),
        action: 'Upgrade now',
        actionTo: buildRoute(
          AppRoute.ChangePlan,
          { subscriptionId },
          {
            action: userContext?.isEligibleForAndroidFreemium
              ? ChangePlanAction.UpgradeToPlus99AndroidFreemium
              : ChangePlanAction.UpgradeToPlus99,
          }
        ),
      }
    } else if (isPOC && subscription?.currentPeriodEndsAt) {
      return {
        key: hasFinishedPOC ? 'Trial ended on' : 'Trial ends on',
        value: formatDate(subscription?.currentPeriodEndsAt, 'shortWithYear'),
      }
    }

    // Default hidden type
    return { key: 'ignorable', hidden: true }
  }

  const planBadge = subscription ? <PlanBadge subscription={subscription} /> : undefined

  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(routeState?.didContactSupport ?? false)

  const handleContactSupport = useCallback(
    (topic: ContactSupportTopic) => {
      contactSupport(
        {
          data: {
            email,
            message: `The user with email ${email} requested support through the plan page for ${subscriptionId}.`,
            formDetails: topic,
          },
        },
        {
          onSuccess: () => {
            setIsSupportDialogOpen(true)
          },
        }
      )
    },
    [contactSupport, setIsSupportDialogOpen, email, subscriptionId]
  )

  const isProPlus400Tier =
    subscription?.status === SubscriptionStatus.Active &&
    subscription?.type === SubscriptionType.Paid &&
    currentPlan === SubscriptionDisplayPlan.Plus

  const isProPlusAndroidFreemium400Tier =
    subscription?.status === SubscriptionStatus.Active &&
    subscription?.type === SubscriptionType.Paid &&
    currentPlan === SubscriptionDisplayPlan.PlusAndroidFreemium

  const plans: Plan[] = useMemo(
    () => [
      {
        plan: SubscriptionDisplayPlan.Pro,
        promotion: promoTiers?.[SubscriptionDisplayPlan.Pro],
        valueLabel: `Basic device identification.`,
        // Pro trials can still be upgraded.
        action: 'Upgrade to Pro',
        actionHref: buildRoute(
          AppRoute.ChangePlan,
          { subscriptionId },
          {
            action:
              !isTrial && currentPlan > SubscriptionDisplayPlan.Pro
                ? ChangePlanAction.DowngradeToPro
                : ChangePlanAction.UpgradeToPro,
          }
        ),

        // November Pricing Experiment (Pro plan is disabled in November 2023)
        // https://www.notion.so/fingerprintjs/November-Pricing-Experiment-acc992ef0092444db2fa07b5f12c3c7b
        isHidden: currentPlan !== SubscriptionDisplayPlan.Pro && !IS_TEST_MODE && !isImpersonating,
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Pro),
        priceSubtitleContent: '100,000 API calls incl. then $2 per 1,000',
      },

      // Q1 2024 Pricing Experiment
      // https://www.notion.so/fingerprintjs/Pro-Plus-Q1-2024-Pricing-Update-e4904343c1eb44d198cf93c41d919e71
      {
        plan: SubscriptionDisplayPlan.Plus99,
        valueLabel: `Everything you need to get started.`,
        action: isProPlus400Tier ? 'Switch to $99/MO' : 'Upgrade to Pro Plus',
        actionHref: buildRoute(AppRoute.ChangePlan, { subscriptionId }, { action: ChangePlanAction.UpgradeToPlus99 }),
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Plus99),
        isHighlighted: currentPlan !== SubscriptionDisplayPlan.Plus99,
        topBadge: generateTopBadge(SubscriptionDisplayPlan.Plus99, currentPlan),
        priceSubtitleContent: '20,000 API calls incl. then $4 per 1,000',
        isHidden:
          userContext?.isEligibleForAndroidFreemium ||
          currentPlan === SubscriptionDisplayPlan.Plus99AndroidFreemium ||
          currentPlan === SubscriptionDisplayPlan.PlusAndroidFreemium,
      },
      // Q1 2025 Android Freemium
      // https://www.notion.so/fingerprintjs/Android-Freemium-Offer-10602f125ebd80fda6a1f83b4e1e0720
      {
        plan: SubscriptionDisplayPlan.Plus99AndroidFreemium,
        valueLabel: `Everything you need to get started.`,
        action: isProPlusAndroidFreemium400Tier ? 'Switch to $99/MO' : 'Upgrade to Pro Plus',
        actionHref: buildRoute(
          AppRoute.ChangePlan,
          { subscriptionId },
          { action: ChangePlanAction.UpgradeToPlus99AndroidFreemium }
        ),
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Plus99AndroidFreemium),
        isHighlighted: currentPlan !== SubscriptionDisplayPlan.Plus99AndroidFreemium,
        topBadge: generateTopBadge(SubscriptionDisplayPlan.Plus99AndroidFreemium, currentPlan),
        priceSubtitleContent: '20,000 API calls incl. then $4 per 1,000',
        isHidden:
          !userContext?.isEligibleForAndroidFreemium &&
          !isProPlusAndroidFreemium400Tier &&
          currentPlan !== SubscriptionDisplayPlan.Plus99AndroidFreemium,
      },
      {
        plan: SubscriptionDisplayPlan.Plus,
        promotion: promoTiers?.[SubscriptionDisplayPlan.Plus],
        valueLabel: `Everything you need to get started.`,
        action: 'Upgrade to Pro Plus',
        actionHref: buildRoute(AppRoute.ChangePlan, { subscriptionId }, { action: ChangePlanAction.UpgradeToPlus }),
        // Q1 2024 Pricing Experiment ($400 Pro Plus is hidden unless it's an active plan)
        // https://www.notion.so/fingerprintjs/Pro-Plus-Q1-2024-Pricing-Update-e4904343c1eb44d198cf93c41d919e71
        isHidden: true,
        locked: willDowngrade || isCancelingOrCanceled,
        onAction: () => trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Plus),
        priceSubtitleContent: '100,000 API calls incl. then $4 per 1,000',
      },
      {
        plan: SubscriptionDisplayPlan.Enterprise,
        promotion: promoTiers?.[SubscriptionDisplayPlan.Enterprise],
        valueLabel: `Build your own plan.`,
        action: 'Request pricing',
        actionHref: AppRoute.ContactSalesEnterprise,
        locked: willDowngrade || isCancelingOrCanceled,
        variant: 'secondary',
        onAction: () => {
          trackPlanChangeIntent(currentPlan, SubscriptionDisplayPlan.Enterprise)
          ampli.appCtaClicked({ 'App Page Path': AppRoute.SubscriptionPlan, ctaName: 'Contact us' })
        },
      },
    ],
    [
      subscriptionId,
      currentPlan,
      isTrial,
      promoTiers,
      isCancelingOrCanceled,
      trackPlanChangeIntent,
      willDowngrade,
      isImpersonating,
      isProPlus400Tier,
      isProPlusAndroidFreemium400Tier,
      userContext?.isEligibleForAndroidFreemium,
    ]
  )

  const androidOveragePrice = useMemo(() => {
    if (
      (currentPlan !== SubscriptionDisplayPlan.Plus99AndroidFreemium &&
        currentPlan !== SubscriptionDisplayPlan.PlusAndroidFreemium) ||
      !subscription
    )
      return null

    const overagePrice = getOveragePrice(subscription)
    if (!overagePrice) return null

    return '$' + overagePrice * 10
  }, [currentPlan, subscription, getOveragePrice])

  const androidAlertInfo = useMemo(() => {
    if (
      currentPlan !== SubscriptionDisplayPlan.Plus99AndroidFreemium &&
      currentPlan !== SubscriptionDisplayPlan.PlusAndroidFreemium
    )
      return null

    const androidEntry = entries.find((entry) => entry.platform === 'Android')
    if (!androidEntry) return null

    const usagePercentage = (androidEntry.currentUsage / androidEntry.includedWithPlan) * 100

    if (usagePercentage >= 100) {
      return {
        message: `You've hit your free Android usage limit — a charge of ${androidOveragePrice} per 1,000 additional Android API calls will apply to your bill.`,
      }
    } else if (usagePercentage >= 80) {
      return {
        message: `You're at 80% of free Android usage — after free usage, a charge of ${androidOveragePrice} per 1,000 additional Android API calls will apply to your bill.`,
      }
    }

    return null
  }, [currentPlan, entries, androidOveragePrice])

  if (isSupportDialogOpen) {
    return (
      <>
        <Header title='Plan & usage' />
        <MainGrid>
          <Grid item xs={12}>
            <StatusDialog
              icon={<CheckCircleOutline color='primary' />}
              title='We received your request!'
              subtitle='Support will reach out to you soon.'
              primaryAction={{
                name: 'Done',
                onAction: () => setIsSupportDialogOpen(false),
              }}
            />
          </Grid>
        </MainGrid>
      </>
    )
  }

  return (
    <>
      <Header title='Plan & usage' />
      <MainColumn>
        <SubHeader
          title={<Typography variant='h2'>Plan and usage</Typography>}
          description={
            isLoadingSubscription ? (
              <Skeleton width={320} height={20} className='transform-none' />
            ) : subscription?.currentPeriodStartedAt && subscription?.currentPeriodEndsAt ? (
              <Typography variant='bodyM'>
                {isTrial ? 'Trial period:' : 'Current billing period:'}{' '}
                <span className='font-medium'>{formatDate(subscription.currentPeriodStartedAt, 'shortWithYear')}</span>{' '}
                to <span className='font-medium'>{formatDate(subscription.currentPeriodEndsAt, 'shortWithYear')}</span>.
                {isEnterprise && (
                  <>
                    {' '}
                    For billing questions,{' '}
                    <Link
                      href={SUPPORT_PAGE_URL}
                      color='primary'
                      underline='hover'
                      target='_blank'
                      style={{ textDecoration: 'none' }}
                      onClick={onContactSupport}
                    >
                      contact us
                    </Link>
                    .
                  </>
                )}
              </Typography>
            ) : null
          }
          actions={
            <LinkButton variant='secondary' href={buildRoute(AppRoute.Billing)}>
              View payments and invoices
            </LinkButton>
          }
        />
        <ContentColumn>
          <ConditionalOverduePaymentCallout subscriptionId={subscription?.id} />
          {isCancelingOrCanceled ? (
            subscription.status === SubscriptionStatus.Canceled ? (
              <Alert variant='error'>
                Your subscription has been canceled. If you want to resume it,{' '}
                <Link
                  component='button'
                  onClick={() => handleContactSupport(ContactSupportTopic.ResumeSubscription)}
                  underline='none'
                  target='_blank'
                >
                  contact support
                </Link>
                .
              </Alert>
            ) : (
              <Alert variant='error'>
                Your subscription will be canceled on {formatDate(date(subscription.cancelAt))}. If you want to resume
                it,{' '}
                <Link
                  component='button'
                  onClick={() => handleContactSupport(ContactSupportTopic.ResumeSubscription)}
                  underline='none'
                  target='_blank'
                >
                  contact support
                </Link>
                .
              </Alert>
            )
          ) : null}
          <Paper className='px-6 py-5'>
            <PlanSummary
              entries={[
                {
                  key: 'Status',
                  value: <SubscriptionStatusDot status={subscription?.status} />,
                },
                {
                  key: 'Plan',
                  value: plan,
                  badge: planBadge,
                },
                getNextPaymentInfo(),
                {
                  key: 'Current Usage',
                  value: formatNum(currentUsage),
                },
              ]}
              isLoading={isLoading}
            />
          </Paper>
        </ContentColumn>

        <ContentColumn>
          <SubHeader
            title='Usage'
            description={`API calls made during the current period${!isEnterprise ? '. Usage resets monthly' : ''}.`}
          />
          {!isLoadingUsageStatus && !hasMadeRequests && subscription ? (
            <Alert variant='warning'>
              We couldn&apos;t help but notice your workspace has no usage. Get up to speed in minutes using our
              powerful integrations.{' '}
              <Link
                component={RouterLink}
                to={buildRoute(AppRoute.Integrations, { subscriptionId: subscription.id })}
                color='inherit'
              >
                Install Fingerprint now
              </Link>
            </Alert>
          ) : null}
          {androidAlertInfo && (
            <Alert
              variant='warning'
              actionProps={{
                children: 'Request Enterprise pricing',
                href: buildRoute(AppRoute.ContactSalesEnterprise),
              }}
            >
              {androidAlertInfo.message}
            </Alert>
          )}
          <Paper className='px-6 py-5 flex flex-col gap-4'>
            <>
              {!isLoading && isEnterprise && (
                <DashboardBanner
                  icon={<DataUsage color='primary' />}
                  title='Are you expecting increase in usage?'
                  action='Contact Premium Support'
                  onClickAction={() => handleContactSupport(ContactSupportTopic.IncreaseUsage)}
                  variant='warning'
                  wideButton
                >
                  Reach out to discuss your plan options.
                </DashboardBanner>
              )}

              <PlanUsage
                title='Identification API'
                description='The identification API collects signals to return a stable, unique identifier.'
                entries={entries}
                totalUsage={currentUsage}
                projected={projectedUsage}
                subscriptionType={subscription?.type}
                isLoading={isLoading}
                docsUrl={DOCS_BILLING}
                appliedDiscount={appliedDiscount}
              />

              {!!subscription?.relatedVisitorsQuota && relatedVisitorsUsage && (
                <PlanUsage
                  title={relatedVisitorsLimitLabel}
                  description='The Return Visitors API associates multiple browsers and WebViews to a single device.'
                  entries={[
                    {
                      platform: 'Related Visitors',
                      includedWithPlan: relatedVisitorsLimit ?? usageLimit ?? 0,
                      currentUsage: relatedVisitorsUsage ?? 0,
                    },
                  ]}
                  totalUsage={relatedVisitorsUsage ?? 0}
                  isRelatedVisitors={true}
                  subscriptionType={subscription.type}
                  isLoading={isLoading}
                />
              )}
            </>
          </Paper>
        </ContentColumn>

        {!isLoading && subscription?.type !== SubscriptionType.Prepaid ? (
          <ContentColumn>
            <SubHeader title='Plans' description='Manage and view Fingerprint plans.' />
            {appliedDiscount ? (
              <>
                <PlanDiscount />
                <Divider />
              </>
            ) : (
              <PlanSelector currentPlan={currentPlan} isTrial={isTrial} plans={plans} isLoading={isLoading} />
            )}
          </ContentColumn>
        ) : null}

        {canCancelSubscription ? (
          <ContentColumn>
            <SubHeader
              title={
                <Typography variant='bodyLMedium' component='h3' className='text-red-800'>
                  Danger zone
                </Typography>
              }
            />
            <Stack gap={2} direction='column' className='items-start'>
              <LinkButton
                variant='destructive'
                data-testid='cancelSubButton'
                onPress={() => trackCancelIntent()}
                href={buildRoute(AppRoute.SubscriptionCancellationSurvey, { subscriptionId })}
                isDisabled={!userCanCancelSubscription}
              >
                Cancel subscription
              </LinkButton>
              {!userCanCancelSubscription && (
                <Typography variant='bodyM' className='text-red-800'>
                  Only account owners may cancel a workspace subscription
                </Typography>
              )}
            </Stack>
          </ContentColumn>
        ) : null}
      </MainColumn>
    </>
  )
}
