import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export function TooltipContainer({ position, children }: PropsWithChildren<{ position?: number }>) {
  return (
    <div
      className='overflow-hidden z-10 absolute top-2 border border-gray-300 rounded-md bg-white shadow-popover text-sm pointer-events-none'
      style={{
        left: position && position <= 0.5 ? `${position * 100}%` : undefined,
        marginLeft: position && position <= 0.5 ? 16 : undefined,
        right: position && position > 0.5 ? `${100 - position * 100}%` : undefined,
        marginRight: position && position > 0.5 ? 16 : undefined,
      }}
    >
      {children}
    </div>
  )
}

export function TooltipHeader({ children }: PropsWithChildren) {
  return (
    <header className='flex items-center h-9 px-4 bg-gray-100 border-b border-gray-300 text-gray-900 font-medium'>
      {children}
    </header>
  )
}

export function TooltipContent({ children, className }: PropsWithChildren<{ className?: string }>) {
  return <div className={clsx('px-4 py-3', className)}>{children}</div>
}
