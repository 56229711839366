import { Button } from '@compass/components'
import { InputLabel, OutlinedInput } from '@mui/material'
import { useStartDeployWorkerMutation } from 'hooks/api/cloudflare'
import { AnalyticsContext } from 'models'
import { ampli } from 'models/ampli'
import { useState } from 'react'

import { GenericError } from '../../../../const'
import WizardStep, { WizardStepActions, WizardStepContent } from '../../../Wizard/Step/WizardStep'
import styles from './DeployWorker.module.scss'

export interface DeployWorkerProps {
  domain: string
  subscriptionId: string
  accountId: string
  apiToken: string
  zoneId: string
  workerName: string
  onSubmitId: (id: string) => void
  analyticsContext: AnalyticsContext
}

export default function DeployWorker({
  domain,
  subscriptionId,
  accountId,
  apiToken,
  zoneId,
  workerName,
  onSubmitId,
  analyticsContext,
}: DeployWorkerProps) {
  const { mutate: startWorkerDeployment, error: deploymentError, isPending } = useStartDeployWorkerMutation()
  const [error, setError] = useState<GenericError>()

  async function startDeployment() {
    ampli.cloudflareIntegrationConfirmClicked({ context: analyticsContext })

    startWorkerDeployment(
      {
        data: {
          domain,
          subscriptionId,
          accountId,
          apiToken,
          zoneId,
          workerName,
        },
      },
      {
        onSettled: (deployRequest, err) => {
          if (!err && deployRequest?.id) {
            onSubmitId(deployRequest?.id)
          } else {
            setError({ code: 'missing_id' })
          }
        },
      }
    )
  }

  return (
    <WizardStep title='Confirm' subtitle='Review and confirm your Cloudflare details.' error={error ?? deploymentError}>
      <WizardStepContent>
        <div className={styles.form}>
          <div>
            <InputLabel htmlFor='domainName'>Cloudflare domain</InputLabel>
            <OutlinedInput id='domainName' fullWidth value={domain} readOnly />
          </div>

          <div>
            <InputLabel htmlFor='workerName'>Worker name</InputLabel>
            <OutlinedInput id='workerName' fullWidth value={workerName} readOnly />
          </div>
        </div>
      </WizardStepContent>
      <WizardStepActions>
        <Button key='deploy' onPress={startDeployment} className={styles.button} isDisabled={isPending}>
          Confirm
        </Button>
      </WizardStepActions>
    </WizardStep>
  )
}
