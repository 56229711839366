import type { AriaToastRegionProps } from '@react-aria/toast'
import { useToastRegion } from '@react-aria/toast'
import type { ToastState } from '@react-stately/toast'
import { useRef } from 'react'
import { ToastParams } from 'toast'

import { Toast } from './Toast'

interface ToastRegionProps<T> extends AriaToastRegionProps {
  state: ToastState<T>
}

export function ToastRegion<T extends ToastParams>({ state, ...props }: ToastRegionProps<T>) {
  const ref = useRef(null)
  const { regionProps } = useToastRegion(props, state, ref)

  return (
    <div {...regionProps} ref={ref} className='fixed bottom-4 right-4 flex flex-col gap-2 z-[1250]'>
      {state.visibleToasts.map((toast) => (
        <Toast key={toast.key} toast={toast} state={state} />
      ))}
    </div>
  )
}
