import { Lock } from '@mui/icons-material'
import { useQueryClient } from '@tanstack/react-query'
import { StaticMessage } from 'components'
import { ExternalLayout } from 'features/commonUI'
import { useAuth } from 'hooks'
import { useRequestMutation } from 'hooks/api/base'
import { useUpdateUserContext } from 'hooks/api/context'
import { useIsFirstRender } from 'hooks/firstRender'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

export function ImpersonatePage() {
  const queryClient = useQueryClient()
  const { mutate: sendImpersonateRequest, isError, error } = useRequestMutation('impersonate')

  const history = useHistory()
  const urlHash = history.location.hash
  const isFirstRender = useIsFirstRender()
  const { setCredentials } = useAuth()

  const oneTimeToken = useMemo(() => {
    if (urlHash && urlHash.indexOf('impersonateOtt') === 1) {
      const [, value] = urlHash.split('=')
      return value
    }

    return undefined
  }, [urlHash])

  const updateUserContext = useUpdateUserContext()
  useEffect(() => {
    if (oneTimeToken && isFirstRender) {
      sendImpersonateRequest(
        { data: { oneTimeToken } },
        {
          onSuccess: ({ accessToken, context }) => {
            setCredentials({ accessToken })
            updateUserContext(context)
            queryClient.invalidateQueries({
              queryKey: ['subscriptions'],
            })
            history.replace('/')
          },
        }
      )
    }
  }, [oneTimeToken, sendImpersonateRequest, isFirstRender, history, setCredentials, updateUserContext, queryClient])

  if (!oneTimeToken) {
    return (
      <ExternalLayout>
        <StaticMessage Icon={Lock} title='Impersonate' message={'Invalid URL.'} />
      </ExternalLayout>
    )
  }

  if (isError) {
    return (
      <ExternalLayout>
        <StaticMessage
          Icon={Lock}
          title='Impersonate'
          message={error?.message ?? 'There was a problem with the impersonation.'}
        />
      </ExternalLayout>
    )
  }

  return (
    <ExternalLayout>
      <StaticMessage Icon={Lock} title='Impersonate' message='Exchanging impersonation token.' />
    </ExternalLayout>
  )
}
