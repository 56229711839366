import { Typography } from '@mui/material'
import { ReactNode } from 'react'

export function ChartTitle({ title, info, action }: { title?: ReactNode; info?: ReactNode; action?: ReactNode }) {
  return (
    <div className='flex flex-col gap-x-2 gap-y-1'>
      <div className='flex gap-x-2 gap-y-4 items-center flex-wrap'>
        {title}
        {action}
      </div>
      <Typography variant='bodyM'>{info}</Typography>
    </div>
  )
}
