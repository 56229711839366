import { LinkButton } from '@compass/LinkButton'
import { Typography } from '@mui/material'

import ChartIcon from '../../UsageChart/Icons/ChartIcon.svg'
import styles from './IntegrationNoUsage.module.scss'

export type IntegrationNoUsageNoticeProps = {
  docsLink?: string
}

export default function IntegrationNoUsageNotice({ docsLink }: IntegrationNoUsageNoticeProps) {
  return (
    <div className={styles.noUsageDataContainer}>
      <div className={styles.noDataIconContainer}>
        <ChartIcon />
      </div>
      <div>
        <Typography variant='semiBody1' component='h4' className={styles.noUsageDataTitle} align='center'>
          No Usage Data Yet
        </Typography>
        <Typography variant='bodyM' className={styles.noUsageDataContainerText} align='center'>
          We need more data to generate this chart. Make some API requests!
        </Typography>
      </div>
      {docsLink && (
        <LinkButton href={docsLink} target='_blank' className={styles.noUsageButton}>
          View Documentation
        </LinkButton>
      )}
    </div>
  )
}
