import { Stack } from '@compass/components'
import { Link, Typography } from '@mui/material'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { AppRoute, buildRoute } from 'appRoutes'
import { DOCS_SERVER_API, GenericError } from 'const'
import { ContentColumn, Header, MainColumn, SubHeader } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { PaginatedVisitsResponse, Visit } from 'models'
import { useCallback, useState } from 'react'

import { useCurrentUser } from '../../../../hooks/api/users'
import { ActionsMenu } from '../ActionsMenu'
import { VisitHistoryFilter } from '../VisitHistoryFilter/VisitHistoryFilter'
import { VisitsTable } from '../VisitsTable/VisitsTable'
import styles from './VisitHistory.module.scss'

export type VisitHistoryProps = {
  query: UseInfiniteQueryResult<InfiniteData<PaginatedVisitsResponse>, GenericError>
}

export function VisitHistory({ query }: VisitHistoryProps) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data: currentUser } = useCurrentUser()
  const getVisitPath = useCallback(
    (visit: Visit): string =>
      buildRoute(AppRoute.IdentificationEventDetails, {
        subscriptionId: currentSubscriptionId,
        requestId: visit.requestId,
      }),
    [currentSubscriptionId]
  )

  const isSearchResults = (query.data?.pages?.[0]?.visits?.length ?? 0) > 0
  const [isFiltering, setIsFiltering] = useState(false)

  return (
    <>
      <Header title='Identification' />
      <MainColumn>
        <ContentColumn>
          <SubHeader
            keepRowLayout
            title={<Typography variant='h2'>Identification events</Typography>}
            description={
              <>
                <Typography variant='bodyM'>
                  Select a row for details. Events data is also available by&nbsp;
                  <Link href={DOCS_SERVER_API} target='_blank' underline='hover'>
                    server API
                  </Link>
                  .
                </Typography>
              </>
            }
            actions={<ActionsMenu isExportDisabled={query.isFetching || !isSearchResults} />}
          />
          <Stack gap={3} direction={{ default: 'column', sm: 'row' }}>
            <VisitHistoryFilter
              onFilterChange={(filtering) => setIsFiltering(filtering)}
              timezone={currentUser?.timezone}
              subscriptionId={currentSubscriptionId}
            />
          </Stack>
          <VisitsTable
            withContainer
            timezone={currentUser?.timezone}
            query={query}
            getVisitPath={getVisitPath}
            className={styles.tableContainer}
            isFiltering={isFiltering}
          />
        </ContentColumn>
      </MainColumn>
    </>
  )
}
