import { DateTime, Interval } from 'luxon'
import { ChartAggregation } from 'models'

export function calculateTimelineTicks(startDate?: DateTime, endDate?: DateTime, granularity?: ChartAggregation) {
  return startDate == null || endDate == null || granularity == null
    ? []
    : Interval.fromDateTimes(startDate.startOf(granularity), endDate)
        .splitBy({
          hour: granularity === 'hour' ? 1 : undefined,
          day: granularity === 'day' ? 1 : undefined,
          month: granularity === 'month' ? 1 : undefined,
        })
        .map((segment) => segment.start!)
}
