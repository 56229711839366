import { Badge, SelectItem, SelectListBox, SelectPopover, SelectTrigger, SelectValue } from '@compass/components'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Grid, Link, Tab, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import {
  DOCS_REQUEST_FILTERING_URL,
  SHOW_MOBILE_REQUEST_FILTERING,
  USE_DISABLE_BOTS_FEATURE,
  USE_WORKSPACE_ENVIRONMENT,
} from 'const'
import { Header, MainColumn, SubHeader } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { pluralize } from 'helpers/pluralize'
import { useDocumentTitle, useIsApplicationFeatureEnabled, useTrafficRules } from 'hooks'
import { useEntityLimit } from 'hooks/api/context'
import countBy from 'lodash/countBy'
import { ApplicationFeatureName, Limits, TrafficRuleType, WorkspaceEnvironment } from 'models'
import { ampli } from 'models/ampli'
import { useCallback, useEffect, useState } from 'react'
import { Select } from 'react-aria-components'

import { useWorkspaceEnvironments } from '../../hooks/api/environment'
import BotsSection from './components/BotsSection/BotsSection'
import MobileSection from './components/MobileSection/MobileSection'
import { NetworkSection } from './components/NetworkSection/NetworkSection'
import WebSection from './components/WebSection/WebSection'
import styles from './RequestFilteringPage.module.scss'

export function RequestFilteringPage() {
  useDocumentTitle('Security')

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const isFilterSearchBotsEnabled = useIsApplicationFeatureEnabled(
    subscriptionId,
    ApplicationFeatureName.FilterSearchBots
  )
  const isFilterAiBotsEnabled = useIsApplicationFeatureEnabled(subscriptionId, ApplicationFeatureName.FilterAiBots)

  const { data: trafficRules = [], isLoading } = useTrafficRules(subscriptionId)

  const allowedTrafficRulesAmount = useEntityLimit(subscriptionId, Limits.TrafficRules)
  const isCreationLimitExceeded = trafficRules.length >= allowedTrafficRulesAmount

  const [activeTab, setActiveTab] = useState<'web' | 'network' | 'mobile' | 'bots'>('web')
  const { data: workspaceEnvironments, isLoading: isLoadingEnvironments } = useWorkspaceEnvironments(subscriptionId)

  const [currentEnvironment, setCurrentEnvironment] = useState<WorkspaceEnvironment | undefined>()

  const typeCount = countBy(
    trafficRules?.filter((rule) => !(rule.value === '*' || rule.workspaceEnvironmentId !== currentEnvironment?.id)),
    (rule) => rule.type
  )

  const webRulesCount = (typeCount[TrafficRuleType.HTTPHeader] ?? 0) + (typeCount[TrafficRuleType.Origin] ?? 0)
  const networkRulesCount = typeCount[TrafficRuleType.Network] ?? 0
  const mobileRulesCount = typeCount[TrafficRuleType.AppPackageName] ?? 0

  const tabs = [
    { label: 'Web', value: 'web', activeRuleCount: webRulesCount },
    { label: 'Network', value: 'network', activeRuleCount: networkRulesCount },
    {
      label: 'Mobile apps',
      value: 'mobile',
      activeRuleCount: mobileRulesCount,
      hidden: !SHOW_MOBILE_REQUEST_FILTERING,
    },
    {
      label: 'Bots',
      value: 'bots',
      activeRuleCount: Number(isFilterSearchBotsEnabled) + Number(isFilterAiBotsEnabled),
      hidden: !USE_DISABLE_BOTS_FEATURE,
    },
  ]

  useEffect(() => {
    if (workspaceEnvironments && workspaceEnvironments.length > 0) {
      setCurrentEnvironment(workspaceEnvironments[0])
    }
  }, [workspaceEnvironments])

  const handleEnvironmentChange = useCallback(
    (key: string) => {
      const chosenEnvironment = workspaceEnvironments?.find((workspaceEnvironment) => workspaceEnvironment.id === key)

      if (chosenEnvironment) {
        setCurrentEnvironment(chosenEnvironment)
      }
    },
    [workspaceEnvironments]
  )

  const onEnvironmentClick = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        return
      }

      ampli.environmentSelected({
        'App Page Path': buildRoute(AppRoute.RequestFiltering, { subscriptionId }),
        'App Page Title': document.title,
      })
    },
    [subscriptionId]
  )

  return (
    <>
      <Header title='Security' />
      <MainColumn>
        <SubHeader
          title={<Typography variant='h2'>Request filtering</Typography>}
          description={
            <>
              <Typography variant='bodyM'>
                You can filter out unwanted visitor identifications using the request filtering rules.&nbsp;
                <Link href={DOCS_REQUEST_FILTERING_URL} target='_blank' underline='hover'>
                  Learn more
                </Link>
              </Typography>
            </>
          }
          actions={
            USE_WORKSPACE_ENVIRONMENT &&
            workspaceEnvironments &&
            currentEnvironment && (
              <div>
                <Select<WorkspaceEnvironment>
                  aria-label='Workspace selector'
                  placeholder='Select a workspace'
                  selectedKey={currentEnvironment.id}
                  onSelectionChange={(key: string) => handleEnvironmentChange(key)}
                  onOpenChange={onEnvironmentClick}
                >
                  <SelectTrigger fullWidth tone='secondary'>
                    <SelectValue<WorkspaceEnvironment>>
                      {({ selectedText, isPlaceholder }) => {
                        return isPlaceholder ? (
                          <>Loading environments&hellip;</>
                        ) : (
                          <span className='flex gap-2'>
                            <span className='truncate'>{selectedText}</span>{' '}
                          </span>
                        )
                      }}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectPopover>
                    <SelectListBox>
                      {workspaceEnvironments?.map((environment) => (
                        <SelectItem key={environment.id} id={environment.id} value={environment}>
                          {environment.name}
                        </SelectItem>
                      ))}
                    </SelectListBox>
                  </SelectPopover>
                </Select>
              </div>
            )
          }
        />
        <TabContext value={activeTab}>
          <Grid item xs={12} padding={0}>
            <TabList
              onChange={(_e, tab) => setActiveTab(tab)}
              scrollButtons='auto'
              variant='scrollable'
              className={styles.tabList}
            >
              {tabs.map((tab) => {
                if (tab.hidden) {
                  return null
                }

                return (
                  <Tab
                    key={tab.value}
                    label={
                      <>
                        <span className='flex items-center gap-2'>
                          {tab.label}
                          {Number(tab.activeRuleCount) > 0 && (
                            <Badge tone='secondary' variant='solid'>
                              {pluralize(Number(tab.activeRuleCount), 'rule')}
                            </Badge>
                          )}
                        </span>
                      </>
                    }
                    value={tab.value}
                    classes={{ root: styles.tabLabel, selected: styles.selectedTab }}
                    disableRipple
                  />
                )
              })}
            </TabList>
          </Grid>
          {!isLoadingEnvironments && currentEnvironment && (
            <Grid item xs={12}>
              <TabPanel value='web' className={styles.tabContent}>
                <WebSection
                  isLoading={isLoading}
                  trafficRules={trafficRules}
                  isCreationLimitExceeded={isCreationLimitExceeded}
                  currentEnvironment={currentEnvironment}
                />
              </TabPanel>
              <TabPanel value='network' className={styles.tabContent}>
                <NetworkSection
                  isLoading={isLoading}
                  trafficRules={trafficRules}
                  isCreationLimitExceeded={isCreationLimitExceeded}
                  currentEnvironment={currentEnvironment}
                />
              </TabPanel>
              <TabPanel value='mobile' className={styles.tabContent}>
                <MobileSection
                  isLoading={isLoading}
                  trafficRules={trafficRules}
                  isCreationLimitExceeded={isCreationLimitExceeded}
                  currentEnvironment={currentEnvironment}
                />
              </TabPanel>
              <TabPanel value='bots' className={styles.tabContent}>
                <BotsSection />
              </TabPanel>
            </Grid>
          )}
        </TabContext>
      </MainColumn>
    </>
  )
}
