import { DnsOutlined as Logo } from '@mui/icons-material'
import { ReactNode, useMemo } from 'react'

import { DOCS_INTEGRATIONS } from '../../../../const'
import { differenceInDays } from '../../../../helpers/date'
import { ProxyIntegrationRequests, SdkHealthSdkDetails } from '../../../../models'
import { SemanticVersion } from '../../../health/utils/SemanticVersion'
import { ThirdPartyIntegration } from '../../components/IntegrationMetadata/integrationMetadata'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import IntegrationPageSection from '../../components/IntegrationPage/IntegrationPageSection/IntegrationPageSection'
import MismatchedDomainsSection, {
  MismatchedDomainsSectionProps,
} from '../../components/IntegrationPage/mismatchedDomainsSection/MismatchedDomainsSection'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import { ProxyIntegrationUsageChart } from '../../components/UsageChart/ProxyIntegrationUsageChart'

export type IntegrationCustomContentsProps = Omit<MismatchedDomainsSectionProps, 'integration'> & {
  subscriptionId: string
  requestsMade?: ProxyIntegrationRequests
  isLoading?: boolean
  health?: SdkHealthSdkDetails
}

function getLastRequestDescription(requestsMade?: ProxyIntegrationRequests): string | undefined {
  const lastDayWithRequests = requestsMade?.proxyRequests
    .filter((it) => it.value !== 0)
    .reduce((prev, curr) => (prev.timestamp > curr.timestamp ? prev : curr))
  if (!lastDayWithRequests) {
    return undefined
  }

  const days = differenceInDays(new Date(lastDayWithRequests.timestamp), new Date())
  if (days === 0) {
    return 'today'
  } else if (days === 1) {
    return 'In the last 24 hours'
  } else {
    return `${days} days ago`
  }
}

export function IntegrationCustomContents({
  subscriptionId,
  isLoading,
  requestsMade,
  health,
  incorrectETLDPlusOneRequests,
  hasMismatchedDomainsError,
  useInvalidEtldPlusOneWithOriginQuery,
}: IntegrationCustomContentsProps) {
  const chartVisible =
    requestsMade &&
    requestsMade.proxyRequests?.length > 0 &&
    requestsMade.proxyRequests.map((it) => it.value).reduce((sum, curr) => (sum += curr), 0) > 0
  const integrationStatus = chartVisible ? IntegrationStatus.ReceivingRequests : IntegrationStatus.NoActivity
  const lastXDays = Math.min(requestsMade?.allRequests?.length ?? 0, 30)
  const lastCustomerVersionFromUsage = health?.versions.sort((a, b) => {
    return SemanticVersion.compare(SemanticVersion.fromString(b.version), SemanticVersion.fromString(a.version))
  })[0]?.version

  const metadata = chartVisible
    ? [
        {
          title: 'Last request',
          value: getLastRequestDescription(requestsMade),
        },
        {
          title: 'Your version',
          value: lastCustomerVersionFromUsage,
        },
      ]
    : []

  const sections = useMemo(() => {
    const result: ReactNode[] = [
      <MismatchedDomainsSection
        key='mismatched-domains'
        integration={ThirdPartyIntegration.CustomProxy}
        hasMismatchedDomainsError={hasMismatchedDomainsError}
        useInvalidEtldPlusOneWithOriginQuery={useInvalidEtldPlusOneWithOriginQuery}
        incorrectETLDPlusOneRequests={incorrectETLDPlusOneRequests}
      />,
    ]

    if (chartVisible) {
      result.push(
        <IntegrationPageSection key='usage' title='Custom Proxy Integration Usage' info={`Last ${lastXDays} Days`}>
          <ProxyIntegrationUsageChart requests={requestsMade} integrationName='Custom' />
        </IntegrationPageSection>
      )
    }

    return result
  }, [
    chartVisible,
    hasMismatchedDomainsError,
    incorrectETLDPlusOneRequests,
    lastXDays,
    requestsMade,
    useInvalidEtldPlusOneWithOriginQuery,
  ])

  return (
    <IntegrationPage
      isLoading={isLoading}
      integrationStatus={integrationStatus}
      title='Custom Proxy Integration'
      docsUrl={DOCS_INTEGRATIONS.customProxy}
      subscriptionId={subscriptionId}
      Logo={Logo}
      metadata={metadata}
      sections={sections}
    />
  )
}
