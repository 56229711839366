import { Button, Stack } from '@compass/components'
import { useSearchParams } from 'hooks/useSearchParams'
import { useUpdatedDeploymentsAvailable } from 'hooks/useUpdatedDeploymentAvailable'
import { RefreshCcwIcon } from 'lucide-react'
import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toastQueue } from 'toast'

export function UpdatedDeploymentAvailable() {
  const { data } = useUpdatedDeploymentsAvailable()
  const [isLoading, setIsLoading] = useState(false)

  // Managed search params, always hide the deployment id from the user,
  // so the saved and shared URLs don't contain it.
  const [searchParams, setSearchParams] = useSearchParams(['dpl'])
  if (searchParams['dpl'] != null) {
    setSearchParams({ dpl: undefined })
  }

  // Use useLocation to always detect routed path changes, so the params are
  // not sticking to the moment a new deployment available is detected.
  const location = useLocation()
  const newSearchParams = new URLSearchParams(location.search)
  newSearchParams.set('dpl', data?.availableDeploymentId ?? "''")

  const forceUrl = `${window.location.protocol}//${window.location.host}${location.pathname}?${newSearchParams.toString()}#${location.hash}`
  const handledUpdates = useRef<string>()
  const previousKey = useRef<string>()

  if (data?.updateAvailable && handledUpdates.current !== data.availableDeploymentId) {
    if (previousKey.current) {
      toastQueue.close(previousKey.current)
    }

    handledUpdates.current = data.availableDeploymentId
    previousKey.current = toastQueue.add({
      children: (
        <Stack gap={3} direction='column'>
          <div>
            <div className='font-medium mb-1'>Update available</div>
            <div className='text-gray-800'>Click the Update button to load the latest version of the dashboard.</div>
          </div>
          <div className='flex justify-end'>
            <Button
              variant='secondary'
              size='sm'
              isLoading={isLoading}
              onPress={() => {
                setIsLoading(true)
                window.location.href = forceUrl
              }}
            >
              <RefreshCcwIcon />
              Update
            </Button>
          </div>
        </Stack>
      ),
    })
  }
  return null
}
