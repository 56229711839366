import { Button } from '@compass/components'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useToast } from 'hooks'
import { ApiKey } from 'models'
import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useEncryptionKeyDeactivateMutation } from '../../../../hooks/api/tokens'
import CalloutBox from '../CalloutBox/CalloutBox'
import styles from './DeactivateApiKeyDialog.module.scss'

interface DeactivateApiKeyDialogProps extends DialogProps {
  apiKey: ApiKey
  onClose: () => void
}

// connected component
export default function DeactivateApiKeyDialog({ apiKey, ...dialogProps }: DeactivateApiKeyDialogProps) {
  const { mutate: sendEncryptionKeyDeactivateRequest, isPending } = useEncryptionKeyDeactivateMutation()
  const { currentSubscriptionId } = useCurrentSubscription()
  const { showToast } = useToast()

  const onDeactivate = useCallback(() => {
    sendEncryptionKeyDeactivateRequest(
      { params: { keyId: apiKey.id, subscriptionId: currentSubscriptionId } },
      {
        onSuccess: () => {
          dialogProps.onClose()
          showToast({ message: "It's been done. Your API token has been dectivated.", severity: 'success' })
        },
        onError: () => {
          showToast({
            message: 'An error occurred when trying to deactivate your API token. Please try again.',
            severity: 'error',
          })
        },
      }
    )
  }, [apiKey.id, currentSubscriptionId, sendEncryptionKeyDeactivateRequest, dialogProps, showToast])

  return (
    <DeactivateApiKeyDialogView {...dialogProps} onDeactivate={onDeactivate} apiKey={apiKey} isLoading={isPending} />
  )
}

export interface DeactivateApiKeyDialogViewProps extends DeactivateApiKeyDialogProps {
  onDeactivate: () => void
}

// display component for storybook
export function DeactivateApiKeyDialogView({
  onDeactivate,
  apiKey,
  isLoading,
  ...dialogProps
}: DeactivateApiKeyDialogViewProps) {
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<{ active: boolean }>({ defaultValues: { active: false } })

  return (
    <Dialog {...dialogProps} isLoading={isLoading} classes={{ paper: styles.dialog }}>
      <DialogTitle onClose={dialogProps.onClose} className={styles.dialogTitle}>
        <Typography variant='h1' component='div' className={styles.heading}>
          Deactivate encryption key
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onDeactivate)}>
        <DialogContent className={styles.dialogContent}>
          <CalloutBox title='Read this carefully!' type='warning'>
            By dectivating your encryption key, all client-side <strong>payloads</strong> will be{' '}
            <strong>unencrypted</strong>. Please ensure your backend is set up to receive unencrypted data. Also, it can
            take a few minutes for changes to be reflected.
          </CalloutBox>
          <Box>
            <FormControlLabel
              control={
                <Controller
                  name='active'
                  control={control}
                  render={({ field }) => (
                    <Checkbox color='primary' id='active' {...field} classes={{ root: styles.checkbox }} />
                  )}
                />
              }
              label={
                <Typography variant='body2'>
                  By deactivating this key, I confirm that client-side payloads will not be encrypted and will no longer
                  require decryption on my servers.
                </Typography>
              }
              className={styles.control}
            />
          </Box>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button variant='ghost' onPress={dialogProps.onClose}>
            Cancel
          </Button>
          <Button type='submit' isDisabled={!isValid || !isDirty}>
            Deactivate key
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
