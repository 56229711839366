import { WithTooltip } from '@compass/WithTooltip'
import { Skeleton, Typography } from '@mui/material'
import { InfoIcon } from 'lucide-react'
import { PropsWithChildren, ReactNode } from 'react'

import styles from './Widget.module.scss'

export function Widget({ title, children, info }: PropsWithChildren<{ title: string; info?: ReactNode }>) {
  return (
    <div className={`${styles.widget} min-w-36`}>
      <div className={styles.titleRow}>
        {title ? <Typography variant='bodyS'>{title}</Typography> : <Skeleton width={112} height={16} />}
        {info ? (
          <WithTooltip content={info}>
            <InfoIcon className='size-3 text-gray-800 relative' />
          </WithTooltip>
        ) : null}
      </div>
      {children ? <div className={styles.children}>{children}</div> : <Skeleton width={96} height={32} />}
    </div>
  )
}
