import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { SDKIntegration, ThirdPartyIntegration } from '../../features/integrations'
import { CloudflareIntegration, DeploymentStatus } from '../../models'
import { useToast } from '../toast'
import { useCloudflareSetup } from '../userStorage'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const inProgressStates = [
  DeploymentStatus.Queued,
  DeploymentStatus.InProgress,
  DeploymentStatus.RemovalInProgress,
  DeploymentStatus.RemovalQueued,
]

// This function exists to specifically fetch data related to Cloudflare, which is excluded from the more generic useIntegrationStatsQuery.
export function useCloudflareIntegrationData(
  subscriptionId: string,
  withRefreshOnPending = false,
  refetchInterval = 10_000
) {
  const buildRequest = useBuildRequest()

  const [shouldRefetch, setShouldRefetch] = useState(false)

  const queryResult = useQuery({
    queryKey: ['cloudflareIntegration', subscriptionId] as const,
    queryFn: () =>
      extractData(buildRequest('cloudflareIntegrationGet', { params: { subscriptionId } })).then(
        (data) => data ?? null
      ), // undefined is no longer a valid data type
    refetchInterval: shouldRefetch ? refetchInterval : undefined,
    refetchIntervalInBackground: shouldRefetch,
  })

  useEffect(() => {
    if (queryResult.data) {
      const lastDeploymentStatus = queryResult.data?.lastDeploymentStatus

      if (lastDeploymentStatus && inProgressStates.includes(lastDeploymentStatus) && withRefreshOnPending) {
        setShouldRefetch(true)
      } else {
        setShouldRefetch(false)
      }
    }
  }, [queryResult.data, withRefreshOnPending])

  return queryResult
}

export function useRemoveCloudflareIntegration() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  const [, { reset }] = useCloudflareSetup()

  return useRequestMutation('removeCloudflareIntegration', {
    onSuccess: async (_, vars) => {
      reset()

      if (vars.params) {
        await queryClient.invalidateQueries({
          queryKey: ['cloudflareIntegration', vars.params.subscriptionId],
        })

        showToast({
          severity: 'success',
          message: 'Your Cloudflare integration is set to be deleted. It make take a few minutes to take effect.',
        })

        queryClient.setQueryData<CloudflareIntegration>(
          ['cloudflareIntegration', vars.params.subscriptionId],
          (oldData) => {
            if (!oldData) {
              return oldData
            }

            return {
              ...oldData,
              lastRemovalError: '',
              lastDeploymentStatus: DeploymentStatus.RemovalQueued,
            }
          }
        )
      }
    },
  })
}

export function useGenerateWorkerName() {
  return useRequestMutation('generateWorkerName')
}

export function useUpdateCloudflareToken() {
  const queryClient = useQueryClient()
  const { showToast } = useToast()

  return useRequestMutation('updateCloudflareToken', {
    onSuccess: async (_, vars) => {
      if (vars.data) {
        await queryClient.invalidateQueries({
          queryKey: ['cloudflareIntegration', vars.data.subscriptionId],
        })

        showToast({
          severity: 'success',
          message: (
            <>
              Changes pending! Give us a <strong>few minutes</strong> for the API token to update on our side.
            </>
          ),
        })

        queryClient.setQueryData<CloudflareIntegration>(
          ['cloudflareIntegration', vars.data.subscriptionId],
          (oldData) => {
            if (!oldData) {
              return oldData
            }

            return {
              ...oldData,
              lastDeploymentStatus: DeploymentStatus.Queued,
            }
          }
        )
      }
    },
    onError: (error) => {
      showToast({
        severity: 'error',
        message:
          error.message ?? 'An error occurred when trying to update your Cloudflare API token. Please try again.',
      })
    },
  })
}

export function useIntegrationRequest() {
  return useRequestMutation('integrationRequest', {
    errorHandling: {
      forceToast: true,
    },
  })
}

export type UseInvalidEtldPlusOneWithOriginDataParams = {
  integrationName: string
  subscriptionId: string
  apiHostname: string
  enabled?: boolean
}

export function useInvalidEtldPlusOneWithOriginData({
  integrationName,
  subscriptionId,
  apiHostname,
  enabled = true,
}: UseInvalidEtldPlusOneWithOriginDataParams) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    enabled,
    queryKey: ['getInvalidETldPlusOneDataWithOrigin', integrationName, subscriptionId, apiHostname],
    queryFn: () =>
      extractData(
        withErrorHandling(
          buildRequest('getInvalidETldPlusOneDataWithOrigin', {
            params: {
              subscriptionId,
              integrationName,
            },
            queryParams: {
              apiHostname,
            },
          })
        )
      ),
  })
}

export function useInvalidETldPlusOneDataByHostname(
  integrationTag: SDKIntegration | ThirdPartyIntegration,
  subscriptionId: string
) {
  const buildRequest = useBuildRequest()

  return useQuery({
    queryKey: ['getInvalidETldPlusOneDataByHostname', integrationTag, subscriptionId],
    queryFn: async () =>
      extractData(
        buildRequest('getInvalidETldPlusOneDataByHostname', {
          params: {
            subscriptionId,
            integrationName: integrationTag,
          },
        })
      ),
    enabled: Boolean(subscriptionId),
  })
}
