import { SelectItem, SelectListBox, SelectPopover, SelectTrigger, SelectValue, Stack } from '@compass/components'
import { Tooltip } from '@compass/Tooltip'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { Select, TooltipTrigger } from 'react-aria-components'

export type OverviewChartType = 'api_usage' | 'events_per_visitor' | 'failed_api_calls' | 'rps'
export type OverviewChartSelectOption = {
  key: OverviewChartType
  label: string
}
export const selectOptions: OverviewChartSelectOption[] = [
  { key: 'api_usage', label: 'API usage' },
  { key: 'events_per_visitor', label: 'Events per visitor' },
  { key: 'failed_api_calls', label: 'Failed API calls' },
  { key: 'rps', label: 'Requests per second (RPS)' },
]
export function InsightsChartSelector({
  selectedChart,
  onSelectionChange,
  className,
}: {
  selectedChart?: OverviewChartType
  onSelectionChange: (selected: OverviewChartType) => void
  className?: string
}) {
  return (
    <Stack gap={2} className={clsx('items-center', className)}>
      <Select<OverviewChartSelectOption>
        aria-label='Change displayed chart'
        selectedKey={selectedChart}
        onSelectionChange={onSelectionChange}
        className='-ml-2'
      >
        <TooltipTrigger delay={750}>
          <Tooltip>Change displayed metric</Tooltip>
          <SelectTrigger className='border-none shadow-none text-base' size='sm' tone='ghost' fullWidth>
            <SelectValue<(typeof selectOptions)[number]> className='flex flex-col text-left'>
              {({ selectedItem }) => {
                if (selectedItem == null) {
                  return <Skeleton width={100} height={20} className='transform-none' />
                }

                return selectedItem.label
              }}
            </SelectValue>
          </SelectTrigger>
        </TooltipTrigger>
        <SelectPopover>
          <SelectListBox>
            {selectOptions?.map((option) => (
              <SelectItem key={option.key} id={option.key} value={option} isDisabled={option.key === selectedChart}>
                {option.label}
              </SelectItem>
            ))}
          </SelectListBox>
        </SelectPopover>
      </Select>
    </Stack>
  )
}
