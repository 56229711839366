import { useQuery, useQueryClient } from '@tanstack/react-query'

import { WorkspaceEnvironment } from '../../models'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const workspaceEnvironmentsQueryKey = 'listWorkspaceEnvironments'

export function useWorkspaceEnvironments(subscriptionId: string | undefined | null) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [workspaceEnvironmentsQueryKey, subscriptionId] as const,
    queryFn: ({ queryKey: [_, subId] }) =>
      extractData(withErrorHandling(buildRequest('workspaceEnvironments', { params: { subscriptionId: subId! } }))),
    enabled: subscriptionId != null,
  })
}

export function useWorkspaceEnvironmentsDeleteMutation() {
  const queryClient = useQueryClient()
  return useRequestMutation('workspaceEnvironmentDelete', {
    onSuccess: (_, { params: { subscriptionId } = {} }) => {
      queryClient.invalidateQueries({
        queryKey: [workspaceEnvironmentsQueryKey, subscriptionId],
      })
    },
  })
}

export function useWorkspaceEnvironmentCreateMutation() {
  const queryClient = useQueryClient()
  return useRequestMutation('workspaceEnvironmentCreate', {
    onSuccess: (payload, { params: { subscriptionId } = {} }) => {
      const currentCache =
        queryClient.getQueryData<WorkspaceEnvironment[]>([workspaceEnvironmentsQueryKey, subscriptionId]) ?? []
      const newCache = [...currentCache, payload]

      queryClient.setQueryData<WorkspaceEnvironment[]>([workspaceEnvironmentsQueryKey, subscriptionId], newCache)
    },
  })
}
