import { Paper } from '@mui/material'
import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'

import { SubHeader } from '../../../../commonUI'
import styles from './IntegrationPageSection.module.scss'

export type IntegrationPageSectionProps = {
  title?: string
  isDanger?: boolean
  description?: string
  actions?: ReactNode
  info?: string
  contentUnderneath?: ReactNode
}

export default function IntegrationPageSection({
  actions,
  contentUnderneath,
  description,
  info,
  isDanger,
  title,
  children,
}: PropsWithChildren<IntegrationPageSectionProps>) {
  return (
    <div>
      <Paper className={styles.container}>
        {(title || description || actions) && (
          <SubHeader
            titleClassName={clsx({
              ['text-red-800']: isDanger,
            })}
            info={info}
            title={title}
            description={description}
            actions={actions}
          />
        )}
        <div className='mt-4'>{children}</div>
      </Paper>
      {contentUnderneath ? <div>{contentUnderneath}</div> : undefined}
    </div>
  )
}
