import { useFilterSearchParams } from 'features/identificationVisits'
import { useCurrentSubscription } from 'features/subscription'
import { useDocumentTitle, useToast } from 'hooks'
import { useVisitsInfiniteQuery } from 'hooks/visits'
import { Settings } from 'luxon'
import { useEffect, useState } from 'react'

import { useCurrentUser } from '../../../../hooks/api/users'
import { DateRangeContextProvider } from '../../../commonUI'
import { VisitHistory } from '../../components/VisitHistory/VisitHistory'

export function IdentificationVisitsPage() {
  const [tzReady, setTzReady] = useState(false)
  // Prefetch the current user for timezone.
  const { isLoading, data: currentUser } = useCurrentUser()
  useEffect(() => {
    if (currentUser?.timezone && !isLoading) {
      Settings.defaultZone = currentUser.timezone
      setTzReady(true)
    }
  }, [currentUser?.timezone, isLoading])

  return tzReady ? (
    <DateRangeContextProvider>
      <Page />
    </DateRangeContextProvider>
  ) : null
}

function Page() {
  useDocumentTitle('Identification Events')

  const { showToast } = useToast()

  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { filterValues } = useFilterSearchParams()

  const query = useVisitsInfiniteQuery({
    subscriptionId,
    ...filterValues,
  })

  useEffect(() => {
    if (query.error) {
      showToast({
        message: 'An error occurred when displaying events. Please try again.',
        severity: 'error',
      })
    }
  }, [query.error, showToast])

  return <VisitHistory query={query} />
}
