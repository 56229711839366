import { datadogRum } from '@datadog/browser-rum'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { generateRandomString } from 'helpers/common'
import { DATADOG_SITES } from 'helpers/datadog-urls'
import { isInternalFingerprintUser } from 'helpers/user'
import { useCustomerBillingType } from 'hooks/analytics'
import { useUserContext } from 'hooks/api/context'
import { useSmartSignals } from 'hooks/smartSignals'
import { useEffect } from 'react'

import { API_URL, IS_ON_VERCEL, VERSION } from '../../const'
import { useAuth } from '../../hooks'

let host = 'dashboard.fingerprint.com'

// To avoid CORS error on staging
if (process.env.REACT_APP_VERCEL_ENV === 'preview') {
  if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === 'master') {
    host = 'dashboard.fpjs.sh'
  } else {
    host = window.location.hostname ?? 'dashboard.fpjs.sh'
  }
}

const randomPath = generateRandomString(5)
const proxy = IS_ON_VERCEL ? `https://${host}/${randomPath}` : undefined

export const useDatadog = () => {
  const { isImpersonating } = useAuth()

  useTrackUser()
  useTrackVisitorId()

  useEffect(() => {
    if (isImpersonating) {
      return
    }

    if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_VERCEL_ENV === 'production') {
      return
    }

    datadogRum.init({
      applicationId: 'a97dc1eb-c91d-4811-b97d-72f09fb030b2',
      clientToken: 'secret',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: DATADOG_SITES['US1'],
      service: 'dashboard',
      env: process.env.REACT_APP_VERCEL_ENV,
      version: VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      startSessionReplayRecordingManually: true,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: false,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [API_URL!],
      storeContextsAcrossPages: true,
      allowFallbackToLocalStorage: true,
      proxy,
    })
  }, [isImpersonating])
}

const useTrackUser = () => {
  const { accessTokenPayload } = useAuth()
  const userId = accessTokenPayload?.id
  const customerId = accessTokenPayload?.customerId
  const { data: context } = useUserContext()
  const billingType = useCustomerBillingType()
  const isInternalUser = context?.email && isInternalFingerprintUser({ email: context.email })

  useEffect(() => {
    if (userId == null || userId === '') {
      datadogRum.clearUser()
      return
    }

    datadogRum.setUserProperty('id', userId)
    datadogRum.setUserProperty('customerId', customerId)
    datadogRum.setUserProperty('billingType', billingType)
    datadogRum.setUserProperty('isInternalUser', !!isInternalUser)
  }, [billingType, customerId, isInternalUser, userId])
}

const useTrackVisitorId = () => {
  const { data: visitorData } = useVisitorData({ extendedResult: true })
  const { data: smartSignals } = useSmartSignals(visitorData?.requestId)
  const visitorId = visitorData?.visitorId
  const botd = smartSignals?.products?.botd?.data

  useEffect(() => {
    datadogRum.setUserProperty('visitorId', visitorId)

    if (botd != null) {
      datadogRum.setUserProperty('botDetected', botd?.bot?.result === 'bad')
    }
  }, [botd, visitorId])
}
