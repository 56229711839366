import { ErrorOutline } from '@mui/icons-material'
import { Alert, AlertTitle, Typography } from '@mui/material'
import { ReactNode, useMemo } from 'react'

import { ProxyIntegrationRequests, SdkHealthSdkDetails } from '../../../../models'
import { SemanticVersion } from '../../../health/utils/SemanticVersion'
import { IntegrationMetadata } from '../../components/IntegrationMetadata/integrationMetadata'
import IntegrationNoUsageNotice from '../../components/IntegrationPage/integrationNoUsageNotice/IntegrationNoUsageNotice'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import IntegrationPageSection from '../../components/IntegrationPage/IntegrationPageSection/IntegrationPageSection'
import MismatchedDomainsSection, {
  MismatchedDomainsSectionProps,
} from '../../components/IntegrationPage/mismatchedDomainsSection/MismatchedDomainsSection'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import { ProxyIntegrationUsageChart } from '../../components/UsageChart/ProxyIntegrationUsageChart'
import styles from './IntegrationGenericContents.module.scss'
import { IntegrationGenericMDX } from './IntegrationGenericMDX'

export type IntegrationGenericContentsProps = Omit<MismatchedDomainsSectionProps, 'integration'> & {
  subscriptionId: string
  requestsMade?: ProxyIntegrationRequests
  health?: SdkHealthSdkDetails
  isLoading?: boolean
  integration: IntegrationMetadata
}

function totalRequestsMade(requestsMade?: ProxyIntegrationRequests): number {
  return requestsMade?.proxyRequests?.reduce((sum, curr) => sum + curr.value, 0) || 0
}

function extractStatusFromUsageData(requestsMade?: ProxyIntegrationRequests): IntegrationStatus {
  return totalRequestsMade(requestsMade) > 0 ? IntegrationStatus.Installed : IntegrationStatus.NotInstalled
}

function extractStatusLabelFromStatus(integrationStatus: IntegrationStatus): string {
  switch (integrationStatus) {
    case IntegrationStatus.Installed:
      return `Kudos! The integration has been successfully installed.`
    default:
    case IntegrationStatus.NotInstalled:
      return `Click (or tap) the “View documentation” button to get started.`
  }
}

export function IntegrationGenericContents({
  subscriptionId,
  isLoading,
  health,
  requestsMade,
  integration,
  useInvalidEtldPlusOneWithOriginQuery,
  hasMismatchedDomainsError,
  incorrectETLDPlusOneRequests,
}: IntegrationGenericContentsProps) {
  const repositoryVersion = health?.latestVersion
  const lastCustomerVersionFromUsage = health?.versions.sort((a, b) => {
    return SemanticVersion.compare(SemanticVersion.fromString(b.version), SemanticVersion.fromString(a.version))
  })[0]?.version

  const status = extractStatusFromUsageData(requestsMade)
  const statusLabel = extractStatusLabelFromStatus(status)

  const chartVisible = requestsMade && totalRequestsMade(requestsMade) > 0

  const lastXDays = Math.min(requestsMade?.allRequests?.length ?? 0, 30)

  const versionDiff = SemanticVersion.getDifference(
    SemanticVersion.fromString(lastCustomerVersionFromUsage ?? ''),
    SemanticVersion.fromString(repositoryVersion ?? '')
  )

  let alertTitle = ''
  switch (versionDiff) {
    case 'major':
      alertTitle = 'Update required'
      break
    case 'minor':
      alertTitle = 'Update needed'
      break
    case 'patch':
      alertTitle = 'Update recommended'
      break
  }

  const alerts = useMemo(() => {
    const result: ReactNode[] = []

    // Show warning if user is using outdated integration
    if (versionDiff !== 'same') {
      result.push(
        <Alert key='update' severity='warning' icon={<ErrorOutline />}>
          {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
          <div>
            <Typography variant='bodyM' className={styles.updateAlertText}>
              Outdated SDKs/integrations lower accuracy and may cause errors. An update is recommended to achieve
              maximum Fingerprint strength.
            </Typography>
          </div>
        </Alert>
      )
    }

    return result
  }, [versionDiff, alertTitle])

  const sections: ReactNode[] = useMemo(() => {
    const result: ReactNode[] = [
      <MismatchedDomainsSection
        key='mismatched-domains'
        integration={integration.integrationTag}
        hasMismatchedDomainsError={hasMismatchedDomainsError}
        useInvalidEtldPlusOneWithOriginQuery={useInvalidEtldPlusOneWithOriginQuery}
        incorrectETLDPlusOneRequests={incorrectETLDPlusOneRequests}
      />,
    ]

    if (status === IntegrationStatus.NotInstalled) {
      if (chartVisible) {
        sections.push(
          <IntegrationPageSection key='usage' title={`${integration.title} Usage`} info={`Last ${lastXDays} Days`}>
            <ProxyIntegrationUsageChart requests={requestsMade} integrationName={integration.title} />
          </IntegrationPageSection>
        )
      }
    } else {
      if (chartVisible) {
        result.push(
          <IntegrationPageSection key='usage' title={`${integration.title} Usage`} info='Last 30 days'>
            <ProxyIntegrationUsageChart requests={requestsMade} integrationName={integration.title} />
          </IntegrationPageSection>
        )
      } else {
        result.push(
          <IntegrationPageSection key='usage' title={`${integration.title} Usage`}>
            <IntegrationNoUsageNotice docsLink={integration.docsLink} />
          </IntegrationPageSection>
        )
      }
    }

    return result
  }, [
    chartVisible,
    hasMismatchedDomainsError,
    incorrectETLDPlusOneRequests,
    integration,
    lastXDays,
    requestsMade,
    status,
    useInvalidEtldPlusOneWithOriginQuery,
  ])

  if (!chartVisible && integration.mdx) {
    return <IntegrationGenericMDX integration={integration} />
  }

  return (
    <>
      <IntegrationPage
        alerts={alerts}
        isLoading={isLoading}
        integrationStatus={status}
        title={integration.title}
        subscriptionId={subscriptionId}
        statusLabel={statusLabel}
        docsUrl={integration.docsLink}
        repoUrl={integration.sourceCodeLink}
        Logo={integration.iconComponent}
        metadata={
          chartVisible && status !== IntegrationStatus.NotInstalled
            ? [
                {
                  title: 'Your version',
                  value: lastCustomerVersionFromUsage,
                  versionBadge: versionDiff !== 'same' ? versionDiff : undefined,
                },
                {
                  title: 'Latest version',
                  value: repositoryVersion,
                },
              ]
            : [
                {
                  title: 'Latest version',
                  value: repositoryVersion,
                },
              ]
        }
        sections={sections}
      />
    </>
  )
}
