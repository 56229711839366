import { Flex, Stack, WithTooltip } from '@compass/components'
import { Paper, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { RegionFlag } from 'components'
import { PlanBadge } from 'components/PlanBadge/PlanBadge'
import { SubscriptionStatusDot } from 'components/SubscriptionStatusDot/SubscriptionStatusDot'
import { SUBSCRIPTION_DISPLAY_PLANS } from 'const'
import { formatDate } from 'features/commonUI'
import { PlanSummary } from 'features/planManagement'
import { getRegionByCode } from 'helpers/content'
import { formatNum } from 'helpers/format'
import { getSubscriptionDisplayPlan } from 'helpers/subscription'
import { useBillingQuota } from 'hooks'
import { InfoIcon } from 'lucide-react'
import { ExpandedSubscription } from 'models'
import { Link } from 'react-router-dom'

import { ActionsMenu } from '../ActionsMenu/ActionsMenu'

export type ApplicationsOverviewProps = {
  subscription: ExpandedSubscription
}

export type ActionsType = 'identification_events' | 'api_keys' | 'plan_and_usage' | 'settings'
export type ActionsSelectOption = {
  key: ActionsType
  label: string
}
export const selectOptions: ActionsSelectOption[] = [
  { key: 'identification_events', label: 'Identification events' },
  { key: 'api_keys', label: 'API keys' },
  { key: 'plan_and_usage', label: 'Plan & usage' },
  { key: 'settings', label: 'Settings' },
]

function extractPeriodDates(subscription: ExpandedSubscription) {
  if (subscription.currentPeriodStartedAt && subscription.currentPeriodEndsAt) {
    return {
      startDate: formatDate(subscription.currentPeriodStartedAt, 'shortWithYear'),
      endDate: formatDate(subscription.currentPeriodEndsAt, 'shortWithYear'),
    }
  }
  return undefined
}

export function WorkspaceTable({ subscription }: ApplicationsOverviewProps) {
  const region = getRegionByCode(subscription.regionCode)
  const plan = subscription ? SUBSCRIPTION_DISPLAY_PLANS[getSubscriptionDisplayPlan(subscription)].name : undefined
  const planBadge = subscription ? <PlanBadge subscription={subscription} /> : undefined
  const { currentUsage = 0, projectedUsage = 0 } = useBillingQuota(subscription)

  const period = extractPeriodDates(subscription)

  return (
    <Paper className='group hover:bg-gray-100 relative' data-testid='workspace-card'>
      <Link
        to={buildRoute(AppRoute.SubscriptionOverview, {
          subscriptionId: subscription.id,
        })}
        className='absolute top-0 left-0 w-full h-full'
      />
      <Stack className='px-6 py-5'>
        <Flex row className='gap-4 items-center'>
          <RegionFlag regionCode={subscription.regionCode} className='flex-shrink-0' />
          <Flex className='grow-1'>
            <Typography variant='semiBody1' component='h2' className='max-w-max'>
              <Link
                className='hover:underline relative'
                to={buildRoute(AppRoute.SubscriptionOverview, {
                  subscriptionId: subscription.id,
                })}
              >
                {subscription.name}
              </Link>
            </Typography>
            {subscription.domain && <Typography variant='bodyS'>{subscription.domain}</Typography>}
          </Flex>
          <div className='flex justify-end items-center gap-2'>
            <ActionsMenu subscriptionId={subscription.id} />
          </div>
        </Flex>
      </Stack>
      <Stack className='border-t px-6 py-5 bg-gray-100 rounded-b group-hover:bg-gray-200'>
        <PlanSummary
          entries={[
            {
              key: 'Status',
              value: <SubscriptionStatusDot status={subscription.status} />,
            },
            {
              key: 'Plan',
              value: plan,
              badge: planBadge,
            },
            {
              key: 'Region',
              label: (
                <Flex row className='gap-1'>
                  <Typography variant='bodyS' component='span'>
                    Region
                  </Typography>
                  <WithTooltip
                    content={`Your workspace data is processed and stored in ${region.regionName}, ${region.country}. The region is set during workspace creation and cannot be changed.`}
                  >
                    <InfoIcon className='size-3 text-gray-800 relative' />
                  </WithTooltip>
                </Flex>
              ),
              value: (
                <Typography variant='bodyMMedium' className='font-mono'>
                  {region.codeExtended}
                </Typography>
              ),
            },
            {
              key: 'Usage',
              label: (
                <Flex row className='gap-1'>
                  <Typography variant='bodyS' component='span'>
                    Usage
                  </Typography>
                  <WithTooltip
                    content={`API calls made during the current period${period ? `: ${period.startDate} - ${period.endDate}` : ''}`}
                  >
                    <InfoIcon className='size-3 text-gray-800 relative' />
                  </WithTooltip>
                </Flex>
              ),
              value: formatNum(currentUsage),
            },
            {
              key: 'Projected usage',
              label: (
                <Flex row className='gap-1'>
                  <Typography variant='bodyS' component='span'>
                    Projected usage
                  </Typography>
                  <WithTooltip
                    content={`Projected API calls for the current period${period ? `: ${period.startDate} - ${period.endDate}` : ''}`}
                  >
                    <InfoIcon className='size-3 text-gray-800 relative' />
                  </WithTooltip>
                </Flex>
              ),
              value:
                typeof projectedUsage !== 'undefined' ? (
                  formatNum(projectedUsage)
                ) : (
                  <Typography variant='bodyS'>-</Typography>
                ),
            },
          ]}
        />
      </Stack>
    </Paper>
  )
}
