import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ValueOf } from 'helpers/types'

import { SuccessResponse } from '../../const'
import { UserSubscriptionSettings } from '../../models'
import { useAuth } from '../user'
import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const KEY = 'userSubscriptionSettings' as const

export function useUserSubscriptionSettings<T extends keyof UserSubscriptionSettings>(
  subscriptionId: string | undefined,
  settingsName: T,
  enabled = true
) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()
  const { isAuthorized } = useAuth()

  return useQuery({
    queryKey: [KEY, subscriptionId, settingsName] as const,
    queryFn: () =>
      extractData<UserSubscriptionSettings[T]>(
        withErrorHandling(
          buildRequest('userSubscriptionSettings', { params: { settingsName, subscriptionId: subscriptionId ?? '' } })
        ) as Promise<SuccessResponse<UserSubscriptionSettings[T]>>
      ),
    enabled: enabled && !!subscriptionId && isAuthorized,
  })
}

type SettingsValue = ValueOf<UserSubscriptionSettings>
export function useUserSubscriptionSettingsUpdate(options?: { revertOnError?: boolean }) {
  const { revertOnError = true } = options ?? {}

  const queryClient = useQueryClient()

  return useRequestMutation('userSubscriptionSettingsUpdate', {
    onMutate: async ({ data, params }) => {
      const queryKey = extractKey(data, params)
      await queryClient.cancelQueries({ queryKey })
      const previousValue = queryClient.getQueryData<SettingsValue>(queryKey)
      queryClient.setQueryData<SettingsValue>(queryKey, data?.settings)
      return { previousValue }
    },
    onError: (_, { data, params }, { previousValue }) => {
      if (!revertOnError) {
        return
      }

      const queryKey = extractKey(data, params)
      queryClient.setQueryData<SettingsValue>(queryKey, previousValue)
    },
    onSettled: (_, error, { data, params }) => {
      if (error && !revertOnError) {
        return
      }

      const queryKey = extractKey(data, params)
      queryClient.invalidateQueries({ queryKey })
    },
  })
}
function extractKey(
  data?: { settings_name: keyof UserSubscriptionSettings; settings: ValueOf<UserSubscriptionSettings> } | undefined,
  params?: { subscriptionId: string } | undefined
) {
  return [KEY, params?.subscriptionId, data?.settings_name]
}
