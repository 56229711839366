import { useQuery } from '@tanstack/react-query'
import { useBuildRequest, useGenericErrorHandler } from 'hooks/api/base'

export const useSdkHealthQuery = (subscriptionId: string) => {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['health', 'sdk', subscriptionId] as const,
    queryFn: () =>
      withErrorHandling(buildRequest('subscriptionSdkUsage', { params: { subscriptionId } })).then(({ data }) => data),
    enabled: subscriptionId != null,
  })
}
