import { WithTooltip } from '@compass/components'
import { InfoOutlined } from '@mui/icons-material'
import { Paper, Skeleton, Typography } from '@mui/material'
import { ChartBarDecreasing } from 'lucide-react'
import { ReactNode, useMemo } from 'react'

import { formatNum, formatNumShort } from '../../../../helpers/format'
import styles from './Distribution.module.scss'
import { DistributionLimiter } from './DistributionLimiter'
import { DistributionHeaderRow, DistributionRow, DistributionRowProps } from './DistributionRow'

type RowProps = Pick<DistributionRowProps, 'background' | 'labelColor'>

export type DistributionData = {
  totalEvents?: string
}

export type DistributionWidgetProps<V extends DistributionData = DistributionData> = Pick<
  DistributionHeaderProps,
  'info'
> & {
  rowProps?: RowProps
  title?: ReactNode
  isError?: boolean
  hideHeader?: boolean
  keyLabel?: string
  valueLabel?: string
  labelKey?: keyof V
  distribution?: V[]
}

export type DistributionHeaderProps = {
  selected?: boolean
  info?: string
  title?: ReactNode
}

function DistributionHeader({ info, title }: DistributionHeaderProps) {
  return (
    <div className={styles.distributionHeader}>
      {title == null ? <Skeleton width={160} height={20} className='transform-none' /> : null}
      {typeof title === 'string' ? <Typography variant='bodyMMedium'>{title}</Typography> : title}

      {info ? (
        <div className='flex flex-grow justify-end'>
          <WithTooltip content={info}>
            <InfoOutlined fontSize='14' color='secondary-text' />
          </WithTooltip>
        </div>
      ) : null}
    </div>
  )
}

function extractTotal<V extends DistributionData>(value: V): string {
  return value.totalEvents ?? '0'
}

function getPercentage(value: string, sumValue: bigint): number {
  return sumValue === 0n ? 0 : Number((BigInt(value) * 100000n) / sumValue) / 100000
}

function getSumValue<V extends DistributionData>(values: V[]) {
  return values.reduce((acc, curr) => acc + BigInt(extractTotal(curr)), 0n)
}

function getRows<V extends DistributionData>(value: V[], labelKey?: keyof V, rowProps?: RowProps) {
  const sumValue = getSumValue(value)

  return value.map((v, i) => {
    const total = extractTotal(v)

    const parsedValue = BigInt(total)
    const formattedValue = parsedValue > 500000n ? formatNumShort(parsedValue) : formatNum(parsedValue)

    return (
      <DistributionRow
        key={i}
        index={i}
        label={labelKey ? v[labelKey as keyof typeof v]?.toString() : undefined}
        value={formattedValue}
        percentage={getPercentage(total, sumValue)}
        {...rowProps}
      />
    )
  })
}

export default function DistributionWidget<V extends DistributionData>({
  title,
  isError,
  hideHeader,
  rowProps,
  info,
  keyLabel,
  valueLabel = 'events',
  distribution,
  labelKey,
}: DistributionWidgetProps<V>) {
  const rows = useMemo(
    () => (distribution != null ? getRows(distribution, labelKey, rowProps) : undefined),
    [distribution, labelKey, rowProps]
  )

  return (
    <Paper style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      {!hideHeader && <DistributionHeader title={title} info={info} />}
      <div className={styles.distributionBody} style={{ flexGrow: 1 }}>
        {isError || (rows != null && rows.length === 0) ? (
          <ErrorContent
            message={
              isError ? 'There was an error displaying data.' : 'There is not enough data to display this chart.'
            }
          />
        ) : (
          <DistributionLimiter
            header={
              <DistributionHeaderRow
                keyLabel={keyLabel}
                valueLabel={valueLabel}
                className={styles.distributionHeaderRow}
              />
            }
            rows={rows}
            className={styles.rowContainer}
          />
        )}
      </div>
    </Paper>
  )
}

function ErrorContent({ message }: { message: string }) {
  return (
    <div className={styles.errorContent}>
      <ChartBarDecreasing className='size-4 text-gray-600' />
      <Typography variant='bodyS'>{message}</Typography>
    </div>
  )
}
