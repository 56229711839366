import { useQuery } from '@tanstack/react-query'
import { useBuildRequest, useGenericErrorHandler } from 'hooks/api/base'

export const useIntegrationStatsQuery = (integrationName: string, subscriptionId: string) => {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: [integrationName, 'stats', subscriptionId] as const,
    queryFn: () =>
      withErrorHandling(
        buildRequest('subscriptionIntegrationUsageStats', { params: { subscriptionId, integrationName } })
      ).then(({ data }) => data),
    enabled: Boolean(subscriptionId),
  })
}
