import { Button, LinkButton } from '@compass/components'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link as LinkMui,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { SUPPORT_PAGE_URL } from 'const'
import { useSubscriptionTrialSelfExtend, useToast, useUiPreferences } from 'hooks'
import { Subscription, SubscriptionStatus, SubscriptionType } from 'models'
import { useCallback, useState } from 'react'

import styles from './ForcedTrialEndModal.module.scss'

type SubscriptionProps = Pick<Subscription, 'id' | 'status' | 'type' | 'extendedTrialStartedAt'>
type Props = {
  upgradeUrl: string
  isOpen: boolean
  onClose: () => void
  extendedTrialStartedAt?: string | Date
  onExtendTrial: () => void
  extendTrialLoading?: boolean
}

// Connected component
export function ForcedTrialEndModal({ id, type, status, extendedTrialStartedAt }: SubscriptionProps) {
  const {
    uiPreferences: { isForcedTrialModalDismissed },
    updateUiPreferences,
  } = useUiPreferences()

  const [isOpen, setOpen] = useState(!isForcedTrialModalDismissed)
  const { mutate: extendTrial, isPending: extendTrialLoading } = useSubscriptionTrialSelfExtend()
  const { showToast } = useToast()

  const handleClose = useCallback(() => {
    updateUiPreferences({ isForcedTrialModalDismissed: true })
    setOpen(false)
  }, [updateUiPreferences, setOpen])

  const handleExtendTrial = useCallback(() => {
    extendTrial(
      { params: { subscriptionId: id } },
      {
        onError: () => {
          showToast({
            severity: 'error',
            message: 'An error occured when extending your free trial. Please try again.',
          })
        },
      }
    )
  }, [id, showToast, extendTrial])

  if (type !== SubscriptionType.TrialOnly || status !== SubscriptionStatus.Canceled) {
    return null
  }

  return (
    <ForcedTrialEndModalLayout
      upgradeUrl={buildRoute(AppRoute.SubscriptionPlan, { subscriptionId: id })}
      isOpen={isOpen}
      onClose={handleClose}
      extendedTrialStartedAt={extendedTrialStartedAt}
      onExtendTrial={handleExtendTrial}
      extendTrialLoading={extendTrialLoading}
    />
  )
}

// Display component for Storybook
export function ForcedTrialEndModalLayout({
  upgradeUrl,
  isOpen,
  onClose,
  extendedTrialStartedAt,
  onExtendTrial,
  extendTrialLoading,
}: Props) {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={smDown}>
      <IconButton className={styles.closeButton} aria-label='close' onClick={onClose} size='large'>
        <CloseIcon />
      </IconButton>

      <DialogTitle className={styles.title}>Your free trial has ended</DialogTitle>
      <DialogContent className={styles.modalContainer}>
        <div className={styles.titleSection}>
          {extendedTrialStartedAt ? (
            <div className={styles.subTitle}>Upgrade now to keep leveraging industry-leading device intelligence.</div>
          ) : (
            <div className={styles.subTitle}>
              Upgrade now or <b>enjoy an extended trial on us</b> (completely free) to keep leveraging industry-leading
              device intelligence.
            </div>
          )}
        </div>

        <LinkButton href={upgradeUrl} fullWidth size='lg'>
          Upgrade now
        </LinkButton>
        {!extendedTrialStartedAt && (
          <Box width='100%' mt='16px'>
            <Button onPress={onExtendTrial} isDisabled={extendTrialLoading} fullWidth variant='outline' size='lg'>
              Extend trial &ndash; free
            </Button>
          </Box>
        )}

        <Box width='100%' mt='32px' fontSize='14px' lineHeight='20px'>
          <b>Have questions?</b>
          <br />
          <LinkMui href={SUPPORT_PAGE_URL} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
            Reach out to us.
          </LinkMui>{' '}
          Our support engineers are happy to help.
        </Box>
      </DialogContent>
    </Dialog>
  )
}
