import { Stack } from '@compass/Layout/Stack'
import { Link, Typography } from '@mui/material'
import { useInvalidEtldPlusOneWithOriginData } from 'hooks/api/integrations'
import { useCallback, useMemo, useState } from 'react'

import { TablePagination } from '../../../../../components/Table/Table'
import { SUPPORT_EMAIL_MAILTO } from '../../../../../const'
import { sumInvalidOriginsCount } from '../../../../../helpers/incorrectEtldPlusOne'
import { pluralize } from '../../../../../helpers/pluralize'
import { IncorrectETLDPlusOneData } from '../../../../../models'
import { GenericEmptyState } from '../../../../commonUI'
import { SDKIntegration, ThirdPartyIntegration } from '../../IntegrationMetadata/integrationMetadata'
import { IntegrationMismatchedDomainsSection } from '../integrationMismatchedDomainsSection/IntegrationMismatchedDomainsSection'
import IntegrationPageSection from '../IntegrationPageSection/IntegrationPageSection'

export type MismatchedDomainsSectionProps = {
  useInvalidEtldPlusOneWithOriginQuery?: typeof useInvalidEtldPlusOneWithOriginData
  incorrectETLDPlusOneRequests?: IncorrectETLDPlusOneData[]
  hasMismatchedDomainsError?: boolean
  integration: ThirdPartyIntegration | SDKIntegration
}

const PER_PAGE = 10

export default function MismatchedDomainsSection({
  incorrectETLDPlusOneRequests = [],
  integration,
  useInvalidEtldPlusOneWithOriginQuery,
  hasMismatchedDomainsError,
}: MismatchedDomainsSectionProps) {
  const invalidOriginsCount = useMemo(() => {
    if (!incorrectETLDPlusOneRequests.length) {
      return 0
    }

    return sumInvalidOriginsCount(incorrectETLDPlusOneRequests)
  }, [incorrectETLDPlusOneRequests])

  const total = incorrectETLDPlusOneRequests.length
  const [page, setPage] = useState(0)
  const nextPage = useCallback(() => setPage((prev) => prev + 1), [])
  const prevPage = useCallback(() => setPage((prev) => prev - 1), [])
  const [start, end] = useMemo(() => {
    return [page * PER_PAGE, Math.min(total, (page + 1) * PER_PAGE)]
  }, [page, total])
  const paginatedData = useMemo(
    () => incorrectETLDPlusOneRequests.slice(start, end),
    [end, incorrectETLDPlusOneRequests, start]
  )

  const hasInvalidRequests = useMemo(
    () => incorrectETLDPlusOneRequests.some((it) => it.invalidRequestsCount > 0),
    [incorrectETLDPlusOneRequests]
  )

  if (!hasInvalidRequests && !hasMismatchedDomainsError) {
    return null
  }

  return (
    <IntegrationPageSection
      contentUnderneath={
        total > PER_PAGE && (
          <div className='w-full flex justify-end mt-2'>
            <Stack direction='row' className='items-center' gap={4}>
              <Typography variant='bodyS'>
                Showing {start + 1}-{end} of {total} domains
              </Typography>
              <TablePagination
                className='mt-0'
                hasPreviousEntries={start > 0}
                hasNextEntries={end < total}
                onRequestNextEntries={nextPage}
                onRequestPreviousEntries={prevPage}
              />
            </Stack>
          </div>
        )
      }
      description={hasMismatchedDomainsError ? undefined : 'Showing data from the last 30 days.'}
      title={hasMismatchedDomainsError ? undefined : pluralize(invalidOriginsCount, 'mismatched domain')}
    >
      {hasMismatchedDomainsError && (
        <GenericEmptyState
          title='Error fetching domains'
          description={
            <>
              An error occurred when fetching domains. Please try again or{' '}
              <Link href={SUPPORT_EMAIL_MAILTO} underline='none'>
                contact support
              </Link>{' '}
              if this issue persists.
            </>
          }
        />
      )}
      {!hasMismatchedDomainsError && (
        <IntegrationMismatchedDomainsSection
          integration={integration}
          useInvalidEtldPlusOneWithOriginQuery={useInvalidEtldPlusOneWithOriginQuery}
          invalidRequests={paginatedData}
        />
      )}
    </IntegrationPageSection>
  )
}
