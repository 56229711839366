import groupBy from 'lodash/groupBy'
import { useMemo } from 'react'

import { formatPriceInDollars } from '../../../helpers/format'
import { Discount, SubscriptionType } from '../../../models'
import { formatOveragePrice, formatUsageValue } from '../helpers/format'
import { UsageEntry } from '../types'

export function useUsageCalculations(
  entries: UsageEntry[],
  subscriptionType?: SubscriptionType,
  appliedDiscount?: Discount
) {
  return useMemo(() => {
    const defaultReturn = {
      usageCostInCents: 0,
      planPriceInCents: 0,
      totalCostInCents: 0,
      finalCostInCents: 0,
      discountAmount: 0,
      getGroupTooltip: () => null,
    }

    if (subscriptionType !== SubscriptionType.Paid || entries.length === 0) {
      return defaultReturn
    }

    const groupEntriesByBillingValues = (entriesArray: UsageEntry[]) => {
      return Object.values(groupBy(entriesArray, (entry) => `${entry.overagePrice}-${entry.includedWithPlan}`))
    }

    const groups = groupEntriesByBillingValues(entries)

    // Calculate totals for each group
    const groupTotals = groups.map((group) => {
      const totalCurrentUsage = group.reduce((sum, entry) => sum + entry.currentUsage, 0)
      const includedUsage = group[0].includedWithPlan
      const overagePrice = group[0].overagePrice || 0
      const overageUsage = Math.max(0, totalCurrentUsage - includedUsage)
      const usageCostInCents = Math.round(overageUsage * (overagePrice / 100) * 100)
      return { totalCurrentUsage, includedUsage, overageUsage, usageCostInCents }
    })

    const planPriceInCents = Math.round(entries[0].cost ? parseFloat(entries[0].cost.replace('$', '')) * 100 : 0)

    const usageCostInCents = groupTotals.reduce((sum, group) => sum + group.usageCostInCents, 0)
    const totalCostInCents = Math.round(planPriceInCents + usageCostInCents)
    const discountAmount = Math.round(
      appliedDiscount?.coupon?.percentOff ? totalCostInCents * (appliedDiscount.coupon.percentOff / 100) : 0
    )
    const finalCostInCents = Math.round(totalCostInCents - discountAmount)

    const generateUsageText = (
      totalCurrentUsage: number,
      includedUsage: number,
      overageUsage: number,
      overagePrice: number,
      groupUsageCostInCents: number
    ) => {
      if (totalCurrentUsage < includedUsage) {
        return (
          <>
            {formatUsageValue(includedUsage)} (included w/ plan) - {formatUsageValue(totalCurrentUsage)} (current usage)
            = {formatUsageValue(includedUsage - totalCurrentUsage)} free remaining
          </>
        )
      } else {
        return (
          <>
            {formatUsageValue(totalCurrentUsage)} (current usage) - {formatUsageValue(includedUsage)} (included w/ plan)
            = {formatUsageValue(overageUsage)}
            <br />
            {formatUsageValue(overageUsage)} * {formatOveragePrice(overagePrice)} (overage price) ={' '}
            {formatPriceInDollars(groupUsageCostInCents)}
          </>
        )
      }
    }

    const getGroupTooltip = (group: UsageEntry[]) => {
      const totalCurrentUsage = group.reduce((sum, entry) => sum + entry.currentUsage, 0)
      const includedUsage = group[0].includedWithPlan
      const overagePrice = group[0].overagePrice || 0
      const overageUsage = Math.max(0, totalCurrentUsage - includedUsage)
      const groupUsageCostInCents = Math.round(overageUsage * overagePrice)

      return generateUsageText(totalCurrentUsage, includedUsage, overageUsage, overagePrice, groupUsageCostInCents)
    }

    return {
      usageCostInCents,
      planPriceInCents,
      totalCostInCents,
      finalCostInCents,
      discountAmount,
      getGroupTooltip,
    }
  }, [entries, subscriptionType, appliedDiscount])
}
