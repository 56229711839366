import { useInvalidETldPlusOneDataByHostname } from '../../../../hooks/api/integrations'
import { useSdkHealthQuery } from '../../../health/hooks/useSdkHealthQuery'
import { useCurrentSubscription } from '../../../subscription'
import { IntegrationMetadata } from '../../components/IntegrationMetadata/integrationMetadata'
import { useIntegrationStatsQuery } from '../../hooks/useIntegrationStatsQuery'
import { IntegrationGenericContents } from './IntegrationGenericContents'

export function IntegrationGenericPage(integration: IntegrationMetadata) {
  const { currentSubscriptionId } = useCurrentSubscription()
  const { data, isLoading: isSDKHealthLoading } = useSdkHealthQuery(currentSubscriptionId)
  const { data: requestsMade, isLoading: statsIsLoading } = useIntegrationStatsQuery(
    integration.integrationTag,
    currentSubscriptionId
  )

  const incorrectETLDPlusOneQuery = useInvalidETldPlusOneDataByHostname(
    integration.integrationTag,
    currentSubscriptionId
  )

  const isLoading = isSDKHealthLoading || statsIsLoading || incorrectETLDPlusOneQuery.isLoading

  const health = data?.platform.find((t) => t.sdkName === integration.title)

  return (
    <>
      <IntegrationGenericContents
        subscriptionId={currentSubscriptionId}
        requestsMade={requestsMade}
        health={health}
        isLoading={isLoading}
        integration={integration}
        incorrectETLDPlusOneRequests={incorrectETLDPlusOneQuery.data}
      />
    </>
  )
}
