import { ToastQueue, useToastQueue } from '@react-stately/toast'
import { createPortal } from 'react-dom'
import { ToastParams } from 'toast'

import { ToastRegion } from './ToastRegion'

export function GlobalToastRegion({ queue, ...props }: { queue: ToastQueue<ToastParams> }) {
  const state = useToastQueue(queue)

  return state.visibleToasts.length > 0 ? createPortal(<ToastRegion {...props} state={state} />, document.body) : null
}
