import { Flex, Stack, WithTooltip } from '@compass/components'
import { Paper, Skeleton, Typography } from '@mui/material'
import { PlanSummary } from 'features/planManagement'
import { InfoIcon } from 'lucide-react'

import { ActionsMenu } from '../ActionsMenu/ActionsMenu'

export function SkeletonWorkspaceTable() {
  return (
    <Paper>
      <Stack className='px-6 py-5'>
        <Flex row className='gap-4 items-center'>
          <Skeleton height={15} width={25} className='transform-none' />

          <Flex className='grow-1 gap-1'>
            <Skeleton height={20} width={94} className='transform-none' />
            <Skeleton height={16} width={72} className='transform-none' />
          </Flex>
          <div className='flex justify-end items-center gap-2'>
            <ActionsMenu subscriptionId='loading-state' />
          </div>
        </Flex>
      </Stack>
      <Stack className='border-t px-6 py-5 bg-gray-50 rounded-b'>
        <PlanSummary
          entries={[
            {
              key: 'Status',
              value: <Skeleton height={20} width={96} className='transform-none' />,
            },
            {
              key: 'Plan',
              value: <Skeleton height={20} width={128} className='transform-none' />,
            },
            {
              key: 'Region',
              label: (
                <Flex row className='gap-1'>
                  <Typography variant='bodyS' component='span'>
                    Region
                  </Typography>
                  <WithTooltip
                    content={
                      <Flex row className='items-center gap-1.5'>
                        Your workspace data is processed and stored in
                        <Skeleton sx={{ bgcolor: 'grey.800' }} height={20} width={96} className='transform-none' />. The
                        region is set during workspace creation and cannot be changed.
                      </Flex>
                    }
                  >
                    <InfoIcon className='size-3 text-gray-800 relative' />
                  </WithTooltip>
                </Flex>
              ),
              value: <Skeleton height={20} width={96} className='transform-none' />,
            },
            {
              key: 'Usage',
              label: (
                <Flex row className='gap-1'>
                  <Typography variant='bodyS' component='span'>
                    Usage
                  </Typography>
                  <WithTooltip
                    content={
                      <Flex row className='items-center gap-1.5'>
                        API calls made during the current period:{' '}
                        <Skeleton sx={{ bgcolor: 'grey.800' }} height={20} width={74} className='transform-none' /> -{' '}
                        <Skeleton sx={{ bgcolor: 'grey.800' }} height={20} width={74} className='transform-none' />
                      </Flex>
                    }
                  >
                    <InfoIcon className='size-3 text-gray-800 relative' />
                  </WithTooltip>
                </Flex>
              ),
              value: <Skeleton height={20} width={128} className='transform-none' />,
            },
            {
              key: 'Projected usage',
              label: (
                <Flex row className='gap-1'>
                  <Typography variant='bodyS' component='span'>
                    Projected usage
                  </Typography>
                  <WithTooltip
                    content={
                      <Flex row className='items-center gap-1.5'>
                        Projected API calls for the current period:{' '}
                        <Skeleton sx={{ bgcolor: 'grey.800' }} height={20} width={74} className='transform-none' /> -{' '}
                        <Skeleton sx={{ bgcolor: 'grey.800' }} height={20} width={74} className='transform-none' />
                      </Flex>
                    }
                  >
                    <InfoIcon className='size-3 text-gray-800 relative' />
                  </WithTooltip>
                </Flex>
              ),
              value: <Skeleton height={20} width={128} className='transform-none' />,
            },
          ]}
        />
      </Stack>
    </Paper>
  )
}
