import { useQuery } from '@tanstack/react-query'
import { useBuildRequest, useGenericErrorHandler } from 'hooks/api/base'

export const useSdkHealthStatusQuery = (subscriptionId: string) => {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['health', 'sdk', 'status', subscriptionId] as const,
    queryFn: () =>
      withErrorHandling(buildRequest('subscriptionSdkHealthStatus', { params: { subscriptionId } })).then(
        ({ data }) => data
      ),
    enabled: subscriptionId != null,
  })
}
