import { LinkButton } from '@compass/components'
import { Grid, Paper, Typography } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { DOCS_SMART_SIGNALS_OVERVIEW } from 'const'
import { ChangePlanAction } from 'features/planManagement'
import { useCurrentSubscription } from 'features/subscription'
import { useUserContext } from 'hooks/api/context'

import SmartSignalsSvg from './smart-signals.svg'
import styles from './SmartSignalIntroduction.module.scss'

export function SmartSignalsIntroduction() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: userContext } = useUserContext()

  return (
    <Paper className={`${styles.card} sm:mt-2 overflow-hidden`}>
      <Grid item xs={12} md={8} className={styles.details}>
        <Typography variant={'h2'} className={styles.cardTitle}>
          Reveal the true intentions of every visitor
        </Typography>
        <Typography variant='body1' className={styles.description}>
          Make smart, data-driven decisions with Smart Signals to prevent increasingly sophisticated fraud and improve
          positive experiences for trusted users. Upgrade your existing plan to reveal existing Smart Signals insights.
        </Typography>
        <div className={styles.buttonsSection}>
          <LinkButton
            href={buildRoute(
              AppRoute.ChangePlan,
              { subscriptionId },
              {
                action: userContext?.isEligibleForAndroidFreemium
                  ? ChangePlanAction.UpgradeToPlus99AndroidFreemium
                  : ChangePlanAction.UpgradeToPlus99,
              }
            )}
            size='lg'
          >
            Upgrade and reveal data
          </LinkButton>
          <LinkButton variant='ghost' size='lg' href={DOCS_SMART_SIGNALS_OVERVIEW} className={styles.infoButton}>
            Learn more
          </LinkButton>
        </div>
      </Grid>
      <Grid xs={12} md={4} className={styles.smartSignalsImageGrid}>
        <SmartSignalsSvg className={styles.smartSignalsImage} />
      </Grid>
    </Paper>
  )
}
