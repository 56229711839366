import { WithTooltip } from '@compass/WithTooltip'
import { Divider, Typography } from '@mui/material'
import clsx from 'clsx'
import { formatPriceInDollars } from 'helpers/format'
import { BillingType, Discount, SubscriptionType } from 'models'
import { useMemo } from 'react'

import { calculateTotals } from '../../../helpers/calculations'
import { getTableColumns } from '../../../helpers/columns'
import { formatCost, formatOveragePrice, formatUsageValue } from '../../../helpers/format'
import { useUsageCalculations } from '../../../hooks/useUsageCalculations'
import { UsageEntry } from '../../../types'

enum UsageTableKey {
  Platform = 'platform',
  CurrentUsage = 'currentUsage',
  IncludedWithPlan = 'includedWithPlan',
  OveragePrice = 'overagePrice',
  Cost = 'cost',
}

interface UsageTableProps {
  entries: UsageEntry[]
  subscriptionType?: SubscriptionType
  billingType?: BillingType
  isRelatedVisitors?: boolean
  appliedDiscount?: Discount
}

export function UsageTable({
  entries,
  subscriptionType,
  billingType,
  isRelatedVisitors,
  appliedDiscount,
}: UsageTableProps) {
  const columns = useMemo(
    () => getTableColumns(subscriptionType, billingType, isRelatedVisitors, entries),
    [subscriptionType, billingType, isRelatedVisitors, entries]
  )

  const totals = useMemo(() => calculateTotals(entries), [entries])
  const showSimplifiedTotal = isRelatedVisitors || entries.length === 1

  const { usageCostInCents, planPriceInCents, discountAmount, finalCostInCents, getGroupTooltip } =
    useUsageCalculations(entries, subscriptionType, appliedDiscount)

  const formatCell = (key: keyof UsageEntry, value: any) => {
    if (value === undefined) return '-'
    switch (key) {
      case UsageTableKey.CurrentUsage:
        return formatUsageValue(value)
      case UsageTableKey.IncludedWithPlan:
        return formatUsageValue(value, subscriptionType)
      case UsageTableKey.OveragePrice:
        return formatOveragePrice(value)
      case UsageTableKey.Cost:
        return formatCost(value)
      default:
        return value
    }
  }

  const getPlatformGroups = (entriesArray: UsageEntry[]) => {
    // Group entries by their billing values
    const groups: { [key: string]: UsageEntry[] } = {}

    entriesArray.forEach((entry) => {
      const key = `${entry.overagePrice}-${entry.includedWithPlan}`
      if (!groups[key]) {
        groups[key] = []
      }
      groups[key].push(entry)
    })

    return Object.values(groups)
  }

  const calculateGroupUsageCost = (group: UsageEntry[], entry: UsageEntry) => {
    return Math.round(
      Math.max(0, group.reduce((sum, e) => sum + e.currentUsage, 0) - entry.includedWithPlan) *
        ((entry.overagePrice ?? 0) / 100) *
        100
    )
  }

  const renderTableRows = () => {
    if (!showSimplifiedTotal && entries.length > 1) {
      const platformGroups = getPlatformGroups(entries)

      return platformGroups
        .map((group, groupIndex) =>
          group.map((entry, entryIndex) => (
            <tr key={`${groupIndex}-${entryIndex}`}>
              {columns.map(({ key }) => {
                // Platform column always shows
                if (key === UsageTableKey.Platform) {
                  return (
                    <td
                      key={key}
                      className={clsx(
                        'px-4 py-1.5 text-left',
                        groupIndex === platformGroups.length - 1 && entryIndex === group.length - 1 && 'pb-3'
                      )}
                    >
                      <Typography variant='bodyM'>{entry.platform}</Typography>
                    </td>
                  )
                }

                // For grouped values (overagePrice and includedWithPlan), show only in first row with rowspan
                if (key === UsageTableKey.IncludedWithPlan || key === UsageTableKey.OveragePrice) {
                  if (entryIndex === 0) {
                    return (
                      <td
                        key={key}
                        rowSpan={group.length}
                        className='px-4 py-1.5 text-right text-gray-800 align-middle'
                      >
                        <Typography variant='bodyM'>{formatCell(key, entry[key])}</Typography>
                      </td>
                    )
                  }
                  return null
                }

                if (key === UsageTableKey.CurrentUsage) {
                  return (
                    <td
                      key={key}
                      className={clsx(
                        'px-4 py-1.5 text-right text-gray-800',
                        groupIndex === platformGroups.length - 1 && entryIndex === group.length - 1 && 'pb-3'
                      )}
                    >
                      <Typography variant='bodyM'>{formatCell(key, entry[key])}</Typography>
                    </td>
                  )
                }

                if (key === UsageTableKey.Cost) {
                  if (!entry.overagePrice && !entry.includedWithPlan) {
                    return <td key={key} className='px-4 py-1.5 text-right' />
                  }

                  if (entryIndex === 0) {
                    const groupUsageCost = calculateGroupUsageCost(group, entry)

                    return (
                      <td key={key} rowSpan={group.length} className='px-4 py-1.5 text-right align-middle'>
                        <WithTooltip content={getGroupTooltip(group)}>
                          <Typography variant='bodyM' className='border-b border-dotted border-gray-600 cursor-help'>
                            {formatPriceInDollars(groupUsageCost)}
                          </Typography>
                        </WithTooltip>
                      </td>
                    )
                  }
                  return null
                }

                return <td key={key} />
              })}
            </tr>
          ))
        )
        .flat()
    }
    return null
  }

  const Totals = () => {
    if (subscriptionType === SubscriptionType.Paid) {
      return (
        <>
          <tr className='bg-gray-100'>
            {columns.map(({ key }, index) => {
              if (key === UsageTableKey.Platform)
                return (
                  <td key={key} className='px-4 py-3 rounded-tl'>
                    <Typography variant='bodyM'>Total</Typography>
                  </td>
                )
              if (key === UsageTableKey.IncludedWithPlan) {
                // Sum all unique includedWithPlan values
                const totalIncluded = getPlatformGroups(entries).reduce(
                  (sum, group) => sum + group[0].includedWithPlan,
                  0
                )
                return (
                  <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                    <Typography variant='bodyMMedium'>{formatCell(key, totalIncluded)}</Typography>
                  </td>
                )
              }
              if (key === UsageTableKey.OveragePrice) return <td key={key} />
              if (key === UsageTableKey.CurrentUsage)
                return (
                  <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                    <Typography variant='bodyMMedium'>{formatCell(key, totals.currentUsage)}</Typography>
                  </td>
                )
              if (key === UsageTableKey.Cost) {
                return (
                  <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                    <Typography variant='bodyMMedium'>{formatPriceInDollars(usageCostInCents)}</Typography>
                  </td>
                )
              }
              return <td key={key} />
            })}
          </tr>
          <tr className='bg-gray-100'>
            {columns.map(({ key }) => {
              if (key === UsageTableKey.Platform)
                return (
                  <td key={key} className='p-4 pt-0'>
                    <Typography variant='bodyM'>Plan price</Typography>
                  </td>
                )
              if (key === UsageTableKey.Cost)
                return (
                  <td key={key} className='p-4 pt-0 text-right'>
                    <Typography variant='bodyMMedium'>{formatPriceInDollars(planPriceInCents)}</Typography>
                  </td>
                )
              return <td key={key} />
            })}
          </tr>
          {appliedDiscount?.coupon?.percentOff && (
            <tr className='bg-gray-100'>
              {columns.map(({ key }) => {
                if (key === UsageTableKey.Platform)
                  return (
                    <td key={key} className='p-4 pt-0'>
                      <Typography variant='bodyM'>Discount ({appliedDiscount?.coupon?.percentOff}% off)</Typography>
                    </td>
                  )
                if (key === UsageTableKey.Cost)
                  return (
                    <td key={key} className='p-4 pt-0 text-right'>
                      <Typography variant='bodyMMedium' className='text-red-800'>
                        -{formatPriceInDollars(discountAmount)}
                      </Typography>
                    </td>
                  )
                return <td key={key} />
              })}
            </tr>
          )}
          <tr className='bg-gray-100'>
            <td colSpan={columns.length} className='px-4 py-0'>
              <Divider className='border-dashed' />
            </td>
          </tr>
          <tr className='bg-gray-100'>
            {columns.map(({ key }) => {
              if (key === UsageTableKey.Platform)
                return (
                  <td key={key} className='p-4'>
                    <Typography variant='bodyLMedium'>Total cost</Typography>
                  </td>
                )
              if (key === UsageTableKey.Cost)
                return (
                  <td key={key} className='p-4 text-right'>
                    <Typography variant='bodyLMedium'>{formatPriceInDollars(finalCostInCents)}</Typography>
                  </td>
                )
              return <td key={key} />
            })}
          </tr>
        </>
      )
    }

    return (
      <tr className='bg-gray-100'>
        {columns.map(({ key }, index) => {
          if (key === UsageTableKey.Platform)
            return (
              <td key={key} className='px-4 py-3 rounded-tl'>
                <Typography variant='bodyM'>Total</Typography>
              </td>
            )
          if (key === UsageTableKey.CurrentUsage)
            return (
              <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                <Typography variant='bodyMMedium'>{formatCell(key, totals.currentUsage)}</Typography>
              </td>
            )
          if (key === UsageTableKey.IncludedWithPlan)
            return (
              <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                <Typography variant='bodyMMedium'>{formatCell(key, entries[0][key])}</Typography>
              </td>
            )
          if (key === UsageTableKey.Cost && totals.cost !== undefined)
            return (
              <td key={key} className={clsx('px-4 py-3 text-right', index === columns.length - 1 && 'rounded-tr')}>
                <Typography variant='bodyMMedium'>${totals.cost.toFixed(2)}</Typography>
              </td>
            )
          return <td key={key} />
        })}
      </tr>
    )
  }

  return (
    <table className='w-full'>
      <thead>
        <tr className='border-b'>
          {columns.map(({ key, header }) => (
            <th
              key={key}
              className={clsx(
                'px-4 py-3 bg-white',
                key === UsageTableKey.Platform ? 'min-w-28 text-left w-full' : 'min-w-40 text-right w-40 max-w-40'
              )}
            >
              <Typography variant='caption'>{header}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={columns.length} className={showSimplifiedTotal ? 'h-3' : 'h-1.5'} />
        </tr>
        {renderTableRows()}
        <Totals />
      </tbody>
    </table>
  )
}
