import { Button } from '@compass/components'
import { Paper } from '@mui/material'
import clsx from 'clsx'
import { GenericError } from 'const'
import { ContentColumn, SubHeader } from 'features/commonUI'
import { usePasswordUpdateMutation, useToast } from 'hooks'
import { UpdatePasswordFormData } from 'models'
import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import CreatePasswordInput from '../../../components/CreatePasswordInput/CreatePasswordInput'
import Loader from '../../../components/Loader/Loader'
import PasswordInput from '../../../components/PasswordInput/PasswordInput'
import styles from './SettingsForms.module.scss'

export function ProfileSecurityForm() {
  const { showToast } = useToast()

  const {
    mutate: updatePassword,
    isPending: isUpdatingPassword,
    error: updatePasswordError,
  } = usePasswordUpdateMutation()

  const handleUpdatePassword = useCallback(
    (payload: UpdatePasswordFormData, onSuccess: () => void) =>
      updatePassword(
        { data: payload },
        {
          onSuccess: () => {
            onSuccess()
            showToast({
              message: 'Successfully updated password.',
              severity: 'success',
            })
          },
        }
      ),
    [showToast, updatePassword]
  )

  return (
    <ProfileSecurityFormContent
      isLoading={isUpdatingPassword}
      error={updatePasswordError}
      onSubmit={handleUpdatePassword}
    />
  )
}

export interface ProfileSecurityFormContentProps {
  isLoading?: boolean
  error?: GenericError | null
  onSubmit: (data: UpdatePasswordFormData, reset: () => void) => void
}

export function ProfileSecurityFormContent({ isLoading, error, onSubmit }: ProfileSecurityFormContentProps) {
  const formMethods = useForm<UpdatePasswordFormData>()
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = formMethods

  function onSuccess() {
    reset()
  }

  return (
    <ContentColumn>
      <SubHeader title='Profile security' description='Manage and update the login credentials for your account.' />
      <Paper className={styles.card}>
        <form onSubmit={handleSubmit((data) => onSubmit(data, onSuccess))} className={styles.form}>
          <FormProvider {...formMethods}>
            <PasswordInput
              name='password'
              label='Current password'
              placeholder='Current password'
              error={error}
              showErrorState={isDirty}
              showHelperText={isDirty}
              inputProps={{
                id: 'current-password',
                autoComplete: 'current-password',
              }}
              className={styles.input}
            />

            <CreatePasswordInput name='newPassword' className={clsx(styles.input, styles.passwordCreate)} />

            <Button
              type='submit'
              aria-label='Update Password'
              isDisabled={!isDirty || !isValid}
              className={styles.button}
            >
              Save
            </Button>
          </FormProvider>
        </form>

        {isLoading && <Loader testId='profile-security-form-loader' />}
      </Paper>
    </ContentColumn>
  )
}
