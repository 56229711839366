import { useCurrentSubscription } from 'features/subscription'
import { useSmartSignal } from 'hooks/api/smart_signals'
import { useMemo } from 'react'

import { CustomDateRange, PredefinedRange } from '../../../helpers/dateRange'
import { ExtendedSignalName, SignalName } from '../utils/SignalName'
import { SmartSignalMetric } from '../utils/SmartSignalMetric'
import { supportedSignalsPresentationalInfo } from '../utils/supportedSignalsPresentationalInfo'

export function useSmartSignalMetrics(dateRange?: CustomDateRange | PredefinedRange) {
  const { currentSubscriptionId } = useCurrentSubscription()

  // we want to be able to add on the backend first, and expose it to
  // the frontend when we have the presentational data set up
  const supportedSignals = useMemo(() => new Set(Object.keys(supportedSignalsPresentationalInfo)), [])

  const { data, isLoading } = useSmartSignal(
    currentSubscriptionId,
    dateRange?.startDate?.toISO() ?? undefined,
    dateRange?.endDate?.toISO() ?? undefined
  )

  const { data: previousPeriodData, isLoading: isLoadingPrevious } = useSmartSignal(
    currentSubscriptionId,
    dateRange?.compareStart?.toISO() ?? undefined,
    dateRange?.compareEnd?.toISO() ?? undefined
  )

  const smartSignalsMetrics: SmartSignalMetric[] = useMemo(() => {
    const currentPeriodSignals = data?.stats?.data
    const previousPeriodSignals = previousPeriodData?.stats?.data

    if (!currentPeriodSignals || !previousPeriodSignals) {
      return []
    }

    const unfilteredSignals = Object.keys(currentPeriodSignals).reduce<SmartSignalMetric[]>((agg, platform) => {
      const signalMetricsForPlatform: SmartSignalMetric[] =
        currentPeriodSignals[platform] &&
        Object.keys(currentPeriodSignals[platform]).flatMap((originalSignalName: SignalName) => {
          let signalName: ExtendedSignalName = originalSignalName
          const currentPeriodSignal = currentPeriodSignals?.[platform]?.[originalSignalName] ?? null
          const previousPeriodSignal = previousPeriodSignals?.[platform]?.[originalSignalName] ?? null

          const currentPeriodValue = currentPeriodSignal ? BigInt(currentPeriodSignal) : null
          const previousPeriodValue = previousPeriodSignal ? BigInt(previousPeriodSignal) : null

          if (originalSignalName === 'totalTampering' && platform === 'android') {
            signalName = 'totalTamperingAndroid'
          }

          if (signalName === 'totalSuccessfulIdentifications' || !supportedSignalsPresentationalInfo[signalName]) {
            return []
          }

          return [
            {
              ...supportedSignalsPresentationalInfo[signalName],
              availabilityBoundary:
                dateRange?.compareStart &&
                dateRange?.compareStart < supportedSignalsPresentationalInfo[signalName]?.availabilityBoundary
                  ? supportedSignalsPresentationalInfo[signalName].availabilityBoundary
                  : undefined,
              signalName,
              value: currentPeriodValue,
              previousPeriodValue,
            },
          ]
        })

      if (signalMetricsForPlatform) {
        agg.push(...signalMetricsForPlatform)
      }
      return agg
    }, [])

    return unfilteredSignals.filter((signal) => supportedSignals.has(signal.signalName))
  }, [data?.stats?.data, dateRange?.compareStart, previousPeriodData?.stats?.data, supportedSignals])

  return {
    isLoading: isLoading || isLoadingPrevious,
    smartSignalsEnabled: data?.smartSignalsEnabled,
    smartSignalsAvailable: data?.smartSignalsAvailable,
    totalApiCalls: data?.totalApiCalls ? BigInt(data.totalApiCalls) : null,
    smartSignalsMetrics,
  }
}
