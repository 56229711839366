import { AppRoute, buildRoute } from 'appRoutes'
import { ChangePlanAction } from 'features/planManagement'
import { useSubscription } from 'hooks'
import { useUserContext } from 'hooks/api/context'
import { useUiPreferences } from 'hooks/uiPreferences'

import { Alert } from '../../../../components/compass/Alert/Alert'
import { SubscriptionTier, SubscriptionType } from '../../../../models/models'

export function AndroidFreemiumBanner({ subscriptionId }: { subscriptionId: string }) {
  const { uiPreferences, updateUiPreferences } = useUiPreferences()
  const { data: subscription } = useSubscription(subscriptionId)
  const { data: userContext } = useUserContext()

  const isAndroidFreemiumUser =
    subscription?.latestTier === SubscriptionTier.Plus99AndroidFreemium ||
    subscription?.latestTier === SubscriptionTier.PlusAndroidFreemium
  const canUpgradeToAndroidFreemium =
    userContext?.isEligibleForAndroidFreemium &&
    (subscription?.type === SubscriptionType.TrialOnly || subscription?.latestTier === SubscriptionTier.Pro)

  const welcomeBannerWasDismissed = uiPreferences.isAndroidFreemiumAnnouncementDismissed
  const upgradeBannerWasDismissed = uiPreferences.isUpgradeToAndroidFreemiumDismissed

  const handleWelcomeBannerClose = () => {
    updateUiPreferences({
      ...uiPreferences,
      isAndroidFreemiumAnnouncementDismissed: true,
    })
  }

  const handleUpgradeBannerClose = () => {
    updateUiPreferences({
      ...uiPreferences,
      isUpgradeToAndroidFreemiumDismissed: true,
    })
  }

  if (isAndroidFreemiumUser && !welcomeBannerWasDismissed) {
    return (
      <Alert
        variant='info'
        onClose={handleWelcomeBannerClose}
        actionProps={{
          children: 'Learn more',
          href: 'https://dev.fingerprint.com/docs/android-api-call-allowance#/',
          target: '_blank',
        }}
      >
        We&apos;ve updated our pricing. Your workspace now includes 500,000 free Android API calls monthly. Enjoy!
      </Alert>
    )
  }

  if (canUpgradeToAndroidFreemium && !upgradeBannerWasDismissed) {
    return (
      <Alert
        variant='info'
        onClose={handleUpgradeBannerClose}
        actionProps={{
          children: 'Upgrade now',
          href: buildRoute(
            AppRoute.ChangePlan,
            { subscriptionId },
            { action: ChangePlanAction.UpgradeToPlus99AndroidFreemium }
          ),
        }}
      >
        Upgrade to Pro Plus and receive 500,000 free Android API calls a month for this workspace.
      </Alert>
    )
  }

  return null
}
