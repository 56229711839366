import { SvgIconComponent } from '@mui/icons-material'
import {
  AppProduct,
  BillingData,
  BillingType,
  BotdCounterType,
  ClientCounterType,
  JobFunctionType,
  JobLevelType,
  Limits,
  PasswordStrengthLevel,
  Product,
  RegionCode,
  RegionCodeExtended,
  RestrictedReason,
  SubscriptionBillingInterval,
  SubscriptionStatus,
  SubscriptionTier,
  SubscriptionType,
  UsageCounterType,
  UserRole,
} from 'models'

import { Feature } from '../components/FeatureList/FeatureList'
import { countries } from './countryStates'
import { COMPANY_NAME } from './env'
import flagEu from './flags/flag_eu.svg?url'
import flagIn from './flags/flag_in.svg?url'
import flagUs from './flags/flag_us.svg?url'

type CountryName = (typeof countries)[number]

export type Region = {
  flag: string | SvgIconComponent
  title: string
  subTitle: string
  shortTitle: string
  regionName: string
  label?: string
  code: RegionCode
  codeExtended: RegionCodeExtended
  country: CountryName
}

export const REGIONS: Record<RegionCode, Region> = {
  [RegionCode.Use1]: {
    flag: flagUs,
    title: 'World Wide / North America (Virginia)',
    subTitle: 'Suitable for worldwide',
    shortTitle: 'North America',
    regionName: 'Virginia',
    label: 'Most popular',
    code: RegionCode.Use1,
    codeExtended: RegionCodeExtended.Use1,
    country: 'United States',
  },
  [RegionCode.Euc1]: {
    flag: flagEu,
    title: 'Europe (Frankfurt)',
    subTitle: 'Best for Europe',
    shortTitle: 'Europe',
    regionName: 'Frankfurt',
    code: RegionCode.Euc1,
    codeExtended: RegionCodeExtended.Euc1,
    country: 'Germany',
  },
  [RegionCode.Aps1]: {
    flag: flagIn,
    title: 'Asia-Pacific (Mumbai)',
    subTitle: 'Best for APAC',
    shortTitle: 'Asia Pacific',
    regionName: 'Mumbai',
    code: RegionCode.Aps1,
    codeExtended: RegionCodeExtended.Aps1,
    country: 'India',
  },
}

export type NamedValue<T> = {
  name: string
  value: T
}

export const RESTRICTED_REASONS: Record<RestrictedReason, NamedValue<RestrictedReason>> = {
  [RestrictedReason.AccountEmailNotConfirmed]: {
    name: 'Email not confirmed',
    value: RestrictedReason.AccountEmailNotConfirmed,
  },
  [RestrictedReason.UniqueVisitorsExceeded]: {
    name: 'Unique visitors exceeded',
    value: RestrictedReason.UniqueVisitorsExceeded,
  },
  [RestrictedReason.ApiCallsExceeded]: {
    name: 'API calls exceeded',
    value: RestrictedReason.ApiCallsExceeded,
  },
  [RestrictedReason.SuspiciousActivity]: {
    name: 'Suspicious activity',
    value: RestrictedReason.SuspiciousActivity,
  },
  [RestrictedReason.ManualReview]: {
    name: '',
    value: RestrictedReason.ManualReview,
  },
}

export const JOB_LEVEL_TYPES: Record<JobLevelType, NamedValue<JobLevelType>> = {
  [JobLevelType.Executive]: { name: 'Executive', value: JobLevelType.Executive },
  [JobLevelType.Director]: { name: 'Director', value: JobLevelType.Director },
  [JobLevelType.Manager]: { name: 'Manager', value: JobLevelType.Manager },
  [JobLevelType.IndividualContributor]: { name: 'Individual Contributor', value: JobLevelType.IndividualContributor },
  [JobLevelType.Other]: { name: 'Other', value: JobLevelType.Other },
}

export const JOB_FUNCTION_TYPES: Record<JobFunctionType, NamedValue<JobFunctionType>> = {
  [JobFunctionType.Product]: { name: 'Product', value: JobFunctionType.Product },
  [JobFunctionType.Engineering]: { name: 'Engineering', value: JobFunctionType.Engineering },
  [JobFunctionType.RiskAndSecurity]: { name: 'Risk & Security', value: JobFunctionType.RiskAndSecurity },
  [JobFunctionType.DataAndAnalytics]: { name: 'Data & Analytics', value: JobFunctionType.DataAndAnalytics },
  [JobFunctionType.Marketing]: { name: 'Marketing', value: JobFunctionType.Marketing },
  [JobFunctionType.Operations]: { name: 'Operations', value: JobFunctionType.Operations },
  [JobFunctionType.Other]: { name: 'Other', value: JobFunctionType.Other },
}

export const BILLING_TYPES: Record<BillingType, NamedValue<BillingType> & { usageUnit: string }> = {
  [BillingType.ApiCalls]: { name: 'Usage', usageUnit: 'requests', value: BillingType.ApiCalls },
  [BillingType.UniqueVisitors]: {
    name: 'Unique visitors',
    usageUnit: 'unique visitors',
    value: BillingType.UniqueVisitors,
  },
}

export const BILLING_INTERVAL: Record<SubscriptionBillingInterval, NamedValue<SubscriptionBillingInterval>> = {
  [SubscriptionBillingInterval.Month]: { name: 'Monthly', value: SubscriptionBillingInterval.Month },
  [SubscriptionBillingInterval.Year]: { name: 'Yearly', value: SubscriptionBillingInterval.Year },
}

export const LIMIT_DEFAULTS: Record<Limits, number> = {
  [Limits.TrafficRules]: 10,
  [Limits.TokenRate]: 5,
  [Limits.Tokens]: 10,
  [Limits.Webhooks]: 5,
  [Limits.SubscriptionTrials]: 1,
  [Limits.WorkspaceEnvironments]: 5,
  [Limits.SslCertificates]: 5,
}

export const USER_ROLES: Record<UserRole, NamedValue<UserRole>> = {
  [UserRole.ReadOnly]: {
    name: 'Read Only',
    value: UserRole.ReadOnly,
  },
  [UserRole.Member]: {
    name: 'Member',
    value: UserRole.Member,
  },
  [UserRole.Administrator]: {
    name: 'Admin',
    value: UserRole.Administrator,
  },
  [UserRole.Owner]: {
    name: 'Owner',
    value: UserRole.Owner,
  },
  [UserRole.Admin]: {
    name: 'Admin',
    value: UserRole.Admin,
  },
}

export const USER_ROLE_DESCRIPTIONS: Record<UserRole, string> = {
  [UserRole.Administrator]: 'Also allowed to invite and manage users.',
  [UserRole.Member]: 'Also allowed to manage applications.',
  [UserRole.ReadOnly]: 'Allowed to view applications.',
  [UserRole.Admin]: '',
  [UserRole.Owner]: '',
}

/** List of valid roles when creating new users. */
export const AVAILABLE_ROLES = Object.values(USER_ROLES).filter(
  ({ value }) => value !== UserRole.Admin && value !== UserRole.Owner
)

export const SUBSCRIPTION_STATUSES: Record<SubscriptionStatus, NamedValue<SubscriptionStatus>> = {
  [SubscriptionStatus.Creating]: {
    name: 'Creating',
    value: SubscriptionStatus.Creating,
  },
  [SubscriptionStatus.Trialing]: {
    name: 'Trialing',
    value: SubscriptionStatus.Trialing,
  },
  [SubscriptionStatus.Active]: {
    name: 'Active',
    value: SubscriptionStatus.Active,
  },
  [SubscriptionStatus.ProofOfConcept]: {
    name: 'Proof of concept',
    value: SubscriptionStatus.ProofOfConcept,
  },
  [SubscriptionStatus.Canceled]: {
    name: 'Canceled',
    value: SubscriptionStatus.Canceled,
  },
  [SubscriptionStatus.PastDue]: {
    name: 'Retrying payment',
    value: SubscriptionStatus.PastDue,
  },
  [SubscriptionStatus.Unpaid]: {
    name: 'Payment required',
    value: SubscriptionStatus.Unpaid,
  },
  [SubscriptionStatus.Incomplete]: {
    name: 'Payment required',
    value: SubscriptionStatus.Incomplete,
  },
  [SubscriptionStatus.IncompleteExpired]: {
    name: 'Payment required',
    value: SubscriptionStatus.IncompleteExpired,
  },
  [SubscriptionStatus.Restricted]: {
    name: 'Restricted',
    value: SubscriptionStatus.Restricted,
  },
}

export const SUBSCRIPTION_TYPES: Record<SubscriptionType, NamedValue<SubscriptionType>> = {
  [SubscriptionType.Free]: {
    name: 'Free',
    value: SubscriptionType.Free,
  },
  [SubscriptionType.Paid]: {
    name: 'Fingerprint pro',
    value: SubscriptionType.Paid,
  },
  [SubscriptionType.TrialOnly]: {
    name: 'Fingerprint pro',
    value: SubscriptionType.TrialOnly,
  },

  [SubscriptionType.Prepaid]: {
    name: 'Fingerprint enterprise',
    value: SubscriptionType.Prepaid,
  },

  [SubscriptionType.ProofOfConcept]: {
    name: 'Trial',
    value: SubscriptionType.ProofOfConcept,
  },
}

export const ACTIVE_SUBSCRIPTION_STATUSES: SubscriptionStatus[] = Object.values(SubscriptionStatus).filter(
  (s) =>
    s !== SubscriptionStatus.Canceled &&
    s !== SubscriptionStatus.Incomplete &&
    s !== SubscriptionStatus.IncompleteExpired &&
    s !== SubscriptionStatus.Creating
)

export const USAGE_RESTRICTION_STATUSES = [RestrictedReason.ApiCallsExceeded, RestrictedReason.UniqueVisitorsExceeded]

export const PASSWORD_STRENGTH_LEVELS: Record<
  PasswordStrengthLevel,
  NamedValue<PasswordStrengthLevel> & {
    colorClass: string
  }
> = {
  [PasswordStrengthLevel.Weak]: { name: 'Weak', value: PasswordStrengthLevel.Weak, colorClass: 'red' },
  [PasswordStrengthLevel.Good]: { name: 'Good', value: PasswordStrengthLevel.Good, colorClass: 'yellow' },
  [PasswordStrengthLevel.Strong]: { name: 'Strong', value: PasswordStrengthLevel.Strong, colorClass: 'green' },
}

export const COOKIE_NAMES = {
  websiteData: 'fpjsWebsiteData',
}

export const TIMES_OF_DAY = Array(24)
  .fill(undefined)
  .map((_, i) => i)
  .map((hour) => {
    const hour12 = hour % 12 === 0 ? 12 : hour % 12
    const displayHour = hour12.toString().padStart(2, '0')

    return { value: hour.toString(), name: hour > 11 ? `${displayHour}:00 PM` : `${displayHour}:00 AM` }
  })

export const DAYS_OF_WEEK = [
  { value: 'monday', name: 'Monday' },
  { value: 'tuesday', name: 'Tuesday' },
  { value: 'wednesday', name: 'Wednesday' },
  { value: 'thursday', name: 'Thursday' },
  { value: 'friday', name: 'Friday' },
  { value: 'saturday', name: 'Saturday' },
  { value: 'sunday', name: 'Sunday' },
]

export const PRODUCT_NAME = COMPANY_NAME // It was Fingerprint Pro

export const RESEND_CONFIRMATION_TIMER_PERIOD = 60

export enum CounterQueryType {
  IdentificationApiCalls = 'identificationApiCalls',
  IdentificationUniqueVisitors = 'identificationUniqueVisitors',
  Botd = 'botd',
}

export const counterQueryTypeToCounterTypes: Record<CounterQueryType, ClientCounterType[]> = {
  [CounterQueryType.IdentificationApiCalls]: [
    UsageCounterType.ApiCalls,
    UsageCounterType.ThrottledCalls,
    UsageCounterType.RestrictedCalls,
  ],
  [CounterQueryType.IdentificationUniqueVisitors]: [
    UsageCounterType.UniqueVisitors,
    UsageCounterType.ApiCalls,
    UsageCounterType.ThrottledCalls,
    UsageCounterType.RestrictedCalls,
  ],
  [CounterQueryType.Botd]: [
    UsageCounterType.ApiCalls,
    BotdCounterType.GoodBots,
    BotdCounterType.BadBots,
    BotdCounterType.Humans,
  ],
}

export const counterQueryTypeToProduct: Record<CounterQueryType, AppProduct> = {
  [CounterQueryType.IdentificationApiCalls]: AppProduct.Identification,
  [CounterQueryType.IdentificationUniqueVisitors]: AppProduct.Identification,
  [CounterQueryType.Botd]: AppProduct.Botd,
}

export enum SubscriptionDisplayPlan {
  Free = 1, // Legacy
  Pro,
  Plus,
  PlusAndroidFreemium,
  Plus99,
  Plus99AndroidFreemium,
  Enterprise,
}

export const SUBSCRIPTION_ANALYTICS_PLAN_NAMES: Record<
  Exclude<SubscriptionDisplayPlan, SubscriptionDisplayPlan.Free>,
  'Fingerprint Pro' | 'Fingerprint Pro Plus' | 'Enterprise'
> = {
  [SubscriptionDisplayPlan.Pro]: 'Fingerprint Pro',
  [SubscriptionDisplayPlan.Plus]: 'Fingerprint Pro Plus',
  [SubscriptionDisplayPlan.PlusAndroidFreemium]: 'Fingerprint Pro Plus',
  [SubscriptionDisplayPlan.Plus99]: 'Fingerprint Pro Plus',
  [SubscriptionDisplayPlan.Plus99AndroidFreemium]: 'Fingerprint Pro Plus',
  [SubscriptionDisplayPlan.Enterprise]: 'Enterprise',
}

export const SUBSCRIPTION_DISPLAY_PLANS: Record<SubscriptionDisplayPlan, NamedValue<SubscriptionDisplayPlan>> = {
  [SubscriptionDisplayPlan.Pro]: {
    name: 'Pro',
    value: SubscriptionDisplayPlan.Pro,
  },
  [SubscriptionDisplayPlan.Plus]: {
    name: 'Pro Plus',
    value: SubscriptionDisplayPlan.Plus,
  },
  [SubscriptionDisplayPlan.PlusAndroidFreemium]: {
    name: 'Pro Plus',
    value: SubscriptionDisplayPlan.PlusAndroidFreemium,
  },
  [SubscriptionDisplayPlan.Plus99]: {
    name: 'Pro Plus',
    value: SubscriptionDisplayPlan.Plus,
  },
  [SubscriptionDisplayPlan.Plus99AndroidFreemium]: {
    name: 'Pro Plus',
    value: SubscriptionDisplayPlan.Plus,
  },
  [SubscriptionDisplayPlan.Enterprise]: {
    name: 'Enterprise',
    value: SubscriptionDisplayPlan.Enterprise,
  },

  [SubscriptionDisplayPlan.Free]: {
    name: 'Free',
    value: SubscriptionDisplayPlan.Free,
  },
}

export const SUBSCRIPTION_TIER_TO_DISPLAY_PLAN = {
  [SubscriptionTier.Pro]: SubscriptionDisplayPlan.Pro,
  [SubscriptionTier.Plus]: SubscriptionDisplayPlan.Plus,
  [SubscriptionTier.PlusAndroidFreemium]: SubscriptionDisplayPlan.PlusAndroidFreemium,
  [SubscriptionTier.Plus99]: SubscriptionDisplayPlan.Plus99,
  [SubscriptionTier.Plus99AndroidFreemium]: SubscriptionDisplayPlan.Plus99AndroidFreemium,
}

export const SUBSCRIPTION_PLAN_PRICES: Record<SubscriptionDisplayPlan, number | 'custom'> = {
  [SubscriptionDisplayPlan.Pro]: 200_00,
  [SubscriptionDisplayPlan.Plus]: 400_00,
  [SubscriptionDisplayPlan.PlusAndroidFreemium]: 400_00,
  [SubscriptionDisplayPlan.Plus99]: 99_00,
  [SubscriptionDisplayPlan.Plus99AndroidFreemium]: 99_00,
  [SubscriptionDisplayPlan.Enterprise]: 'custom',

  [SubscriptionDisplayPlan.Free]: 0,
}

export type StatusColor = 'blue' | 'green' | 'orange' | 'red' | 'gray' | 'yellow'

export const STATUS_COLOR_MAP: Record<SubscriptionStatus, StatusColor> = {
  [SubscriptionStatus.Active]: 'green',
  [SubscriptionStatus.Creating]: 'green',
  [SubscriptionStatus.ProofOfConcept]: 'green',
  [SubscriptionStatus.Trialing]: 'blue',
  [SubscriptionStatus.Restricted]: 'yellow',
  [SubscriptionStatus.Unpaid]: 'yellow',
  [SubscriptionStatus.Canceled]: 'red',
  [SubscriptionStatus.Incomplete]: 'red',
  [SubscriptionStatus.IncompleteExpired]: 'red',
  [SubscriptionStatus.PastDue]: 'red',
}

// TODO Remove hard coded billing data after switching to new price endpoint.
export const PRO_PLUS_BILLING_DATA: BillingData = {
  overagePrice: 0.4,
  prepaidQuantity: 100_000,
  flatAmount: 40_000,
  unitLabel: 'API call',
  product: Product.ApiCalls,
}

export const PRO_PLUS_ANDROID_FREEMIUM_BILLING_DATA: BillingData = {
  overagePrice: 0.4,
  prepaidQuantity: 100_000,
  flatAmount: 40_000,
  unitLabel: 'API call',
  product: Product.ApiCalls,
  platformBilling: {
    android: {
      overagePrice: 0.4,
      prepaidQuantity: 500_000,
    },
  },
}

export const PRO_PLUS_99_BILLING_DATA: BillingData = {
  overagePrice: 0.4,
  prepaidQuantity: 20_000,
  flatAmount: 9900,
  unitLabel: 'API call',
  product: Product.ApiCalls,
}

export const PRO_PLUS_99_ANDROID_FREEMIUM_BILLING_DATA: BillingData = {
  overagePrice: 0.4,
  prepaidQuantity: 20_000,
  flatAmount: 9900,
  unitLabel: 'API call',
  product: Product.ApiCalls,
  platformBilling: {
    android: {
      overagePrice: 0.4,
      prepaidQuantity: 500_000,
    },
  },
}

const ENTERPRISE_FEATURES: Array<Feature> = [
  { name: 'All Smart Signals' },
  { name: 'Advanced network detection' },
  { name: 'Proxy integrations' },
  { name: '99.9% SLA' },
  { name: 'Enterprise-grade compliance and security' },
  { name: 'Dedicated customer success manager' },
]

export const PLAN_FEATURES: Record<SubscriptionDisplayPlan, Array<Feature>> = {
  [SubscriptionDisplayPlan.Free]: [],
  [SubscriptionDisplayPlan.Pro]: [{ name: 'Visitor identification' }],
  [SubscriptionDisplayPlan.Plus]: [
    { name: 'Visitor identification' },
    { name: 'Smart Signals' },
    { name: 'Browser bot detection' },
    { name: 'VPN detection' },
  ],
  [SubscriptionDisplayPlan.PlusAndroidFreemium]: [
    { name: 'Visitor identification' },
    { name: 'Smart Signals' },
    { name: 'Browser bot detection' },
    { name: 'VPN detection' },
    { name: '500,000 Android API calls', tooltip: 'Only available to 1 workspace per account' },
  ],
  [SubscriptionDisplayPlan.Plus99]: [
    { name: 'Visitor identification' },
    { name: 'Smart Signals' },
    { name: 'Browser bot detection' },
    { name: 'VPN detection' },
  ],
  [SubscriptionDisplayPlan.Plus99AndroidFreemium]: [
    { name: 'Visitor identification' },
    { name: 'Smart Signals' },
    { name: 'Browser bot detection' },
    { name: 'VPN detection' },
    { name: '500,000 Android API calls', tooltip: 'Only available to 1 workspace per account' },
  ],
  [SubscriptionDisplayPlan.Enterprise]: ENTERPRISE_FEATURES,
}

export const SIGNAL_NAMES: Record<AppProduct, string> = {
  [AppProduct.Identification]: 'Identification',
  [AppProduct.Botd]: 'Bot',
  [AppProduct.Incognito]: 'Incognito',
  [AppProduct.IpResolution]: 'Ip resolution',
  [AppProduct.Tampering]: 'Browser tampering',
  [AppProduct.Vpn]: 'VPN',
  [AppProduct.Tor]: 'TOR',
  [AppProduct.IpBlacklist]: 'Ip blocklist',
  [AppProduct.MobileRootApps]: 'Root apps (mobile)',
  [AppProduct.MobileEmulator]: 'Emulator (mobile)',
  [AppProduct.Proxy]: 'Proxy',
  [AppProduct.PrivacySettings]: 'Privacy settings',
  [AppProduct.MobileAppCloners]: 'App cloners (mobile)',
  [AppProduct.MobileRecentFactoryReset]: 'Recent factory reset (mobile)',
  [AppProduct.MobileJailbreak]: 'Jailbreak (mobile)',
  [AppProduct.MobileFrida]: 'Frida (mobile)',
  [AppProduct.MobileGeolocationSpoofing]: 'Geolocation spoofing (mobile)',
  [AppProduct.VirtualMachine]: 'Virtual machine',
  [AppProduct.RawDeviceAttributes]: 'Raw device attributes',
}

/** A subscription that has these signals enabled is considered to have access to smart signals. */
export const SMART_SIGNALS = [
  AppProduct.Identification,
  AppProduct.Botd,
  AppProduct.Incognito,
  AppProduct.IpResolution,
  AppProduct.Tampering,
  AppProduct.Vpn,
  AppProduct.PrivacySettings,
  AppProduct.VirtualMachine,
]

/** Products that should be highlighted as smart signals for pro plus subscriptions. */
export const PRO_PLUS_DISPLAY_SIGNALS = [
  AppProduct.Incognito,
  AppProduct.Vpn,
  AppProduct.Botd,
  AppProduct.Tampering,
  AppProduct.VirtualMachine,
  AppProduct.PrivacySettings,
  AppProduct.MobileRootApps,
  AppProduct.MobileEmulator,
  AppProduct.MobileAppCloners,
  AppProduct.MobileJailbreak,
  AppProduct.MobileFrida,
  AppProduct.MobileGeolocationSpoofing,
]

/** Products that should be highlighted as smart signals for enterprise subscriptions. */
export const ENTERPRISE_SMART_SIGNALS = [
  AppProduct.Incognito,
  AppProduct.Vpn,
  AppProduct.Botd,
  AppProduct.Tampering,
  AppProduct.VirtualMachine,
  AppProduct.PrivacySettings,
  AppProduct.MobileRootApps,
  AppProduct.MobileEmulator,
  AppProduct.MobileAppCloners,
  AppProduct.MobileJailbreak,
  AppProduct.MobileFrida,
  AppProduct.MobileGeolocationSpoofing,
  AppProduct.IpBlacklist,
  AppProduct.Tor,
  AppProduct.Proxy,
]

export const DEFAULT_ENVIRONMENT = 'Default environment'
