import { useQuery } from '@tanstack/react-query'

import { extractData, useBuildRequest, useGenericErrorHandler } from './base'

export function useSmartSignal(subscriptionId: string, from?: string, to?: string, enabled = true) {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()

  return useQuery({
    queryKey: ['smart_signals', subscriptionId, from, to] as const,
    queryFn: () =>
      extractData(
        withErrorHandling(
          buildRequest('smartSignalsGet', {
            params: {
              subscriptionId,
            },
            queryParams: {
              from: from!,
              to: to!,
            },
          })
        )
      ),
    enabled: subscriptionId != null && from != null && to != null && enabled,
    staleTime: 3600000, // 1 hour in milliseconds
    gcTime: 3600000, // 1 hour in milliseconds
  })
}
