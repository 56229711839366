import { WithTooltip } from '@compass/WithTooltip'
import { useMemo } from 'react'

import styles from './PlatformFilterIndicator.module.scss'

interface PlatformFilterIndicatorProps {
  platforms: string[]
  hideNonSelected?: boolean
}

export const colorMap: Record<string, string> = {
  ios: '#2196F3',
  android: '#4CAF50',
  js: '#FF9800',
  none: 'hsl(var(--fpds-color-gray-2))',
}

type PlatformKey = 'js' | 'android' | 'ios'
type PlatformName = 'web' | 'Android' | 'iOS'

const platformNames: Record<PlatformKey, PlatformName> = {
  js: 'web',
  android: 'Android',
  ios: 'iOS',
}

const allPlatforms = ['js', 'android', 'ios']

export function PlatformFilterIndicator({ platforms, hideNonSelected = true }: PlatformFilterIndicatorProps) {
  const tooltipText = useMemo(() => buildTooltipText(platforms), [platforms])

  return (
    <WithTooltip content={tooltipText} className='w-max'>
      <span className={styles.platformDiv}>
        {hideNonSelected &&
          platforms.map((signal, index) => (
            <span
              key={index}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: colorMap[signal] || '',
                marginLeft: index === 0 ? '0' : '-5px',
                border: '1px solid white',
              }}
            />
          ))}

        {!hideNonSelected &&
          allPlatforms.map((signal, index) => (
            <span
              key={index}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: platforms.includes(signal) ? colorMap[signal] : colorMap['none'],
                marginLeft: index === 0 ? '0' : '-5px',
                border: '1px solid white',
              }}
            />
          ))}
      </span>
    </WithTooltip>
  )
}

const prefix = 'Signal supported on'
function buildTooltipText(platforms: string[]) {
  const platformStrings = platforms.map((platform: PlatformKey) => platformNames[platform])

  switch (platformStrings.length) {
    case 1:
      return `${prefix} ${platformStrings[0]}`

    case 2:
      return `${prefix} ${platformStrings.join(' and ')}`

    default:
      return `${prefix} ${platformStrings.slice(0, -1).join(', ') + ', and ' + platformStrings[platformStrings.length - 1]}`
  }
}
