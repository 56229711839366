import groupBy from 'lodash/groupBy'
import { ExpandedSubscription, SubscriptionType } from 'models'
import { useMemo } from 'react'

export function useSubscriptionsInfo(subscriptions?: ExpandedSubscription[]) {
  const groupedSubscriptions = useMemo(() => groupBy(subscriptions, 'type'), [subscriptions])

  const { enterpriseSubscriptions, pocSubscriptions, otherSubscriptions } = useMemo(
    () => ({
      enterpriseSubscriptions: groupedSubscriptions[SubscriptionType.Prepaid] ?? [],
      pocSubscriptions: groupedSubscriptions[SubscriptionType.ProofOfConcept] ?? [],
      otherSubscriptions: [
        ...(groupedSubscriptions[SubscriptionType.Paid] ?? []),
        ...(groupedSubscriptions[SubscriptionType.Free] ?? []),
        ...(groupedSubscriptions[SubscriptionType.TrialOnly] ?? []),
      ],
    }),
    [groupedSubscriptions]
  )

  const isAnyEnterpriseSubscriptions = enterpriseSubscriptions?.length > 0
  const isAnyPocSubscriptions = pocSubscriptions?.length > 0
  const isAnyOtherSubscriptions = otherSubscriptions?.length > 0
  const isMixedSubscriptions =
    (isAnyEnterpriseSubscriptions && isAnyPocSubscriptions) ||
    (isAnyEnterpriseSubscriptions && isAnyOtherSubscriptions) ||
    (isAnyOtherSubscriptions && isAnyPocSubscriptions)

  const totalEnterpriseCurrentUsage = useMemo(() => {
    return enterpriseSubscriptions?.reduce((acc, subscription) => {
      acc += subscription.usageQuota?.billed ?? 0
      return acc
    }, 0)
  }, [enterpriseSubscriptions])

  const totalEnterpriseProjectedUsage = useMemo(() => {
    return enterpriseSubscriptions?.reduce((acc, subscription) => {
      acc += subscription.projectedUsage ?? 0
      return acc
    }, 0)
  }, [enterpriseSubscriptions])

  return {
    enterpriseSubscriptions,
    isAnyEnterpriseSubscriptions,
    isAnyPocSubscriptions,
    isAnyOtherSubscriptions,
    isMixedSubscriptions,
    pocSubscriptions,
    otherSubscriptions,
    totalEnterpriseCurrentUsage,
    totalEnterpriseProjectedUsage,
  }
}
