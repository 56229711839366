import { Check } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'

import styles from './FeatureList.module.scss'

export interface Feature {
  name: string
  badge?: string
  tooltip?: string
}

export interface FeatureListProps {
  features: Feature[]
  className?: string
}

export function FeatureList({ features, className }: FeatureListProps) {
  return (
    <ul className={clsx(styles.features, className)}>
      {features.map(({ name, tooltip }) => (
        <li key={name} className={styles.feature}>
          <Check fontSize='medium' className={styles.icon} />
          <Tooltip title={tooltip || ''} arrow placement='top'>
            <Typography
              variant='body3'
              component='span'
              className={tooltip ? 'underline decoration-dotted cursor-help' : ''}
            >
              {name}
            </Typography>
          </Tooltip>
        </li>
      ))}
    </ul>
  )
}
