import { Stack } from '@compass/components'
import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Typography } from '@mui/material'
import { formatNum } from 'helpers/format'
import { ChevronDown, OctagonAlert } from 'lucide-react'
import { IncorrectETLDPlusOneData } from 'models'
import { useState } from 'react'

import { useInvalidEtldPlusOneWithOriginData } from '../../../../../hooks/api/integrations'
import { useCurrentSubscription } from '../../../../subscription'
import { SDKIntegration, ThirdPartyIntegration } from '../../IntegrationMetadata/integrationMetadata'
import MismatchedDomainsDistribution from '../../mismatchedDomainsTable/MismatchedDomainsDistribution'
import { IntendedDomainCallout } from '../intendedDomainCallout/IntendedDomainCallout'
import MismatchedDomainsAccordionSection from '../mismatchedDomainsAccordionSection/MismatchedDomainsAccordionSection'
import MismatchedDomainsCsvExportButton from '../mismatchedDomainsCsvExportButton/MismatchedDomainsCsvExportButton'

export type MismatchedDomainsAccordionProps = {
  data?: IncorrectETLDPlusOneData
  integration: ThirdPartyIntegration | SDKIntegration
  useInvalidEtldPlusOneWithOriginQuery?: typeof useInvalidEtldPlusOneWithOriginData
  isLoading?: boolean
}

export function MismatchedDomainsAccordion({
  data,
  integration,
  isLoading,
  useInvalidEtldPlusOneWithOriginQuery = useInvalidEtldPlusOneWithOriginData,
}: MismatchedDomainsAccordionProps) {
  const { currentSubscriptionId } = useCurrentSubscription()

  const domainEtld = data?.apiEtldPlusOne ?? ''
  const mismatchesCount = data?.invalidRequestsCount ?? 0
  const allRequestsCount = data?.allRequestsCount ?? 0
  const [isOpen, setIsOpen] = useState(false)

  const query = useInvalidEtldPlusOneWithOriginQuery({
    integrationName: integration,
    subscriptionId: currentSubscriptionId,
    apiHostname: data?.apiHostname ?? '',
    enabled: Boolean(isOpen && data),
  })

  return (
    <Accordion
      expanded={isOpen}
      onChange={(_, value) => setIsOpen(value)}
      disableGutters
      elevation={0}
      className='border-0'
      sx={{
        '&.Mui-expanded:before': {
          transition: 'none',
          opacity: 1,
        },
      }}
    >
      <AccordionSummary expandIcon={<ChevronDown size={16} />}>
        <Stack direction='column' gap={0}>
          <Stack direction='column'>
            {isLoading && (
              <Stack direction='row'>
                <Skeleton height={20} variant='text' />
                <Skeleton height={20} variant='text' />
              </Stack>
            )}
            {data && (
              <>
                <Typography variant='bodyMMedium'>{data.apiHostname}</Typography>
                <Stack direction='row'>
                  <Typography variant='bodyS'>
                    {allRequestsCount === 1 ? '1 request' : `${formatNum(allRequestsCount)} requests`}&nbsp;·&nbsp;
                    <span className='text-red-800'>
                      {mismatchesCount === 1 ? '1 mismatch' : `${formatNum(mismatchesCount)} mismatches`}
                    </span>
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {data && (
          <Stack direction='column' gap={6}>
            <IntendedDomainCallout domain={data.apiHostname} domainEtld={domainEtld} />
            <MismatchedDomainsAccordionSection
              action={<MismatchedDomainsCsvExportButton domain={data.apiHostname} data={query.data} />}
              icon={<OctagonAlert size={16} className='text-red-700' />}
              label='Mismatched domains'
            >
              <MismatchedDomainsDistribution isError={query.isError} data={query.data} />
            </MismatchedDomainsAccordionSection>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
