import {
  DefinedUseInfiniteQueryResult,
  InfiniteData,
  keepPreviousData,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { GenericError, isGenericError } from 'const'

import { PaginatedVisitsResponse, Visit, VisitsFilter, VisitsFilterAndDateRange } from '../models'
import { extractData, useBuildRequest } from './api/base'

const DEFAULT_VISIT_QUERY_LIMIT = 20

export type VisitFilter = { key: keyof Visit; value: string }

export type UsePaginatedVisitsParams = {
  subscriptionId: string
  limit?: number
  reverse?: boolean
} & VisitsFilterAndDateRange

export const TOTAL_HITS_MAX_LIMIT = 100
export function useVisitsInfiniteQuery(
  params: UsePaginatedVisitsParams
): DefinedUseInfiniteQueryResult<InfiniteData<PaginatedVisitsResponse>, GenericError> {
  const { subscriptionId, limit, period, since, before, ...rest } = params
  const buildRequest = useBuildRequest()

  const queryFn: QueryFunction<PaginatedVisitsResponse, QueryKey, string | undefined> = ({
    pageParam: paginationKey,
  }) =>
    buildRequest('visitsGet', {
      queryParams: {
        ...rest,
        before: before.endOf('day').valueOf(),
        since: since.startOf('day').valueOf(),
        limit: limit ?? DEFAULT_VISIT_QUERY_LIMIT,
        // In order to optimize the requests, only fetch the total hit counter on the first page.
        ...(paginationKey ? { paginationKey } : { trackTotalHits: TOTAL_HITS_MAX_LIMIT }),
      },
      params: { subscriptionId },
    })
      .then(({ data }) => data)
      .catch((e: GenericError) => {
        // mgmt-api handles old/deactivated subscriptions differently, map it to no value.
        if (isGenericError(e) && e.code === 'value_not_found') {
          return { visits: [] } satisfies PaginatedVisitsResponse
        }

        throw e
      })

  return useInfiniteQuery({
    queryKey: ['visits', params] as const,
    queryFn,
    getNextPageParam: (lastPage) => lastPage.paginationKey,
    initialPageParam: undefined,
    initialData: undefined,
    placeholderData: keepPreviousData,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: params.subscriptionId != null,
  })
}

export function useVisitsByVisitorInfiniteQuery(
  params: { subscriptionId: string; visitorId: string; limit?: number } & Omit<VisitsFilter, 'limit'>
): DefinedUseInfiniteQueryResult<InfiniteData<PaginatedVisitsResponse>, GenericError> {
  const { subscriptionId, visitorId, limit } = params
  const buildRequest = useBuildRequest()

  const queryFn: QueryFunction<PaginatedVisitsResponse, QueryKey, string | undefined> = ({
    pageParam: paginationKey,
  }) =>
    extractData(
      buildRequest('visitorGet', {
        queryParams: {
          limit: limit ?? 10,
          ...(paginationKey ? { paginationKey } : {}),
        },
        params: { subscriptionId, visitorId },
      })
    )

  return useInfiniteQuery({
    queryKey: ['visitor', subscriptionId, visitorId] as const,
    queryFn,
    getNextPageParam: (lastPage) => lastPage.paginationKey,
    initialPageParam: undefined,
    initialData: undefined,
    placeholderData: keepPreviousData,
    enabled: params.subscriptionId != null && visitorId != null && visitorId !== '',
  })
}
