import { Separator, Stack } from '@compass/components'
import { useInvalidEtldPlusOneWithOriginData } from 'hooks/api/integrations'
import { IncorrectETLDPlusOneData } from 'models'

import { SDKIntegration, ThirdPartyIntegration } from '../../IntegrationMetadata/integrationMetadata'
import { IntegrationMismatchedDomainsAlert } from '../integrationMismatchedDomainsAlert/IntegrationMismatchedDomainsAlert'
import { MismatchedDomainsAccordion } from '../mismatchedDomainsAccordion/MismatchedDomainsAccordion'

export type IntegrationMismatchedDomainsSectionProps = {
  useInvalidEtldPlusOneWithOriginQuery?: typeof useInvalidEtldPlusOneWithOriginData
  invalidRequests: IncorrectETLDPlusOneData[]
  integration: ThirdPartyIntegration | SDKIntegration
}

export function IntegrationMismatchedDomainsSection({
  invalidRequests,
  integration,
  useInvalidEtldPlusOneWithOriginQuery,
}: IntegrationMismatchedDomainsSectionProps) {
  return (
    <Stack direction='column' gap={6}>
      <IntegrationMismatchedDomainsAlert />
      <div className='overflow-hidden border rounded'>
        {invalidRequests.map((it, index, arr) => (
          <div key={it.apiHostname}>
            <MismatchedDomainsAccordion
              useInvalidEtldPlusOneWithOriginQuery={useInvalidEtldPlusOneWithOriginQuery}
              integration={integration}
              data={it}
            />
            {index < arr.length - 1 && <Separator />}
          </div>
        ))}
      </div>
    </Stack>
  )
}
