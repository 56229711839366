import { Flex, WithTooltip } from '@compass/components'
import { LinkButton } from '@compass/LinkButton'
import { Paper, Typography } from '@mui/material'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { ContentColumn, GenericEmptyState, SubHeader } from 'features/commonUI'
import { usePermissions } from 'hooks'
import { CircleX as CircleXIcon, Plus as PlusIcon, RefreshCcw as RefreshCcwIcon } from 'lucide-react'
import { ExpandedSubscription } from 'models'
import { ampli } from 'models/ampli'
import { Fragment, ReactNode } from 'react'

import { AppRoute, buildRoute } from '../../../../appRoutes'
import { useSubscriptionsInfo } from '../../hooks/useSubscriptionsInfo'
import { TotalUsage } from '../TotalUsage/TotalUsage'
import { SkeletonWorkspaceTable } from '../WorkspaceTable/SkeletonWorkSpaceTable'
import { WorkspaceTable } from '../WorkspaceTable/WorkspaceTable'
import styles from './ApplicationsOverview.module.scss'

export type ApplicationsOverviewProps = {
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  subscriptions?: ExpandedSubscription[]
  onRetryClick: () => void
}

export default function ApplicationsOverview({
  subscriptions,
  isFetching,
  isLoading,
  isError,
  onRetryClick,
}: ApplicationsOverviewProps) {
  const displayErrorState = !isLoading && isError

  const { isReadOnly, subscriptionPermissions } = usePermissions()
  const canCreateNewWorkspace = subscriptionPermissions.canCreate(subscriptions)
  const {
    enterpriseSubscriptions,
    isAnyEnterpriseSubscriptions,
    isAnyPocSubscriptions,
    isAnyOtherSubscriptions,
    isMixedSubscriptions,
    pocSubscriptions,
    otherSubscriptions,
    totalEnterpriseCurrentUsage,
    totalEnterpriseProjectedUsage,
  } = useSubscriptionsInfo(subscriptions)

  return (
    <>
      <ContentColumn className='gap-6 sm:gap-8'>
        <SubHeader
          title={
            <Typography variant='h2' component='h2'>
              Workspaces
            </Typography>
          }
          description='Workspaces are where teams manage their Fingerprint setup and view device intelligence.'
          actions={
            <ButtonWithTooltipIfDisabled isDisabled={isLoading || isReadOnly || !canCreateNewWorkspace}>
              <LinkButton
                href={buildRoute(AppRoute.AddSecondaryApplication)}
                isDisabled={isLoading || isReadOnly || !canCreateNewWorkspace}
              >
                <PlusIcon />
                New workspace
              </LinkButton>
            </ButtonWithTooltipIfDisabled>
          }
        />

        {isLoading && <SkeletonWorkspaceTable />}

        {displayErrorState && (
          <Paper className='py-8 px-6'>
            <Flex className='items-center gap-4'>
              <GenericEmptyState
                icon={<CircleXIcon />}
                className='py-0 px-0'
                title='Failed to load workspaces'
                description='If this error persists, please contact support.'
                buttonProps={{
                  onPress: onRetryClick,
                  variant: 'secondary',
                  isDisabled: isFetching,
                  children: (
                    <>
                      <RefreshCcwIcon />
                      Retry
                    </>
                  ),
                }}
              />
            </Flex>
          </Paper>
        )}

        {isAnyEnterpriseSubscriptions && (
          <Flex className='gap-4'>
            {enterpriseSubscriptions.map((subscription, index) => {
              return (
                <Fragment key={subscription.id}>
                  {index == 0 && isMixedSubscriptions && <SubHeader title='Enterprise' />}
                  {index == 0 && (
                    <TotalUsage
                      totalUsage={totalEnterpriseCurrentUsage}
                      totalProjectedUsage={totalEnterpriseProjectedUsage}
                      isLoading={isLoading}
                    />
                  )}
                  <WorkspaceTable subscription={subscription} />
                </Fragment>
              )
            })}
          </Flex>
        )}

        {isAnyPocSubscriptions && (
          <Flex className='gap-4'>
            {pocSubscriptions.map((subscription, index) => {
              return (
                <Fragment key={subscription.id}>
                  {index == 0 && isMixedSubscriptions && <SubHeader title='Proof of concept' />}
                  <WorkspaceTable subscription={subscription} />
                </Fragment>
              )
            })}
          </Flex>
        )}

        {isAnyOtherSubscriptions && (
          <Flex className='gap-4'>
            {otherSubscriptions.map((subscription, index) => {
              return (
                <Fragment key={subscription.id}>
                  {index == 0 && isMixedSubscriptions && <SubHeader title={'Other'} />}
                  <WorkspaceTable subscription={subscription} />
                </Fragment>
              )
            })}
          </Flex>
        )}
      </ContentColumn>

      <div className={styles.bannerSection}>
        <DashboardBanner
          variant='info'
          title={
            <Typography component='span' fontWeight={500}>
              Need additional security, support, and enterprise-scale features?
            </Typography>
          }
          action='Talk to Sales'
          actionUrl={AppRoute.ContactSalesEnterprise}
          onClickAction={() => {
            ampli.appCtaClicked({ 'App Page Path': AppRoute.WorkspaceList, ctaName: 'Talk to Sales' })
          }}
        >
          <Typography variant='bodyM'>Talk to our Sales team to learn more about Enterprise pricing.</Typography>
        </DashboardBanner>
      </div>
    </>
  )
}

function ButtonWithTooltipIfDisabled({ children, isDisabled }: { children: ReactNode; isDisabled: boolean }) {
  if (!isDisabled) return children

  return <WithTooltip content='Your current role does not have permission to create workspaces'>{children}</WithTooltip>
}
