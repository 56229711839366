import { Button } from '@compass/components'
import { InfoOutlined } from '@mui/icons-material'
import { Dialog, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import DashboardBanner from 'components/DashboardBanner/DashboardBanner'
import { DialogActions, DialogContent, DialogTitle } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { useToast } from 'hooks'
import { useUserContext } from 'hooks/api/context'
import { useContactSupportMutation } from 'hooks/api/support'
import { useLocalStorage } from 'hooks/localStorage'
import { ContactSupportTopic } from 'models'
import { ampli } from 'models/ampli'
import { useState } from 'react'

const STORAGE_KEY = 'request-more-subdomains-dismissed'

export const CustomSubdomainCTA = () => {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: context } = useUserContext()
  const { showToast } = useToast()
  const { mutate: contactSupport, isPending: isContactingSupport } = useContactSupportMutation()
  const [storedValue, setValue] = useLocalStorage<boolean>(STORAGE_KEY, false)
  const [open, setOpen] = useState(false)

  const onAddSubdomainClick = () => {
    if (isContactingSupport) {
      return
    }
    ampli.appCtaClicked({ 'App Page Path': AppRoute.Subdomains, ctaName: 'Contact us' })

    contactSupport(
      {
        data: {
          email: context?.email!,
          message: `The user with the email ${context?.email} requested support through the custom subdomain page for ${subscriptionId}.`,
          formDetails: ContactSupportTopic.Generic,
        },
      },
      {
        onSuccess: () => {
          setValue(true)
          setOpen(true)
        },
        onError: () => {
          showToast({
            message: 'An error occurred when creating a support ticket. Please try again.',
            severity: 'error',
          })
        },
      }
    )
  }

  if (storedValue) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle onClose={() => setOpen(false)}>
          <Typography variant='h1'>Support request received!</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant='body2'>
            Our team has received your request for additional custom subdomains.
            <br />
            <br />
            We&apos;ll be in touch with you by email shortly.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onPress={() => setOpen(false)} size='lg' autoFocus fullWidth>
            Okay thanks, close this
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div style={{ marginBottom: '24px' }}>
      <DashboardBanner
        variant='warning'
        icon={<InfoOutlined color='primary' />}
        title='Need to use Fingerprint on more domains?'
        action='Request subdomains'
        onClickAction={onAddSubdomainClick}
      >
        Reach out to us to learn more about additional SSL certificates.
      </DashboardBanner>
    </div>
  )
}
