import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js'
import { useMutation } from '@tanstack/react-query'
import { GenericError } from 'const'
import { BillingDetails, CardBrand, PaymentMethod } from 'models'
import { AddPaymentMethodProperties, ampli } from 'models/ampli'
import { useMemo } from 'react'

import { usePaymentMethods } from './api/payment_methods'
import { useToast } from './toast'

/**
 * This hook will create a payment method from stripe card
 * Component should be wrapped with stripe <Elements /> provider
 *
 * @example
 * function component() {
 *  const { createCardPaymentMethod, error } = useStripeCardElement()
 *  return <CardElement />
 * }
 */
export function useStripeCardElement() {
  const stripe = useStripe()
  const elements = useElements()
  const { showToast } = useToast()

  async function createCardPaymentMethod(billingDetails: BillingDetails) {
    if (!stripe || !elements) {
      // this error is generic, yet distinct from the default one below so we can trace it back if need be.
      return Promise.reject({ message: 'An error occurred creating a new payment method' })
    }

    const response = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!,
      billing_details: billingDetails,
    })

    if (response.error) {
      return Promise.reject({ message: response.error.message })
    }

    if (!response.paymentMethod) {
      return Promise.reject()
    }

    return response.paymentMethod
  }

  return useMutation<StripePaymentMethod, GenericError, BillingDetails>({
    mutationFn: createCardPaymentMethod,
    onError: (error: GenericError) => {
      showToast({ severity: 'error', message: error.message ?? 'Failed to create a payment method' })
    },
  })
}

/**
 * This hook will try to find an existing payment method with the given ID, or create one if it doesn't exist
 *
 * @example
 * const [
 *   findOrCreatePaymentMethod,
 *   { isLoading: isPaymentMethodProcessing, error: paymentMethodError },
 * ] = useExistingOrNewPaymentMethod()
 *
 * const paymentMethod = await findOrCreatePaymentMethod(existisngPaymentMethodId, billingDetails)
 */
export function useExistingOrNewPaymentMethod(
  source: AddPaymentMethodProperties['source']
): [
  (existingPaymentMethodId?: string, billingDetails?: BillingDetails) => Promise<PaymentMethod | undefined>,
  { isLoading: boolean; error: GenericError | null },
] {
  const { data: paymentMethods = [], isLoading: paymentMethodsLoading } = usePaymentMethods()
  const { isPending, error, mutateAsync: createPaymentMethod } = useStripeCardElement()

  const findOrCreatePaymentMethod = useMemo(
    () => async (existingPaymentMethodId?: string, billingDetails?: BillingDetails) => {
      let paymentMethod: PaymentMethod | undefined

      // If we have billing details to create a new payment method, that should take priority.
      if (!billingDetails?.name && existingPaymentMethodId) {
        paymentMethod = paymentMethods.find(({ id }) => id === existingPaymentMethodId)
      } else {
        // TODO check billingDetails existence
        const newPaymentMethod = await createPaymentMethod(billingDetails!, {
          onSuccess: () => {
            ampli.addPaymentMethod({ source })
          },
        })

        if (newPaymentMethod && newPaymentMethod.card && newPaymentMethod.billing_details) {
          paymentMethod = {
            stripeId: newPaymentMethod.id,
            cardBrand: newPaymentMethod.card.brand as CardBrand,
            cardLast4: newPaymentMethod.card.last4,
            cardExpMonth: newPaymentMethod.card.exp_month,
            cardExpYear: newPaymentMethod.card.exp_year,
            cardCountry: newPaymentMethod.card.country || undefined,
            cardFunding: newPaymentMethod.card.funding,
            cardholderName: newPaymentMethod.billing_details.name,
          } as PaymentMethod
        }
      }

      return paymentMethod
    },
    [createPaymentMethod, paymentMethods, source]
  )

  return [
    findOrCreatePaymentMethod,
    useMemo(
      () => ({
        isLoading: isPending || paymentMethodsLoading,
        error,
      }),
      [error, isPending, paymentMethodsLoading]
    ),
  ]
}
