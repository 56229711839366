import { cn } from '@compass/utils'
import { Alert, Paper, Skeleton, TableHead, Typography } from '@mui/material'
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query'
import { AppRoute, buildRoute } from 'appRoutes'
import clsx from 'clsx'
import IpLocation from 'components/IpLocation/IpLocation'
import Loader from 'components/Loader/Loader'
import {
  ColumnDescriptor,
  Table,
  TableBody,
  TableBodyData,
  TableBodyEmpty,
  TableBodyError,
  TableBodyLoading,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from 'components/Table/Table'
import { GenericError } from 'const'
import { GenericEmptyState } from 'features/commonUI'
import { useCurrentSubscription, useCurrentSubscriptionData } from 'features/subscription'
import { RouterPath } from 'helpers/types'
import { usePaginationForQuery } from 'hooks/usePaginationForQuery'
import { TOTAL_HITS_MAX_LIMIT } from 'hooks/visits'
import { Meh, SearchX } from 'lucide-react'
import { DateTime } from 'luxon'
import { PaginatedVisitsResponse, Visit } from 'models'
import { Link as RouterLink } from 'react-router-dom'

import { IntegrationStep } from '../../../../models/integrationStatus'
import { LimitSelector } from '../LimitSelector'
import styles from './VisitsTable.module.scss'

export interface VisitsTableProps {
  query: UseInfiniteQueryResult<InfiniteData<PaginatedVisitsResponse>, GenericError>
  emptyAlert?: string
  columns?: ColumnDescriptor<Visit>[]
  selectedRequestId?: string
  withContainer?: boolean
  error?: GenericError | null
  getVisitPath?: (visit: Visit) => RouterPath
  className?: string
  isFiltering?: boolean
  timezone?: string
}

export function VisitsTable({
  query,
  columns = defaultColumns,
  selectedRequestId,
  withContainer,
  error,
  getVisitPath,
  className,
  timezone,
  isFiltering = false,
}: VisitsTableProps) {
  const columnCount = columns?.length ?? defaultColumns.length
  const columnWidth = `${100 / columnCount}%`

  const { data, isFetching } = query
  const {
    values: visits,
    currentPageNumber,
    hasPreviousEntries,
    goToPreviousPage,
    hasNextEntries,
    goToNextPage,
  } = usePaginationForQuery(query, (page) => page.visits)

  const pages = data?.pages

  const shownItemsStart = pages ? (pages[0]?.visits.length ?? 0) * currentPageNumber + 1 : undefined
  const shownItemsEnd =
    shownItemsStart !== undefined && visits !== undefined ? shownItemsStart + visits.length - 1 : undefined
  const totalHits = pages?.[0]?.totalHits
  const totalHitsIsNotExact = totalHits == null || totalHits === TOTAL_HITS_MAX_LIMIT
  const lastPageIsFull = pages?.[0]?.visits.length === visits?.length
  const largestValueOnScreen = Math.max(totalHits ?? 0, shownItemsEnd ?? 0)
  const totalItems =
    totalHits === undefined
      ? undefined
      : `${largestValueOnScreen}${totalHitsIsNotExact && lastPageIsFull && hasNextEntries ? '+' : ''}`
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { subscription } = useCurrentSubscriptionData()
  const hasMadeRequests = subscription?.integrationStep !== IntegrationStep.ApiCalls

  return (
    <TableContainer component={withContainer ? Paper : undefined} className={clsx('relative mt-6 p-0', className)}>
      {totalItems && (
        <Typography
          component='caption'
          variant='bodyM'
          className='text-left py-3 px-4 border-b border-gray-400 w-full flex justify-start sticky left-0'
        >
          {totalItems} events matching
        </Typography>
      )}
      <Table style={{ minWidth: 'unset' }} className='relative my-3'>
        {query.isFetching ? <Loader className={styles.loader} testId='visits-table-loader' /> : null}
        <TableHead>
          <TableRow>
            {visits !== undefined && visits.length === 0
              ? null
              : columns.map(({ label, align }) => (
                  <TableCell key={label} width={columnWidth} align={align ?? 'left'} className='px-4'>
                    {label}
                  </TableCell>
                ))}
          </TableRow>
        </TableHead>

        <TableBody columnCount={columnCount} isLoading={isFetching} showBodyLoader={visits === undefined} error={error}>
          <TableBodyLoading>
            <div style={{ minHeight: 688, display: 'flex', flexDirection: 'column', gap: 36 }}>
              {new Array(10).fill('').map((_, index) => (
                <Skeleton key={index} animation={false} />
              ))}
            </div>
          </TableBodyLoading>
          <TableBodyData>
            {visits?.map((visit) => {
              const isSelected = selectedRequestId === visit.requestId
              const isInteractive = !!getVisitPath && !isSelected

              let rowProps = {}
              if (isInteractive) {
                rowProps = { component: RouterLink, to: getVisitPath(visit) }
              }

              return (
                <TableRow
                  {...rowProps}
                  key={visit.requestId}
                  className={clsx({ [styles.selected]: isSelected })}
                  interactive={isInteractive}
                >
                  {columns.map(({ key, monospace, align }) => {
                    return (
                      <TableCell key={key} align={align ?? 'left'} monospace={monospace} className='px-4'>
                        <>{preprocess(key, visit, timezone)}</>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBodyData>

          <TableBodyEmpty>
            {isFiltering || hasMadeRequests ? (
              <GenericEmptyState
                icon={<SearchX />}
                title='No results were found'
                description='Try expanding the date range, adjusting any filters, or perhaps take a coffee break.'
              />
            ) : (
              <GenericEmptyState
                icon={<Meh />}
                title="There's nothing here"
                description='Events will appear here once your app is sending requests.'
                buttonProps={{
                  children: 'Install Fingerprint now',
                  variant: 'primary',
                  href: buildRoute(AppRoute.Integrations, { subscriptionId }),
                }}
              />
            )}
          </TableBodyEmpty>

          <TableBodyError>
            <Alert severity='error'>{error?.message ?? 'There was an error getting the visits.'}</Alert>
          </TableBodyError>
        </TableBody>
      </Table>

      <TableFooter className='flex flex-col justify-between md:flex-row items-start md:items-center min-h-12 py-3 px-4 mt-6 gap-6 border-t border-gray-400'>
        <div className='flex flex-row items-center gap-2'>
          <Typography variant='bodyM'>Show</Typography> <LimitSelector />
        </div>
        <div className='flex flex-row items-center justify-end gap-4'>
          {shownItemsEnd ? (
            <Typography variant='bodyS'>
              {totalItems === undefined ? (
                <>
                  {shownItemsStart}-{shownItemsEnd} events
                </>
              ) : (
                <>
                  {shownItemsStart}-{shownItemsEnd} of {totalItems} events
                </>
              )}
            </Typography>
          ) : null}
          {!error && (
            <TablePagination
              hasNextEntries={hasNextEntries}
              hasPreviousEntries={hasPreviousEntries}
              onRequestNextEntries={goToNextPage}
              onRequestPreviousEntries={goToPreviousPage}
              isLoading={isFetching}
              className={cn('m-0 p-0 gap-0')}
            />
          )}
        </div>
      </TableFooter>
    </TableContainer>
  )
}

const defaultColumns: ColumnDescriptor<Visit>[] = [
  { key: 'visitorId', label: 'Visitor ID', monospace: true },
  { key: 'ip', label: 'IP Address' },
  { key: 'requestId', label: 'Request ID', monospace: true },
  { key: 'time', label: 'Date' },
]

const preprocess = (key: keyof Visit, visit?: Visit, timezone?: string) => {
  switch (key) {
    case 'time': {
      const time = visit?.time
      return time
        ? DateTime.fromISO(time)
            .setZone(timezone ?? 'UTC')
            .toFormat('MM/dd/yyyy HH:mm:ss')
        : ''
    }

    case 'ip': {
      const ipAddress = visit?.ip
      return ipAddress ? <IpLocation ipAddress={ipAddress} ipLocation={visit?.ipLocation} source='visits' /> : '-'
    }

    default:
      return visit?.[key]
  }
}
