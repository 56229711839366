import { Stack } from '@compass/Layout/Stack'
import { Typography } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'

export type MismatchedDomainsAccordionSectionProps = {
  icon: ReactNode
  label: string
  action?: ReactNode
}

export default function MismatchedDomainsAccordionSection({
  icon,
  label,
  children,
  action,
}: PropsWithChildren<MismatchedDomainsAccordionSectionProps>) {
  return (
    <Stack direction='column' gap={4}>
      <Stack gap={2} className='justify-between'>
        <Stack gap={2} className='h-8 items-center'>
          {icon}
          <Typography variant='bodyMMedium'>{label}</Typography>
        </Stack>
        {action}
      </Stack>
      {children}
    </Stack>
  )
}
