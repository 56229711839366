import { Button } from '@compass/components'
import { cn } from '@compass/utils'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import {
  Alert,
  Skeleton,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
  TableContainer as MuiTableContainer,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TableProps as MuiTableProps,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'
import { OrderLowercase } from 'models'
import { Children, ElementType, PropsWithChildren, ReactElement, ReactNode } from 'react'

import { GenericError } from '../../const'
import { SupportEmail } from '../SupportEmail'
import styles from './Table.module.scss'

export const TableHead = MuiTableHead

export const Table = (props: MuiTableProps) => (
  <MuiTable className={clsx({ root: styles.table }, props.className)} {...props} />
)

const StyledTableRow = withStyles({
  root: {
    textDecoration: 'none',
    '&:first-child': {
      '& .MuiTableCell-root': { borderTop: 'none' },
    },
    '&:last-child': {
      '& .MuiTableCell-root': { borderBottom: 'none' },
    },
  },
})(MuiTableRow)

export interface TableRowProps {
  interactive?: boolean
}

export function TableRow({ interactive, ...props }: MuiTableRowProps & TableRowProps) {
  return <StyledTableRow {...props} className={clsx({ [styles.interactiveRow]: interactive }, props.className)} />
}

const StyledTableCell = withStyles({
  root: {
    paddingLeft: 8,
    paddingRight: 8,
    '&:last-child': {
      paddingRight: 8,
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  body: { paddingTop: 16, paddingBottom: 16, fontSize: 14, fontWeight: 400, color: '#212121' },
  head: {
    paddingTop: 0,
    paddingBottom: 8,
    textTransform: 'uppercase',
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
})(MuiTableCell)

export interface TableCellProps {
  monospace?: boolean
}

export function TableCell({ monospace, ...props }: MuiTableCellProps & TableCellProps) {
  return <StyledTableCell {...props} className={clsx({ [styles.monospace]: monospace }, props.className)} />
}

export interface TableContainerProps {
  component?: ElementType
  className?: string
}

export function TableContainer({ children, component, className }: PropsWithChildren<TableContainerProps>) {
  return (
    <MuiTableContainer
      component={component ?? 'div'}
      className={cn({ ['flex flex-col justify-between p-6']: Boolean(component) }, className)}
    >
      {children}
    </MuiTableContainer>
  )
}

export function TableBodyData({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

export function TableBodyLoading({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

export function TableBodyEmpty({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

export function TableBodyError({ children }: PropsWithChildren<{}>) {
  return <>{children}</>
}

function DefaultTableBodyLoading() {
  return <Skeleton data-testid='table-loading-skeleton' />
}

function DefaultTableBodyEmpty() {
  return <Alert severity='info'>No data.</Alert>
}

interface DefaultTableBodyErrorProps {
  error?: GenericError | string | null
}

function DefaultTableBodyError({ error }: DefaultTableBodyErrorProps) {
  const errorMessage = typeof error === 'string' ? error : error?.message

  return (
    <Alert severity='error'>
      {errorMessage ?? (
        <>
          Something went wrong. Please try again or contact <SupportEmail />
        </>
      )}
    </Alert>
  )
}

export interface TableBodyProps {
  columnCount: number
  children?: ReactElement | ReactElement[]
  isLoading?: boolean
  showBodyLoader?: boolean
  error?: GenericError | string | null
}

export function TableBody({ columnCount, isLoading, showBodyLoader = true, error, children }: TableBodyProps) {
  let loadingBody = <DefaultTableBodyLoading />
  let emptyBody = <DefaultTableBodyEmpty />
  let errorBody = <DefaultTableBodyError error={error} />
  let dataBody: ReactElement | undefined

  if (children) {
    Children.map(children, (child) => {
      switch (child.type) {
        case TableBodyLoading:
          loadingBody = child
          break
        case TableBodyEmpty:
          emptyBody = child
          break
        case TableBodyError:
          errorBody = child
          break
        case TableBodyData:
          dataBody = child
      }
    })
  }

  let status: ReactElement | undefined

  if (isLoading && showBodyLoader) {
    status = loadingBody
  } else if (error) {
    status = errorBody
  } else if (!dataBody || Children.count(dataBody.props.children) === 0) {
    status = emptyBody
  }

  return (
    <MuiTableBody>
      {status ? (
        <TableRow>
          <TableCell colSpan={columnCount} className={styles.statusCell}>
            {status}
          </TableCell>
        </TableRow>
      ) : (
        <>{dataBody}</>
      )}
    </MuiTableBody>
  )
}

export interface TableHeaderProps {
  title: string
  actions?: ReactNode | ReactNode[]
}

export function TableHeader({ title, actions }: TableHeaderProps) {
  return (
    <thead className={styles.header}>
      <Typography variant='h2'>{title}</Typography>

      {actions}
    </thead>
  )
}

export interface TablePaginationProps {
  hasPreviousEntries?: boolean
  hasNextEntries?: boolean
  isLoading?: boolean
  onRequestPreviousEntries?: () => void
  onRequestNextEntries?: () => void
  className?: string
}

export function TablePagination({
  hasPreviousEntries,
  hasNextEntries,
  isLoading,
  onRequestPreviousEntries,
  onRequestNextEntries,
  className,
}: TablePaginationProps) {
  if (!hasPreviousEntries && !hasNextEntries) {
    return null
  }

  return (
    <nav className={cn('flex mt-6 gap-2', className)}>
      <Button
        variant='ghost'
        isIcon
        aria-label='Previous page'
        isDisabled={isLoading || !hasPreviousEntries}
        onPress={onRequestPreviousEntries}
      >
        <NavigateBefore />
      </Button>

      <Button
        variant='ghost'
        isIcon
        aria-label='Next page'
        isDisabled={isLoading || !hasNextEntries}
        onPress={onRequestNextEntries}
      >
        <NavigateNext />
      </Button>
    </nav>
  )
}

export const TableFooter = ({ className, ...props }: PropsWithChildren<{ className?: string }>) => {
  return <MuiTableFooter {...props} className={clsx('w-full flex justify-end sticky left-0 mt-auto', className)} />
}

export interface ColumnDescriptor<T> extends MuiTableCellProps {
  key: keyof T
  label: string
  monospace?: boolean
  sortable?: boolean
}

export type TableHeadCellProps<Entity> = {
  columnId: keyof Entity
  orderBy?: keyof Entity
  order?: OrderLowercase
  handleSort: (orderBy: keyof Entity, order: OrderLowercase) => void
  className?: string
} & MuiTableCellProps

export function SortableTableHeadCell<Entity>({
  columnId,
  orderBy,
  order,
  handleSort,
  className,
  children,
  ...props
}: PropsWithChildren<TableHeadCellProps<Entity>>) {
  const isSortedByColumn = columnId === orderBy
  return (
    <TableCell
      className={clsx(styles.headCell, className)}
      sortDirection={isSortedByColumn ? order : undefined}
      {...props}
    >
      <TableSortLabel
        active={isSortedByColumn}
        direction={isSortedByColumn ? order : 'desc'}
        onClick={() => handleSort(columnId, order === 'desc' ? 'asc' : 'desc')}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  )
}
