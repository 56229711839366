import { Button } from '@compass/components'
import { ArrowBack } from '@mui/icons-material'
import { Alert, Grid } from '@mui/material'
import { AppRoute, buildRoute } from 'appRoutes'
import { Header, MainGrid } from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import { SubscriptionTier, SubscriptionType } from 'models'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import FullpageLoader from '../../../../components/Loader/FullpageLoader/FullpageLoader'
import { SUBSCRIPTION_DISPLAY_PLANS, SubscriptionDisplayPlan } from '../../../../const'
import { getSubscriptionDisplayPlan } from '../../../../helpers/subscription'
import { trackSubscriptionCanceled } from '../../../../helpers/vendor'
import {
  useConfirmationDialog,
  useDocumentTitle,
  usePermissions,
  useSubscription,
  useSubscriptionDelete,
} from '../../../../hooks'
import { useQueryParams } from '../../../../hooks/queryParams'
import CancelSubscription from '../../components/ChangePlan/Cancel'
import { ChangePlanStep } from '../../components/ChangePlan/ChangePlan'
import Downgrade from '../../components/ChangePlan/Downgrade'
import Upgrade from '../../components/ChangePlan/Upgrade'
import styles from './ChangePlan.module.scss'

export enum ChangePlanAction {
  UpgradeToPro = 'upgrade_pro',
  UpgradeToPlus = 'upgrade_plus',
  UpgradeToPlus99 = 'upgrade_plus_q1_2024',
  UpgradeToPlus99AndroidFreemium = 'upgrade_plus_android_freemium_q1_2025',

  DowngradeToPro = 'downgrade_pro',

  Cancel = 'cancel',
}

export function ChangePlanPage() {
  useDocumentTitle('Change plan')

  const { action } = useQueryParams() as { action: ChangePlanAction }
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription(subscriptionId)
  const { subscriptionPermissions } = usePermissions()
  const canCancelSubscription = subscription && subscriptionPermissions.canCancel(subscription)
  const currentPlan =
    (!isLoadingSubscription && subscription && getSubscriptionDisplayPlan(subscription)) || SubscriptionDisplayPlan.Pro
  const history = useHistory()
  const { openDialog } = useConfirmationDialog()
  const { mutate: sendSubscriptionCancelRequest, isPending: isCancelingSubscription } =
    useSubscriptionDelete(subscription)
  const isLoading = isLoadingSubscription || isCancelingSubscription

  const [step, setStep] = useState(ChangePlanStep.Overview)

  function handleGoBack() {
    const actionTakesFeedback = [ChangePlanAction.DowngradeToPro].includes(action)

    let target = step - 1
    if (!actionTakesFeedback && target === ChangePlanStep.Feedback) {
      --target
    }

    if (target < ChangePlanStep.Overview) {
      history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
    } else {
      setStep(target)
    }
  }

  // If the current action is invalid for the subscription's state, go back to the plan page.
  // Prevents users from making invalid change requests by editing the URL manually.
  useEffect(() => {
    // If the subscription id is not set, or the step is confirmation, do not redirect.
    if (!subscriptionId || step === ChangePlanStep.Confirmation) {
      return
    }

    const isTrialSubscription = subscription?.type === SubscriptionType.TrialOnly

    switch (action) {
      case ChangePlanAction.UpgradeToPro:
        if (currentPlan === SubscriptionDisplayPlan.Pro && !isTrialSubscription) {
          return history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
        }
        break
      case ChangePlanAction.UpgradeToPlus:
        if (currentPlan === SubscriptionDisplayPlan.Plus && !isTrialSubscription) {
          return history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
        }
        break
      case ChangePlanAction.UpgradeToPlus99:
        if (currentPlan === SubscriptionDisplayPlan.Plus99 && !isTrialSubscription) {
          return history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
        }
        break
      case ChangePlanAction.UpgradeToPlus99AndroidFreemium:
        if (currentPlan === SubscriptionDisplayPlan.Plus99AndroidFreemium && !isTrialSubscription) {
          return history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
        }
        break
      case ChangePlanAction.DowngradeToPro:
        if (currentPlan !== SubscriptionDisplayPlan.Plus && currentPlan !== SubscriptionDisplayPlan.Plus99) {
          return history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
        }
        break
      case ChangePlanAction.Cancel:
        if (!canCancelSubscription) {
          return history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
        }
        break
      default:
        return history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
    }
  }, [action, history, subscriptionId, currentPlan, canCancelSubscription, subscription, step])

  function handleCancelSubscription() {
    openDialog({
      label: 'Cancel?',
      content: <Alert severity='warning'>Cancelling will delete all of your data and it cannot be recovered.</Alert>,
      onConfirm: () =>
        sendSubscriptionCancelRequest(
          { params: { id: subscriptionId } },
          {
            onSuccess: () => {
              trackSubscriptionCanceled(subscription!.type)
              history.push(buildRoute(AppRoute.SubscriptionPlan, { subscriptionId }))
            },
          }
        ),
    })
  }

  return (
    <>
      <Header title='Plan & usage' />
      <MainGrid>
        {step !== ChangePlanStep.Confirmation && (
          <Grid item xs={12}>
            <Button onPress={handleGoBack} variant='ghost'>
              <ArrowBack />
              {step === ChangePlanStep.Overview ? 'Plan' : 'Back'}
            </Button>
          </Grid>
        )}

        <Grid item xs={12} className={styles.content}>
          {action === ChangePlanAction.UpgradeToPro && (
            <Upgrade
              newPlan={SubscriptionTier.Pro}
              step={step}
              promotionId={subscription?.promotions?.find((el) => el.priceLookupKey === SubscriptionTier.Pro)?.id}
              onChangeStep={setStep}
            />
          )}

          {action === ChangePlanAction.UpgradeToPlus && (
            <Upgrade
              newPlan={SubscriptionTier.Plus}
              step={step}
              promotionId={subscription?.promotions?.find((el) => el.priceLookupKey === SubscriptionTier.Plus)?.id}
              onChangeStep={setStep}
            />
          )}

          {action === ChangePlanAction.UpgradeToPlus99 && (
            <Upgrade
              newPlan={SubscriptionTier.Plus99}
              step={step}
              promotionId={subscription?.promotions?.find((el) => el.priceLookupKey === SubscriptionTier.Plus)?.id}
              onChangeStep={setStep}
            />
          )}

          {action === ChangePlanAction.UpgradeToPlus99AndroidFreemium && (
            <Upgrade
              newPlan={SubscriptionTier.Plus99AndroidFreemium}
              step={step}
              promotionId={subscription?.promotions?.find((el) => el.priceLookupKey === SubscriptionTier.Plus)?.id}
              onChangeStep={setStep}
            />
          )}

          {action === ChangePlanAction.DowngradeToPro && (
            <Downgrade currentPlan={currentPlan} step={step} onChangeStep={setStep} />
          )}

          {action === ChangePlanAction.Cancel && (
            <CancelSubscription
              subscriptionId={subscriptionId}
              currentPlan={SUBSCRIPTION_DISPLAY_PLANS[currentPlan].name}
              onCancel={handleCancelSubscription}
              isLoading={isCancelingSubscription}
            />
          )}
        </Grid>
        {isLoading && <FullpageLoader />}
      </MainGrid>
    </>
  )
}
