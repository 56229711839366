import { SubscriptionDistribution } from '../../../../models'

/** Label keys for selecting the label value from the object. */
export const LABEL_KEY_MAPPING: Record<SubscriptionDistribution, string> = {
  [SubscriptionDistribution.BrowserCounters]: 'browserName',
  [SubscriptionDistribution.ClientTypeCounters]: 'clientType',
  [SubscriptionDistribution.ClientVersionCounters]: 'clientInformation',
  [SubscriptionDistribution.CountryCounters]: 'IpInfo',
  [SubscriptionDistribution.DomainCounters]: 'domain',
  // [SubscriptionDistribution.Ipv4Counters]: 'ipv4',
  [SubscriptionDistribution.OperatingSystemCounters]: 'operatingSystem',
  [SubscriptionDistribution.UrlCounters]: 'url',
}
/** Label above the keys in the distribution row. */
export const CAPTION_MAPPING: Record<SubscriptionDistribution, string> = {
  [SubscriptionDistribution.BrowserCounters]: 'browser',
  [SubscriptionDistribution.ClientTypeCounters]: 'client type',
  [SubscriptionDistribution.ClientVersionCounters]: 'client version',
  [SubscriptionDistribution.CountryCounters]: 'country',
  [SubscriptionDistribution.DomainCounters]: 'domain',
  // [SubscriptionDistribution.Ipv4Counters]: 'IP',
  [SubscriptionDistribution.OperatingSystemCounters]: 'OS',
  [SubscriptionDistribution.UrlCounters]: 'URL',
}
/** Header title next to the info icon. */
export const HEADER_MAPPING: Record<SubscriptionDistribution, string> = {
  [SubscriptionDistribution.BrowserCounters]: 'Top browsers',
  [SubscriptionDistribution.ClientTypeCounters]: 'Top client types',
  [SubscriptionDistribution.ClientVersionCounters]: 'Top client versions',
  [SubscriptionDistribution.CountryCounters]: 'Top countries',
  [SubscriptionDistribution.DomainCounters]: 'Top origins',
  // [SubscriptionDistribution.Ipv4Counters]: 'Top IP addresses',
  [SubscriptionDistribution.OperatingSystemCounters]: 'Top operating systems',
  [SubscriptionDistribution.UrlCounters]: 'Top URLs',
}
/** Header info icon tooltip. */
export const INFO_MAPPING: Record<SubscriptionDistribution, string> = {
  [SubscriptionDistribution.BrowserCounters]: 'Number of events per specific browser.',
  [SubscriptionDistribution.ClientTypeCounters]: 'API usage by client type.',
  [SubscriptionDistribution.ClientVersionCounters]: 'API usage by client version.',
  [SubscriptionDistribution.CountryCounters]: 'Number of events per country.',
  [SubscriptionDistribution.DomainCounters]: 'Number of events per origin domain or mobile app.',
  // [SubscriptionDistribution.Ipv4Counters]: 'Number of events recorded per IP address.',
  [SubscriptionDistribution.OperatingSystemCounters]: 'Number of events per specific operating system.',
  [SubscriptionDistribution.UrlCounters]: 'Number of events originating from specific URLs.',
}
/** Option label in the switch menu. */
export const OPTION_MAPPING: Record<SubscriptionDistribution, string> = {
  [SubscriptionDistribution.BrowserCounters]: 'Browser',
  [SubscriptionDistribution.ClientTypeCounters]: 'Client type',
  [SubscriptionDistribution.ClientVersionCounters]: 'Client version',
  [SubscriptionDistribution.CountryCounters]: 'Country',
  [SubscriptionDistribution.DomainCounters]: 'Origin',
  // [SubscriptionDistribution.Ipv4Counters]: 'IP',
  [SubscriptionDistribution.OperatingSystemCounters]: 'Operating system',
  [SubscriptionDistribution.UrlCounters]: 'URL',
}
