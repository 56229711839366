import { Button } from '@compass/components'
import { Add } from '@mui/icons-material'
import { Alert, AlertTitle, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Dialog, DialogTitle } from 'features/commonUI'
import { Pencil } from 'lucide-react'
import { DateTime } from 'luxon'
import { ReactNode, useCallback, useMemo, useState } from 'react'

import { DOCS_INTEGRATIONS, SUPPORT_EMAIL_MAILTO, SUPPORT_PAGE_URL } from '../../../../const'
import { CloudflareIntegration, ProxyIntegrationRequests } from '../../../../models'
import { extractCloudflareAuthError } from '../../cloudflareHelpers'
import CloudflareConfigurationValues from '../../components/CloudflareConfigurationValues/CloudflareConfigurationValues'
import { IntegrationCloudflareEditProps } from '../../components/IntegrationCloudflareModal/Edit/IntegrationCloudflareEdit'
import IntegrationCloudflareModal from '../../components/IntegrationCloudflareModal/IntegrationCloudflareModal'
import Logo from '../../components/IntegrationMetadata/icons/cloudflare.svg'
import { ThirdPartyIntegration } from '../../components/IntegrationMetadata/integrationMetadata'
import IntegrationNoUsageNotice from '../../components/IntegrationPage/integrationNoUsageNotice/IntegrationNoUsageNotice'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import IntegrationPageSection from '../../components/IntegrationPage/IntegrationPageSection/IntegrationPageSection'
import MismatchedDomainsSection, {
  MismatchedDomainsSectionProps,
} from '../../components/IntegrationPage/mismatchedDomainsSection/MismatchedDomainsSection'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import { ProxyIntegrationUsageChart } from '../../components/UsageChart/ProxyIntegrationUsageChart'
import CloudflareDescription from './CloudflareDescription'
import styles from './IntegrationCloudflareContents.module.scss'
import { IntegrationCloudflareRemoval } from './IntegrationCloudflareRemoval'

export type IntegrationCloudflareContentsProps = Pick<IntegrationCloudflareEditProps, 'validateToken'> &
  Omit<MismatchedDomainsSectionProps, 'integration'> & {
    status: IntegrationStatus
    error?: string
    subscriptionId: string
    cloudflareData?: CloudflareIntegration | null
    isLoading?: boolean
    onUpdate?: (apiToken: string) => Promise<void>
    onRemove: () => Promise<void>
    isDialogLoading?: boolean
    requestsMade?: ProxyIntegrationRequests
  }

const TOKEN_PERMISSIONS_URL = `${DOCS_INTEGRATIONS.cloudflare}#cloudflare-api-token`

export default function IntegrationCloudflareContents({
  status,
  cloudflareData,
  isLoading,
  subscriptionId,
  error,
  onUpdate,
  isDialogLoading,
  validateToken,
  onRemove,
  requestsMade,
  hasMismatchedDomainsError,
  useInvalidEtldPlusOneWithOriginQuery,
  incorrectETLDPlusOneRequests,
}: IntegrationCloudflareContentsProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const openRemovalModal = useCallback(() => setIsRemoving(true), [])
  const closeRemovalModal = useCallback(() => setIsRemoving(false), [])
  const closeDialog = useCallback(() => setIsDialogOpen(false), [])
  const openDialog = useCallback(() => setIsDialogOpen(true), [])

  const chartVisible =
    requestsMade &&
    requestsMade.proxyRequests?.length > 0 &&
    requestsMade.proxyRequests.map((it) => it.value).reduce((sum, curr) => sum + curr, 0) > 0
  const lastXDays = Math.min(requestsMade?.allRequests?.length ?? 0, 30)

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const authError = extractCloudflareAuthError(error)

  const statusLabel = useMemo(() => {
    switch (status) {
      case IntegrationStatus.ChangesPending:
        return `The API token that was recently provided is being updated. If successful, this badge will change back to green! `

      case IntegrationStatus.RemovalPending:
        return 'We are removing the associated Cloudflare Workers in the background. Check back in a few minutes.'

      case IntegrationStatus.Installed:
        return `Kudos! The integration has been successfully installed.`

      case IntegrationStatus.IssueDetected:
        return authError ? (
          `The API token provided is not valid. See below for details.`
        ) : (
          <>
            Error occurred, please{' '}
            <Link color='inherit' href={SUPPORT_PAGE_URL} target='_blank'>
              contact support
            </Link>{' '}
            for more details.
          </>
        )

      default:
        return null
    }
  }, [authError, status])

  const alertProps = useMemo(() => {
    if (cloudflareData?.lastRemovalError) {
      return {
        title: 'Deletion failed',
        content: (
          <Typography>
            We couldn&apos;t remove this integration as you requested. Please try again or{' '}
            <Link href={SUPPORT_EMAIL_MAILTO} color='inherit'>
              contact support
            </Link>{' '}
            if this issue persists.
          </Typography>
        ),
      }
    }

    if (!error) {
      return undefined
    }

    if (authError) {
      return {
        title: `The Cloudflare API token you're using seems to be invalid`,
        content: (
          <>
            To fix this, please add a token that holds the{' '}
            <Link color='inherit' href={TOKEN_PERMISSIONS_URL} target='_blank'>
              required permissions
            </Link>
            . Otherwise, this integration may fail to work properly.
          </>
        ),
      }
    }

    return {
      title: 'An error occurred in your integration',
      content: error,
    }
  }, [authError, error, cloudflareData])

  const sections = useMemo<ReactNode[]>(() => {
    const result: ReactNode[] = [
      <MismatchedDomainsSection
        hasMismatchedDomainsError={hasMismatchedDomainsError}
        useInvalidEtldPlusOneWithOriginQuery={useInvalidEtldPlusOneWithOriginQuery}
        integration={ThirdPartyIntegration.Cloudflare}
        key='mismatched-domains'
        incorrectETLDPlusOneRequests={incorrectETLDPlusOneRequests}
      />,
    ]

    // Handle the case where the integration is not installed
    if (status === IntegrationStatus.NotInstalled) {
      if (chartVisible) {
        result.push(
          <IntegrationPageSection key='usage' title='Cloudflare Usage' info={`Last ${lastXDays} Days`}>
            <ProxyIntegrationUsageChart requests={requestsMade} integrationName='Cloudflare' />
          </IntegrationPageSection>
        )
      } else {
        result.push(
          <IntegrationPageSection key='overview'>
            <CloudflareDescription />
          </IntegrationPageSection>
        )
      }

      return result
    }

    // Handle the case where the integration is installed or other statuses
    if (chartVisible) {
      result.push(
        <IntegrationPageSection key='usage' title='Cloudflare Usage' info='Last 30 Days'>
          <ProxyIntegrationUsageChart requests={requestsMade} integrationName='Cloudflare' />
        </IntegrationPageSection>
      )
    } else {
      result.push(
        <IntegrationPageSection key='usage' title='Cloudflare Usage'>
          <IntegrationNoUsageNotice docsLink={DOCS_INTEGRATIONS.cloudflare} />
        </IntegrationPageSection>
      )
    }

    result.push(
      <IntegrationPageSection
        key='configuration'
        title='Configuration'
        description='Below is your Cloudflare worker configuration. Note, only the token is editable.'
        actions={
          <Button
            isDisabled={status === IntegrationStatus.ChangesPending}
            size='lg'
            fullWidth={smDown}
            onPress={openDialog}
            variant='outline'
          >
            <Pencil />
            Edit API Token
          </Button>
        }
      >
        {alertProps ? (
          <Alert className={styles.alert} severity='error'>
            <AlertTitle>{alertProps.title}</AlertTitle>
            {alertProps.content}
          </Alert>
        ) : null}
        <CloudflareConfigurationValues authError={authError} integrationData={cloudflareData!} />
      </IntegrationPageSection>
    )

    result.push(
      <IntegrationPageSection key='danger' title='Danger zone' isDanger>
        <Button
          isDisabled={status === IntegrationStatus.RemovalPending}
          onPress={openRemovalModal}
          variant='destructive'
        >
          Delete integration
        </Button>
      </IntegrationPageSection>
    )

    return result
  }, [
    hasMismatchedDomainsError,
    useInvalidEtldPlusOneWithOriginQuery,
    incorrectETLDPlusOneRequests,
    status,
    chartVisible,
    alertProps,
    authError,
    cloudflareData,
    smDown,
    openDialog,
    openRemovalModal,
    lastXDays,
    requestsMade,
  ])

  return (
    <>
      <IntegrationPage
        statusLabel={statusLabel}
        sections={sections}
        docsUrl={DOCS_INTEGRATIONS.cloudflare}
        repoUrl='https://github.com/fingerprintjs/fingerprintjs-pro-cloudflare-worker'
        isLoading={isLoading}
        integrationStatus={status}
        connectButton={
          <Button onPress={openDialog}>
            <Add fontSize='inherit' />
            Connect
          </Button>
        }
        Logo={Logo}
        title='Cloudflare'
        subscriptionId={subscriptionId}
        metadata={
          cloudflareData && status !== IntegrationStatus.NotInstalled
            ? [
                {
                  title: 'Last deployed on',
                  value: cloudflareData.lastDeploymentCompletedAt
                    ? DateTime.fromJSDate(new Date(cloudflareData.lastDeploymentCompletedAt)).toFormat('MMM d, yyyy')
                    : undefined,
                },
                {
                  title: 'Last version',
                  value: cloudflareData.lastVersion,
                },
              ]
            : []
        }
      />
      {cloudflareData ? (
        <Dialog open={isRemoving} onClose={closeRemovalModal} scroll='body'>
          <DialogTitle onClose={closeRemovalModal} />
          <div className={styles.dialog}>
            <IntegrationCloudflareRemoval
              onClose={closeRemovalModal}
              integration={cloudflareData}
              onRemove={onRemove}
            />
          </div>
        </Dialog>
      ) : null}
      <Dialog isLoading={isDialogLoading} open={isDialogOpen} onClose={closeDialog} scroll='body'>
        <DialogTitle onClose={closeDialog} />
        <div className={styles.dialog}>
          <IntegrationCloudflareModal
            isAuthError={Boolean(authError)}
            validateToken={validateToken}
            onSubmit={onUpdate}
            onClose={closeDialog}
          />
        </div>
      </Dialog>
    </>
  )
}
