import { CircleCheck, Info, OctagonAlert, TriangleAlert, X } from 'lucide-react'
import { tv, VariantProps } from 'tailwind-variants'

import { ButtonOrLink, ButtonOrLinkProps } from '../ButtonOrLink'
import { Button } from '../components'
import { cn } from '../utils'

export type AlertProps = {
  className?: string
  onClose?: () => void
  icon?: React.ReactNode
  actionProps?: ButtonOrLinkProps
  children: React.ReactNode
}

export const alertStyles = tv({
  base: [
    'flex items-center justify-between',
    'rounded-sm border',
    'selection:text-white',
    '[&_svg]:size-4 [&_svg]:text-inherit [&_svg]:flex-shrink-0',
  ],
  variants: {
    variant: {
      info: 'bg-blue-200 border-blue-400 text-blue-800 selection:bg-blue-800',
      error: 'bg-red-200 border-red-400 text-red-800 selection:bg-red-800',
      primary: 'bg-orange-200 border-orange-400 text-orange-800 selection:bg-orange-700',
      secondary: 'bg-gray-200 border-gray-400 text-gray-800 selection:bg-orange-700',
      special: 'bg-purple-200 border-purple-400 text-purple-800 selection:bg-purple-800',
      success: 'bg-green-200 border-green-400 text-green-800 selection:bg-green-800',
      warning: 'bg-yellow-200 border-yellow-400 text-yellow-900 selection:bg-yellow-700',
    },
    alt: {
      true: 'bg-transparent',
      false: 'shadow-inset-t-white-200',
    },
    size: {
      default: 'gap-3 pl-4 pr-3 py-3 text-base',
      sm: 'gap-2 pl-3 pr-2 py-2 text-sm',
    },
  },
  defaultVariants: {
    variant: 'secondary',
    size: 'default',
  },
})

const defaultIcons = {
  info: <Info />,
  error: <OctagonAlert />,
  primary: <Info />,
  secondary: <Info />,
  special: <Info />,
  success: <CircleCheck />,
  warning: <TriangleAlert />,
}

export function Alert({
  variant = 'secondary',
  size,
  alt,
  className,
  onClose,
  icon = defaultIcons[variant],
  actionProps,
  children,
}: AlertProps & VariantProps<typeof alertStyles>) {
  return (
    <div role='alert' className={cn(alertStyles({ variant, size, alt }), className)}>
      <div className={`flex items-center flex-1 ${size === 'sm' ? 'gap-2' : 'gap-3'}`}>
        {icon}
        <div className='flex-1'>{children}</div>
      </div>

      {actionProps && (
        <div className='flex-shrink-0'>
          <ButtonOrLink {...actionProps} alt={actionProps.alt ?? alt} variant={actionProps.variant ?? 'secondary'} />
        </div>
      )}

      {onClose && (
        <Button
          isIcon
          variant='ghost'
          className='hover:bg-black/[0.05] pressed:bg-black/10 disabled:text-gray-600'
          onPress={onClose}
          aria-label='Dismiss'
        >
          <X />
        </Button>
      )}
    </div>
  )
}
