import { Button, LinkButton } from '@compass/components'
import { ErrorOutline } from '@mui/icons-material'
import { Alert, AlertTitle, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import { Pencil } from 'lucide-react'
import { ReactNode, useCallback, useMemo, useState } from 'react'

import { DOCS_INTEGRATIONS, SUPPORT_EMAIL_MAILTO, SUPPORT_PAGE_URL } from '../../../../const'
import {
  CloudFrontIntegration,
  CloudFrontIntegrationDeploymentStatus,
  ProxyIntegrationRequests,
  SdkHealthSdkDetails,
} from '../../../../models'
import { Dialog, DialogTitle } from '../../../commonUI'
import { SemanticVersion } from '../../../health/utils/SemanticVersion'
import IntegrationKeyValueList from '../../components/IntegrationKeyValueList/IntegrationKeyValueList'
import Logo from '../../components/IntegrationMetadata/icons/aws.svg'
import { ThirdPartyIntegration } from '../../components/IntegrationMetadata/integrationMetadata'
import IntegrationNoUsageNotice from '../../components/IntegrationPage/integrationNoUsageNotice/IntegrationNoUsageNotice'
import IntegrationPage from '../../components/IntegrationPage/IntegrationPage'
import IntegrationPageSection from '../../components/IntegrationPage/IntegrationPageSection/IntegrationPageSection'
import MismatchedDomainsSection, {
  MismatchedDomainsSectionProps,
} from '../../components/IntegrationPage/mismatchedDomainsSection/MismatchedDomainsSection'
import { IntegrationStatus } from '../../components/IntegrationPage/types'
import { IntegrationRemovalDialog } from '../../components/IntegrationRemovalDialog/IntegrationRemovalDialog'
import { ProxyIntegrationUsageChart } from '../../components/UsageChart/ProxyIntegrationUsageChart'
import IntegrationCloudFrontConnectModal from './IntegrationCloudFrontConnectModal/IntegrationCloudFrontConnectModal'
import styles from './IntegrationCloudFrontContents.module.scss'

export type IntegrationCloudFrontContentsProps = Omit<MismatchedDomainsSectionProps, 'integration'> & {
  subscriptionId: string
  health?: SdkHealthSdkDetails
  isLoading?: boolean
  isConnecting?: boolean
  isDeleting?: boolean
  cloudFrontData?: CloudFrontIntegration
  connectCloudFrontIntegration: (managementLambdaURL: string, apiToken: string) => void
  disconnectCloudFrontIntegration: () => void
  requestsMade?: ProxyIntegrationRequests
}

function extractStatusFromFromCloudFrontData(cloudFrontData?: CloudFrontIntegration): IntegrationStatus {
  if (!cloudFrontData) {
    return IntegrationStatus.UpdatesDisabled
  }

  switch (cloudFrontData.lastDeploymentStatus) {
    default:
    case CloudFrontIntegrationDeploymentStatus.Completed:
      return IntegrationStatus.UpdatedEnabled
    case CloudFrontIntegrationDeploymentStatus.InProgress:
      return IntegrationStatus.ChangesPending
    case CloudFrontIntegrationDeploymentStatus.Error:
      return IntegrationStatus.IssueDetected
  }
}

function extractStatusLabelFromStatus(integrationStatus: IntegrationStatus): string {
  switch (integrationStatus) {
    case IntegrationStatus.UpdatedEnabled:
      return `Kudos! Your integration will be kept up-to-date automatically.`
    default:
    case IntegrationStatus.UpdatesDisabled:
      return `Click (or tap) the “View documentation” button to get started.`
  }
}

export function IntegrationCloudFrontContents({
  subscriptionId,
  isLoading,
  health,
  requestsMade,
  cloudFrontData,
  connectCloudFrontIntegration,
  disconnectCloudFrontIntegration,
  isConnecting,
  isDeleting,
  incorrectETLDPlusOneRequests,
  useInvalidEtldPlusOneWithOriginQuery,
  hasMismatchedDomainsError,
}: IntegrationCloudFrontContentsProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const closeDialog = useCallback(() => setIsDialogOpen(false), [])
  const openDialog = useCallback(() => setIsDialogOpen(true), [])

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [isRemoving, setIsRemoving] = useState(false)
  const closeRemovalModal = useCallback(() => {
    setIsRemoving(false)
  }, [])

  const repositoryVersion = health?.latestVersion
  const lastCustomerVersionFromUsage = health?.versions.sort((a, b) => {
    return SemanticVersion.compare(SemanticVersion.fromString(b.version), SemanticVersion.fromString(a.version))
  })[0]?.version

  const status = extractStatusFromFromCloudFrontData(cloudFrontData)
  const statusLabel = extractStatusLabelFromStatus(status)

  const chartVisible =
    requestsMade &&
    requestsMade.proxyRequests?.length > 0 &&
    requestsMade.proxyRequests.map((it) => it.value).reduce((sum, curr) => sum + curr, 0) > 0

  const lastXDays = Math.min(requestsMade?.allRequests?.length ?? 0, 30)

  const versionDiff = SemanticVersion.getDifference(
    SemanticVersion.fromString(lastCustomerVersionFromUsage ?? ''),
    SemanticVersion.fromString(repositoryVersion ?? '')
  )

  let alertTitle = ''
  switch (versionDiff) {
    case 'major':
      alertTitle = 'Update required'
      break
    case 'minor':
      alertTitle = 'Update needed'
      break
    case 'patch':
      alertTitle = 'Update recommended'
      break
  }

  const alerts = useMemo((): ReactNode[] => {
    const result: ReactNode[] = []

    // If user has made requests with integration, but hasn't enabled updates, show a warning to enable updates
    if (chartVisible && status === IntegrationStatus.UpdatesDisabled) {
      result.push(
        <Alert key='update' severity='warning' icon={<ErrorOutline />}>
          {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
          <div>
            <Typography variant='bodyM' className={styles.updateAlertText}>
              Automatic updates are not enabled. We recommend enabling updates for your CloudFront integration to ensure
              it remains up to date. Outdated integrations will lower Fingerprint accuracy.
            </Typography>
          </div>
        </Alert>
      )
    }

    /*
     * If the user enabled updates but the versions are different, show a warning
     * to explain the update is coming soon
     */
    if (status === IntegrationStatus.UpdatedEnabled && versionDiff !== 'same') {
      result.push(
        <Alert key='update' severity='warning' icon={<ErrorOutline />}>
          <div>
            <Typography variant='bodyM' className={styles.updateAlertText}>
              A new version of the CloudFront integration is available and gradually being rolled out. Your integration
              will be updated automatically in the next few days. If you have any questions, please get in touch with{' '}
              <Link href={SUPPORT_PAGE_URL} style={{ textDecoration: 'none' }}>
                our support team
              </Link>
              .
            </Typography>
          </div>
        </Alert>
      )
    }

    return result
  }, [alertTitle, chartVisible, status, versionDiff])
  const sections: ReactNode[] = useMemo(() => {
    const result: ReactNode[] = [
      <MismatchedDomainsSection
        hasMismatchedDomainsError={hasMismatchedDomainsError}
        useInvalidEtldPlusOneWithOriginQuery={useInvalidEtldPlusOneWithOriginQuery}
        integration={ThirdPartyIntegration.CloudFront}
        key='mismatched-domains'
        incorrectETLDPlusOneRequests={incorrectETLDPlusOneRequests}
      />,
    ]

    if (status === IntegrationStatus.UpdatesDisabled) {
      if (chartVisible) {
        result.push(
          <IntegrationPageSection key='usage' title='AWS CloudFront Usage' info={`Last ${lastXDays} Days`}>
            <ProxyIntegrationUsageChart requests={requestsMade} integrationName='CloudFront' />
          </IntegrationPageSection>
        )
      } else {
        result.push(
          <IntegrationPageSection key='overview'>
            <div className={styles.notInstalledContainer}>
              <Typography variant='body1'>
                Fingerprint AWS CloudFront Proxy Integration is responsible for proxying device intelligence and
                agent-download requests between your website and Fingerprint through your AWS infrastructure. This
                improves both effectiveness and accuracy of visitor identification.
              </Typography>
              <Typography variant='body1' className={styles.notInstalledParagraphSupport}>
                The best way to get started is with our dedicated CloudFront Proxy Integration guide. If you have any
                questions while setting it up, please{' '}
                <Link href={SUPPORT_EMAIL_MAILTO} target='_blank'>
                  contact support
                </Link>
                .
              </Typography>
              <LinkButton
                className={clsx([styles.docsLink, styles.notInstalledButton])}
                href={DOCS_INTEGRATIONS.cloudFront}
                target='_blank'
              >
                View Documentation
              </LinkButton>
            </div>
          </IntegrationPageSection>
        )
      }
    } else {
      if (chartVisible) {
        result.push(
          <IntegrationPageSection key='usage' title='AWS CloudFront Usage' info='Last 30 Days'>
            <ProxyIntegrationUsageChart requests={requestsMade} integrationName='CloudFront' />
          </IntegrationPageSection>
        )
      } else {
        result.push(
          <IntegrationPageSection key='usage' title='AWS CloudFront Usage'>
            <IntegrationNoUsageNotice docsLink={DOCS_INTEGRATIONS.cloudFront} />
          </IntegrationPageSection>
        )
      }

      result.push(
        <IntegrationPageSection
          title='Configuration'
          key='configuration'
          description='Below is your AWS CloudFront integration configuration.'
          actions={
            <Button fullWidth={smDown} onPress={openDialog} variant='secondary'>
              <Pencil />
              Edit
            </Button>
          }
        >
          <IntegrationKeyValueList
            className={clsx(styles.list)}
            itemClassName={clsx(styles.item)}
            items={[
              {
                key: 'Management Lambda URL',
                value: cloudFrontData?.managementLambdaPublicURL,
                info: 'URL of the Management Lambda function you have deployed when installing the integration. The function is responsible for keeping your integration up to date.',
              },
              {
                key: 'Auth token',
                value: cloudFrontData?.lastDeploymentError || '••••••••••••••••••••••••••••••••••',
                info: 'Authentication token for your Management Lambda function.',
              },
            ]}
          />
        </IntegrationPageSection>
      )

      result.push(
        <IntegrationPageSection key='danger' title='Danger zone' isDanger>
          <Button
            className={styles.disconnectButton}
            onPress={() => {
              setIsRemoving(true)
            }}
            variant='destructive'
          >
            Disable automatic updates
          </Button>
        </IntegrationPageSection>
      )
    }

    return result
  }, [
    hasMismatchedDomainsError,
    useInvalidEtldPlusOneWithOriginQuery,
    chartVisible,
    cloudFrontData,
    incorrectETLDPlusOneRequests,
    lastXDays,
    openDialog,
    requestsMade,
    smDown,
    status,
  ])

  return (
    <>
      <IntegrationPage
        alerts={alerts}
        isLoading={isLoading}
        integrationStatus={status}
        title='AWS CloudFront'
        subscriptionId={subscriptionId}
        statusLabel={statusLabel}
        connectButton={
          <>
            <Button variant={chartVisible ? 'primary' : 'secondary'} onPress={openDialog}>
              Enable updates
            </Button>
          </>
        }
        docsUrl={DOCS_INTEGRATIONS.cloudFront}
        repoUrl='https://github.com/fingerprintjs/fingerprint-pro-cloudfront-integration'
        Logo={Logo}
        metadata={[
          {
            title: 'Your version',
            value: lastCustomerVersionFromUsage,
            versionBadge: versionDiff !== 'same' ? versionDiff : undefined,
          },
          {
            title: 'Latest version',
            value: repositoryVersion,
          },
        ]}
        sections={sections}
      />
      {status !== IntegrationStatus.UpdatesDisabled ? (
        <Dialog isLoading={isDeleting} open={isRemoving} onClose={closeRemovalModal} scroll='body'>
          <DialogTitle onClose={closeRemovalModal} />
          <div className={styles.dialog}>
            <IntegrationRemovalDialog
              onClose={closeRemovalModal}
              title='Disable updates for CloudFront integration'
              onSubmit={disconnectCloudFrontIntegration}
              beforeInputContent={
                <>
                  <Alert severity='warning'>
                    Disabling updates will not remove the integration from your AWS account. To fully remove it, take
                    action in your AWS account.
                  </Alert>
                  <Typography>
                    To maintain Fingerprint accuracy, it is recommended to keep automatic updates enabled. Disabling
                    updates will not stop the integration from proxying requests, but over time it can lead to lower
                    accuracy or even service disruption.
                  </Typography>
                </>
              }
            />
          </div>
        </Dialog>
      ) : null}
      <Dialog isLoading={isConnecting} open={isDialogOpen} onClose={closeDialog} scroll='body'>
        <DialogTitle onClose={closeDialog} />
        <div className={styles.dialog}>
          <IntegrationCloudFrontConnectModal
            integration={cloudFrontData}
            onClose={closeDialog}
            mode={status === IntegrationStatus.UpdatesDisabled ? 'enableUpdates' : 'manage'}
            onSubmit={async (managementLambdaURL, authToken) =>
              connectCloudFrontIntegration(managementLambdaURL, authToken)
            }
          />
        </div>
      </Dialog>
    </>
  )
}
