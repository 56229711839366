import { useQuery, useQueryClient } from '@tanstack/react-query'
import { PaymentMethod } from 'models'

import { extractData, useBuildRequest, useGenericErrorHandler, useRequestMutation } from './base'

const PAYMENT_METHODS_QUERY_KEY = ['payment_methods'] as const

export function usePaymentMethods() {
  const buildRequest = useBuildRequest()
  const { withErrorHandling } = useGenericErrorHandler()
  return useQuery({
    queryKey: PAYMENT_METHODS_QUERY_KEY,
    queryFn: () => extractData(withErrorHandling(buildRequest('paymentMethods'))),
  })
}

export function usePaymentMethodCreateMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('paymentMethodCreate', {
    onSuccess: (payload) => {
      const currentValues = queryClient.getQueryData<PaymentMethod[]>(PAYMENT_METHODS_QUERY_KEY) ?? []
      const newCache = [...currentValues, payload]

      queryClient.setQueryData<PaymentMethod[]>(PAYMENT_METHODS_QUERY_KEY, newCache)
    },
  })
}

export function usePaymentMethodMakeDefaultMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('paymentMethodMakeDefault', {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PAYMENT_METHODS_QUERY_KEY })
    },
  })
}

export function usePaymentMethodDeleteMutation() {
  const queryClient = useQueryClient()

  return useRequestMutation('paymentMethodDelete', {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PAYMENT_METHODS_QUERY_KEY })
    },
    errorHandling: {
      forceToast: true,
    },
  })
}
