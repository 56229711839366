import { Stack } from '@compass/components'
import { Skeleton, Typography } from '@mui/material'
import { AppRoute } from 'appRoutes'
import {
  interpretDateRange,
  PersistentDateRangePicker,
  useDateRangeContext,
  useDefaultPredefinedRanges,
} from 'features/commonUI'
import { useCurrentSubscription } from 'features/subscription'
import * as hooks from 'hooks'
import { useCurrentUser } from 'hooks/api/users'

import { DistributionCharts } from '../Distributions/DistributionCharts'
import { NumericWidgets } from '../WorkspaceInsights/NumericWidgets'
import { InsightsChart } from './charts/InsightsChart'
import styles from './SubscriptionOverview.module.scss'

export function Insights() {
  const { currentSubscriptionId: subscriptionId } = useCurrentSubscription()
  const { data: subscription } = hooks.useSubscription(subscriptionId)
  const { data: currentUser } = useCurrentUser()

  return (
    <div className={styles.insights}>
      <InsightsHeader timezone={currentUser?.timezone} subscriptionId={subscriptionId} />
      <NumericWidgets subscriptionId={subscription?.id} />
      <InsightsChart />
      <DistributionWidgets />
    </div>
  )
}

function InsightsHeader({ timezone, subscriptionId }: { timezone?: string; subscriptionId: string }) {
  const { setDateRange, today } = useDateRangeContext()
  const { ranges } = useDefaultPredefinedRanges()

  return (
    <Stack
      direction={{ default: 'column', sm: 'row' }}
      gapX={10}
      gapY={4}
      className='flex-wrap justify-between lg:items-start'
    >
      <Typography variant='h2'>Insights</Typography>

      {today && setDateRange && timezone ? (
        <PersistentDateRangePicker
          defaultDateRange={ranges.last_7_days}
          route={AppRoute.SubscriptionOverview}
          routeParams={{ subscriptionId }}
          rangeInterpreter={(dateRange) => interpretDateRange({ dateRange })}
          timezone={timezone}
        />
      ) : (
        <Skeleton width={200} />
      )}
    </Stack>
  )
}

function DistributionWidgets() {
  return (
    <div className={styles.distributionWidgets}>
      <DistributionCharts />
    </div>
  )
}
